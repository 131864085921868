@import "../_variables.scss";
@import "../_mixin.scss";


/* box-border-bottom
---------------------------------------------------------- */
.box-border-bottom {
  padding-bottom: 40px;
  border-bottom: 1px solid #e0e0e0;
  @include mq-pc {
    padding-bottom: 80px;
  }
}
