@import "../_variables.scss";
@import "../_mixin.scss";

/* card-customer
---------------------------------------------------------- */
.card-customer {
  padding: 30px 25px;

  @include mq-pc {
    padding: 50px;
  }

  .c-ttl-02 {
    .text {
      font-size: 2rem;

      @include mq-pc {
        font-size: 2.4rem;
      }
    }
  }
}

/* list-card-customer
---------------------------------------------------------- */
.list-card-customer {
  .card-customer {
    height: 100%;
  }
}
