@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 accordion
========================================================== */
/* js-accordion
---------------------------------------------------------- */
.js-accordion {
  @include mq-pc {
    display: block;
    
    .issue {
      display: none;
    }
  }

  @include mq-sp {
    &.-sp {
      .subject{
        position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background: url(/assets/images/common/ico_acc_01.png) no-repeat;
        background-size: 100%;
        width: 21px;
        height: 21px;
      }
      &.-on::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background: url(/assets/images/common/ico_acc_02.png) no-repeat;
        background-size: 100%;
        width: 21px;
        height: 21px;
      }
    }

      .issue {
        display: none;
        margin-top: 15px;
      }
    }
  }
}

.js-accordion-sub {
  + .js-accordion-sub {
    margin-top: 1px;
  }

  .issue-sub {
    display: none;
  }
}