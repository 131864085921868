@import '../_variables.scss';
@import '../_mixin.scss';

@include mq-pc {
  .only-sp {
    display: none !important;
  }
}
@include mq-sp {
  .only-pc {
    display: none !important;
  }
}