@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 section
========================================================== */
/* c-sec
---------------------------------------------------------- */
.c-sec {
  margin-bottom: 40px;
  @include mq-pc{
    margin-bottom: 80px;
  }
  &:last-child{
    margin-bottom: 0;
  }
}

/* c-sec-part
---------------------------------------------------------- */
.c-sec-part {
  margin-bottom: 55px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    margin-bottom: 90px;
  }
}