@import "../_variables.scss";
@import "../_mixin.scss";

/* gas-equation
---------------------------------------------------------- */
.gas-equation {
  display: flex;
  font-size: 26px;
  justify-content: center;
  margin: 0 -28px;
  @include mq-sp{
    margin: 0;
    flex-direction: column;
    font-size: 16px;
  }
  >div{
    padding: 17px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
    margin: 0 28px;
      @include mq-sp{
      margin: 0 0 40px 0;
      padding: 25px;
        }
    &.result{
      background: $color-orange;
      width: 280px;
      position: relative;
      &::after{
        content: '';
        background: url(/assets/images/common/ico_equa_02.png) no-repeat center;
        background-size: 100%;
        position: absolute;
      }
      @include mq-pc{
        &::after{
          top: calc(50% - 13px) ;
          right: -24px;
          width: 16px;
          height: 26px;
          transform: rotate(90deg) translateY(-50%);
        }
      }
      @include mq-sp{
        width: 100%;
        &::after{
          bottom: -30px;
          width: 13px;
          height: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &.var1{
      background: #888;
      width: 280px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        background: url(/assets/images/common/ico_equa_01.png) no-repeat center;
        background-size: 100%;
      }
      @include mq-pc{
        &::after{
        top: 50%;
        right: -41px;
        width: 26px;
        height: 26px;
        transform: translateY(-50%);
        }
      }
      @include mq-sp{
        width: 100%;
        &::after{
          bottom: -30px;
          width: 20px;
          height: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &.var2{
      background: $color-blue;
      width: 397px;
      flex-direction: column;
      @include mq-sp{
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
  span {
    font-size: 13px;
    display: block;
    @include mq-pc{
    font-size: 20px;
  }

  }
}