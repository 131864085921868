@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Modal
========================================================== */
/* c-modal
---------------------------------------------------------- */
.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);
  visibility: hidden;
  opacity: 0;
  z-index: 0;
  transition: all .2s;

  &.-show {
    visibility: visible;
    opacity: 1;
    z-index: 100;
  }

  .modal-wrap {
    position: relative;
    z-index: 1;
    padding-top: 80px;
    padding-bottom: 35px;

    @include mq-pc {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  .modal-cont {
    position: relative;
    background-color: #fff;

    .btn-close {
      position: absolute;
      top: -24px;
      right: -24px;
      width: 48px;
      height: 48px;
      background: url('/assets/images/common/btn_close_modal.png') 0 0 no-repeat;
      background-size: 100% auto;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .3);
        border-radius: 50%;
        opacity: 0;
        transition: opacity .2s;
      }

      &:hover:before {
        opacity: 1;
      }

      @include mq-mx(1230) {
        top: -20px;
        right: -10px;
      }
    }

    .inner {
      padding: 40px 15px;

      @include mq-pc {
        padding: 80px 12.8% 100px;
      }
    }
  }

  @include mq-pc{

  }
}
