@import "../_variables.scss";
@import "../_mixin.scss";


/* img-payment-01
---------------------------------------------------------- */
.img-payment-01 {
  max-width: 748px;
  margin: 0 auto 32px;
  figcaption {
    color: #ff0000;
    font-size: 1.2rem;
    font-weight: bold;
  }
  img {
    max-width: 100%;
  }
}
