@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 link
========================================================== */
/* c-link-basic
---------------------------------------------------------- */
.c-link-basic {
  @include is-opacity();
}

/* c-link-arrow
---------------------------------------------------------- */
.c-link-arrow {
font-weight: bold;
color: $color-black;

  &:after {
    content: "";
    position: relative;
    top: 2px;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    background: url('/assets/images/common/icon_arrow_link_blue.png') 0 0 no-repeat;
    background-size: 100% auto;
    @include mq-pc {
      margin-left: 10px;
    }
  }
}

a.c-link-arrow {
  @include is-opacity;
}

/* c-link-pdf
---------------------------------------------------------- */
.c-link-pdf {
  @include is-opacity;

  &:after {
    content: "";
    position: relative;
    top: 2px;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    background: url('/assets/images/common/icon_pdf_blue.png') 0 0 no-repeat;
    background-size: 100% auto;
    @include mq-pc {
      width: 20px;
      height: 20px;
      top: 3px;
    }
  }
}

/* c-link-blank
---------------------------------------------------------- */
.c-link-blank {
  @include is-opacity;
  
  &:after {
    content: "";
    position: relative;
    top: -1px;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    background: url('/assets/images/common/icon_new_window_blue.png') 0 0 no-repeat;
    background-size: 100% auto;
  }
  &.-style1 {
    &:after {
      background:url('/assets/images/common/icon_new_window_line_blue.png') 0 0 no-repeat;
      background-size: 100% auto;
    }
  }
}

/* c-link-underline
---------------------------------------------------------- */
.c-link-underline {
  color: $color-blue;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

/* c-link-underline
---------------------------------------------------------- */
.c-tel-number {
  position: relative;
  padding-left: 26px;
  font-size: 2.4rem;
  font-weight: bold;
  font-family: 'Roboto Condensed';
  @include mq-pc {
    font-size: 3rem;
  }
  &:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 21px;
    background: url('/assets/images/common/ico_tel.png') 0 0 no-repeat;
    background-size: 16px 21px;
    top: 2px;
    left: 4px;
    @include mq-pc {
      top: 5px;
      left: 0;
      width: 20px;
      height: 26px;
      background: url('/assets/images/common/ico_tel.png') 0 0 no-repeat;
      background-size: 20px 26px;
    }
  }
  & + a {
    position: relative;
    padding-left: 30px;
    font-size: 2.4rem;
    font-weight: bold;
    font-family: 'Roboto Condensed';
    &:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 21px;
      background: url('/assets/images/common/ico_tel.png') 0 0 no-repeat;
      background-size: 16px 21px;
      top: 2px;
      left: 4px;
      @include mq-pc {
        width: 20px;
        height: 26px;
        background: url('/assets/images/common/ico_tel.png') 0 0 no-repeat;
        background-size: 20px 26px;
      }
    }
  }

  &.-large {
    font-size: 3.6rem;

    @include mq-pc {
      padding-left: 36px;
      font-size: 4.8rem;
    }
    &:before {
      width: 20px;
      height: 26px;
      background-size: 20px 26px;
      top: 8px;
      left: 0;
      @include mq-pc {
        top: 13px;
        width: 26px;
        height: 32px;
        background-size: 26px 32px;
      }
    }

    & + a {
      font-size: 3.6rem;

      @include mq-pc {
        font-size: 4.8rem;
      }
      &:before {
        width: 20px;
        height: 26px;
        background-size: 20px 26px;
        top: 8px;
        left: 0;
        @include mq-pc {
          top: 5px;
          width: 26px;
          height: 32px;
          background-size: 26px 32px;
        }
      }
    }
    
  }
}
