@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 header
========================================================== */
.header {
  @include mq-pc {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background-color: $color-navi;
  }

  > .container {
    position: relative;
  }

  .header-main {
    height: 80px;

    @include mq-sp {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      height: 50px;
      background-color: $color-navi;
      display: flex;
      justify-content: space-between;
    }

    .logo {
      @include mq-pc {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;
      }
  
      @include mq-mn(1170) {
        left: 30px;
      }

      @include mq-mx(1169) {
        left: 15px;
      }

      @include mq-sp {
        position: relative;
      }
  
      a {
        display: inline-block;
        line-height: 1;
        width: 172px;

        @include mq-mx(1000) {
          width: 120px;
        }
  
        @include mq-sp {
          display: flex;
          align-items: center;
          height: 50px;
        }

        img {
          width: 100%;
        }
      }
    }

    .btn-his {
      display: flex;
      align-items: center;
      padding: 0 15px;
    }

    .btn-contact {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      background-color: $color-orange;
    }

    .btn-menu {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
  
      @include mq-pc {
        display: none;
      }
  
      .line {
        position: relative;
        width: 20px;
        height: 1px;
        background-color: #fff;
        margin-bottom: 6px;
        transform-origin: left center;
        transition: transform .2s, left .2s;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
  
      //-active
      &.-active {
        .line {
          &:nth-child(1) {
            transform: rotate(45deg);
            left: 3px;
          }
    
          &:nth-child(2) {
            opacity: 0;
          }
    
          &:nth-child(3) {
            transform: rotate(-45deg);
            left: 3px;
          }
        }
      }
    }
  }
}