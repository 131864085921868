@import '../_variables.scss';
@import '../_mixin.scss';

$spaceamounts: (0, 1, 5, 8, 10, 12, 13, 14, 15, 16, 17, 20, 22, 25, 28, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 115,120, 150, 305); // Adjust this to include the pixel amounts you need.

$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .mx-#{$space} {
      margin-left : #{$space}px !important;
      margin-right : #{$space}px !important;
    }
    .my-#{$space} {
      margin-top : #{$space}px !important;
      margin-bottom : #{$space}px !important;
    }
    .ma-#{$space} {
      margin : #{$space}px !important;
    }
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
    .px-#{$space} {
      padding-left : #{$space}px !important;
      padding-right : #{$space}px !important;
    }
    .py-#{$space} {
      padding-top : #{$space}px !important;
      padding-bottom : #{$space}px !important;
    }
    .pa-#{$space} {
      padding : #{$space}px !important;
    }
  }
}

@include mq-pc {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-pc-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }
      .mx-pc-#{$space} {
        margin-left : #{$space}px !important;
        margin-right : #{$space}px !important;
      }
      .my-pc-#{$space} {
        margin-top : #{$space}px !important;
        margin-bottom : #{$space}px !important;
      }
      .ma-pc-#{$space} {
        margin : #{$space}px !important;
      }
      .p#{str-slice($side, 0, 1)}-pc-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
      .px-pc-#{$space} {
        padding-left : #{$space}px !important;
        padding-right : #{$space}px !important;
      }
      .py-pc-#{$space} {
        padding-top : #{$space}px !important;
        padding-bottom : #{$space}px !important;
      }
      .pa-pc-#{$space} {
        padding : #{$space}px !important;
      }
    }
  }
}

@include mq-sp {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-sp-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }
      .ma-sp-#{$space} {
        margin : #{$space}px !important;
      }
      .mx-sp-#{$space} {
        margin-left : #{$space}px !important;
        margin-right : #{$space}px !important;
      }
      .my-sp-#{$space} {
        margin-top : #{$space}px !important;
        margin-bottom : #{$space}px !important;
      }
      .p#{str-slice($side, 0, 1)}-sp-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
      .px-sp-#{$space} {
        padding-left : #{$space}px !important;
        padding-right : #{$space}px !important;
      }
      .py-sp-#{$space} {
        padding-top : #{$space}px !important;
        padding-bottom : #{$space}px !important;
      }
      .pa-sp-#{$space} {
        padding : #{$space}px !important;
      }
    }
  }
}

.ma-0 {
  margin: 0 auto !important;
}

@include mq-pc {
  .ma-pc-0 {
    margin: 0 auto !important;
  }
}

@include mq-sp {
  .ma-sp-0 {
    margin: 0 auto !important;
  }
}