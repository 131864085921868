@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Tab
========================================================== */
/* c-tabs-wrap
---------------------------------------------------------- */
.c-tabs-wrap {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 30px;
  }
}

/* c-tabs-nav
---------------------------------------------------------- */
.c-tabs-nav {
  margin-bottom: 20px;

  @include mq-sp {
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 15px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 4px;
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #a0a0a0;
    }

    .c-tabs {
      margin-bottom: 15px;
    }
  }
  .c-tabs {
    max-width: 1170px;
    &.-nest {
      margin-bottom: 65px;

      @include mq-sp {
        margin-bottom: 15px;
      }
    }
  }
}

/* c-tabs
---------------------------------------------------------- */
.c-tabs {
  display: flex;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 70px;
  //justify-content:center;
  >li {
    align-items: baseline;
    width: 10%;
    flex: 1 1 auto;
    height: 100%;
    border-right: 1px solid #ecedef;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #fff;
    color: $color-blue;

    @include mq-sp {
      flex: 0 0 95px;
    }

    .label {
      font-size: 1.8rem;
      line-height: 1.4;
      font-weight: 700;
      text-align: center;
      padding: 15px;
      cursor: pointer;
      // @include is-opacity;

      @include mq-sp {
        font-size: 1.4rem;
      }
    }

    &:last-child {
      border-right: none;
    }

    &.-active {
      background: $color-blue;
      color: #fff;
    }

    &.-disable {
      color: rgba($color: $color-blue, $alpha: 0.3);
      cursor: not-allowed;

      &:hover {
        opacity: 1;
      }
    }

    .notice {
      font-size: 1.2rem;
      display: block;
    }
  }

  &.-nest {
    .tab-nest {
      background: $color-blue;
      width: 100%;
      position: absolute;
      top: 70px;
      left: 0;
      display: flex;
      padding: 10px;
      visibility: hidden;

      > .item {
        //width: 33.3%;
        flex-grow: 1;
        padding: 10px;
        border: 1px solid rgba($color: #fff, $alpha: 0.3);
        border-radius: 4px;
        margin: 0 5px;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.5;
        color: #fff;
        cursor: pointer;
        @include is-opacity;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &.-active {
          border: 1px solid #fff;
          background: #fff;
          color: $color-blue;
          cursor: default;

          &:hover {
            opacity: 1;
          }
        }
      }

      @include mq-sp {
        display: none;
      }
    }
  }
}

/* c-tabs-contents
---------------------------------------------------------- */
.c-tabs-contents {
  &.-nest{
    .tab-content {

      /*>div {
        display: none;
        &:first-child{
          display: block;
        }
      }*/
    }

  }
}

/* tab-nest-select
---------------------------------------------------------- */
.tab-nest-select {
  position: relative;
  @include mq-pc {
    display: none;
  }

  @include mq-sp {
    display: block;
    margin-bottom: 35px;
    padding: 17px 50px 17px 20px;
    border: 2px solid #e0e0e0;
    border-radius: 5px;
    background-color: #fff;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      width: 11px;
      height: 7px;
      background: url('/assets/images/common/icon_arrow_selectbox.png') 0 0 no-repeat;
      background-size: 100% auto;
    }

    .txt-selected {
      font-size: 1.4rem;
      line-height: 1;
    }

    select {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 0;
      appearance: none;
      opacity: 0;
    }
  }
}

/* ==========================================================
 Tab JS
========================================================== */
.js-tab-module {
  .js-tab-content {
    display: none;
  }
}