@import '../_variables.scss';
@import '../_mixin.scss';

/* c-grid
---------------------------------------------------------- */
/*
  Exam
  <div class="row pd-32 pd-sp-0">
    <div class="col-pc-6 col-sp-12">
      test
    </div>
    <div class="col-pc-6 col-sp-12">
      test
    </div>
    <div class="col-pc-6 col-sp-12">
      test
    </div>
  </div>
  **pd-32 -> .row { margin-left: -32px; margin-right: -32px;}
          -> .row [class^="col"] { padding-left: 32px; padding-right: 32px;}
*/
.c-grid {
  .row {
    display: flex;
    flex-wrap: wrap;

    @for $pd from 0 through 60 {
      &.pd-#{$pd} {
        margin-left: -($pd) + px;
        margin-right: -($pd) + px;
        margin-top: -($pd * 2) + px;
        & > [class^="col"] {
          padding-left: $pd + px;
          padding-right: $pd + px;
          margin-top: $pd * 2 + px;
        }
      }
    }
    @include mq-pc() {
      @for $pd from 0 through 60 {
        &.pd-pc-#{$pd} {
          margin-left: -($pd) + px;
          margin-right: -($pd) + px;
          margin-top: -($pd * 2) + px;
          & > [class^="col"] {
            padding-left: $pd + px;
            padding-right: $pd + px;
            margin-top: $pd * 2 + px;
          }
        }
      }
    }
    @include mq-sp() {
      @for $pd from 0 through 60 {
        &.pd-sp-#{$pd} {
          margin-left: -($pd) + px;
          margin-right: -($pd) + px;
          margin-top: -($pd * 2) + px;
          & > [class^="col"] {
            padding-left: $pd + px;
            padding-right: $pd + px;
            margin-top: $pd * 2 + px;
          }
        }
      }
    }
  }

  .col {
    width: 100%;
  }

/* c-grid col-i
---------------------------------------------------------- */
  $i: 1;
  $col-count: 12 !default;
  $col-width: 100% / $col-count;

  @function col($columns){
    @return $col-width * $columns;
  }
  @for $i from $col-count through 1 {
    .col-#{$i} {width: $col-width * $i;}
  }

  @include mq-pc {
    @for $i from $col-count through 1 {
      .col-pc-#{$i} {width: $col-width * $i;}
    }
  }

  @include mq-sp {
    @for $i from $col-count through 1 {
      .col-sp-#{$i} {width: $col-width * $i;}
    }
  }
}
