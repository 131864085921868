@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Image
========================================================== */
/* c-image
---------------------------------------------------------- */
.c-image {
  img {
    max-width: 100%;

    &.full-width {
      width: 100%;
    }
  }

  // -center
  &.-center {
    text-align: center;
  }
}

/* c-mainimg
---------------------------------------------------------- */
.c-mainimg {
  img {
    width: 100%;
  }

  @include mq-mx(1170) {
    padding-right: 0;
    padding-left: 0;
    margin-left: -15px;
    margin-right: -15px;

    &.-center {
      text-align: center;
    }
  }
}