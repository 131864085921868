@import "../_variables.scss";
@import "../_mixin.scss";

.mainvisual-about {
  position: relative;
  visibility: hidden;
  height: 500px;

  .list-slider {
    overflow: hidden;

    .item {
      .image {
        transition: transform 25s;

        &.-animate {
          transform: scale(1.5, 1.5);
        }

        img {
          width: 100%;
        }
      }
    }

    .slick-slide {
      transition: opacity 1000ms ease 0s !important;
    }
  }

  .box-text {
    @include mq-pc {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 1230px;
      padding: 0 30px;
      color: #fff;
    }

    @include mq-mx(1230) {
      width: 100%;
      padding: 0 15px;
    }

    @include mq-sp {
      padding: 15px;
      background-color: #c6c8cd;
      color: $color-black;
    }

    .inner {
      opacity: 0;
      visibility: hidden;
      transition: all 1s;

      &.-show {
        opacity: 1;
        visibility: visible;
      }
      @include mq-sp {
        padding: 30px 20px;
        background-color: #fff;
      }
    }

    .title {
      margin-bottom: 15px;
      font-weight: bold;
      font-size: 2.3rem;
      line-height: 1.5;

      @include mq-pc {
        margin-bottom: 25px;
        font-size: 4.2rem;
      }
    }

    .text {
      font-size: 1.4rem;
      line-height: 1.78;

      @include mq-pc {
        font-size: 1.6rem;
        line-height: 2.25;
      }
    }
  }
}

.contents {
  &.-about {
    .container {
      max-width: 100%;
      padding-right: 0;
      padding-left: 0;
    }
  }
}
.sec-about-in {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 0 15px;
  @include mq-pc {
    max-width: 1170px;
    padding: 0;
  }
}

.ttl-about-01 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  line-height: 1.4;
  font-weight: bold;
  @include mq-pc {
    margin-bottom: 35px;
    font-size: 3.2rem;
  }
  &.-white {
    color: $color-white;
  }
  &.-letter1 {
    letter-spacing: 0.08em;
  }
}

.txt-about-01 {
  margin-bottom: 15px;
  display: inline-block;
  border-top: 1px solid #385199;
  border-bottom: 1px solid #385199;
  color: #385199;
  font-weight: bold;
  padding: 5px 10px;
  @include mq-pc {
    margin-bottom: 28px;
    padding: 7px 15px;
  }
  &.-white {
    color: $color-white;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
}
.txt-about-02 {
  color: $color-white;
  &.-letter1 {
    letter-spacing: 0.12em;
  }
}

.sec-about-01 {
  padding: 60px 0 62px;
  @include mq-pc {
    padding: 100px 0 90px;
  }
}
.sec-about-02 {
  position: relative;
  min-height: 100vh;
  padding: 60px 0 30px;

  @include mq-pc {
    padding: 105px 0 20px;
    background-image: url(/assets/images/about/bg_about_01_pc.jpg);
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }

  @include mq-sp {
    .bg-sp-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip: rect(0, auto, auto, 0);

      .bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/assets/images/about/bg_about_01_sp.jpg);
        background-size: cover;
        background-position: center;
        transform: translateZ(0);
        will-change: transform;
      }
    }
  }
}

.sec-about-03 {
  position: relative;
  padding: 60px 0 60px;
  min-height: 100vh;

  @include mq-pc {
    padding: 115px 0 100px;
    background-image: url(/assets/images/about/bg_about_02_pc.jpg);
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }

  @include mq-sp {
    .bg-sp-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip: rect(0, auto, auto, 0);

      .bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/assets/images/about/bg_about_02_sp.jpg);
        background-size: cover;
        background-position: center;
        transform: translateZ(0);
        will-change: transform;
      }
    }
  }

  .c-btn-group {
    width: 100%;
    padding: 0;
    @include mq-pc {
      width: 610px;
      
    }
    .c-btn {
      padding: 20px;
      @include mq-pc {
        padding: 15px;
      }
    }
  }
}
.sec-about-04 {
  position: relative;
  padding: 77px 0 66px;
  min-height: 100vh;
  @include mq-pc {
    padding: 100px 0 100px;
    background-image: url(/assets/images/about/bg_about_03_pc.jpg);
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }

  @include mq-sp {
    .bg-sp-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip: rect(0, auto, auto, 0);

      .bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/assets/images/about/bg_about_03_sp.jpg);
        background-size: cover;
        background-position: center;
        transform: translateZ(0);
        will-change: transform;
      }
    }
  }
  .c-btn-group {
    width: 100%;
    @include mq-pc {
      width: 610px;
    }
    .c-btn {
      padding: 20px;
      @include mq-pc {
        padding: 15px;
      }
    }
  }
}
.sec-about-05 {
  position: relative;
  padding: 67px 0 48px;
  min-height: 100vh;
  @include mq-pc {
    padding: 100px 0 75px;
    background-image: url(/assets/images/about/bg_about_04_pc.jpg);
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
  @include mq-sp {
    .bg-sp-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip: rect(0, auto, auto, 0);

      .bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/assets/images/about/bg_about_04_sp.jpg);
        background-size: cover;
        background-position: center;
        transform: translateZ(0);
        will-change: transform;
      }
    }
  }
}
.sec-about-06 {
  padding: 70px 0 192px;
  background: url(/assets/images/about/bg_about_05_sp.jpg) center top no-repeat;
  background-size: cover;
  @include mq-pc {
    padding: 100px 0 447px;
    background: url(/assets/images/about/bg_about_05_pc.jpg) center top no-repeat;
    background-size: cover;
  }
}
.tag-about-01 {
  display: inline-block;
  padding: 7px 15px;
  width: 135px;
  height: 45px;
  font-size: 2.1rem;
  font-weight: bold;
  line-height: 1.4;
  color: $color-white;
  text-align: center;
  border-radius: 40px;
  border: 1px solid #2157a7;
  margin: 0 0 0 -68px;
  background-color: #2157a7;
  @include mq-pc {
    padding: 12px 15px;
    width: 180px;
    height: 58px;
    font-size: 2.4rem;
    margin: 0 0 0 -90px;
  }
}



.list-about-01 {
  &>.item {
    padding: 45px 28px 30px;
    margin-bottom: 40px;
    background-color: $color-white;
    position: relative;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    @include mq-pc {
      margin-bottom: 60px;
      padding: 80px 80px 75px;
    }
    &.-style1 {
      .box-left-in {
        width: 50%;

        @include mq-sp {
          width: 100%;
        }
      }
    }
    .tag-about-01 {
      position: absolute;
      top: -28px;
      left: 50%;
      z-index: 4;
    }
    .c-box-dark {
      background: #edeff3;
      padding: 12px 16px;
      margin-bottom: 15px;
      @include mq-pc {
        padding: 25px 32px;
        margin-bottom: -20px;  
      }
    }
    .image {
      position: static;
      z-index: 3;
      @include mq-pc {
        position: absolute;
        z-index: 3;
      }
      &.-image1 {
        top: 12px;
        right: 110px;
        width: 233px;
        height: inherit;
        margin: 0 auto;
        @include mq-pc {
          width: 331px;
          height: 306px;
        }
      }
      &.-image2 {
        top: 72px;
        right: 67px;
        width: 260px;
        height: inherit;
        margin: 0 0 0 auto;
        position: relative;
        top: 0;
        right: -10px;
        @include mq-pc {
          width: 369px;
          height: 304px;
          margin: 0;
          position: absolute;
          top: 75px;
          right: 66px;
        }
        
      }
      &.-image3 {
        top: 0;
        right: 0;
        width: auto;
        margin: 0 -28px -30px;
        overflow: hidden;
        @include mq-pc {
          margin: 0;
          height: 293px;
          max-width: 50%;
        }
        img {
          width: 100%;
          @include mq-pc {
            width: inherit;
          }
        }
      }
    }
    .box-left-in {
      width: 100%;
      @include mq-pc {
        width: 535px;
      }
    }
    .box-dark-about {
      width: 152px;
      height: 95px;
      position: absolute;
      bottom: 133px;
      left: 28px;
      z-index: 2;
      background-color: #edeff3;
      border-radius: 10px;
      color: #385199;
      font-size: 1.4rem;
      line-height: 1.45;
      font-weight: bold;
      padding: 17px 20px;
      @include mq-pc {
        width: 215px;
        height: 135px;
        top: 96px;
        right: 273px;
        bottom: inherit;
        left: inherit;
        font-size: 2rem;
        padding: 26px 27px;
      }
    }
  }
}
.list-about-02{
  counter-reset: my-sec-counter;
  &>.item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 30px 0 30px 0;
    border-bottom: 1px solid #ebebeb;
    @include mq-pc {
      padding: 45px 0 40px 0;
    }
    &:last-child {
      border-bottom: none;
    }

    .title {
      width: 100%;
      font-size: 2rem;
      color: #385199;
      line-height: 1.3;
      padding-left: 30px;
      position: relative;
      margin-bottom: 10px;
      font-weight: bold;
      @include mq-pc {
        font-size: 2.4rem;
        width: 47%;
        padding-left: 50px;
        margin-bottom: 0;
      }
      &:before {
        counter-increment: my-sec-counter;
        content: counter(my-sec-counter);
        font-family: $font-roboto;
        position: absolute;
        top: 50%;
        left: 0;
        font-size: 4.5rem;
        font-style: italic;
        margin-top: -30px;
        @include mq-pc {
          font-size: 5.6rem;
          margin-top: -35px;
        }
      }
    }
    .details {
      width: 100%;
      @include mq-pc {
        width: 50%;
      }
    }
  }
}
.list-about-03 {
  .c-btn {
    min-width: 100%;
    height: 60px;
    padding: 20px;
    @include mq-pc {
      min-width: 310px;
      height: inherit;
      padding: 15px;
    }
  }
  &>.item {
    padding: 25px 25px 40px;
    margin-bottom: 15px;
    background-color: $color-white;
    position: relative;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    @include mq-pc {
      margin-bottom: 30px;
      padding: 70px 80px 30px;
    }
    .title {
      font-size: 2.2rem;
      @include mq-pc {
        font-size: 2.8rem;
      }
    }
    .title1 {
      font-size: 2.2rem;
      @include mq-pc {
        font-size: 2.8rem;
      }
    }
  }
}
.list-about-04 {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  flex-wrap: wrap;
  @include mq-pc {
    margin-bottom: 35px;
  }
  &>.item {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    @include mq-pc {
      margin-bottom: 10px;
      width: 225px;
      margin: 0 30px;
      display: block;
    }
    .icon {
      flex-shrink: 0;
      width: 112px;
      @include mq-pc {
        width: inherit;
        margin-bottom: 16px;
      }
    }
    .text {
      line-height: 1.4;
      margin-left: 20px;
      text-align: left;
      @include mq-pc {
        width: inherit;
        margin-left: 0;
        text-align: center; 
      }
    }
  }
}
.box-about-01 {
  margin-bottom: 40px;
  position: relative;
  background-color: $color-white;
  padding: 25px 25px 0;
  @include mq-pc {
    margin-bottom: 60px;
    padding: 25px 80px;
  }
  &>.title {
    position: absolute;
    top: -16px;
    left: 47%;
    display: inline-block;
    padding: 4px 15px;
    width: 206px;
    height: 34px;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.4;
    color: $color-white;
    text-align: center;
    border-radius: 40px;
    margin: 0 0 0 -90px;
    background-color: #000;
    @include mq-pc {
      padding: 12px 15px;
      top: -25px;
      width: 242px;
      height: 50px;
    }
  }
}
.card-about-01 {
  background-color: $color-white;
  padding: 25px;
  height: 367px;
  @include mq-pc {
    height: inherit;
    padding: 40px;
  }
  &.-blackground1 {
    background: $color-white url(/assets/images/about/bg_list_about_01_sp.jpg) center bottom no-repeat;
    background-size: 100% auto;
    @include mq-pc {
      background: $color-white url(/assets/images/about/bg_list_about_01_pc.jpg) 0 0 no-repeat;
      background-size: auto auto;
    }
  }
  &.-blackground2 {
    background: $color-white url(/assets/images/about/bg_list_about_02_sp.jpg) center bottom no-repeat;
    background-size: 100% auto;
    height: 411px;
    @include mq-pc {
      background: $color-white url(/assets/images/about/bg_list_about_02_pc.jpg) 0 0 no-repeat;
      background-size: auto auto;
      height: inherit;
    }
  }
  &.-blackground3 {
    background: $color-white url(/assets/images/about/bg_list_about_03_sp.jpg) center 88% no-repeat;
    background-size: 121px auto;
    height: 368px;
    @include mq-pc {
      height: 417px;
      background: $color-white url(/assets/images/about/bg_list_about_03_pc.jpg) center 88% no-repeat;
      background-size: inherit;
    }
  }
  &.-blackground4 {
    background: $color-white url(/assets/images/about/bg_list_about_04_sp.jpg) center bottom no-repeat;
    height: 367px;
    background-size: 100% auto;
    @include mq-pc {
      background: $color-white url(/assets/images/about/bg_list_about_04_pc.jpg) center bottom no-repeat;
      height: 417px;
    }
  }
  &.-blackground5 {
    background: $color-white url(/assets/images/about/bg_list_about_05_sp.jpg) center bottom no-repeat;
    height: 367px;
    background-size: 100% auto;
    @include mq-pc {
      height: 417px;
      background: $color-white url(/assets/images/about/bg_list_about_05_pc.jpg) center bottom no-repeat;
    }
  }
  .title {
    margin-bottom: 7px;
    font-size: 2.2rem;
    font-weight: bold;
    @include mq-pc {
      margin-bottom: 12px;
      font-size: 2.8rem;
    }
  }
  .text {
    letter-spacing: 0.06em;
    margin-bottom: 10px;
    @include mq-pc {
      margin-bottom: 0;
    }
  }
}
