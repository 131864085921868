@import "../_variables.scss";
@import "../_mixin.scss";

/* text-price
---------------------------------------------------------- */
.txt-price {
  display: inline-block;
  position: relative;
  font-size: 2rem;
  font-weight: 700;
  color: $color-black;
  line-height: 1;

  @include mq-pc {
    font-size: 2.6rem;
  }

  .number {
    font-size: 6rem;

    @include mq-pc {
      font-size: 8rem;
    }
  }

  .txt-note {
    font-size: 1.2rem;
    text-align: right;
    position: absolute;
    right: 15px;
    bottom: -15px;
  }

  &-wrap {
    text-align: center;
  }
}

/* box-okaimono-about
---------------------------------------------------------- */
.box-okaimono-about {
  background-color: #f6f7f9;
  padding: 35px 25px;
  margin-top: 45px;

  @include mq-pc {
    padding: 70px 80px 65px;
  }

  .title {
    font-size: 1.8rem;
    font-weight: 700;
    color: $color-blue;
    margin-bottom: 12px;

    @include mq-pc {
      font-size: 2rem;
    }
  }

  .c-list-note > .item{
    font-size: 1.1rem;
    margin-bottom: 2px;

    @include mq-pc {
      font-size: 1.4rem;
    }
  }
}

.slider {
  padding: 0 115px;
  margin-right: auto;
  margin-left: auto;

  @include mq-sp {
    padding: 0;
    margin-left: -15px;
    margin-right: -15px;
  }

  .box-okaimono-slider {
    text-align: center;
    outline: none;

    @include mq-sp {
      width: 194px;
      padding: 0 18px;
    }

    .link {
      display: block;
      @include is-opacity;
    }

    img {
      padding: 0 10px;
      margin: 0 auto;

      @include mq-sp {
        width: 100%;
        padding: 0;
      }
    }

    .text {
      margin-top: 15px;
      @include mq-sp {
        margin-top: 10px;
      }
    }
  }


  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 45%;

    display: block;

    width: 60px;
    height: 60px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    @include is-opacity;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    @include is-opacity;
  }

  .slick-prev:before,
  .slick-next:before {
    width: 60px;
    height: 60px;
    display: block;
  }

  .slick-prev {
    left: 40px;
  }

  [dir='rtl'] .slick-prev {
    right: 40px;
    left: auto;
  }

  .slick-prev:before {
    content: '';
    background: url(/assets/images/okaimono/ico_arrow_left.png) no-repeat;
  }

  [dir='rtl'] .slick-prev:before {
    content: '';
    background: url(/assets/images/okaimono/ico_arrow_left.png) no-repeat;
  }

  .slick-next {
    right: 40px;
  }
  [dir='rtl'] .slick-next {
    right: auto;
    left: 40px;
  }

  .slick-next:before {
    content: '';
    background: url(/assets/images/okaimono/ico_arrow_right.png) no-repeat;
  }
  
  [dir='rtl'] .slick-next:before {
    content: '';
    background: url(/assets/images/okaimono/ico_arrow_right.png) no-repeat;
  }

}



.c-card-service-wrap {
  @include mq-sp {
    overflow: hidden;
    padding-top: 30px;
  }
}