@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 html5 elements
========================================================== */

article, aside, figure, figcaption, footer, header, nav, section {
  display: block;
}

/* ==========================================================
 reseting
========================================================== */
html {
  font-size: 62.5%;
}

body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea {
  margin: 0;
  font-size: 100%;
}

input, textarea, select {
  /*-webkit-appearance: none;*/
}

textarea {
  resize: none;
}

button {
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font-size: 100%;
  color: inherit;
  cursor: pointer;
  -webkit-appearance: none;
}

label {
  // cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset {
  border: 0;
}

img {
  max-width: 100%;
  border: 0;
  height: auto;
  vertical-align: top;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, strong, em {
  font-size: 100%;
  font-weight: normal;
}

a, input {
  /* outline: none; -webkit-tap-highlight-color:rgba(0,0,0,0);*/
  overflow-wrap: break-word;
  word-wrap: break-word;
}

sup, sub {
  font-size: 63%;
}

sup {
  vertical-align: top;
}

sub {
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* ==========================================================
 base Link
========================================================== */

a {
  text-decoration: none;
  color: #333;

  &:hover, &:active, &:focus, &:visited {
    text-decoration: none;
  }
}

/* ==========================================================
 clearfix
========================================================== */

.clearfix {
  &:before, &:after {
    content: "";
    display: table;
  }
}

.contents {
  &:before, &:after {
    content: "";
    display: table;
  }
}

.clearfix:after, .contents:after {
  clear: both;
}

/* For IE 6/7
---------------------------------------------------------- */

.clearfix, .contents {
  *zoom: 1;
}