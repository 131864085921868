@import "../_variables.scss";
@import "../_mixin.scss";

/* sec-flow
---------------------------------------------------------- */
.sec-flow {

}
.ttl-flow {
  font-size: 2rem;
  font-weight: 700;
  color: $color-black;
  text-align: center;
  margin-bottom: 25px;

  @include mq-pc {
    font-size: 2.4rem;
    margin-bottom: 50px;
  }
}

/* list-flow
---------------------------------------------------------- */
.card-flow {
  position: relative;
  border: 1px solid #ebebeb;
  padding: 28px 25px 30px;

  @include mq-pc {
    padding: 40px 50px 45px;
    height: 100%;
  }

  .label {
    position: absolute;
    left: 0;
    top: 0;
    width: 35px;
    height: 35px;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 35px;
    color: #fff;
    text-align: center;
    background-color: $color-black;

    @include mq-pc {
      width: 40px;
      height: 40px;
      font-size: 2rem;
      line-height: 40px;
    }
  }

  .title {
    font-size: 1.6rem; 
    font-weight: 700;
    text-align: center;

    @include mq-pc {
      font-size: 2rem;
    }
  }

  .text {
    font-size: 1.3rem; 
    margin-top: 18px;

    @include mq-pc {
      font-size: 1.4rem;
      margin-top: 22px;
    }
  }

  .image {
    text-align: center;
    margin-top: 12px;

    @include mq-pc {
      margin-top: 17px;
    }
  }
}
