@import "../_variables.scss";
@import "../_mixin.scss";

.nav-news{
  padding-top: 45px;
  @include mq-pc{
    padding-top: 67px;
  }
}
.nav-news > .list-news{

  @include mq-pc{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
	&:not(:first-of-type){
    margin-top: 10px;
    @include mq-pc{
      margin-top: 0;
      line-height: 1.7;
    }
	}
  > dt{
    flex: 0 0 100%;
    font-size: 1.4rem;
    font-weight: bold;
    color: #111111;
    @include mq-pc{
      flex: 0 0 78px;
      font-size: 1.6rem;
      display: block;
    }
  }
  > dd{
    flex: 1;
    @include mq-pc{
      flex: 0 0 auto;
      display: block;
    }
  }
}

.list-archive{
  display: flex;
  flex-wrap: wrap;
  margin: 5px -5px 0;
}
.list-archive > .item{
  margin: 0;
  width: 25%;
  padding: 7px 5px;
  @include mq-pc{
    width: auto;
  }
  > .link{
    display: inline-flex;
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding: 8px 12px;
    font-size: 1.2rem;
    border-radius: 50px;
    height: 100%;
    background: #fff;
    vertical-align: middle;
    color: #385199;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: background 0.2s;
    @include mq-pc{
      font-size: 1.4rem;
      padding: 8px 21px;
    }
    &:hover, &.is-active{
      background: #385199;
      color: #fff;
    }
  }
}
.list-archive.u-2-column-sp{
  margin-top: 0;
}
.list-archive.u-2-column-sp > .item{
  width: 50%;
  padding: 10px 5px;
  @include mq-pc{
    width: auto;
  }
  > .link{
    width: 100%;
  }
}

.box-news{
  padding: 33px 0 180px;
  @include mq-pc{
    padding-bottom: 185px;
  }
}
.box-news > .item{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 26px 0 20px;
  @include mq-pc{
    flex-wrap: nowrap;
    padding: 26px 0 30px;
  }
  &:not(:first-of-type){
    border-top: 1px solid #ebebeb;
  }
  &:last-of-type{
    @include mq-pc{
      border-bottom: 1px solid #ebebeb;
    }
  }
  >.date{
    position: relative;
    top: 9px;
		flex: 0 1 auto;
		font-size: 1.4rem;
		font-weight: bold;
    color: #333333;
    line-height: 1.1;
    @include mq-pc{
      flex: 0 0 135px;
      font-size: 1.6rem;
    }
	}
	> .category{
      flex: 0 1 auto;
      display: inline-flex;
      overflow: hidden;
      position: relative;
      z-index: 1;
      padding: 5px 28px;
      font-size: 1rem;
      border-radius: 50px;
      min-width: 135px;
      height: 100%;
      background: #fff;
      vertical-align: middle;
      color: #385199;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      margin-left: 15px;
      transition: background 0.2s;
      @include mq-pc{
        font-size: 1.2rem;
        padding: 5px 18px;
      }
      &:hover, &.is-active{
        background: #385199;
        color: #fff;
        .link{
          color: #fff;
        }
      }
      .link{
        color: #385199;
      }
		}
	>.title{
    flex: 1 1 100%;
    margin-top: 10px;
    @include mq-pc{
      //flex: 1 1 auto;
      margin-top: 0;
      font-size: 1.6rem;
      margin-left: 25px;
    }
		.link{
      font-size: 1.4rem;
      font-weight: 500;
      color: #333333;
      letter-spacing: -0.02em;
      @include mq-pc{
        font-size: 1.6rem;
      }
		}
	}
}