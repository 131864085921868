@import '../_variables.scss';
@import '../_mixin.scss';

.d-none         { display: none !important; }
.d-inline       { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block        { display: block !important; }
.d-table        { display: table !important; }
.d-table-row    { display: table-row !important; }
.d-table-cell   { display: table-cell !important; }
.d-flex         { display: flex !important; }
.d-inline-flex  { display: inline-flex !important; }

.visible-hid {visibility: hidden;}

@include mq-pc {
  .d-pc-none         { display: none !important; }
  .d-pc-inline       { display: inline !important; }
  .d-pc-inline-block { display: inline-block !important; }
  .d-pc-block        { display: block !important; }
  .d-pc-table        { display: table !important; }
  .d-pc-table-row    { display: table-row !important; }
  .d-pc-table-cell   { display: table-cell !important; }
  .d-pc-flex         { display: flex !important; }
  .d-pc-inline-flex  { display: inline-flex !important; }
}

@include mq-sp {
  .d-sp-none         { display: none !important; }
  .d-sp-inline       { display: inline !important; }
  .d-sp-inline-block { display: inline-block !important; }
  .d-sp-block        { display: block !important; }
  .d-sp-table        { display: table !important; }
  .d-sp-table-row    { display: table-row !important; }
  .d-sp-table-cell   { display: table-cell !important; }
  .d-sp-flex         { display: flex !important; }
  .d-sp-inline-flex  { display: inline-flex !important; }
}
