@import "../_variables.scss";
@import "../_mixin.scss";

/* ==========================================================
 button
========================================================== */
/* c-btn
---------------------------------------------------------- */
.c-btn {
  position: relative;
  display: inline-block;
  padding: 15px 45px 15px 15px;
  background-color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.5;
  color: $color-blue;
  text-align: center;
  appearance: none;
  @include is-opacity;

  @include mq-sp {
    display: block;
  }

  @include mq-pc {
    min-width: 400px;
    max-width: 100%;
    padding: 18px 40px 18px 18px;
    font-size: 1.6rem;

    &.-active:hover {
      opacity: 1;
    }
  }

  .text {
    display: inline-flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }
  }

  &.-blue {
    background-color: $color-blue;
    color: #fff;
  }

  &.-orange {
    background-color: $color-orange;
    color: #fff;
  }

  &.-orang-light {
    background-color: $color-orange-light;
    color: #fff;
  }

  &.-arrow_right {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 7px;
      height: 11px;
      background: url('/assets/images/common/icon_arrow_right_white.png') 0 0 no-repeat;
      background-size: 100% auto;
    }
  }

  &.-arrow_down {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 11px;
      height: 7px;
      background: url('/assets/images/common/icon_arrow_down_white.png') 0 0 no-repeat;
      background-size: 100% auto;
    }
  }

  &.d-block {
    width: 100% !important;
    min-width: 0;
  }
}

/* ==========================================================
 button-group
========================================================== */
/* c-btn-group
---------------------------------------------------------- */
.c-btn-group {
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    width: 830px;
    margin: 0 auto 100px;
  }

  .row {
    > [class^="col"] {
      @include mq-sp {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* c-btn-tabnav
---------------------------------------------------------- */
.c-btn-tabnav {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  .c-btn {
    &.-active {
      background-color: $color-blue;
      color: #fff;
      cursor: default;
    }

    &.-denki {
      color: #f98700;
  
      &.-active {
        background-color: #f98700;
        color: #fff;
      }
    }
  
    &.-gas {
      color: #2157a7;
  
      &.-active {
        background-color: #2157a7;
        color: #fff;
      }
    }
  }

  &.-blue {
    .c-btn {
      &.-active {
        background-color: $color-blue;
        color: #fff;
      }
    }
  }

  &.-flow {
    .c-btn {
      height: 100%;
      padding-left: 10px;
      padding-right: 10px;
      
      .text {
        @include mq-sp {
          background-repeat: no-repeat;
          background-position: top center;
          background-size: 35px 23px;
          padding-top: 30px;
        }

        @include mq-pc {
          background-repeat: no-repeat;
          background-position: center left;
          background-size: 70px 46px;
          padding-left: 90px;
        }
      }

      &.-current {
        .text {
          @include mq-sp {
            background-image: url('/assets/images/flow/ico_flow_denki_no.png');
          }

          @include mq-pc {
            background-image: url('/assets/images/flow/ico_flow_denki_no.png');
          }
        }

        &.-active {
          .text {
            @include mq-sp {
              background-image: url('/assets/images/flow/ico_flow_denki_on.png');
            }
  
            @include mq-pc {
              background-image: url('/assets/images/flow/ico_flow_denki_on.png');
            }
          }
        }
      }

      &.-new {
        .text {
          @include mq-sp {
            background-image: url('/assets/images/flow/ico_flow_house_no.png');
          }

          @include mq-pc {
            background-image: url('/assets/images/flow/ico_flow_house_no.png');
          }
        }
        &.-active {
          .text {
            @include mq-sp {
              background-image: url('/assets/images/flow/ico_flow_house_on.png');
            }
  
            @include mq-pc {
              background-image: url('/assets/images/flow/ico_flow_house_on.png');
            }
          }
        }
      }
    }
    @include mq-pc {
      width: 830px;
    }
  }

  @include mq-pc {
    width: 700px;
    margin: 0 auto 50px;
  }
}

/* c-btn-group-anchor
---------------------------------------------------------- */
.c-btn-group-anchor {
  margin-top: -1px;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
  }

  > .item {
    width: 100%;
    margin-top: 1px;

    @include mq-pc {
      width: 235px;
      margin-top: 1px;
      padding-left: 1px;

      &:first-child {
        padding-left: 0;
      }
    }
  }
}