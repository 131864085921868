/* ==========================================================
 fonts
========================================================== */
// YuGothic
@font-face {
  font-family: YuGothicWebFont;
  font-style: normal;
  font-weight: 500;
  src: url('/assets/inc/font/YuGothic-Medium.woff');
}
@font-face {
  font-family: YuGothicWebFont;
  font-style: normal;
  font-weight: bold;
  src: url('/assets/inc/font/YuGothic-Bold.woff');
}

// Roboto
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&display=swap');

/* ==========================================================
 base
========================================================== */
@import './base/reset.scss';
@import './base/layout.scss';
@import './base/grid.scss';

/* ==========================================================
 components
========================================================== */
@import "components/accordion.scss";
@import "components/article.scss";
@import "components/border.scss";
@import "components/box.scss";
@import "components/button.scss";
@import "components/card.scss";
@import "components/footer.scss";
@import "components/form.scss";
@import "components/header.scss";
@import "components/heading.scss";
@import "components/icon.scss";
@import "components/image.scss";
@import "components/link.scss";
@import "components/list.scss";
@import "components/mainvisual.scss";
@import "components/modal.scss";
@import "components/navglobal.scss";
@import "components/section.scss";
@import "components/sidebtn.scss";
@import "components/slider.scss";
@import "components/tab.scss";
@import "components/table.scss";
@import "components/text.scss";

/* ==========================================================
 pages
========================================================== */
@import "pages/about.scss";
@import "pages/agency_list.scss";
@import "pages/ask.scss";
@import "pages/flow.scss";
@import "pages/gasu-moshimo.scss";
@import "pages/majimena-gasu.scss";
@import "pages/musubi-project.scss";
@import "pages/news.scss";
@import "pages/okaimono.scss";
@import "pages/payment.scss";
@import "pages/prime.scss";
@import "pages/qa.scss";
@import "pages/recruit.scss";
@import "pages/sitemap.scss";
@import "pages/teiden.scss";
@import "pages/top.scss";
@import "pages/voice_kouatsu.scss";

/* ==========================================================
 utils
========================================================== */
@import "utils/background.scss";
@import "utils/border.scss";
@import "utils/display.scss";
@import "utils/flex.scss";
@import "utils/hover.scss";
@import "utils/lineheight.scss";
@import "utils/responsive_display.scss";
@import "utils/spacing.scss";
@import "utils/text.scss";
@import "utils/width.scss";
