@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 text
========================================================== */
/* c-txt-paragraph
---------------------------------------------------------- */
.c-txt-paragraph {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    margin-bottom: 35px;
  }
}

/* c-txt-semi
---------------------------------------------------------- */
.c-txt-semi {
  font-size: 1.3rem;
  line-height: 1.77;

  @include mq-pc {
    font-size: 1.4rem;
    line-height: 2;
  }
}

/* c-txt-small
---------------------------------------------------------- */
.c-txt-small {
  font-size: 1.2rem;
  line-height: 1.75;

  @include mq-pc {
    line-height: 2.2;
  }
}

