@import "../_variables.scss";
@import "../_mixin.scss";


/* btn-list-recruit
---------------------------------------------------------- */
.btn-list-recruit {
  max-width: 770px;
  margin: 0 auto;
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      @include mq-pc {
        width: 370px;
        margin-bottom: 0;
      }
      a {
        position: relative;
        display: block;
        padding: 23px 10px;
        background-color: #2157a7;
        font-size: 1.65rem;
        font-weight: bold;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        appearance: none;
        @include mq-pc {
          font-size: 2rem;
          padding: 30px 10px;
          @include is-opacity;
        }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          width: 7px;
          height: 11px;
          background: url('/assets/images/common/icon_arrow_right_white.png') 0 0 no-repeat;
          background-size: 100% auto;
        }
      }
    }
  }
}
