
@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 layout
========================================================== */
html {
  &.-locked {
    position: fixed;
    left: 0;
    width: 100%;
  }
}

body {
  position: relative;
  background-color: #f6f7f9;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", YuGothicWebFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.875;
  color: #333;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;

  @include mq-pc {
    padding-top: 80px;
  }

  @include mq-sp {
    padding-top: 50px;
    min-width: 1px;
    font-size: 1.4rem;
    line-height: 1.78;
  }

  @media all and (-ms-high-contrast: none) {
    font-family: "メイリオ", "Meiryo", sans-serif;
  }
}

/* wrapper
---------------------------------------------------------- */
.wrapper {
  overflow: hidden;

  @include mq-pc {
  }

  @include mq-sp {
  }
}

/* nav-breadcrumb
---------------------------------------------------------- */
.nav-breadcrumb {
  position: relative;
  z-index: 2;
  margin-top: -20px;

  @include mq-pc {
    + .contents {
      margin-top: -20px;
    }
  }

  @include mq-sp {
    display: none;
  }

  .list-link {
    display: inline-flex;
    padding: 0 15px;
    background-color: #fff;
    border-radius: 25px;

    .item {
      position: relative;
      font-size: 1.1rem;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 10px;
        background-color: #333;
        z-index: 2;
      }

      &:last-child {
        span {
          font-weight: bold;
        }
        &:after {
          display: none;
        }
      }

    }

    a, span {
      display: block;
      padding: 15px;
      line-height: 1;
    }

    a {
      &:hover {
        @include mq-pc {
          opacity: 0.7;
        }
      }
    }
  }
}

/* contents
---------------------------------------------------------- */
.contents {
  &.-subpage {
    padding-top: 50px;
    padding-bottom: 95px;

    @include mq-pc {
      padding-top: 80px;
      padding-bottom: 150px;
    }
  }
}

/* container
---------------------------------------------------------- */
.container {
  padding-right: 30px;
  padding-left: 30px;

  @include mq-pc {
    max-width: 1230px;
    margin-right: auto;
    margin-left: auto;
  }

  @include mq-mx(1170) {
    padding-right: 15px;
    padding-left: 15px;
  }

  // -narrow
  &.-narrow {
    @include mq-pc {
      max-width: 930px;
    }
  }
}

