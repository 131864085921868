@import "../_variables.scss";
@import "../_mixin.scss";


.c-list-fag {
  margin-bottom: 40px;
  @include mq-pc {
    margin-bottom: 50px;
  }
  li {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
/* ttl-question
---------------------------------------------------------- */
.ttl-question {
  position: relative;
  padding: 0 20px 22px 20px;
  font-size: 1.5rem;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 30px;
  font-weight: bold;
  @include mq-pc {
    font-size: 2.2rem;
    padding: 0 20px 29px 43px;
    margin-bottom: 0;
  }
  &:before {
    content: 'Q';
    position: absolute;
    left: 0;
    top: -6px;
    color: $color-blue;
    font-weight: bold;
    font-family: 'Roboto Condensed';
    font-size: 2rem;
    @include mq-pc {
      font-size: 2.8rem;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -20px;
    background: url(/assets/images/common/ico_acc_01.png) no-repeat;
    background-size: 100%;
    width: 21px;
    height: 21px;
    @include mq-pc {
      right: 20px;
      margin-top: -29px;
    }
  }
}
.box-answer {
  display: none;
  padding: 5px 0;
  margin-bottom: 20px;
  @include mq-pc {
    padding: 10px 0;
  }
}

/* ist-ask-sns
---------------------------------------------------------- */
.list-ask-sns {
  .list {
    @include mq-sp {
      margin: -40px -40px 0;
    }
    .item {
      @include mq-pc {
        padding: 0 35px;
      }

      @include mq-sp {
        padding: 0 30px;
        margin-top: 40px;
      }
    }
  }
}
