@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 navglobal
========================================================== */
.nav-global {
  @include mq-pc {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 232px);
  }

  @include mq-mn(1170) {
    right: 30px;
  }

  @include mq-mx(1169) {
    right: 0;
    width: calc(100% - 172px);
  } 

  @include mq-sp {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 98;
    width: 100%;
    overflow-y: auto;
    padding-top: 50px;
    background-color: rgba(0, 0, 0, .8);
  }

  //btn-contact
  .btn-contact {
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 0 20px;
    background-color: $color-orange;
    font-weight: bold;
    font-size: 1.6rem;
    color: #fff;
    @include is-opacity;

    @include mq-mx(1169) {
      font-size: 1.4rem;
    }
  }

  // btn-his
  .btn-his {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 10px 23px;
  }

  // btn-close
  .btn-close {
    @include mq-pc {
      display: none;
    }

    @include mq-sp {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      background-color: #bbb;
      margin-top: -1px;
    }
  }
}

/* list-gnav
---------------------------------------------------------- */
.list-gnav {
  @include mq-pc {
    display: flex;

    > .item {
      .link-main {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 25px;
        height: 80px;
        font-weight: bold;
        font-size: 1.6rem;
        color: #fff;

        @include mq-mx(1169) {
          font-size: 1.4rem;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: 100% auto;
          background-position: center bottom;
          background-repeat: no-repeat;
          opacity: 0;
          visibility: hidden;
          transition: opacity .2s;
        }
      }

      &:nth-child(1) {
        .link-main:after {
          background-image: url('/assets/images/common/bg_hover_current_01.png');
        }
      }   

      &:nth-child(2) {
        .link-main:after {
          background-image: url('/assets/images/common/bg_hover_current_02.png');
        }
      }   

      &:nth-child(3) {
        .link-main:after {
          background-image: url('/assets/images/common/bg_hover_current_03.png');
        }
      }   

      // active
      &.-active {
        .link-main:after {
          opacity: 1;
          visibility: visible;
        }
      }

      // current
      &.-current {
        .link-main:after {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  @include mq-sp {
    > .item {
      border-bottom: 1px solid #e8ecf0;

      .link-main {
        position: relative;
        display: block;
        padding: 22px 15px;
        background-color: #fff;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;

        &.accd {
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            width: 11px;
            height: 11px;
            background-image: url('/assets/images/common/icon_plus.png');
            background-size: 100% auto;
            background-position: 0 0;
            background-repeat: no-repeat;
          }

          &.-active:after {
            background-image: url('/assets/images/common/icon_minus.png');
          }
        }

        .icon {
          display: block;
          margin-bottom: 10px;
          text-align: center;
        }

        .text {
          display: block;
        }
      }
    }
  }

  // -column2
  &.-column2 {
    display: flex;

    > .item {
      width: 50%;

      &:nth-child(odd) {
        border-right: 1px solid #e8ecf0;
      }

      .link-main {
        height: 100%;
      }
    }
  }
}

/* megamenu-panel
---------------------------------------------------------- */
.megamenu-panel {
  @include mq-pc {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .95);
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    transition: all .3s;
  }

  &.-show {
    visibility: visible;
    opacity: 1;
    z-index: 99;
  }

  @include mq-sp {
    display: none;
  }

  .container {
    @include mq-pc {
      padding-top: 50px;
      padding-bottom: 60px;
    }

    @include mq-sp {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .heading {
    @include mq-pc {
      margin-bottom: 40px;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1.45;
      color: #fff;
      text-align: center;
    }

    @include mq-sp {
      display: none;
    }
  }

  .cont {
    .col {
      &.-left {
        @include mq-sp {
          .box-link-main, .box-link-banner {
            @include mq-sp {
              border-bottom: 1px solid #fff;
            }
          }
        }
      }
    }
  }

  // box-link-main
  .box-link-main {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ecf0f3;
    padding-top: 10px;
    padding-bottom: 10px;
    @include is-opacity;

    @include mq-pc {
      flex-direction: column;
    }

    @include mq-sp {
      height: 75px !important;
    }

    .icon {
      display: block;
      margin-bottom: 10px;
    }

    .text {
      font-weight: bold;
      font-size: 2rem;

      @include mq-sp {
        padding-left: 10px;
        font-size: 1.3rem;
      }
    }
  }

  // list-link-main
  .list-link-main {
    .item {
      @include mq-pc {
        padding-right: 1px;
        padding-bottom: 1px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  // box-link-banner
  .box-link-banner {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 15px 0 30px;
    overflow: hidden;
    background-color: #ecf0f3;
    @include is-opacity;

    @include mq-sp {
      height: 50px;
      padding-left: 15px;
    }

    img.img-bg  {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: auto;
      height: 100%;
      max-width: none;
    }

    .text {
      position: relative;
      font-size: 1.4rem;
      font-weight: bold;

      @include mq-sp {
        font-size: 1.2rem;
      }
    }
  }

  // list-link-banner
  .list-link-banner {
    @include mq-sp {
      display: flex;
      flex-wrap: wrap;
    }

    .item {
      @include mq-pc {
        padding-bottom: 1px;

        &:last-child {
          padding-bottom: 0;
        }

        &.-special {
          .box-link-banner {
            height: 90px;
          }
        }
      }

      @include mq-sp {
        width: 100%;
        border-bottom: 1px solid #fff;

        &.-special {
          width: 50%;
          border-left: 1px solid #fff;

          .box-link-banner {
            height: auto;
            align-items: flex-start;
            justify-content: center;
            padding: 20px 0 0;
            text-align: center;

            img.img-bg {
              position: relative;
              top: inherit;
              right: inherit;
              left: 0;
              bottom: 0;
              transform: translate(0, 0);
              width: 100%;
              height: auto;
            }
          }

          .text {
            position: absolute;
            top: 30px;
            left: 0;
            width: 100%;
            text-align: center;
          }
        }
      }

      .text {
        font-size: 1.4rem;

        @include mq-sp {
          font-size: 1.2rem;
        }
      }
    }
  }

  // box-link-icon
  .box-link-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.7;
    color: #fff;
    @include is-opacity;

    @include mq-sp {
      padding: 15px;
      background-color: #ecf0f3;
      font-weight: 500;
      font-size: 1rem;
      color: #333;
    }

    .icon {
      line-height: 1;
      margin-left: 5px;
    }

    &.-blue {
      background-color: $color-blue;
      font-weight: bold;
      color: #fff;

      @include mq-pc {
        padding-right: 30px;
        padding-left: 30px;
      }

      @include mq-sp {
        font-size: 1.2rem;
      }
    }

    &.-orange {
      background-color: $color-orange;
      font-weight: bold;
      color: #fff;

      @include mq-pc {
        padding-right: 30px;
        padding-left: 30px;
      }

      @include mq-sp {
        font-size: 1.2rem;
      }
    }
  }

  // list-link-icon
  .list-link-icon {
    display: flex;
    flex-wrap: wrap;

    @include mq-pc {
      margin-bottom: 40px;
    }

    .item {
      width: 50%;

      @include mq-pc {
        .box-link-icon {
          border-bottom: 1px solid #3c4253;
        }
  
        &:nth-child(-n+2) {
          .box-link-icon {
            border-top: 1px solid #3c4253;
          }
        }
  
        &:nth-child(odd) {
          padding-right: 15px;
        }
  
        &:nth-child(even) {
          padding-left: 15px;
        }
      }

      @include mq-sp {
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;

        &:nth-child(even) {
          border-right: 0;
        }
         
        .box-link-icon {
          height: 100%;
        }
      }

      .text {
        font-size: 1.4rem;

        @include mq-sp {
          font-size: 1rem;
        }
      }
    }
  }
}

/* megamenu01
---------------------------------------------------------- */
.megamenu01 {
  .cont {
    @include mq-pc {
      display: flex;
    } 
  }

  .col {
    @include mq-pc {
      &.-left {
        width: 50%;
        padding-right: 15px;
      }
  
      &.-right {
        width: 50%;
        padding-left: 15px;
      }
    }
  }
}

/* megamenu02
---------------------------------------------------------- */
.megamenu02 {
  .cont {
    @include mq-pc {
      display: flex;
    }
  }

  .col {
    @include mq-pc {
      &.-left {
        width: 33%;
        padding-right: 15px;
      }
  
      &.-right {
        width: 67%;
        padding-left: 15px;
      }
    }
  }
}