@charset "UTF-8";
/* ==========================================================
 fonts
========================================================== */
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&display=swap");
@font-face {
  font-family: YuGothicWebFont;
  font-style: normal;
  font-weight: 500;
  src: url("/assets/inc/font/YuGothic-Medium.woff");
}

@font-face {
  font-family: YuGothicWebFont;
  font-style: normal;
  font-weight: bold;
  src: url("/assets/inc/font/YuGothic-Bold.woff");
}

/* ==========================================================
 base
========================================================== */
/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 html5 elements
========================================================== */
article, aside, figure, figcaption, footer, header, nav, section {
  display: block;
}

/* ==========================================================
 reseting
========================================================== */
html {
  font-size: 62.5%;
}

body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea {
  margin: 0;
  font-size: 100%;
}

input, textarea, select {
  /*-webkit-appearance: none;*/
}

textarea {
  resize: none;
}

button {
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font-size: 100%;
  color: inherit;
  cursor: pointer;
  -webkit-appearance: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset {
  border: 0;
}

img {
  max-width: 100%;
  border: 0;
  height: auto;
  vertical-align: top;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, strong, em {
  font-size: 100%;
  font-weight: normal;
}

a, input {
  /* outline: none; -webkit-tap-highlight-color:rgba(0,0,0,0);*/
  overflow-wrap: break-word;
  word-wrap: break-word;
}

sup, sub {
  font-size: 63%;
}

sup {
  vertical-align: top;
}

sub {
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* ==========================================================
 base Link
========================================================== */
a {
  text-decoration: none;
  color: #333;
}

a:hover, a:active, a:focus, a:visited {
  text-decoration: none;
}

/* ==========================================================
 clearfix
========================================================== */
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.contents:before, .contents:after {
  content: "";
  display: table;
}

.clearfix:after, .contents:after {
  clear: both;
}

/* For IE 6/7
---------------------------------------------------------- */
.clearfix, .contents {
  *zoom: 1;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 layout
========================================================== */
html.-locked {
  position: fixed;
  left: 0;
  width: 100%;
}

body {
  position: relative;
  background-color: #f6f7f9;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", YuGothicWebFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.875;
  color: #333;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

@media (min-width: 897px) {
  body {
    padding-top: 80px;
  }
}

@media (max-width: 896px) {
  body {
    padding-top: 50px;
    min-width: 1px;
    font-size: 1.4rem;
    line-height: 1.78;
  }
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: "メイリオ", "Meiryo", sans-serif;
  }
}

/* wrapper
---------------------------------------------------------- */
.wrapper {
  overflow: hidden;
}

/* nav-breadcrumb
---------------------------------------------------------- */
.nav-breadcrumb {
  position: relative;
  z-index: 2;
  margin-top: -20px;
}

@media (min-width: 897px) {
  .nav-breadcrumb + .contents {
    margin-top: -20px;
  }
}

@media (max-width: 896px) {
  .nav-breadcrumb {
    display: none;
  }
}

.nav-breadcrumb .list-link {
  display: inline-flex;
  padding: 0 15px;
  background-color: #fff;
  border-radius: 25px;
}

.nav-breadcrumb .list-link .item {
  position: relative;
  font-size: 1.1rem;
}

.nav-breadcrumb .list-link .item:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 10px;
  background-color: #333;
  z-index: 2;
}

.nav-breadcrumb .list-link .item:last-child span {
  font-weight: bold;
}

.nav-breadcrumb .list-link .item:last-child:after {
  display: none;
}

.nav-breadcrumb .list-link a, .nav-breadcrumb .list-link span {
  display: block;
  padding: 15px;
  line-height: 1;
}

@media (min-width: 897px) {
  .nav-breadcrumb .list-link a:hover {
    opacity: 0.7;
  }
}

/* contents
---------------------------------------------------------- */
.contents.-subpage {
  padding-top: 50px;
  padding-bottom: 95px;
}

@media (min-width: 897px) {
  .contents.-subpage {
    padding-top: 80px;
    padding-bottom: 150px;
  }
}

/* container
---------------------------------------------------------- */
.container {
  padding-right: 30px;
  padding-left: 30px;
}

@media (min-width: 897px) {
  .container {
    max-width: 1230px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 1170px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 897px) {
  .container.-narrow {
    max-width: 930px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* c-grid
---------------------------------------------------------- */
/*
  Exam
  <div class="row pd-32 pd-sp-0">
    <div class="col-pc-6 col-sp-12">
      test
    </div>
    <div class="col-pc-6 col-sp-12">
      test
    </div>
    <div class="col-pc-6 col-sp-12">
      test
    </div>
  </div>
  **pd-32 -> .row { margin-left: -32px; margin-right: -32px;}
          -> .row [class^="col"] { padding-left: 32px; padding-right: 32px;}
*/
.c-grid {
  /* c-grid col-i
---------------------------------------------------------- */
}

.c-grid .row {
  display: flex;
  flex-wrap: wrap;
}

.c-grid .row.pd-0 {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}

.c-grid .row.pd-0 > [class^="col"] {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 0px;
}

.c-grid .row.pd-1 {
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -2px;
}

.c-grid .row.pd-1 > [class^="col"] {
  padding-left: 1px;
  padding-right: 1px;
  margin-top: 2px;
}

.c-grid .row.pd-2 {
  margin-left: -2px;
  margin-right: -2px;
  margin-top: -4px;
}

.c-grid .row.pd-2 > [class^="col"] {
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 4px;
}

.c-grid .row.pd-3 {
  margin-left: -3px;
  margin-right: -3px;
  margin-top: -6px;
}

.c-grid .row.pd-3 > [class^="col"] {
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 6px;
}

.c-grid .row.pd-4 {
  margin-left: -4px;
  margin-right: -4px;
  margin-top: -8px;
}

.c-grid .row.pd-4 > [class^="col"] {
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 8px;
}

.c-grid .row.pd-5 {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}

.c-grid .row.pd-5 > [class^="col"] {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
}

.c-grid .row.pd-6 {
  margin-left: -6px;
  margin-right: -6px;
  margin-top: -12px;
}

.c-grid .row.pd-6 > [class^="col"] {
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 12px;
}

.c-grid .row.pd-7 {
  margin-left: -7px;
  margin-right: -7px;
  margin-top: -14px;
}

.c-grid .row.pd-7 > [class^="col"] {
  padding-left: 7px;
  padding-right: 7px;
  margin-top: 14px;
}

.c-grid .row.pd-8 {
  margin-left: -8px;
  margin-right: -8px;
  margin-top: -16px;
}

.c-grid .row.pd-8 > [class^="col"] {
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 16px;
}

.c-grid .row.pd-9 {
  margin-left: -9px;
  margin-right: -9px;
  margin-top: -18px;
}

.c-grid .row.pd-9 > [class^="col"] {
  padding-left: 9px;
  padding-right: 9px;
  margin-top: 18px;
}

.c-grid .row.pd-10 {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -20px;
}

.c-grid .row.pd-10 > [class^="col"] {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
}

.c-grid .row.pd-11 {
  margin-left: -11px;
  margin-right: -11px;
  margin-top: -22px;
}

.c-grid .row.pd-11 > [class^="col"] {
  padding-left: 11px;
  padding-right: 11px;
  margin-top: 22px;
}

.c-grid .row.pd-12 {
  margin-left: -12px;
  margin-right: -12px;
  margin-top: -24px;
}

.c-grid .row.pd-12 > [class^="col"] {
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 24px;
}

.c-grid .row.pd-13 {
  margin-left: -13px;
  margin-right: -13px;
  margin-top: -26px;
}

.c-grid .row.pd-13 > [class^="col"] {
  padding-left: 13px;
  padding-right: 13px;
  margin-top: 26px;
}

.c-grid .row.pd-14 {
  margin-left: -14px;
  margin-right: -14px;
  margin-top: -28px;
}

.c-grid .row.pd-14 > [class^="col"] {
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 28px;
}

.c-grid .row.pd-15 {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -30px;
}

.c-grid .row.pd-15 > [class^="col"] {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 30px;
}

.c-grid .row.pd-16 {
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -32px;
}

.c-grid .row.pd-16 > [class^="col"] {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 32px;
}

.c-grid .row.pd-17 {
  margin-left: -17px;
  margin-right: -17px;
  margin-top: -34px;
}

.c-grid .row.pd-17 > [class^="col"] {
  padding-left: 17px;
  padding-right: 17px;
  margin-top: 34px;
}

.c-grid .row.pd-18 {
  margin-left: -18px;
  margin-right: -18px;
  margin-top: -36px;
}

.c-grid .row.pd-18 > [class^="col"] {
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 36px;
}

.c-grid .row.pd-19 {
  margin-left: -19px;
  margin-right: -19px;
  margin-top: -38px;
}

.c-grid .row.pd-19 > [class^="col"] {
  padding-left: 19px;
  padding-right: 19px;
  margin-top: 38px;
}

.c-grid .row.pd-20 {
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -40px;
}

.c-grid .row.pd-20 > [class^="col"] {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 40px;
}

.c-grid .row.pd-21 {
  margin-left: -21px;
  margin-right: -21px;
  margin-top: -42px;
}

.c-grid .row.pd-21 > [class^="col"] {
  padding-left: 21px;
  padding-right: 21px;
  margin-top: 42px;
}

.c-grid .row.pd-22 {
  margin-left: -22px;
  margin-right: -22px;
  margin-top: -44px;
}

.c-grid .row.pd-22 > [class^="col"] {
  padding-left: 22px;
  padding-right: 22px;
  margin-top: 44px;
}

.c-grid .row.pd-23 {
  margin-left: -23px;
  margin-right: -23px;
  margin-top: -46px;
}

.c-grid .row.pd-23 > [class^="col"] {
  padding-left: 23px;
  padding-right: 23px;
  margin-top: 46px;
}

.c-grid .row.pd-24 {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -48px;
}

.c-grid .row.pd-24 > [class^="col"] {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 48px;
}

.c-grid .row.pd-25 {
  margin-left: -25px;
  margin-right: -25px;
  margin-top: -50px;
}

.c-grid .row.pd-25 > [class^="col"] {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 50px;
}

.c-grid .row.pd-26 {
  margin-left: -26px;
  margin-right: -26px;
  margin-top: -52px;
}

.c-grid .row.pd-26 > [class^="col"] {
  padding-left: 26px;
  padding-right: 26px;
  margin-top: 52px;
}

.c-grid .row.pd-27 {
  margin-left: -27px;
  margin-right: -27px;
  margin-top: -54px;
}

.c-grid .row.pd-27 > [class^="col"] {
  padding-left: 27px;
  padding-right: 27px;
  margin-top: 54px;
}

.c-grid .row.pd-28 {
  margin-left: -28px;
  margin-right: -28px;
  margin-top: -56px;
}

.c-grid .row.pd-28 > [class^="col"] {
  padding-left: 28px;
  padding-right: 28px;
  margin-top: 56px;
}

.c-grid .row.pd-29 {
  margin-left: -29px;
  margin-right: -29px;
  margin-top: -58px;
}

.c-grid .row.pd-29 > [class^="col"] {
  padding-left: 29px;
  padding-right: 29px;
  margin-top: 58px;
}

.c-grid .row.pd-30 {
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -60px;
}

.c-grid .row.pd-30 > [class^="col"] {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 60px;
}

.c-grid .row.pd-31 {
  margin-left: -31px;
  margin-right: -31px;
  margin-top: -62px;
}

.c-grid .row.pd-31 > [class^="col"] {
  padding-left: 31px;
  padding-right: 31px;
  margin-top: 62px;
}

.c-grid .row.pd-32 {
  margin-left: -32px;
  margin-right: -32px;
  margin-top: -64px;
}

.c-grid .row.pd-32 > [class^="col"] {
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 64px;
}

.c-grid .row.pd-33 {
  margin-left: -33px;
  margin-right: -33px;
  margin-top: -66px;
}

.c-grid .row.pd-33 > [class^="col"] {
  padding-left: 33px;
  padding-right: 33px;
  margin-top: 66px;
}

.c-grid .row.pd-34 {
  margin-left: -34px;
  margin-right: -34px;
  margin-top: -68px;
}

.c-grid .row.pd-34 > [class^="col"] {
  padding-left: 34px;
  padding-right: 34px;
  margin-top: 68px;
}

.c-grid .row.pd-35 {
  margin-left: -35px;
  margin-right: -35px;
  margin-top: -70px;
}

.c-grid .row.pd-35 > [class^="col"] {
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 70px;
}

.c-grid .row.pd-36 {
  margin-left: -36px;
  margin-right: -36px;
  margin-top: -72px;
}

.c-grid .row.pd-36 > [class^="col"] {
  padding-left: 36px;
  padding-right: 36px;
  margin-top: 72px;
}

.c-grid .row.pd-37 {
  margin-left: -37px;
  margin-right: -37px;
  margin-top: -74px;
}

.c-grid .row.pd-37 > [class^="col"] {
  padding-left: 37px;
  padding-right: 37px;
  margin-top: 74px;
}

.c-grid .row.pd-38 {
  margin-left: -38px;
  margin-right: -38px;
  margin-top: -76px;
}

.c-grid .row.pd-38 > [class^="col"] {
  padding-left: 38px;
  padding-right: 38px;
  margin-top: 76px;
}

.c-grid .row.pd-39 {
  margin-left: -39px;
  margin-right: -39px;
  margin-top: -78px;
}

.c-grid .row.pd-39 > [class^="col"] {
  padding-left: 39px;
  padding-right: 39px;
  margin-top: 78px;
}

.c-grid .row.pd-40 {
  margin-left: -40px;
  margin-right: -40px;
  margin-top: -80px;
}

.c-grid .row.pd-40 > [class^="col"] {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 80px;
}

.c-grid .row.pd-41 {
  margin-left: -41px;
  margin-right: -41px;
  margin-top: -82px;
}

.c-grid .row.pd-41 > [class^="col"] {
  padding-left: 41px;
  padding-right: 41px;
  margin-top: 82px;
}

.c-grid .row.pd-42 {
  margin-left: -42px;
  margin-right: -42px;
  margin-top: -84px;
}

.c-grid .row.pd-42 > [class^="col"] {
  padding-left: 42px;
  padding-right: 42px;
  margin-top: 84px;
}

.c-grid .row.pd-43 {
  margin-left: -43px;
  margin-right: -43px;
  margin-top: -86px;
}

.c-grid .row.pd-43 > [class^="col"] {
  padding-left: 43px;
  padding-right: 43px;
  margin-top: 86px;
}

.c-grid .row.pd-44 {
  margin-left: -44px;
  margin-right: -44px;
  margin-top: -88px;
}

.c-grid .row.pd-44 > [class^="col"] {
  padding-left: 44px;
  padding-right: 44px;
  margin-top: 88px;
}

.c-grid .row.pd-45 {
  margin-left: -45px;
  margin-right: -45px;
  margin-top: -90px;
}

.c-grid .row.pd-45 > [class^="col"] {
  padding-left: 45px;
  padding-right: 45px;
  margin-top: 90px;
}

.c-grid .row.pd-46 {
  margin-left: -46px;
  margin-right: -46px;
  margin-top: -92px;
}

.c-grid .row.pd-46 > [class^="col"] {
  padding-left: 46px;
  padding-right: 46px;
  margin-top: 92px;
}

.c-grid .row.pd-47 {
  margin-left: -47px;
  margin-right: -47px;
  margin-top: -94px;
}

.c-grid .row.pd-47 > [class^="col"] {
  padding-left: 47px;
  padding-right: 47px;
  margin-top: 94px;
}

.c-grid .row.pd-48 {
  margin-left: -48px;
  margin-right: -48px;
  margin-top: -96px;
}

.c-grid .row.pd-48 > [class^="col"] {
  padding-left: 48px;
  padding-right: 48px;
  margin-top: 96px;
}

.c-grid .row.pd-49 {
  margin-left: -49px;
  margin-right: -49px;
  margin-top: -98px;
}

.c-grid .row.pd-49 > [class^="col"] {
  padding-left: 49px;
  padding-right: 49px;
  margin-top: 98px;
}

.c-grid .row.pd-50 {
  margin-left: -50px;
  margin-right: -50px;
  margin-top: -100px;
}

.c-grid .row.pd-50 > [class^="col"] {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 100px;
}

.c-grid .row.pd-51 {
  margin-left: -51px;
  margin-right: -51px;
  margin-top: -102px;
}

.c-grid .row.pd-51 > [class^="col"] {
  padding-left: 51px;
  padding-right: 51px;
  margin-top: 102px;
}

.c-grid .row.pd-52 {
  margin-left: -52px;
  margin-right: -52px;
  margin-top: -104px;
}

.c-grid .row.pd-52 > [class^="col"] {
  padding-left: 52px;
  padding-right: 52px;
  margin-top: 104px;
}

.c-grid .row.pd-53 {
  margin-left: -53px;
  margin-right: -53px;
  margin-top: -106px;
}

.c-grid .row.pd-53 > [class^="col"] {
  padding-left: 53px;
  padding-right: 53px;
  margin-top: 106px;
}

.c-grid .row.pd-54 {
  margin-left: -54px;
  margin-right: -54px;
  margin-top: -108px;
}

.c-grid .row.pd-54 > [class^="col"] {
  padding-left: 54px;
  padding-right: 54px;
  margin-top: 108px;
}

.c-grid .row.pd-55 {
  margin-left: -55px;
  margin-right: -55px;
  margin-top: -110px;
}

.c-grid .row.pd-55 > [class^="col"] {
  padding-left: 55px;
  padding-right: 55px;
  margin-top: 110px;
}

.c-grid .row.pd-56 {
  margin-left: -56px;
  margin-right: -56px;
  margin-top: -112px;
}

.c-grid .row.pd-56 > [class^="col"] {
  padding-left: 56px;
  padding-right: 56px;
  margin-top: 112px;
}

.c-grid .row.pd-57 {
  margin-left: -57px;
  margin-right: -57px;
  margin-top: -114px;
}

.c-grid .row.pd-57 > [class^="col"] {
  padding-left: 57px;
  padding-right: 57px;
  margin-top: 114px;
}

.c-grid .row.pd-58 {
  margin-left: -58px;
  margin-right: -58px;
  margin-top: -116px;
}

.c-grid .row.pd-58 > [class^="col"] {
  padding-left: 58px;
  padding-right: 58px;
  margin-top: 116px;
}

.c-grid .row.pd-59 {
  margin-left: -59px;
  margin-right: -59px;
  margin-top: -118px;
}

.c-grid .row.pd-59 > [class^="col"] {
  padding-left: 59px;
  padding-right: 59px;
  margin-top: 118px;
}

.c-grid .row.pd-60 {
  margin-left: -60px;
  margin-right: -60px;
  margin-top: -120px;
}

.c-grid .row.pd-60 > [class^="col"] {
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 120px;
}

@media (min-width: 897px) {
  .c-grid .row.pd-pc-0 {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .c-grid .row.pd-pc-0 > [class^="col"] {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
  }
  .c-grid .row.pd-pc-1 {
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -2px;
  }
  .c-grid .row.pd-pc-1 > [class^="col"] {
    padding-left: 1px;
    padding-right: 1px;
    margin-top: 2px;
  }
  .c-grid .row.pd-pc-2 {
    margin-left: -2px;
    margin-right: -2px;
    margin-top: -4px;
  }
  .c-grid .row.pd-pc-2 > [class^="col"] {
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 4px;
  }
  .c-grid .row.pd-pc-3 {
    margin-left: -3px;
    margin-right: -3px;
    margin-top: -6px;
  }
  .c-grid .row.pd-pc-3 > [class^="col"] {
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 6px;
  }
  .c-grid .row.pd-pc-4 {
    margin-left: -4px;
    margin-right: -4px;
    margin-top: -8px;
  }
  .c-grid .row.pd-pc-4 > [class^="col"] {
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 8px;
  }
  .c-grid .row.pd-pc-5 {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -10px;
  }
  .c-grid .row.pd-pc-5 > [class^="col"] {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .c-grid .row.pd-pc-6 {
    margin-left: -6px;
    margin-right: -6px;
    margin-top: -12px;
  }
  .c-grid .row.pd-pc-6 > [class^="col"] {
    padding-left: 6px;
    padding-right: 6px;
    margin-top: 12px;
  }
  .c-grid .row.pd-pc-7 {
    margin-left: -7px;
    margin-right: -7px;
    margin-top: -14px;
  }
  .c-grid .row.pd-pc-7 > [class^="col"] {
    padding-left: 7px;
    padding-right: 7px;
    margin-top: 14px;
  }
  .c-grid .row.pd-pc-8 {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -16px;
  }
  .c-grid .row.pd-pc-8 > [class^="col"] {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 16px;
  }
  .c-grid .row.pd-pc-9 {
    margin-left: -9px;
    margin-right: -9px;
    margin-top: -18px;
  }
  .c-grid .row.pd-pc-9 > [class^="col"] {
    padding-left: 9px;
    padding-right: 9px;
    margin-top: 18px;
  }
  .c-grid .row.pd-pc-10 {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -20px;
  }
  .c-grid .row.pd-pc-10 > [class^="col"] {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
  }
  .c-grid .row.pd-pc-11 {
    margin-left: -11px;
    margin-right: -11px;
    margin-top: -22px;
  }
  .c-grid .row.pd-pc-11 > [class^="col"] {
    padding-left: 11px;
    padding-right: 11px;
    margin-top: 22px;
  }
  .c-grid .row.pd-pc-12 {
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -24px;
  }
  .c-grid .row.pd-pc-12 > [class^="col"] {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 24px;
  }
  .c-grid .row.pd-pc-13 {
    margin-left: -13px;
    margin-right: -13px;
    margin-top: -26px;
  }
  .c-grid .row.pd-pc-13 > [class^="col"] {
    padding-left: 13px;
    padding-right: 13px;
    margin-top: 26px;
  }
  .c-grid .row.pd-pc-14 {
    margin-left: -14px;
    margin-right: -14px;
    margin-top: -28px;
  }
  .c-grid .row.pd-pc-14 > [class^="col"] {
    padding-left: 14px;
    padding-right: 14px;
    margin-top: 28px;
  }
  .c-grid .row.pd-pc-15 {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -30px;
  }
  .c-grid .row.pd-pc-15 > [class^="col"] {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
  }
  .c-grid .row.pd-pc-16 {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -32px;
  }
  .c-grid .row.pd-pc-16 > [class^="col"] {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 32px;
  }
  .c-grid .row.pd-pc-17 {
    margin-left: -17px;
    margin-right: -17px;
    margin-top: -34px;
  }
  .c-grid .row.pd-pc-17 > [class^="col"] {
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 34px;
  }
  .c-grid .row.pd-pc-18 {
    margin-left: -18px;
    margin-right: -18px;
    margin-top: -36px;
  }
  .c-grid .row.pd-pc-18 > [class^="col"] {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 36px;
  }
  .c-grid .row.pd-pc-19 {
    margin-left: -19px;
    margin-right: -19px;
    margin-top: -38px;
  }
  .c-grid .row.pd-pc-19 > [class^="col"] {
    padding-left: 19px;
    padding-right: 19px;
    margin-top: 38px;
  }
  .c-grid .row.pd-pc-20 {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -40px;
  }
  .c-grid .row.pd-pc-20 > [class^="col"] {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;
  }
  .c-grid .row.pd-pc-21 {
    margin-left: -21px;
    margin-right: -21px;
    margin-top: -42px;
  }
  .c-grid .row.pd-pc-21 > [class^="col"] {
    padding-left: 21px;
    padding-right: 21px;
    margin-top: 42px;
  }
  .c-grid .row.pd-pc-22 {
    margin-left: -22px;
    margin-right: -22px;
    margin-top: -44px;
  }
  .c-grid .row.pd-pc-22 > [class^="col"] {
    padding-left: 22px;
    padding-right: 22px;
    margin-top: 44px;
  }
  .c-grid .row.pd-pc-23 {
    margin-left: -23px;
    margin-right: -23px;
    margin-top: -46px;
  }
  .c-grid .row.pd-pc-23 > [class^="col"] {
    padding-left: 23px;
    padding-right: 23px;
    margin-top: 46px;
  }
  .c-grid .row.pd-pc-24 {
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -48px;
  }
  .c-grid .row.pd-pc-24 > [class^="col"] {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 48px;
  }
  .c-grid .row.pd-pc-25 {
    margin-left: -25px;
    margin-right: -25px;
    margin-top: -50px;
  }
  .c-grid .row.pd-pc-25 > [class^="col"] {
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 50px;
  }
  .c-grid .row.pd-pc-26 {
    margin-left: -26px;
    margin-right: -26px;
    margin-top: -52px;
  }
  .c-grid .row.pd-pc-26 > [class^="col"] {
    padding-left: 26px;
    padding-right: 26px;
    margin-top: 52px;
  }
  .c-grid .row.pd-pc-27 {
    margin-left: -27px;
    margin-right: -27px;
    margin-top: -54px;
  }
  .c-grid .row.pd-pc-27 > [class^="col"] {
    padding-left: 27px;
    padding-right: 27px;
    margin-top: 54px;
  }
  .c-grid .row.pd-pc-28 {
    margin-left: -28px;
    margin-right: -28px;
    margin-top: -56px;
  }
  .c-grid .row.pd-pc-28 > [class^="col"] {
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 56px;
  }
  .c-grid .row.pd-pc-29 {
    margin-left: -29px;
    margin-right: -29px;
    margin-top: -58px;
  }
  .c-grid .row.pd-pc-29 > [class^="col"] {
    padding-left: 29px;
    padding-right: 29px;
    margin-top: 58px;
  }
  .c-grid .row.pd-pc-30 {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -60px;
  }
  .c-grid .row.pd-pc-30 > [class^="col"] {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 60px;
  }
  .c-grid .row.pd-pc-31 {
    margin-left: -31px;
    margin-right: -31px;
    margin-top: -62px;
  }
  .c-grid .row.pd-pc-31 > [class^="col"] {
    padding-left: 31px;
    padding-right: 31px;
    margin-top: 62px;
  }
  .c-grid .row.pd-pc-32 {
    margin-left: -32px;
    margin-right: -32px;
    margin-top: -64px;
  }
  .c-grid .row.pd-pc-32 > [class^="col"] {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 64px;
  }
  .c-grid .row.pd-pc-33 {
    margin-left: -33px;
    margin-right: -33px;
    margin-top: -66px;
  }
  .c-grid .row.pd-pc-33 > [class^="col"] {
    padding-left: 33px;
    padding-right: 33px;
    margin-top: 66px;
  }
  .c-grid .row.pd-pc-34 {
    margin-left: -34px;
    margin-right: -34px;
    margin-top: -68px;
  }
  .c-grid .row.pd-pc-34 > [class^="col"] {
    padding-left: 34px;
    padding-right: 34px;
    margin-top: 68px;
  }
  .c-grid .row.pd-pc-35 {
    margin-left: -35px;
    margin-right: -35px;
    margin-top: -70px;
  }
  .c-grid .row.pd-pc-35 > [class^="col"] {
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 70px;
  }
  .c-grid .row.pd-pc-36 {
    margin-left: -36px;
    margin-right: -36px;
    margin-top: -72px;
  }
  .c-grid .row.pd-pc-36 > [class^="col"] {
    padding-left: 36px;
    padding-right: 36px;
    margin-top: 72px;
  }
  .c-grid .row.pd-pc-37 {
    margin-left: -37px;
    margin-right: -37px;
    margin-top: -74px;
  }
  .c-grid .row.pd-pc-37 > [class^="col"] {
    padding-left: 37px;
    padding-right: 37px;
    margin-top: 74px;
  }
  .c-grid .row.pd-pc-38 {
    margin-left: -38px;
    margin-right: -38px;
    margin-top: -76px;
  }
  .c-grid .row.pd-pc-38 > [class^="col"] {
    padding-left: 38px;
    padding-right: 38px;
    margin-top: 76px;
  }
  .c-grid .row.pd-pc-39 {
    margin-left: -39px;
    margin-right: -39px;
    margin-top: -78px;
  }
  .c-grid .row.pd-pc-39 > [class^="col"] {
    padding-left: 39px;
    padding-right: 39px;
    margin-top: 78px;
  }
  .c-grid .row.pd-pc-40 {
    margin-left: -40px;
    margin-right: -40px;
    margin-top: -80px;
  }
  .c-grid .row.pd-pc-40 > [class^="col"] {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 80px;
  }
  .c-grid .row.pd-pc-41 {
    margin-left: -41px;
    margin-right: -41px;
    margin-top: -82px;
  }
  .c-grid .row.pd-pc-41 > [class^="col"] {
    padding-left: 41px;
    padding-right: 41px;
    margin-top: 82px;
  }
  .c-grid .row.pd-pc-42 {
    margin-left: -42px;
    margin-right: -42px;
    margin-top: -84px;
  }
  .c-grid .row.pd-pc-42 > [class^="col"] {
    padding-left: 42px;
    padding-right: 42px;
    margin-top: 84px;
  }
  .c-grid .row.pd-pc-43 {
    margin-left: -43px;
    margin-right: -43px;
    margin-top: -86px;
  }
  .c-grid .row.pd-pc-43 > [class^="col"] {
    padding-left: 43px;
    padding-right: 43px;
    margin-top: 86px;
  }
  .c-grid .row.pd-pc-44 {
    margin-left: -44px;
    margin-right: -44px;
    margin-top: -88px;
  }
  .c-grid .row.pd-pc-44 > [class^="col"] {
    padding-left: 44px;
    padding-right: 44px;
    margin-top: 88px;
  }
  .c-grid .row.pd-pc-45 {
    margin-left: -45px;
    margin-right: -45px;
    margin-top: -90px;
  }
  .c-grid .row.pd-pc-45 > [class^="col"] {
    padding-left: 45px;
    padding-right: 45px;
    margin-top: 90px;
  }
  .c-grid .row.pd-pc-46 {
    margin-left: -46px;
    margin-right: -46px;
    margin-top: -92px;
  }
  .c-grid .row.pd-pc-46 > [class^="col"] {
    padding-left: 46px;
    padding-right: 46px;
    margin-top: 92px;
  }
  .c-grid .row.pd-pc-47 {
    margin-left: -47px;
    margin-right: -47px;
    margin-top: -94px;
  }
  .c-grid .row.pd-pc-47 > [class^="col"] {
    padding-left: 47px;
    padding-right: 47px;
    margin-top: 94px;
  }
  .c-grid .row.pd-pc-48 {
    margin-left: -48px;
    margin-right: -48px;
    margin-top: -96px;
  }
  .c-grid .row.pd-pc-48 > [class^="col"] {
    padding-left: 48px;
    padding-right: 48px;
    margin-top: 96px;
  }
  .c-grid .row.pd-pc-49 {
    margin-left: -49px;
    margin-right: -49px;
    margin-top: -98px;
  }
  .c-grid .row.pd-pc-49 > [class^="col"] {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 98px;
  }
  .c-grid .row.pd-pc-50 {
    margin-left: -50px;
    margin-right: -50px;
    margin-top: -100px;
  }
  .c-grid .row.pd-pc-50 > [class^="col"] {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 100px;
  }
  .c-grid .row.pd-pc-51 {
    margin-left: -51px;
    margin-right: -51px;
    margin-top: -102px;
  }
  .c-grid .row.pd-pc-51 > [class^="col"] {
    padding-left: 51px;
    padding-right: 51px;
    margin-top: 102px;
  }
  .c-grid .row.pd-pc-52 {
    margin-left: -52px;
    margin-right: -52px;
    margin-top: -104px;
  }
  .c-grid .row.pd-pc-52 > [class^="col"] {
    padding-left: 52px;
    padding-right: 52px;
    margin-top: 104px;
  }
  .c-grid .row.pd-pc-53 {
    margin-left: -53px;
    margin-right: -53px;
    margin-top: -106px;
  }
  .c-grid .row.pd-pc-53 > [class^="col"] {
    padding-left: 53px;
    padding-right: 53px;
    margin-top: 106px;
  }
  .c-grid .row.pd-pc-54 {
    margin-left: -54px;
    margin-right: -54px;
    margin-top: -108px;
  }
  .c-grid .row.pd-pc-54 > [class^="col"] {
    padding-left: 54px;
    padding-right: 54px;
    margin-top: 108px;
  }
  .c-grid .row.pd-pc-55 {
    margin-left: -55px;
    margin-right: -55px;
    margin-top: -110px;
  }
  .c-grid .row.pd-pc-55 > [class^="col"] {
    padding-left: 55px;
    padding-right: 55px;
    margin-top: 110px;
  }
  .c-grid .row.pd-pc-56 {
    margin-left: -56px;
    margin-right: -56px;
    margin-top: -112px;
  }
  .c-grid .row.pd-pc-56 > [class^="col"] {
    padding-left: 56px;
    padding-right: 56px;
    margin-top: 112px;
  }
  .c-grid .row.pd-pc-57 {
    margin-left: -57px;
    margin-right: -57px;
    margin-top: -114px;
  }
  .c-grid .row.pd-pc-57 > [class^="col"] {
    padding-left: 57px;
    padding-right: 57px;
    margin-top: 114px;
  }
  .c-grid .row.pd-pc-58 {
    margin-left: -58px;
    margin-right: -58px;
    margin-top: -116px;
  }
  .c-grid .row.pd-pc-58 > [class^="col"] {
    padding-left: 58px;
    padding-right: 58px;
    margin-top: 116px;
  }
  .c-grid .row.pd-pc-59 {
    margin-left: -59px;
    margin-right: -59px;
    margin-top: -118px;
  }
  .c-grid .row.pd-pc-59 > [class^="col"] {
    padding-left: 59px;
    padding-right: 59px;
    margin-top: 118px;
  }
  .c-grid .row.pd-pc-60 {
    margin-left: -60px;
    margin-right: -60px;
    margin-top: -120px;
  }
  .c-grid .row.pd-pc-60 > [class^="col"] {
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 120px;
  }
}

@media (max-width: 896px) {
  .c-grid .row.pd-sp-0 {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .c-grid .row.pd-sp-0 > [class^="col"] {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
  }
  .c-grid .row.pd-sp-1 {
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -2px;
  }
  .c-grid .row.pd-sp-1 > [class^="col"] {
    padding-left: 1px;
    padding-right: 1px;
    margin-top: 2px;
  }
  .c-grid .row.pd-sp-2 {
    margin-left: -2px;
    margin-right: -2px;
    margin-top: -4px;
  }
  .c-grid .row.pd-sp-2 > [class^="col"] {
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 4px;
  }
  .c-grid .row.pd-sp-3 {
    margin-left: -3px;
    margin-right: -3px;
    margin-top: -6px;
  }
  .c-grid .row.pd-sp-3 > [class^="col"] {
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 6px;
  }
  .c-grid .row.pd-sp-4 {
    margin-left: -4px;
    margin-right: -4px;
    margin-top: -8px;
  }
  .c-grid .row.pd-sp-4 > [class^="col"] {
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 8px;
  }
  .c-grid .row.pd-sp-5 {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -10px;
  }
  .c-grid .row.pd-sp-5 > [class^="col"] {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .c-grid .row.pd-sp-6 {
    margin-left: -6px;
    margin-right: -6px;
    margin-top: -12px;
  }
  .c-grid .row.pd-sp-6 > [class^="col"] {
    padding-left: 6px;
    padding-right: 6px;
    margin-top: 12px;
  }
  .c-grid .row.pd-sp-7 {
    margin-left: -7px;
    margin-right: -7px;
    margin-top: -14px;
  }
  .c-grid .row.pd-sp-7 > [class^="col"] {
    padding-left: 7px;
    padding-right: 7px;
    margin-top: 14px;
  }
  .c-grid .row.pd-sp-8 {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -16px;
  }
  .c-grid .row.pd-sp-8 > [class^="col"] {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 16px;
  }
  .c-grid .row.pd-sp-9 {
    margin-left: -9px;
    margin-right: -9px;
    margin-top: -18px;
  }
  .c-grid .row.pd-sp-9 > [class^="col"] {
    padding-left: 9px;
    padding-right: 9px;
    margin-top: 18px;
  }
  .c-grid .row.pd-sp-10 {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -20px;
  }
  .c-grid .row.pd-sp-10 > [class^="col"] {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
  }
  .c-grid .row.pd-sp-11 {
    margin-left: -11px;
    margin-right: -11px;
    margin-top: -22px;
  }
  .c-grid .row.pd-sp-11 > [class^="col"] {
    padding-left: 11px;
    padding-right: 11px;
    margin-top: 22px;
  }
  .c-grid .row.pd-sp-12 {
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -24px;
  }
  .c-grid .row.pd-sp-12 > [class^="col"] {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 24px;
  }
  .c-grid .row.pd-sp-13 {
    margin-left: -13px;
    margin-right: -13px;
    margin-top: -26px;
  }
  .c-grid .row.pd-sp-13 > [class^="col"] {
    padding-left: 13px;
    padding-right: 13px;
    margin-top: 26px;
  }
  .c-grid .row.pd-sp-14 {
    margin-left: -14px;
    margin-right: -14px;
    margin-top: -28px;
  }
  .c-grid .row.pd-sp-14 > [class^="col"] {
    padding-left: 14px;
    padding-right: 14px;
    margin-top: 28px;
  }
  .c-grid .row.pd-sp-15 {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -30px;
  }
  .c-grid .row.pd-sp-15 > [class^="col"] {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
  }
  .c-grid .row.pd-sp-16 {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -32px;
  }
  .c-grid .row.pd-sp-16 > [class^="col"] {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 32px;
  }
  .c-grid .row.pd-sp-17 {
    margin-left: -17px;
    margin-right: -17px;
    margin-top: -34px;
  }
  .c-grid .row.pd-sp-17 > [class^="col"] {
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 34px;
  }
  .c-grid .row.pd-sp-18 {
    margin-left: -18px;
    margin-right: -18px;
    margin-top: -36px;
  }
  .c-grid .row.pd-sp-18 > [class^="col"] {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 36px;
  }
  .c-grid .row.pd-sp-19 {
    margin-left: -19px;
    margin-right: -19px;
    margin-top: -38px;
  }
  .c-grid .row.pd-sp-19 > [class^="col"] {
    padding-left: 19px;
    padding-right: 19px;
    margin-top: 38px;
  }
  .c-grid .row.pd-sp-20 {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -40px;
  }
  .c-grid .row.pd-sp-20 > [class^="col"] {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;
  }
  .c-grid .row.pd-sp-21 {
    margin-left: -21px;
    margin-right: -21px;
    margin-top: -42px;
  }
  .c-grid .row.pd-sp-21 > [class^="col"] {
    padding-left: 21px;
    padding-right: 21px;
    margin-top: 42px;
  }
  .c-grid .row.pd-sp-22 {
    margin-left: -22px;
    margin-right: -22px;
    margin-top: -44px;
  }
  .c-grid .row.pd-sp-22 > [class^="col"] {
    padding-left: 22px;
    padding-right: 22px;
    margin-top: 44px;
  }
  .c-grid .row.pd-sp-23 {
    margin-left: -23px;
    margin-right: -23px;
    margin-top: -46px;
  }
  .c-grid .row.pd-sp-23 > [class^="col"] {
    padding-left: 23px;
    padding-right: 23px;
    margin-top: 46px;
  }
  .c-grid .row.pd-sp-24 {
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -48px;
  }
  .c-grid .row.pd-sp-24 > [class^="col"] {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 48px;
  }
  .c-grid .row.pd-sp-25 {
    margin-left: -25px;
    margin-right: -25px;
    margin-top: -50px;
  }
  .c-grid .row.pd-sp-25 > [class^="col"] {
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 50px;
  }
  .c-grid .row.pd-sp-26 {
    margin-left: -26px;
    margin-right: -26px;
    margin-top: -52px;
  }
  .c-grid .row.pd-sp-26 > [class^="col"] {
    padding-left: 26px;
    padding-right: 26px;
    margin-top: 52px;
  }
  .c-grid .row.pd-sp-27 {
    margin-left: -27px;
    margin-right: -27px;
    margin-top: -54px;
  }
  .c-grid .row.pd-sp-27 > [class^="col"] {
    padding-left: 27px;
    padding-right: 27px;
    margin-top: 54px;
  }
  .c-grid .row.pd-sp-28 {
    margin-left: -28px;
    margin-right: -28px;
    margin-top: -56px;
  }
  .c-grid .row.pd-sp-28 > [class^="col"] {
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 56px;
  }
  .c-grid .row.pd-sp-29 {
    margin-left: -29px;
    margin-right: -29px;
    margin-top: -58px;
  }
  .c-grid .row.pd-sp-29 > [class^="col"] {
    padding-left: 29px;
    padding-right: 29px;
    margin-top: 58px;
  }
  .c-grid .row.pd-sp-30 {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -60px;
  }
  .c-grid .row.pd-sp-30 > [class^="col"] {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 60px;
  }
  .c-grid .row.pd-sp-31 {
    margin-left: -31px;
    margin-right: -31px;
    margin-top: -62px;
  }
  .c-grid .row.pd-sp-31 > [class^="col"] {
    padding-left: 31px;
    padding-right: 31px;
    margin-top: 62px;
  }
  .c-grid .row.pd-sp-32 {
    margin-left: -32px;
    margin-right: -32px;
    margin-top: -64px;
  }
  .c-grid .row.pd-sp-32 > [class^="col"] {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 64px;
  }
  .c-grid .row.pd-sp-33 {
    margin-left: -33px;
    margin-right: -33px;
    margin-top: -66px;
  }
  .c-grid .row.pd-sp-33 > [class^="col"] {
    padding-left: 33px;
    padding-right: 33px;
    margin-top: 66px;
  }
  .c-grid .row.pd-sp-34 {
    margin-left: -34px;
    margin-right: -34px;
    margin-top: -68px;
  }
  .c-grid .row.pd-sp-34 > [class^="col"] {
    padding-left: 34px;
    padding-right: 34px;
    margin-top: 68px;
  }
  .c-grid .row.pd-sp-35 {
    margin-left: -35px;
    margin-right: -35px;
    margin-top: -70px;
  }
  .c-grid .row.pd-sp-35 > [class^="col"] {
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 70px;
  }
  .c-grid .row.pd-sp-36 {
    margin-left: -36px;
    margin-right: -36px;
    margin-top: -72px;
  }
  .c-grid .row.pd-sp-36 > [class^="col"] {
    padding-left: 36px;
    padding-right: 36px;
    margin-top: 72px;
  }
  .c-grid .row.pd-sp-37 {
    margin-left: -37px;
    margin-right: -37px;
    margin-top: -74px;
  }
  .c-grid .row.pd-sp-37 > [class^="col"] {
    padding-left: 37px;
    padding-right: 37px;
    margin-top: 74px;
  }
  .c-grid .row.pd-sp-38 {
    margin-left: -38px;
    margin-right: -38px;
    margin-top: -76px;
  }
  .c-grid .row.pd-sp-38 > [class^="col"] {
    padding-left: 38px;
    padding-right: 38px;
    margin-top: 76px;
  }
  .c-grid .row.pd-sp-39 {
    margin-left: -39px;
    margin-right: -39px;
    margin-top: -78px;
  }
  .c-grid .row.pd-sp-39 > [class^="col"] {
    padding-left: 39px;
    padding-right: 39px;
    margin-top: 78px;
  }
  .c-grid .row.pd-sp-40 {
    margin-left: -40px;
    margin-right: -40px;
    margin-top: -80px;
  }
  .c-grid .row.pd-sp-40 > [class^="col"] {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 80px;
  }
  .c-grid .row.pd-sp-41 {
    margin-left: -41px;
    margin-right: -41px;
    margin-top: -82px;
  }
  .c-grid .row.pd-sp-41 > [class^="col"] {
    padding-left: 41px;
    padding-right: 41px;
    margin-top: 82px;
  }
  .c-grid .row.pd-sp-42 {
    margin-left: -42px;
    margin-right: -42px;
    margin-top: -84px;
  }
  .c-grid .row.pd-sp-42 > [class^="col"] {
    padding-left: 42px;
    padding-right: 42px;
    margin-top: 84px;
  }
  .c-grid .row.pd-sp-43 {
    margin-left: -43px;
    margin-right: -43px;
    margin-top: -86px;
  }
  .c-grid .row.pd-sp-43 > [class^="col"] {
    padding-left: 43px;
    padding-right: 43px;
    margin-top: 86px;
  }
  .c-grid .row.pd-sp-44 {
    margin-left: -44px;
    margin-right: -44px;
    margin-top: -88px;
  }
  .c-grid .row.pd-sp-44 > [class^="col"] {
    padding-left: 44px;
    padding-right: 44px;
    margin-top: 88px;
  }
  .c-grid .row.pd-sp-45 {
    margin-left: -45px;
    margin-right: -45px;
    margin-top: -90px;
  }
  .c-grid .row.pd-sp-45 > [class^="col"] {
    padding-left: 45px;
    padding-right: 45px;
    margin-top: 90px;
  }
  .c-grid .row.pd-sp-46 {
    margin-left: -46px;
    margin-right: -46px;
    margin-top: -92px;
  }
  .c-grid .row.pd-sp-46 > [class^="col"] {
    padding-left: 46px;
    padding-right: 46px;
    margin-top: 92px;
  }
  .c-grid .row.pd-sp-47 {
    margin-left: -47px;
    margin-right: -47px;
    margin-top: -94px;
  }
  .c-grid .row.pd-sp-47 > [class^="col"] {
    padding-left: 47px;
    padding-right: 47px;
    margin-top: 94px;
  }
  .c-grid .row.pd-sp-48 {
    margin-left: -48px;
    margin-right: -48px;
    margin-top: -96px;
  }
  .c-grid .row.pd-sp-48 > [class^="col"] {
    padding-left: 48px;
    padding-right: 48px;
    margin-top: 96px;
  }
  .c-grid .row.pd-sp-49 {
    margin-left: -49px;
    margin-right: -49px;
    margin-top: -98px;
  }
  .c-grid .row.pd-sp-49 > [class^="col"] {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 98px;
  }
  .c-grid .row.pd-sp-50 {
    margin-left: -50px;
    margin-right: -50px;
    margin-top: -100px;
  }
  .c-grid .row.pd-sp-50 > [class^="col"] {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 100px;
  }
  .c-grid .row.pd-sp-51 {
    margin-left: -51px;
    margin-right: -51px;
    margin-top: -102px;
  }
  .c-grid .row.pd-sp-51 > [class^="col"] {
    padding-left: 51px;
    padding-right: 51px;
    margin-top: 102px;
  }
  .c-grid .row.pd-sp-52 {
    margin-left: -52px;
    margin-right: -52px;
    margin-top: -104px;
  }
  .c-grid .row.pd-sp-52 > [class^="col"] {
    padding-left: 52px;
    padding-right: 52px;
    margin-top: 104px;
  }
  .c-grid .row.pd-sp-53 {
    margin-left: -53px;
    margin-right: -53px;
    margin-top: -106px;
  }
  .c-grid .row.pd-sp-53 > [class^="col"] {
    padding-left: 53px;
    padding-right: 53px;
    margin-top: 106px;
  }
  .c-grid .row.pd-sp-54 {
    margin-left: -54px;
    margin-right: -54px;
    margin-top: -108px;
  }
  .c-grid .row.pd-sp-54 > [class^="col"] {
    padding-left: 54px;
    padding-right: 54px;
    margin-top: 108px;
  }
  .c-grid .row.pd-sp-55 {
    margin-left: -55px;
    margin-right: -55px;
    margin-top: -110px;
  }
  .c-grid .row.pd-sp-55 > [class^="col"] {
    padding-left: 55px;
    padding-right: 55px;
    margin-top: 110px;
  }
  .c-grid .row.pd-sp-56 {
    margin-left: -56px;
    margin-right: -56px;
    margin-top: -112px;
  }
  .c-grid .row.pd-sp-56 > [class^="col"] {
    padding-left: 56px;
    padding-right: 56px;
    margin-top: 112px;
  }
  .c-grid .row.pd-sp-57 {
    margin-left: -57px;
    margin-right: -57px;
    margin-top: -114px;
  }
  .c-grid .row.pd-sp-57 > [class^="col"] {
    padding-left: 57px;
    padding-right: 57px;
    margin-top: 114px;
  }
  .c-grid .row.pd-sp-58 {
    margin-left: -58px;
    margin-right: -58px;
    margin-top: -116px;
  }
  .c-grid .row.pd-sp-58 > [class^="col"] {
    padding-left: 58px;
    padding-right: 58px;
    margin-top: 116px;
  }
  .c-grid .row.pd-sp-59 {
    margin-left: -59px;
    margin-right: -59px;
    margin-top: -118px;
  }
  .c-grid .row.pd-sp-59 > [class^="col"] {
    padding-left: 59px;
    padding-right: 59px;
    margin-top: 118px;
  }
  .c-grid .row.pd-sp-60 {
    margin-left: -60px;
    margin-right: -60px;
    margin-top: -120px;
  }
  .c-grid .row.pd-sp-60 > [class^="col"] {
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 120px;
  }
}

.c-grid .col {
  width: 100%;
}

.c-grid .col-12 {
  width: 100%;
}

.c-grid .col-11 {
  width: 91.66667%;
}

.c-grid .col-10 {
  width: 83.33333%;
}

.c-grid .col-9 {
  width: 75%;
}

.c-grid .col-8 {
  width: 66.66667%;
}

.c-grid .col-7 {
  width: 58.33333%;
}

.c-grid .col-6 {
  width: 50%;
}

.c-grid .col-5 {
  width: 41.66667%;
}

.c-grid .col-4 {
  width: 33.33333%;
}

.c-grid .col-3 {
  width: 25%;
}

.c-grid .col-2 {
  width: 16.66667%;
}

.c-grid .col-1 {
  width: 8.33333%;
}

@media (min-width: 897px) {
  .c-grid .col-pc-12 {
    width: 100%;
  }
  .c-grid .col-pc-11 {
    width: 91.66667%;
  }
  .c-grid .col-pc-10 {
    width: 83.33333%;
  }
  .c-grid .col-pc-9 {
    width: 75%;
  }
  .c-grid .col-pc-8 {
    width: 66.66667%;
  }
  .c-grid .col-pc-7 {
    width: 58.33333%;
  }
  .c-grid .col-pc-6 {
    width: 50%;
  }
  .c-grid .col-pc-5 {
    width: 41.66667%;
  }
  .c-grid .col-pc-4 {
    width: 33.33333%;
  }
  .c-grid .col-pc-3 {
    width: 25%;
  }
  .c-grid .col-pc-2 {
    width: 16.66667%;
  }
  .c-grid .col-pc-1 {
    width: 8.33333%;
  }
}

@media (max-width: 896px) {
  .c-grid .col-sp-12 {
    width: 100%;
  }
  .c-grid .col-sp-11 {
    width: 91.66667%;
  }
  .c-grid .col-sp-10 {
    width: 83.33333%;
  }
  .c-grid .col-sp-9 {
    width: 75%;
  }
  .c-grid .col-sp-8 {
    width: 66.66667%;
  }
  .c-grid .col-sp-7 {
    width: 58.33333%;
  }
  .c-grid .col-sp-6 {
    width: 50%;
  }
  .c-grid .col-sp-5 {
    width: 41.66667%;
  }
  .c-grid .col-sp-4 {
    width: 33.33333%;
  }
  .c-grid .col-sp-3 {
    width: 25%;
  }
  .c-grid .col-sp-2 {
    width: 16.66667%;
  }
  .c-grid .col-sp-1 {
    width: 8.33333%;
  }
}

/* ==========================================================
 components
========================================================== */
/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 accordion
========================================================== */
/* js-accordion
---------------------------------------------------------- */
@media (min-width: 897px) {
  .js-accordion {
    display: block;
  }
  .js-accordion .issue {
    display: none;
  }
}

@media (max-width: 896px) {
  .js-accordion.-sp .subject {
    position: relative;
  }
  .js-accordion.-sp .subject::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background: url(/assets/images/common/ico_acc_01.png) no-repeat;
    background-size: 100%;
    width: 21px;
    height: 21px;
  }
  .js-accordion.-sp .subject.-on::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background: url(/assets/images/common/ico_acc_02.png) no-repeat;
    background-size: 100%;
    width: 21px;
    height: 21px;
  }
  .js-accordion.-sp .issue {
    display: none;
    margin-top: 15px;
  }
}

.js-accordion-sub + .js-accordion-sub {
  margin-top: 1px;
}

.js-accordion-sub .issue-sub {
  display: none;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 Article
========================================================== */
/* c-article
---------------------------------------------------------- */
.c-article .sec-main {
  padding-top: 50px;
  padding-bottom: 40px;
}

@media (min-width: 897px) {
  .c-article .sec-main {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.c-article .heading {
  margin-bottom: 35px;
}

@media (min-width: 897px) {
  .c-article .heading {
    margin-bottom: 45px;
  }
}

.c-article .heading .cate {
  display: inline-block;
  padding: 2px 5px;
  border-top: 1px solid #2157a7;
  border-bottom: 1px solid #2157a7;
  font-size: 1.4rem;
  font-weight: bold;
  color: #2157a7;
}

@media (min-width: 897px) {
  .c-article .heading .cate {
    padding: 7px 10px;
    font-size: 1.6rem;
  }
}

.c-article .heading .title {
  margin-bottom: 10px;
}

.c-article .heading .title:last-child {
  margin-bottom: 0;
}

.c-article .sec {
  margin-bottom: 40px;
}

@media (min-width: 897px) {
  .c-article .sec {
    margin-bottom: 60px;
  }
}

.c-article p {
  margin-bottom: 30px;
}

.c-article p:last-child {
  margin-bottom: 0;
}

.c-article .box-link {
  display: block;
  margin-bottom: 50px;
}

@media (min-width: 897px) {
  .c-article .box-link {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .c-article .box-link:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .c-article .box-link {
    display: flex;
    margin-bottom: 90px;
  }
}

.c-article .box-link .label {
  display: block;
  padding: 10px;
  background-color: #1b4d96;
  font-weight: bold;
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
}

@media (min-width: 897px) {
  .c-article .box-link .label {
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 1.6rem;
    text-align: left;
  }
}

.c-article .box-link .detail {
  flex-grow: 1;
  display: block;
  position: relative;
  padding: 15px 25px;
  background-color: #2157a7;
  font-weight: bold;
  font-size: 1.6rem;
  color: #fff;
}

@media (min-width: 897px) {
  .c-article .box-link .detail {
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 2rem;
  }
}

.c-article .box-link .detail:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 13px;
  height: 12px;
  background: url("/assets/images/common/icon_arrow_tail_white.png") 0 0 no-repeat;
  background-size: 100% auto;
}

@media (min-width: 897px) {
  .c-article .box-link .detail:after {
    right: 25px;
    width: 18px;
    height: 16px;
  }
}

.c-article .box-shop-info .image {
  position: relative;
  z-index: 1;
  padding-top: 55%;
}

.c-article .box-shop-info .image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

@media (min-width: 897px) {
  .c-article .box-shop-info .detail {
    margin-top: -10px;
  }
}

.c-article .box-shop-info .title {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.6rem;
}

@media (min-width: 897px) {
  .c-article .box-shop-info .title {
    font-size: 2rem;
  }
}

.c-article .box-shop-info table {
  width: 100%;
  margin-bottom: 15px;
}

.c-article .box-shop-info table:last-child {
  margin-bottom: 0;
}

.c-article .box-shop-info table tr {
  border-bottom: 1px solid #e0e0e0;
}

.c-article .box-shop-info table tr:first-child {
  border-top: 1px solid #e0e0e0;
}

.c-article .box-shop-info table th, .c-article .box-shop-info table td {
  vertical-align: top;
  padding: 8px 0;
  font-size: 1.3rem;
}

@media (min-width: 897px) {
  .c-article .box-shop-info table th, .c-article .box-shop-info table td {
    font-size: 1.4rem;
  }
}

.c-article .box-shop-info table th {
  width: 110px;
}

.c-article .sec-relate {
  padding-top: 50px;
  padding-bottom: 95px;
  background-color: #fff;
}

@media (min-width: 897px) {
  .c-article .sec-relate {
    padding-top: 80px;
    padding-bottom: 150px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 box
========================================================== */
/* c-border
---------------------------------------------------------- */
.c-border-bottom {
  border-bottom: 1px solid #f3f3f3;
}

.c-border-top {
  border-bottom: 1px solid #f3f3f3;
}

.c-border-all {
  border: 1px solid #f3f3f3;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 box
========================================================== */
/* c-box-white-shadow
---------------------------------------------------------- */
.c-box-white-shadow {
  padding: 30px 25px 40px;
  margin-bottom: 50px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.c-box-white-shadow:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-box-white-shadow {
    padding: 80px 7% 90px;
    margin-bottom: 70px;
  }
}

/* c-box-dark
---------------------------------------------------------- */
.c-box-dark {
  margin-bottom: 25px;
  background-color: #f6f7f9;
  padding: 20px 20px 25px;
}

@media (min-width: 897px) {
  .c-box-dark {
    padding: 45px 50px 40px;
  }
}

/* c-box-contact
---------------------------------------------------------- */
@media (min-width: 897px) {
  .c-box-contact {
    display: flex;
    align-items: center;
    width: 555px;
    margin: 0 auto;
  }
}

.c-box-contact .title {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

@media (min-width: 897px) {
  .c-box-contact .title {
    width: 175px;
    flex: 1 0 auto;
    font-size: 2rem;
    padding-bottom: 0;
    text-align: left;
  }
}

.c-box-contact .title:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 30px;
  height: 2px;
  margin: 0 auto;
  background-color: #e0e0e0;
}

@media (min-width: 897px) {
  .c-box-contact .title:after {
    top: -13px;
    left: inherit;
    width: 2px;
    height: 63px;
  }
}

.c-box-contact .box {
  text-align: center;
}

@media (min-width: 897px) {
  .c-box-contact .box {
    width: 100%;
    text-align: left;
    padding-left: 40px;
  }
}

.c-box-contact .box .c-tel-number {
  line-height: 1.2;
}

/* c-box-accd
---------------------------------------------------------- */
.c-box-accd {
  margin-bottom: 20px;
  background-color: #fff;
}

.c-box-accd:last-child {
  margin-bottom: 0;
}

.c-box-accd .accd-heading {
  position: relative;
  cursor: pointer;
}

.c-box-accd .accd-heading .inner {
  padding: 25px 15px 15px;
}

@media (min-width: 897px) {
  .c-box-accd .accd-heading .inner {
    padding: 35px 100px 35px 40px;
  }
}

@media (min-width: 897px) {
  .c-box-accd .accd-heading:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-image: url("/assets/images/common/icon_accd_black.png");
    background-size: 100% auto;
    background-position: 0 0;
    background-repeat: no-repeat;
  }
}

.c-box-accd .accd-heading .c-ttl-n-label {
  margin-bottom: 5px;
}

@media (min-width: 897px) {
  .c-box-accd .accd-heading .c-ttl-n-label {
    margin-bottom: 10px;
  }
}

@media (max-width: 896px) {
  .c-box-accd .accd-heading .c-ttl-n-label .label {
    margin-bottom: 10px;
  }
}

.c-box-accd .accd-heading.-on {
  background-color: #228bdb;
}

.c-box-accd .accd-heading.-on .c-ttl-n-label {
  color: #fff;
}

.c-box-accd .accd-heading.-on .c-ttl-n-label .label {
  color: #fff;
  border-color: #fff;
}

.c-box-accd .accd-heading.-on .c-ttl-03 .text {
  color: #fff;
}

@media (max-width: 896px) {
  .c-box-accd .accd-heading.-on .accd-btn.-see_detail {
    display: none;
  }
}

@media (min-width: 897px) {
  .c-box-accd .accd-heading.-on:after {
    background-image: url("/assets/images/common/icon_accd_white_on.png");
  }
}

.c-box-accd .accd-content {
  display: none;
  padding: 25px 15px;
  border-left: 2px solid #228bdb;
  border-right: 2px solid #228bdb;
  border-top: 0;
}

@media (min-width: 897px) {
  .c-box-accd .accd-content {
    padding: 50px;
    border-width: 4px;
  }
}

.c-box-accd .accd-btn {
  position: relative;
  padding: 15px;
  background-color: #228bdb;
  color: #fff;
  line-height: 1;
  cursor: pointer;
}

@media (min-width: 897px) {
  .c-box-accd .accd-btn {
    padding: 16px;
    text-align: center;
  }
}

@media (max-width: 896px) {
  .c-box-accd .accd-btn:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    background-image: url("/assets/images/common/icon_plus_white.png");
    background-size: 100% auto;
    background-position: 0 0;
    background-repeat: no-repeat;
  }
}

.c-box-accd .accd-btn .text {
  display: block;
  color: #fff;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
}

@media (min-width: 897px) {
  .c-box-accd .accd-btn .text {
    display: inline-block;
    align-items: center;
    font-size: 1.6rem;
  }
  .c-box-accd .accd-btn .text .icon-x {
    position: relative;
    top: -2px;
    display: inline-block;
    margin-left: 5px;
    font-weight: normal;
  }
}

@media (min-width: 897px) {
  .c-box-accd .accd-btn.-see_detail {
    display: none;
  }
}

.c-box-accd .accd-btn.-close {
  display: none;
}

@media (max-width: 896px) {
  .c-box-accd .accd-btn.-close:after {
    background-image: url("/assets/images/common/icon_minus_white.png");
  }
}

/* c-box-balloon
---------------------------------------------------------- */
.c-box-balloon {
  position: relative;
  display: inline-block;
  padding: 20px;
  margin-bottom: 25px;
  border: 1px solid #2157a7;
  border-radius: 10px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #2157a7;
}

.c-box-balloon:after {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  width: 26px;
  height: 14px;
  background: url("/assets/images/common/icon_balloon_tail.png") 0 0 no-repeat;
  background-size: 100% auto;
}

@media (min-width: 897px) {
  .c-box-balloon {
    margin-bottom: 30px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 button
========================================================== */
/* c-btn
---------------------------------------------------------- */
.c-btn {
  position: relative;
  display: inline-block;
  padding: 15px 45px 15px 15px;
  background-color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.5;
  color: #2157a7;
  text-align: center;
  appearance: none;
}

@media (min-width: 897px) {
  .c-btn {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .c-btn:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

@media (max-width: 896px) {
  .c-btn {
    display: block;
  }
}

@media (min-width: 897px) {
  .c-btn {
    min-width: 400px;
    max-width: 100%;
    padding: 18px 40px 18px 18px;
    font-size: 1.6rem;
  }
  .c-btn.-active:hover {
    opacity: 1;
  }
}

.c-btn .text {
  display: inline-flex;
  align-items: center;
}

.c-btn .text .icon {
  margin-right: 10px;
}

.c-btn.-blue {
  background-color: #2157a7;
  color: #fff;
}

.c-btn.-orange {
  background-color: #f93b00;
  color: #fff;
}

.c-btn.-orang-light {
  background-color: #f98700;
  color: #fff;
}

.c-btn.-arrow_right:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 7px;
  height: 11px;
  background: url("/assets/images/common/icon_arrow_right_white.png") 0 0 no-repeat;
  background-size: 100% auto;
}

.c-btn.-arrow_down:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 11px;
  height: 7px;
  background: url("/assets/images/common/icon_arrow_down_white.png") 0 0 no-repeat;
  background-size: 100% auto;
}

.c-btn.d-block {
  width: 100% !important;
  min-width: 0;
}

/* ==========================================================
 button-group
========================================================== */
/* c-btn-group
---------------------------------------------------------- */
.c-btn-group {
  margin-bottom: 50px;
}

.c-btn-group:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-btn-group {
    width: 830px;
    margin: 0 auto 100px;
  }
}

@media (max-width: 896px) {
  .c-btn-group .row > [class^="col"]:last-child {
    margin-bottom: 0;
  }
}

/* c-btn-tabnav
---------------------------------------------------------- */
.c-btn-tabnav {
  margin-bottom: 25px;
}

.c-btn-tabnav:last-child {
  margin-bottom: 0;
}

.c-btn-tabnav .c-btn.-active {
  background-color: #2157a7;
  color: #fff;
  cursor: default;
}

.c-btn-tabnav .c-btn.-denki {
  color: #f98700;
}

.c-btn-tabnav .c-btn.-denki.-active {
  background-color: #f98700;
  color: #fff;
}

.c-btn-tabnav .c-btn.-gas {
  color: #2157a7;
}

.c-btn-tabnav .c-btn.-gas.-active {
  background-color: #2157a7;
  color: #fff;
}

.c-btn-tabnav.-blue .c-btn.-active {
  background-color: #2157a7;
  color: #fff;
}

.c-btn-tabnav.-flow .c-btn {
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 896px) {
  .c-btn-tabnav.-flow .c-btn .text {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 35px 23px;
    padding-top: 30px;
  }
}

@media (min-width: 897px) {
  .c-btn-tabnav.-flow .c-btn .text {
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 70px 46px;
    padding-left: 90px;
  }
}

@media (max-width: 896px) {
  .c-btn-tabnav.-flow .c-btn.-current .text {
    background-image: url("/assets/images/flow/ico_flow_denki_no.png");
  }
}

@media (min-width: 897px) {
  .c-btn-tabnav.-flow .c-btn.-current .text {
    background-image: url("/assets/images/flow/ico_flow_denki_no.png");
  }
}

@media (max-width: 896px) {
  .c-btn-tabnav.-flow .c-btn.-current.-active .text {
    background-image: url("/assets/images/flow/ico_flow_denki_on.png");
  }
}

@media (min-width: 897px) {
  .c-btn-tabnav.-flow .c-btn.-current.-active .text {
    background-image: url("/assets/images/flow/ico_flow_denki_on.png");
  }
}

@media (max-width: 896px) {
  .c-btn-tabnav.-flow .c-btn.-new .text {
    background-image: url("/assets/images/flow/ico_flow_house_no.png");
  }
}

@media (min-width: 897px) {
  .c-btn-tabnav.-flow .c-btn.-new .text {
    background-image: url("/assets/images/flow/ico_flow_house_no.png");
  }
}

@media (max-width: 896px) {
  .c-btn-tabnav.-flow .c-btn.-new.-active .text {
    background-image: url("/assets/images/flow/ico_flow_house_on.png");
  }
}

@media (min-width: 897px) {
  .c-btn-tabnav.-flow .c-btn.-new.-active .text {
    background-image: url("/assets/images/flow/ico_flow_house_on.png");
  }
}

@media (min-width: 897px) {
  .c-btn-tabnav.-flow {
    width: 830px;
  }
}

@media (min-width: 897px) {
  .c-btn-tabnav {
    width: 700px;
    margin: 0 auto 50px;
  }
}

/* c-btn-group-anchor
---------------------------------------------------------- */
.c-btn-group-anchor {
  margin-top: -1px;
  margin-bottom: 25px;
}

.c-btn-group-anchor:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-btn-group-anchor {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
  }
}

.c-btn-group-anchor > .item {
  width: 100%;
  margin-top: 1px;
}

@media (min-width: 897px) {
  .c-btn-group-anchor > .item {
    width: 235px;
    margin-top: 1px;
    padding-left: 1px;
  }
  .c-btn-group-anchor > .item:first-child {
    padding-left: 0;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 list
========================================================== */
/* c-card-image
---------------------------------------------------------- */
.c-card-image > * {
  display: block;
}

.c-card-image .image {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.c-card-image .image.-border {
  border: 1px solid #ebebeb;
}

.c-card-image .image img {
  transition: transform .3s;
  width: 100%;
}

.c-card-image .detail {
  padding-top: 15px;
}

.c-card-image .ttl-blue {
  margin-bottom: 15px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.35;
  color: #2157a7;
}

.c-card-image .ttl-blue:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-card-image .ttl-blue {
    font-size: 2.4rem;
  }
}

.c-card-image .txt-detail {
  font-weight: bold;
  color: #111;
}

.c-card-image.-frame {
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.c-card-image.-frame a {
  transition: background-color .2s;
}

.c-card-image.-frame a:hover {
  background-color: #ededed;
}

.c-card-image.-frame .detail {
  padding: 25px;
}

@media (min-width: 897px) {
  .c-card-image.-frame .detail {
    padding: 30px 50px;
  }
}

.c-card-image > a:hover .image img {
  transform: translate(0, 0) scale(1.1, 1.1);
}

@media (min-width: 897px) {
  .c-card-image.-hover_opacity > a {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .c-card-image.-hover_opacity > a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.c-card-image.-hover_opacity > a:hover .image img {
  transform: translate(0, 0) scale(1, 1);
}

/* c-card-point
---------------------------------------------------------- */
.c-card-point {
  position: relative;
}

.c-card-point .image {
  overflow: hidden;
  border-radius: 14px;
}

.c-card-point .image img {
  width: 100%;
}

.c-card-point .label {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -23px;
  left: -4px;
  width: 65px;
  height: 65px;
  border-radius: 65px;
  background-color: #006cb5;
}

.c-card-point .label .text {
  display: block;
  font-size: 3.0rem;
  text-align: center;
  color: #fff;
  line-height: 1.1;
  padding-top: 10px;
}

.c-card-point .label .txt-point {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.1rem;
}

.c-card-point.-frame .image {
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
}

/* c-card-service
---------------------------------------------------------- */
.c-card-service {
  position: relative;
  height: 100%;
  background-color: #f6f7f9;
  border-radius: 8px;
  padding: 64px 20px 40px;
  text-align: center;
}

@media (min-width: 897px) {
  .c-card-service {
    padding-top: 61px;
    padding-bottom: 30px;
  }
}

.c-card-service .icon {
  position: absolute;
  top: -64px;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 897px) {
  .c-card-service .icon {
    top: -61px;
  }
}

.c-card-service .icon img {
  width: 134px;
}

@media (min-width: 897px) {
  .c-card-service .icon img {
    width: 122px;
  }
}

.c-card-service .title {
  font-size: 2.2rem;
  font-weight: 700;
  color: #f98700;
  line-height: 1.4;
  letter-spacing: 0.2rem;
}

@media (min-width: 897px) {
  .c-card-service .title {
    font-size: 2rem;
  }
}

.c-card-service .title .sub {
  display: block;
  font-size: 1.5rem;
}

@media (min-width: 897px) {
  .c-card-service .title .sub {
    font-size: 1.4rem;
  }
}

.c-card-service .text {
  color: #111;
  margin-top: 18px;
}

@media (min-width: 897px) {
  .c-card-service .text {
    line-height: 1.7;
    margin-top: 11px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 footer
========================================================== */
.footer .btn-pagetop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  visibility: hidden;
  opacity: 0;
  z-index: 0;
  transition: all .2s;
}

.footer .btn-pagetop.-show {
  visibility: visible;
  opacity: 1;
  z-index: 98;
}

@media (max-width: 896px) {
  .footer .btn-pagetop {
    right: 15px;
    bottom: 15px;
  }
}

.footer .btn-pagetop a {
  display: block;
  width: 60px;
  height: 60px;
  opacity: .7;
  transition: opacity .2s;
}

.footer .btn-pagetop a:hover {
  opacity: .5;
}

@media (max-width: 896px) {
  .footer .btn-pagetop a {
    width: 30px;
    height: 30px;
  }
}

.footer .btn-pagetop a img {
  width: 100%;
}

/* footer-top
---------------------------------------------------------- */
.footer-top {
  background-color: #202636;
}

@media (max-width: 896px) {
  .footer-top .container {
    padding-right: 0;
    padding-left: 0;
  }
}

.footer-top .list-link .col:first-child a .box {
  border-left: 0;
}

.footer-top .list-link a {
  position: relative;
  display: block;
  padding: 50px 0;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  line-height: 1.5;
  text-align: center;
}

@media (min-width: 897px) {
  .footer-top .list-link a:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
  }
  .footer-top .list-link a:hover:after {
    visibility: visible;
    opacity: 1;
  }
  .footer-top .list-link a.link-apply:after {
    background-image: url("/assets/images/common/bg_footer_hover_01.png");
  }
  .footer-top .list-link a.link-denki:after {
    background-image: url("/assets/images/common/bg_footer_hover_02.png");
  }
  .footer-top .list-link a.link-gas:after {
    background-image: url("/assets/images/common/bg_footer_hover_03.png");
  }
}

@media (max-width: 896px) {
  .footer-top .list-link a {
    padding: 20px 0;
    font-size: 1.1rem;
  }
}

.footer-top .list-link a .box {
  padding: 10px 10px 15px;
  border-left: 1px solid #3c4253;
}

@media (max-width: 896px) {
  .footer-top .list-link a .box {
    padding: 5px;
  }
}

.footer-top .list-link a .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  text-align: center;
  line-height: 1;
}

@media (max-width: 896px) {
  .footer-top .list-link a .icon {
    margin-bottom: 5px;
  }
}

/* footer-mid
---------------------------------------------------------- */
.footer-mid {
  background-color: #262d40;
}

@media (max-width: 896px) {
  .footer-mid .container {
    padding-right: 0;
    padding-left: 0;
  }
}

.footer-mid .heading {
  padding-top: 60px;
  padding-bottom: 40px;
}

@media (min-width: 897px) {
  .footer-mid .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    border-bottom: 1px solid #3c4253;
  }
}

@media (max-width: 896px) {
  .footer-mid .heading {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.footer-mid .heading .list-link {
  display: flex;
  margin-left: 80px;
}

.footer-mid .heading .list-link .item {
  margin-left: 50px;
}

.footer-mid .heading .list-link .item:first-child {
  margin-left: 0;
}

.footer-mid .heading .list-link a {
  display: inline-block;
  position: relative;
  font-size: 1.6rem;
  color: #fff;
}

@media (min-width: 897px) {
  .footer-mid .heading .list-link a {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .footer-mid .heading .list-link a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.footer-mid .heading .list-link a:after {
  content: "";
  position: relative;
  top: 2px;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  background: url("/assets/images/common/icon_arrow_link.png") 0 0 no-repeat;
  background-size: 100% auto;
}

.footer-mid .heading .btn-sitemap {
  display: inline-block;
  color: #fff;
  font-size: 1.4rem;
  opacity: .7;
  transition: opacity .2s;
}

.footer-mid .heading .btn-sitemap:hover {
  opacity: .5;
}

.footer-mid .heading .btn-sitemap:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 11px;
  height: 9px;
  margin-left: 5px;
  background: url("/assets/images/common/icon_menu.png") 0 0 no-repeat;
  background-size: 100% auto;
}

.footer-mid .box-sns {
  padding: 30px 15px;
}

@media (min-width: 897px) {
  .footer-mid .box-sns {
    padding-top: 50px;
    padding-bottom: 55px;
  }
}

/* list-footer-link
---------------------------------------------------------- */
@media (min-width: 897px) {
  .list-footer-link {
    display: flex;
  }
}

@media (min-width: 897px) {
  .list-footer-link > .item.-denki {
    width: 57%;
  }
  .list-footer-link > .item.-gas {
    width: 27%;
    padding-left: 35px;
  }
  .list-footer-link > .item.-other {
    width: 16%;
  }
}

@media (max-width: 896px) {
  .list-footer-link > .item {
    border-top: 1px solid #3c4253;
  }
  .list-footer-link > .item:last-child {
    border-bottom: 1px solid #3c4253;
  }
}

.list-footer-link .link-main {
  position: relative;
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
}

@media (max-width: 896px) {
  .list-footer-link .link-main {
    display: block;
    padding: 13px 15px;
    font-size: 1.3rem;
  }
}

@media (max-width: 896px) {
  .list-footer-link .link-main.accd:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 11px;
    height: 11px;
    background-image: url("/assets/images/common/icon_plus_white.png");
    background-position: 0 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .list-footer-link .link-main.accd.-active:after {
    background-image: url("/assets/images/common/icon_minus_white.png");
  }
}

@media (min-width: 897px) {
  .list-footer-link .box-link-sub {
    padding-top: 15px;
  }
}

@media (max-width: 896px) {
  .list-footer-link .box-link-sub {
    display: none;
    border-top: 1px solid #3c4253;
  }
}

@media (max-width: 896px) {
  .list-footer-link .list-link-sub {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1px;
    background-color: #2e364b;
  }
}

@media (min-width: 897px) {
  .list-footer-link .list-link-sub .item {
    margin-bottom: 10px;
  }
  .list-footer-link .list-link-sub .item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 896px) {
  .list-footer-link .list-link-sub .item {
    width: 50%;
    border-bottom: 1px solid #3c4253;
  }
  .list-footer-link .list-link-sub .item:nth-child(odd) {
    border-right: 1px solid #3c4253;
  }
}

.list-footer-link .list-link-sub a {
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.7);
}

@media (min-width: 897px) {
  .list-footer-link .list-link-sub a {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .list-footer-link .list-link-sub a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

@media (max-width: 896px) {
  .list-footer-link .list-link-sub a {
    display: block;
    padding: 18px 15px;
    font-size: 1rem;
  }
}

/* footer-bottom
---------------------------------------------------------- */
.footer-bottom {
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 896px) {
  .footer-bottom {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.footer-bottom .box-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

@media (max-width: 896px) {
  .footer-bottom .box-logo {
    display: block;
    text-align: center;
  }
}

@media (max-width: 896px) {
  .footer-bottom .box-logo .logo-large {
    margin-bottom: 27px;
  }
}

.footer-bottom .box-logo .box {
  display: flex;
  align-items: center;
}

@media (max-width: 896px) {
  .footer-bottom .box-logo .box {
    display: block;
  }
}

.footer-bottom .box-logo .box .list-logo-01 {
  display: flex;
  align-items: center;
}

@media (max-width: 896px) {
  .footer-bottom .box-logo .box .list-logo-01 {
    margin-bottom: 25px;
  }
}

.footer-bottom .box-logo .box .list-logo-01 .item {
  padding: 0 20px;
}

@media (max-width: 896px) {
  .footer-bottom .box-logo .box .list-logo-01 .item {
    width: 25%;
    padding: 0 15px;
  }
}

.footer-bottom .box-logo .box .list-logo-01 .item:first-child {
  padding-left: 0;
}

@media (max-width: 896px) {
  .footer-bottom .box-logo .box .list-logo-01 .item:last-child {
    padding-right: 0;
  }
}

.footer-bottom .box-logo .box .list-logo-02 {
  display: flex;
  align-items: center;
}

.footer-bottom .box-logo .box .list-logo-02 .item {
  padding: 0 20px;
}

@media (max-width: 896px) {
  .footer-bottom .box-logo .box .list-logo-02 .item {
    width: 25%;
    padding: 0 28px;
  }
}

@media (max-width: 896px) {
  .footer-bottom .box-logo .box .list-logo-02 .item:first-child {
    padding-left: 0;
  }
}

.footer-bottom .box-logo .box .list-logo-02 .item:last-child {
  padding-right: 0;
}

.footer-bottom .box-logo .box img {
  display: block;
}

@media (max-width: 896px) {
  .footer-bottom .box-logo .box img {
    margin: 0 auto;
  }
}

.footer-bottom .box-logo .link-logo {
  display: block;
}

@media (min-width: 897px) {
  .footer-bottom .box-logo .link-logo {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .footer-bottom .box-logo .link-logo:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.footer-bottom .copyright {
  font-family: 'Roboto Condensed';
  font-size: 1.2rem;
  color: #999;
  text-align: center;
}

@media (max-width: 896px) {
  .footer-bottom .copyright {
    font-size: .9rem;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 form component
========================================================== */
/* c-input-text
---------------------------------------------------------- */
/* c-select
---------------------------------------------------------- */
/* c-radio
---------------------------------------------------------- */
/* c-checkbox
---------------------------------------------------------- */
/* c-text-area
---------------------------------------------------------- */
/* ==========================================================
 form layout
========================================================== */
/* c-form
---------------------------------------------------------- */
/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 header
========================================================== */
@media (min-width: 897px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background-color: #262d40;
  }
}

.header > .container {
  position: relative;
}

.header .header-main {
  height: 80px;
}

@media (max-width: 896px) {
  .header .header-main {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 50px;
    background-color: #262d40;
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 897px) {
  .header .header-main .logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
  }
}

@media (min-width: 1170px) {
  .header .header-main .logo {
    left: 30px;
  }
}

@media (max-width: 1169px) {
  .header .header-main .logo {
    left: 15px;
  }
}

@media (max-width: 896px) {
  .header .header-main .logo {
    position: relative;
  }
}

.header .header-main .logo a {
  display: inline-block;
  line-height: 1;
  width: 172px;
}

@media (max-width: 1000px) {
  .header .header-main .logo a {
    width: 120px;
  }
}

@media (max-width: 896px) {
  .header .header-main .logo a {
    display: flex;
    align-items: center;
    height: 50px;
  }
}

.header .header-main .logo a img {
  width: 100%;
}

.header .header-main .btn-his {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.header .header-main .btn-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  background-color: #f93b00;
}

.header .header-main .btn-menu {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

@media (min-width: 897px) {
  .header .header-main .btn-menu {
    display: none;
  }
}

.header .header-main .btn-menu .line {
  position: relative;
  width: 20px;
  height: 1px;
  background-color: #fff;
  margin-bottom: 6px;
  transform-origin: left center;
  transition: transform .2s, left .2s;
}

.header .header-main .btn-menu .line:last-child {
  margin-bottom: 0;
}

.header .header-main .btn-menu.-active .line:nth-child(1) {
  transform: rotate(45deg);
  left: 3px;
}

.header .header-main .btn-menu.-active .line:nth-child(2) {
  opacity: 0;
}

.header .header-main .btn-menu.-active .line:nth-child(3) {
  transform: rotate(-45deg);
  left: 3px;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 heading
========================================================== */
/* c-ttl-01
---------------------------------------------------------- */
.c-ttl-01 {
  margin-bottom: 20px;
}

.c-ttl-01:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-ttl-01 {
    margin-bottom: 40px;
    text-align: center;
  }
}

.c-ttl-01 .text {
  display: inline-block;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.4;
  color: #111;
}

@media (min-width: 897px) {
  .c-ttl-01 .text {
    font-size: 3.2rem;
  }
}

.c-ttl-01.-txt_small .text {
  font-size: 2rem;
}

@media (min-width: 897px) {
  .c-ttl-01.-txt_small .text {
    font-size: 2.4rem;
  }
}

/* c-ttl-02
---------------------------------------------------------- */
.c-ttl-02 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #e0e0e0;
}

@media (min-width: 897px) {
  .c-ttl-02 {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
}

.c-ttl-02:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: #2157a7;
}

@media (min-width: 897px) {
  .c-ttl-02:after {
    width: 70px;
  }
}

.c-ttl-02 .text {
  display: inline-block;
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 1.4;
  color: #2157a7;
}

.c-ttl-02 .text .c-link-arrow {
  color: #2157a7;
  position: relative;
}

.c-ttl-02 .text .c-link-arrow::after {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

@media (min-width: 897px) {
  .c-ttl-02 .text {
    font-size: 2.6rem;
  }
}

.c-ttl-02 .sub {
  display: block;
  font-weight: bold;
  font-size: 1.5rem;
  color: #111;
}

@media (min-width: 897px) {
  .c-ttl-02 .sub {
    margin-top: 10px;
    font-size: 1.8rem;
  }
}

.c-ttl-02 .date {
  display: block;
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2157a7;
}

@media (min-width: 897px) {
  .c-ttl-02 .date {
    display: inline-block;
    margin-top: 0;
  }
}

.c-ttl-02.-center {
  padding-bottom: 10px;
  text-align: center;
  border-bottom: 0;
  color: #333;
}

@media (min-width: 897px) {
  .c-ttl-02.-center {
    padding-bottom: 20px;
  }
}

.c-ttl-02.-center:after {
  width: 40px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 896px) {
  .c-ttl-02.-center:after {
    width: 30px;
  }
}

.c-ttl-02.-center .text {
  color: #111;
}

.c-ttl-02.-no_bd_gray {
  border-bottom: none;
}

@media (min-width: 897px) {
  .c-ttl-02.-no_bd_gray:after {
    width: 40px;
  }
}

/* c-ttl-03
---------------------------------------------------------- */
.c-ttl-03 {
  position: relative;
  padding-left: 10px;
  margin-bottom: 15px;
  border-left: 2px solid #2157a7;
}

.c-ttl-03:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-ttl-03 {
    padding-left: 15px;
    border-width: 4px;
    line-height: 1.4;
  }
}

.c-ttl-03 .text {
  display: inline-block;
  font-weight: bold;
  font-size: 1.6rem;
  color: #111;
}

@media (min-width: 897px) {
  .c-ttl-03 .text {
    line-height: 1.4;
    font-size: 2rem;
  }
}

.c-ttl-03.-no_border {
  padding-left: 0;
  border-left: 0;
}

/* c-ttl-04
---------------------------------------------------------- */
.c-ttl-04 {
  margin-bottom: 10px;
}

.c-ttl-04:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-ttl-04 {
    margin-bottom: 20px;
  }
}

.c-ttl-04 .text {
  display: inline-block;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.4;
  color: #111;
}

@media (min-width: 897px) {
  .c-ttl-04 .text {
    font-size: 1.8rem;
  }
}

/* c-ttl-05
---------------------------------------------------------- */
.c-ttl-05 {
  margin-bottom: 25px;
}

.c-ttl-05:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-ttl-05 {
    margin-bottom: 40px;
  }
}

.c-ttl-05 .text {
  display: block;
  padding-bottom: 9px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 1.7rem;
  font-weight: bold;
  color: #111;
}

@media (min-width: 897px) {
  .c-ttl-05 .text {
    padding-bottom: 13px;
  }
}

/* c-ttl-dot
---------------------------------------------------------- */
.c-ttl-dot {
  margin-bottom: 15px;
}

.c-ttl-dot .text {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.4;
  color: #111;
}

@media (min-width: 897px) {
  .c-ttl-dot .text {
    font-size: 2rem;
  }
}

/* c-ttl-bar
---------------------------------------------------------- */
.c-ttl-bar {
  position: relative;
  background-color: #999;
}

.c-ttl-bar .label {
  display: block;
  height: 100%;
  padding: 15px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.4;
  color: #fff;
  text-align: center;
}

@media (min-width: 897px) {
  .c-ttl-bar .label {
    padding: 18px 15px;
    font-size: 2.2rem;
  }
}

.c-ttl-bar .label.-orange {
  background-color: #f93b00;
}

.c-ttl-bar.-blue {
  background-color: #2157a7;
}

.c-ttl-bar .text {
  display: block;
  padding: 15px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.4;
  color: #fff;
  text-align: center;
}

@media (min-width: 897px) {
  .c-ttl-bar .text {
    padding: 18px 15px;
    font-size: 2.2rem;
  }
}

@media (min-width: 897px) {
  .c-ttl-bar.-small .text {
    padding: 15px;
    font-size: 1.6rem;
  }
}

.c-ttl-bar.-accd:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 21px;
  height: 21px;
  background-image: url("/assets/images/common/icon_accd_white.png");
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
}

@media (min-width: 897px) {
  .c-ttl-bar.-accd:after {
    right: 30px;
  }
}

.c-ttl-bar.-accd.-on:after {
  background-image: url("/assets/images/common/icon_accd_white_on.png");
}

@media (max-width: 896px) {
  .c-ttl-bar.-accd .text {
    padding: 15px 40px 15px 15px;
  }
}

/* c-ttl-label
---------------------------------------------------------- */
.c-ttl-label {
  display: inline-block;
  margin-bottom: 40px;
  padding: 2px 5px;
  width: 120px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.4;
  color: #2157a7;
  text-align: center;
  border-radius: 100px;
  border: 1px solid #2157a7;
}

.c-ttl-label:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-ttl-label {
    padding: 2px 10px;
    width: 134px;
  }
}

.c-ttl-label.-auto_width {
  width: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.c-ttl-label.-blue {
  background-color: #2157a7;
  color: #fff;
}

.c-ttl-label.-black {
  background-color: #111;
  border-color: #111;
  color: #fff;
}

/* c-ttl-en
---------------------------------------------------------- */
.c-ttl-en {
  font-size: 1.8rem;
  font-family: "Roboto Condensed";
  line-height: 1.4;
  color: #2157a7;
}

@media (min-width: 897px) {
  .c-ttl-en {
    font-size: 2.2rem;
  }
}

/* c-ttl-part
---------------------------------------------------------- */
.c-ttl-part {
  margin-bottom: 40px;
  padding: 10px;
  background-color: #333;
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

@media (min-width: 897px) {
  .c-ttl-part {
    padding: 15px;
    font-size: 1.8rem;
  }
}

.c-ttl-n-label {
  display: flex;
  justify-content: flex-start;
  color: #2157a7;
  font-weight: 700;
  margin-bottom: 15px;
  flex-direction: column-reverse;
  align-items: flex-start;
}

@media (min-width: 897px) {
  .c-ttl-n-label {
    font-size: 1.8rem;
    flex-direction: row;
    align-items: center;
  }
}

.c-ttl-n-label > .label {
  display: inline-block;
  padding: 5px 15px;
  font-size: 1.2rem;
  line-height: 1.4;
  color: #2157a7;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #2157a7;
  margin: 0 0 15px 0;
}

@media (min-width: 897px) {
  .c-ttl-n-label > .label {
    margin: 0 0 0 15px;
    font-size: 1.3rem;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 Icon
========================================================== */
/* c-icon
---------------------------------------------------------- */
.c-icon {
  text-decoration: underline;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 Image
========================================================== */
/* c-image
---------------------------------------------------------- */
.c-image img {
  max-width: 100%;
}

.c-image img.full-width {
  width: 100%;
}

.c-image.-center {
  text-align: center;
}

/* c-mainimg
---------------------------------------------------------- */
.c-mainimg img {
  width: 100%;
}

@media (max-width: 1170px) {
  .c-mainimg {
    padding-right: 0;
    padding-left: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
  .c-mainimg.-center {
    text-align: center;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 link
========================================================== */
/* c-link-basic
---------------------------------------------------------- */
@media (min-width: 897px) {
  .c-link-basic {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .c-link-basic:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

/* c-link-arrow
---------------------------------------------------------- */
.c-link-arrow {
  font-weight: bold;
  color: #111;
}

.c-link-arrow:after {
  content: "";
  position: relative;
  top: 2px;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  background: url("/assets/images/common/icon_arrow_link_blue.png") 0 0 no-repeat;
  background-size: 100% auto;
}

@media (min-width: 897px) {
  .c-link-arrow:after {
    margin-left: 10px;
  }
}

@media (min-width: 897px) {
  a.c-link-arrow {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  a.c-link-arrow:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

/* c-link-pdf
---------------------------------------------------------- */
@media (min-width: 897px) {
  .c-link-pdf {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .c-link-pdf:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.c-link-pdf:after {
  content: "";
  position: relative;
  top: 2px;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  background: url("/assets/images/common/icon_pdf_blue.png") 0 0 no-repeat;
  background-size: 100% auto;
}

@media (min-width: 897px) {
  .c-link-pdf:after {
    width: 20px;
    height: 20px;
    top: 3px;
  }
}

/* c-link-blank
---------------------------------------------------------- */
@media (min-width: 897px) {
  .c-link-blank {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .c-link-blank:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.c-link-blank:after {
  content: "";
  position: relative;
  top: -1px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  background: url("/assets/images/common/icon_new_window_blue.png") 0 0 no-repeat;
  background-size: 100% auto;
}

.c-link-blank.-style1:after {
  background: url("/assets/images/common/icon_new_window_line_blue.png") 0 0 no-repeat;
  background-size: 100% auto;
}

/* c-link-underline
---------------------------------------------------------- */
.c-link-underline {
  color: #2157a7;
  text-decoration: underline;
}

.c-link-underline:hover {
  text-decoration: none;
}

/* c-link-underline
---------------------------------------------------------- */
.c-tel-number {
  position: relative;
  padding-left: 26px;
  font-size: 2.4rem;
  font-weight: bold;
  font-family: 'Roboto Condensed';
}

@media (min-width: 897px) {
  .c-tel-number {
    font-size: 3rem;
  }
}

.c-tel-number:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 21px;
  background: url("/assets/images/common/ico_tel.png") 0 0 no-repeat;
  background-size: 16px 21px;
  top: 2px;
  left: 4px;
}

@media (min-width: 897px) {
  .c-tel-number:before {
    top: 5px;
    left: 0;
    width: 20px;
    height: 26px;
    background: url("/assets/images/common/ico_tel.png") 0 0 no-repeat;
    background-size: 20px 26px;
  }
}

.c-tel-number + a {
  position: relative;
  padding-left: 30px;
  font-size: 2.4rem;
  font-weight: bold;
  font-family: 'Roboto Condensed';
}

.c-tel-number + a:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 21px;
  background: url("/assets/images/common/ico_tel.png") 0 0 no-repeat;
  background-size: 16px 21px;
  top: 2px;
  left: 4px;
}

@media (min-width: 897px) {
  .c-tel-number + a:before {
    width: 20px;
    height: 26px;
    background: url("/assets/images/common/ico_tel.png") 0 0 no-repeat;
    background-size: 20px 26px;
  }
}

.c-tel-number.-large {
  font-size: 3.6rem;
}

@media (min-width: 897px) {
  .c-tel-number.-large {
    padding-left: 36px;
    font-size: 4.8rem;
  }
}

.c-tel-number.-large:before {
  width: 20px;
  height: 26px;
  background-size: 20px 26px;
  top: 8px;
  left: 0;
}

@media (min-width: 897px) {
  .c-tel-number.-large:before {
    top: 13px;
    width: 26px;
    height: 32px;
    background-size: 26px 32px;
  }
}

.c-tel-number.-large + a {
  font-size: 3.6rem;
}

@media (min-width: 897px) {
  .c-tel-number.-large + a {
    font-size: 4.8rem;
  }
}

.c-tel-number.-large + a:before {
  width: 20px;
  height: 26px;
  background-size: 20px 26px;
  top: 8px;
  left: 0;
}

@media (min-width: 897px) {
  .c-tel-number.-large + a:before {
    top: 5px;
    width: 26px;
    height: 32px;
    background-size: 26px 32px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 list
========================================================== */
/* c-list-bullet
---------------------------------------------------------- */
.c-list-bullet {
  margin-bottom: 20px;
}

.c-list-bullet:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-list-bullet {
    margin-bottom: 40px;
  }
}

.c-list-bullet > .item {
  position: relative;
  padding-left: 15px;
  margin-bottom: 5px;
}

.c-list-bullet > .item:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-list-bullet > .item {
    padding-left: 20px;
  }
}

.c-list-bullet > .item:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #111;
}

@media (min-width: 897px) {
  .c-list-bullet > .item:before {
    top: 9px;
  }
}

/* c-list-number
---------------------------------------------------------- */
.c-list-number {
  counter-reset: number;
  margin-bottom: 20px;
}

.c-list-number:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-list-number {
    margin-bottom: 40px;
  }
}

.c-list-number > .item {
  position: relative;
  counter-increment: number;
  padding-left: 18px;
  margin-bottom: 5px;
}

.c-list-number > .item:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-list-number > .item {
    padding-left: 34px;
  }
}

.c-list-number > .item:before {
  content: counter(number);
  position: absolute;
  top: 0;
  left: 0;
}

/* c-list-news
---------------------------------------------------------- */
.c-list-news {
  margin-bottom: 45px;
}

@media (min-width: 897px) {
  .c-list-news {
    margin-bottom: 80px;
  }
}

.c-list-news .date {
  display: block;
}

@media (min-width: 897px) {
  .c-list-news .date {
    display: inline-block;
  }
}

@media (min-width: 897px) {
  .c-list-news .c-link-blank {
    margin-left: 30px;
  }
}

/* c-list-note
---------------------------------------------------------- */
.c-list-note > .item {
  font-size: 1.2rem;
  position: relative;
  padding-left: 18px;
}

.c-list-note > .item:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-list-note > .item {
    padding-left: 16px;
  }
}

.c-list-note > .item:before {
  content: '※';
  position: absolute;
  top: 0;
  left: 0;
}

/* c-list-note-number
---------------------------------------------------------- */
.c-list-note-number {
  counter-reset: number;
}

.c-list-note-number > .item {
  font-size: 1.2rem;
  position: relative;
  counter-increment: number;
  padding-left: 26px;
}

.c-list-note-number > .item:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-list-note-number > .item {
    padding-left: 30px;
  }
}

.c-list-note-number > .item:before {
  content: "※" counter(number);
  position: absolute;
  top: 0;
  left: 0;
}

/* c-list-qa
---------------------------------------------------------- */
.c-list-qa > .item {
  border-bottom: 1px solid #ebebeb;
}

.c-list-qa .heading {
  position: relative;
  padding: 20px 30px 20px 0;
}

@media (min-width: 897px) {
  .c-list-qa .heading {
    padding: 35px 35px 35px 0;
  }
}

.c-list-qa .heading .q {
  margin-right: 10px;
  color: #2157a7;
  font-size: 2rem;
}

.c-list-qa .heading:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 21px;
  height: 21px;
  background-image: url("/assets/images/common/icon_plus_lg.png");
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
}

@media (min-width: 897px) {
  .c-list-qa .heading:after {
    right: 20px;
  }
}

.c-list-qa .heading.-on:after {
  background-image: url("/assets/images/common/icon_minus_lg.png");
}

.c-list-qa .question {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.4;
  padding-right: 5px;
}

@media (min-width: 897px) {
  .c-list-qa .question {
    font-size: 2.2rem;
  }
}

.c-list-qa .question .q {
  position: relative;
  top: -1px;
  margin-right: 10px;
  color: #2157a7;
  font-size: 2rem;
  font-family: "Roboto Condensed";
}

@media (min-width: 897px) {
  .c-list-qa .question .q {
    font-size: 2.8rem;
    margin-right: 15px;
  }
}

.c-list-qa .answer {
  display: none;
  margin-top: -10px;
  font-size: 1.4rem;
  padding-bottom: 20px;
}

@media (min-width: 897px) {
  .c-list-qa .answer {
    margin-top: -3px;
    padding-bottom: 30px;
    font-size: 1.6rem;
  }
}

/* c-list-service
---------------------------------------------------------- */
.c-list-service > .item {
  margin-top: 90px !important;
}

@media (min-width: 897px) {
  .c-list-service > .item {
    margin-top: 100px !important;
  }
}

/* c-list-step
---------------------------------------------------------- */
.c-list-step > .item {
  position: relative;
  display: flex;
  padding-top: 30px;
  padding-bottom: 50px;
  border-bottom: 1px solid #ebebeb;
}

@media (max-width: 896px) {
  .c-list-step > .item {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.c-list-step > .item:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: -19px;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 19px 19px 0 19px;
  border-color: #ffffff transparent transparent transparent;
  z-index: 2;
}

@media (max-width: 896px) {
  .c-list-step > .item:before {
    bottom: -9px;
    border-width: 9px 9px 0 9px;
    border-color: #ffffff transparent transparent transparent;
  }
}

.c-list-step > .item:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: -21px;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 21px 21px 0 21px;
  border-color: #ebebeb transparent transparent transparent;
}

@media (max-width: 896px) {
  .c-list-step > .item:after {
    bottom: -11px;
    border-width: 11px 11px 0 11px;
    border-color: #ebebeb transparent transparent transparent;
  }
}

.c-list-step > .item:last-child {
  border-bottom: none;
}

.c-list-step > .item:last-child:before, .c-list-step > .item:last-child:after {
  display: none;
}

.c-list-step > .item .box-icon {
  width: 180px;
  height: 180px;
  background-color: #f6f7f9;
  border-radius: 180px;
  text-align: center;
}

@media (min-width: 897px) {
  .c-list-step > .item .box-icon {
    flex: 1 0 auto;
  }
}

@media (max-width: 896px) {
  .c-list-step > .item .box-icon {
    width: 135px;
    height: 135px;
    border-radius: 135px;
  }
}

.c-list-step > .item .box-icon .icon {
  height: 72px;
}

@media (max-width: 896px) {
  .c-list-step > .item .box-icon .icon {
    height: 54px;
  }
}

.c-list-step > .item .box-icon .number {
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 35px;
  margin-bottom: 4px;
}

@media (max-width: 896px) {
  .c-list-step > .item .box-icon .number {
    font-size: 1.1rem;
    margin-top: 28px;
  }
}

.c-list-step > .item .box-icon .number .num {
  font-size: 3rem;
  margin-left: 4px;
  line-height: 1;
}

@media (max-width: 896px) {
  .c-list-step > .item .box-icon .number .num {
    font-size: 2.3rem;
  }
}

.c-list-step > .item .detail {
  width: 100%;
  padding-top: 35px;
  padding-left: 40px;
}

@media (max-width: 896px) {
  .c-list-step > .item .detail {
    padding-left: 0;
    padding-top: 20px;
  }
}

.c-list-step > .item .title {
  font-size: 2rem;
  font-weight: 700;
  color: #2157a7;
  line-height: 1.3;
}

.c-list-step > .item .title .-sm {
  font-size: 1.4rem;
}

@media (max-width: 896px) {
  .c-list-step > .item .title {
    font-size: 1.6rem;
  }
}

.c-list-step > .item .text {
  margin-top: 10px;
}

.c-list-step .c-box-dark {
  padding: 25px 30px;
}

@media (max-width: 896px) {
  .c-list-step .c-box-dark {
    padding: 20px 25px;
  }
}

/* c-list-link
---------------------------------------------------------- */
.c-list-link {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
  margin-left: -44px;
}

@media (min-width: 897px) {
  .c-list-link {
    margin-left: -65px;
  }
}

.c-list-link .item {
  margin-left: 44px;
  margin-bottom: 15px;
}

@media (min-width: 897px) {
  .c-list-link .item {
    margin-left: 65px;
  }
}

/* c-list-flow
---------------------------------------------------------- */
.c-list-flow {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
}

@media (max-width: 896px) {
  .c-list-flow {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.c-list-flow > .item {
  position: relative;
  padding: 0 15px;
}

@media (max-width: 896px) {
  .c-list-flow > .item {
    width: 50%;
    padding: 0 10px;
  }
}

.c-list-flow > .item:last-child:after {
  display: none;
}

.c-list-flow > .item:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -7px;
  width: 14px;
  height: 23px;
  transform: translateY(-50%);
  background: url(/assets/images/common/ico_arrow_flow.png) no-repeat;
  background-size: 14px 23px;
}

.c-list-flow > .item .box-icon {
  width: 180px;
  height: 180px;
  background-color: #f6f7f9;
  border-radius: 180px;
  text-align: center;
}

@media (max-width: 896px) {
  .c-list-flow > .item .box-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36vw;
    height: 36vw;
    border-radius: 36vw;
    margin: 0 auto;
  }
}

.c-list-flow > .item .box-icon img {
  width: auto;
  height: 58px;
}

@media (max-width: 896px) {
  .c-list-flow > .item .box-icon img {
    height: 36px;
  }
}

.c-list-flow > .item .box-icon .number {
  font-size: 1.4rem;
  font-weight: 700;
  padding-top: 22px;
  padding-bottom: 4px;
}

@media (max-width: 896px) {
  .c-list-flow > .item .box-icon .number {
    font-size: 1.1rem;
  }
}

.c-list-flow > .item .box-icon .number .num {
  font-size: 3rem;
  margin-left: 4px;
  line-height: 1;
}

@media (max-width: 896px) {
  .c-list-flow > .item .box-icon .number .num {
    font-size: 2.3rem;
    padding-top: 16px;
  }
}

.c-list-flow > .item .box-icon .text {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  line-height: 1.4;
  margin-top: 5px;
}

@media (max-width: 896px) {
  .c-list-flow > .item .box-icon .text {
    align-items: flex-start;
    font-size: 1.2rem;
    margin-top: 5px;
    letter-spacing: 0;
    height: 32px;
  }
}

.c-list-flow > .item .box-icon .text.-long {
  line-height: 1;
}

@media (min-width: 897px) {
  .c-list-flow {
    justify-content: center;
  }
  .c-list-flow .item {
    width: 33.33%;
  }
  .c-list-flow .box-icon {
    margin: 0 auto;
  }
}

@media (min-width: 1231px) {
  .c-list-flow .item {
    width: 20%;
  }
}

/* c-list-sns
---------------------------------------------------------- */
.c-list-sns {
  display: flex;
  justify-content: center;
}

.c-list-sns .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: -20px -20px 0;
}

@media (min-width: 897px) {
  .c-list-sns .list {
    margin: -15px -15px 0;
  }
}

.c-list-sns .list .item {
  padding: 0 20px;
  margin-top: 20px;
  line-height: 1;
}

@media (min-width: 897px) {
  .c-list-sns .list .item {
    padding: 0 15px;
    margin-top: 15px;
  }
}

.c-list-sns .list a {
  display: flex;
  align-items: center;
}

@media (min-width: 897px) {
  .c-list-sns .list a {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .c-list-sns .list a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.c-list-sns .list a img {
  display: block;
  max-width: none;
}

/* ==========================================================
 mainvisual
========================================================== */
/* c-mainvisual
---------------------------------------------------------- */
.c-mainvisual {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
  background-color: #fff;
}

@media (min-width: 897px) {
  .c-mainvisual {
    height: 240px;
  }
}

.c-mainvisual .box .icon {
  display: block;
  margin-bottom: 15px;
  text-align: center;
}

.c-mainvisual .box .text {
  font-size: 3rem;
  line-height: 1.4;
  font-weight: bold;
  color: #111;
}

@media (min-width: 897px) {
  .c-mainvisual .box .text {
    font-size: 3.6rem;
  }
}

.c-mainvisual.-denki {
  background-image: linear-gradient(to right, #fa9b23, #f8b831);
}

.c-mainvisual.-denki .box .text {
  color: #fff;
}

.c-mainvisual.-gas {
  background-image: linear-gradient(to right, #23a0e8, #17b5e2);
}

.c-mainvisual.-gas .box .text {
  color: #fff;
}

.c-mainvisual.-musubi_project {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 15px;
  height: auto;
}

@media (min-width: 897px) {
  .c-mainvisual.-musubi_project {
    padding: 50px 15px 90px;
  }
}

.c-mainvisual.-musubi_project img {
  max-width: 100%;
}

/* c-mainvisual-img
---------------------------------------------------------- */
.c-mainvisual-img {
  position: relative;
  width: 100%;
}

.c-mainvisual-img .box {
  width: 100%;
}

.c-mainvisual-img figure img {
  width: 100%;
}

.c-mainvisual-img .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  letter-spacing: 0.15em;
}

@media (min-width: 897px) {
  .c-mainvisual-img .text {
    font-size: 3.6rem;
  }
}

@media (min-width: 1170px) {
  .c-mainvisual-img .text {
    width: 1170px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 Modal
========================================================== */
/* c-modal
---------------------------------------------------------- */
.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  opacity: 0;
  z-index: 0;
  transition: all .2s;
}

.c-modal.-show {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}

.c-modal .modal-wrap {
  position: relative;
  z-index: 1;
  padding-top: 80px;
  padding-bottom: 35px;
}

@media (min-width: 897px) {
  .c-modal .modal-wrap {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.c-modal .modal-cont {
  position: relative;
  background-color: #fff;
}

.c-modal .modal-cont .btn-close {
  position: absolute;
  top: -24px;
  right: -24px;
  width: 48px;
  height: 48px;
  background: url("/assets/images/common/btn_close_modal.png") 0 0 no-repeat;
  background-size: 100% auto;
  cursor: pointer;
}

.c-modal .modal-cont .btn-close:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  opacity: 0;
  transition: opacity .2s;
}

.c-modal .modal-cont .btn-close:hover:before {
  opacity: 1;
}

@media (max-width: 1230px) {
  .c-modal .modal-cont .btn-close {
    top: -20px;
    right: -10px;
  }
}

.c-modal .modal-cont .inner {
  padding: 40px 15px;
}

@media (min-width: 897px) {
  .c-modal .modal-cont .inner {
    padding: 80px 12.8% 100px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 navglobal
========================================================== */
@media (min-width: 897px) {
  .nav-global {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 232px);
  }
}

@media (min-width: 1170px) {
  .nav-global {
    right: 30px;
  }
}

@media (max-width: 1169px) {
  .nav-global {
    right: 0;
    width: calc(100% - 172px);
  }
}

@media (max-width: 896px) {
  .nav-global {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 98;
    width: 100%;
    overflow-y: auto;
    padding-top: 50px;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.nav-global .btn-contact {
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  background-color: #f93b00;
  font-weight: bold;
  font-size: 1.6rem;
  color: #fff;
}

@media (min-width: 897px) {
  .nav-global .btn-contact {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .nav-global .btn-contact:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

@media (max-width: 1169px) {
  .nav-global .btn-contact {
    font-size: 1.4rem;
  }
}

.nav-global .btn-his {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 10px 23px;
}

@media (min-width: 897px) {
  .nav-global .btn-close {
    display: none;
  }
}

@media (max-width: 896px) {
  .nav-global .btn-close {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #bbb;
    margin-top: -1px;
  }
}

/* list-gnav
---------------------------------------------------------- */
@media (min-width: 897px) {
  .list-gnav {
    display: flex;
  }
  .list-gnav > .item .link-main {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 25px;
    height: 80px;
    font-weight: bold;
    font-size: 1.6rem;
    color: #fff;
  }
}

@media (min-width: 897px) and (max-width: 1169px) {
  .list-gnav > .item .link-main {
    font-size: 1.4rem;
  }
}

@media (min-width: 897px) {
  .list-gnav > .item .link-main:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    background-position: center bottom;
    background-repeat: no-repeat;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s;
  }
  .list-gnav > .item:nth-child(1) .link-main:after {
    background-image: url("/assets/images/common/bg_hover_current_01.png");
  }
  .list-gnav > .item:nth-child(2) .link-main:after {
    background-image: url("/assets/images/common/bg_hover_current_02.png");
  }
  .list-gnav > .item:nth-child(3) .link-main:after {
    background-image: url("/assets/images/common/bg_hover_current_03.png");
  }
  .list-gnav > .item.-active .link-main:after {
    opacity: 1;
    visibility: visible;
  }
  .list-gnav > .item.-current .link-main:after {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 896px) {
  .list-gnav > .item {
    border-bottom: 1px solid #e8ecf0;
  }
  .list-gnav > .item .link-main {
    position: relative;
    display: block;
    padding: 22px 15px;
    background-color: #fff;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .list-gnav > .item .link-main.accd:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 11px;
    height: 11px;
    background-image: url("/assets/images/common/icon_plus.png");
    background-size: 100% auto;
    background-position: 0 0;
    background-repeat: no-repeat;
  }
  .list-gnav > .item .link-main.accd.-active:after {
    background-image: url("/assets/images/common/icon_minus.png");
  }
  .list-gnav > .item .link-main .icon {
    display: block;
    margin-bottom: 10px;
    text-align: center;
  }
  .list-gnav > .item .link-main .text {
    display: block;
  }
}

.list-gnav.-column2 {
  display: flex;
}

.list-gnav.-column2 > .item {
  width: 50%;
}

.list-gnav.-column2 > .item:nth-child(odd) {
  border-right: 1px solid #e8ecf0;
}

.list-gnav.-column2 > .item .link-main {
  height: 100%;
}

/* megamenu-panel
---------------------------------------------------------- */
@media (min-width: 897px) {
  .megamenu-panel {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    transition: all .3s;
  }
}

.megamenu-panel.-show {
  visibility: visible;
  opacity: 1;
  z-index: 99;
}

@media (max-width: 896px) {
  .megamenu-panel {
    display: none;
  }
}

@media (min-width: 897px) {
  .megamenu-panel .container {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

@media (max-width: 896px) {
  .megamenu-panel .container {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 897px) {
  .megamenu-panel .heading {
    margin-bottom: 40px;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.45;
    color: #fff;
    text-align: center;
  }
}

@media (max-width: 896px) {
  .megamenu-panel .heading {
    display: none;
  }
}

@media (max-width: 896px) and (max-width: 896px) {
  .megamenu-panel .cont .col.-left .box-link-main, .megamenu-panel .cont .col.-left .box-link-banner {
    border-bottom: 1px solid #fff;
  }
}

.megamenu-panel .box-link-main {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ecf0f3;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 897px) {
  .megamenu-panel .box-link-main {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .megamenu-panel .box-link-main:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .megamenu-panel .box-link-main {
    flex-direction: column;
  }
}

@media (max-width: 896px) {
  .megamenu-panel .box-link-main {
    height: 75px !important;
  }
}

.megamenu-panel .box-link-main .icon {
  display: block;
  margin-bottom: 10px;
}

.megamenu-panel .box-link-main .text {
  font-weight: bold;
  font-size: 2rem;
}

@media (max-width: 896px) {
  .megamenu-panel .box-link-main .text {
    padding-left: 10px;
    font-size: 1.3rem;
  }
}

@media (min-width: 897px) {
  .megamenu-panel .list-link-main .item {
    padding-right: 1px;
    padding-bottom: 1px;
  }
  .megamenu-panel .list-link-main .item:last-child {
    padding-bottom: 0;
  }
}

.megamenu-panel .box-link-banner {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 15px 0 30px;
  overflow: hidden;
  background-color: #ecf0f3;
}

@media (min-width: 897px) {
  .megamenu-panel .box-link-banner {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .megamenu-panel .box-link-banner:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

@media (max-width: 896px) {
  .megamenu-panel .box-link-banner {
    height: 50px;
    padding-left: 15px;
  }
}

.megamenu-panel .box-link-banner img.img-bg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: auto;
  height: 100%;
  max-width: none;
}

.megamenu-panel .box-link-banner .text {
  position: relative;
  font-size: 1.4rem;
  font-weight: bold;
}

@media (max-width: 896px) {
  .megamenu-panel .box-link-banner .text {
    font-size: 1.2rem;
  }
}

@media (max-width: 896px) {
  .megamenu-panel .list-link-banner {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 897px) {
  .megamenu-panel .list-link-banner .item {
    padding-bottom: 1px;
  }
  .megamenu-panel .list-link-banner .item:last-child {
    padding-bottom: 0;
  }
  .megamenu-panel .list-link-banner .item.-special .box-link-banner {
    height: 90px;
  }
}

@media (max-width: 896px) {
  .megamenu-panel .list-link-banner .item {
    width: 100%;
    border-bottom: 1px solid #fff;
  }
  .megamenu-panel .list-link-banner .item.-special {
    width: 50%;
    border-left: 1px solid #fff;
  }
  .megamenu-panel .list-link-banner .item.-special .box-link-banner {
    height: auto;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 0 0;
    text-align: center;
  }
  .megamenu-panel .list-link-banner .item.-special .box-link-banner img.img-bg {
    position: relative;
    top: inherit;
    right: inherit;
    left: 0;
    bottom: 0;
    transform: translate(0, 0);
    width: 100%;
    height: auto;
  }
  .megamenu-panel .list-link-banner .item.-special .text {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    text-align: center;
  }
}

.megamenu-panel .list-link-banner .item .text {
  font-size: 1.4rem;
}

@media (max-width: 896px) {
  .megamenu-panel .list-link-banner .item .text {
    font-size: 1.2rem;
  }
}

.megamenu-panel .box-link-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.7;
  color: #fff;
}

@media (min-width: 897px) {
  .megamenu-panel .box-link-icon {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .megamenu-panel .box-link-icon:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

@media (max-width: 896px) {
  .megamenu-panel .box-link-icon {
    padding: 15px;
    background-color: #ecf0f3;
    font-weight: 500;
    font-size: 1rem;
    color: #333;
  }
}

.megamenu-panel .box-link-icon .icon {
  line-height: 1;
  margin-left: 5px;
}

.megamenu-panel .box-link-icon.-blue {
  background-color: #2157a7;
  font-weight: bold;
  color: #fff;
}

@media (min-width: 897px) {
  .megamenu-panel .box-link-icon.-blue {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 896px) {
  .megamenu-panel .box-link-icon.-blue {
    font-size: 1.2rem;
  }
}

.megamenu-panel .box-link-icon.-orange {
  background-color: #f93b00;
  font-weight: bold;
  color: #fff;
}

@media (min-width: 897px) {
  .megamenu-panel .box-link-icon.-orange {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 896px) {
  .megamenu-panel .box-link-icon.-orange {
    font-size: 1.2rem;
  }
}

.megamenu-panel .list-link-icon {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 897px) {
  .megamenu-panel .list-link-icon {
    margin-bottom: 40px;
  }
}

.megamenu-panel .list-link-icon .item {
  width: 50%;
}

@media (min-width: 897px) {
  .megamenu-panel .list-link-icon .item .box-link-icon {
    border-bottom: 1px solid #3c4253;
  }
  .megamenu-panel .list-link-icon .item:nth-child(-n+2) .box-link-icon {
    border-top: 1px solid #3c4253;
  }
  .megamenu-panel .list-link-icon .item:nth-child(odd) {
    padding-right: 15px;
  }
  .megamenu-panel .list-link-icon .item:nth-child(even) {
    padding-left: 15px;
  }
}

@media (max-width: 896px) {
  .megamenu-panel .list-link-icon .item {
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
  .megamenu-panel .list-link-icon .item:nth-child(even) {
    border-right: 0;
  }
  .megamenu-panel .list-link-icon .item .box-link-icon {
    height: 100%;
  }
}

.megamenu-panel .list-link-icon .item .text {
  font-size: 1.4rem;
}

@media (max-width: 896px) {
  .megamenu-panel .list-link-icon .item .text {
    font-size: 1rem;
  }
}

/* megamenu01
---------------------------------------------------------- */
@media (min-width: 897px) {
  .megamenu01 .cont {
    display: flex;
  }
}

@media (min-width: 897px) {
  .megamenu01 .col.-left {
    width: 50%;
    padding-right: 15px;
  }
  .megamenu01 .col.-right {
    width: 50%;
    padding-left: 15px;
  }
}

/* megamenu02
---------------------------------------------------------- */
@media (min-width: 897px) {
  .megamenu02 .cont {
    display: flex;
  }
}

@media (min-width: 897px) {
  .megamenu02 .col.-left {
    width: 33%;
    padding-right: 15px;
  }
  .megamenu02 .col.-right {
    width: 67%;
    padding-left: 15px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 section
========================================================== */
/* c-sec
---------------------------------------------------------- */
.c-sec {
  margin-bottom: 40px;
}

@media (min-width: 897px) {
  .c-sec {
    margin-bottom: 80px;
  }
}

.c-sec:last-child {
  margin-bottom: 0;
}

/* c-sec-part
---------------------------------------------------------- */
.c-sec-part {
  margin-bottom: 55px;
}

.c-sec-part:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-sec-part {
    margin-bottom: 90px;
  }
}

.nav-side-button {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(185px, -50%);
  width: 250px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  box-shadow: 0px 0px 10px 0px #ededed;
  transition: transform .4s, opacity .2s;
  visibility: hidden;
  opacity: 0;
  z-index: 0;
}

.nav-side-button.-show {
  visibility: visible;
  opacity: 1;
  z-index: 98;
}

@media (max-width: 896px) {
  .nav-side-button {
    display: none;
  }
}

.nav-side-button .item {
  position: relative;
}

.nav-side-button .item:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  width: 100%;
  height: 1px;
  background-color: #ededed;
}

.nav-side-button .item:last-child:after {
  display: none;
}

.nav-side-button .item a {
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 20px;
  transition: background-color .2s;
}

.nav-side-button .item a:hover {
  background-color: #ededed;
}

.nav-side-button .item a .icon {
  flex-shrink: 0;
  display: block;
  width: 32px;
  text-align: center;
}

.nav-side-button .item a .text {
  flex-grow: 1;
  padding-left: 13px;
  font-size: 1.4rem;
  font-weight: bold;
}

.nav-side-button:hover {
  transform: translate(0, -50%);
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 Slider
========================================================== */
/* c-slider-thumbnail
---------------------------------------------------------- */
.c-slider-thumbnail {
  margin-bottom: 25px;
  height: 500px;
  visibility: hidden;
}

@media (min-width: 897px) {
  .c-slider-thumbnail {
    margin-bottom: 40px;
  }
}

.c-slider-thumbnail .slider-main {
  margin-bottom: 15px;
  overflow: hidden;
}

@media (min-width: 897px) {
  .c-slider-thumbnail .slider-main {
    margin-bottom: 20px;
  }
}

.c-slider-thumbnail .slider-main .slider .item {
  position: relative;
  overflow: hidden;
  outline: none;
}

.c-slider-thumbnail .slider-main .slider img {
  width: 100%;
}

.c-slider-thumbnail .slick-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -10px;
}

.c-slider-thumbnail .slick-dots > li {
  position: relative;
  width: 18%;
  padding-top: 10%;
  margin-top: 10px;
  overflow: hidden;
  cursor: pointer;
  outline: none;
}

@media (min-width: 897px) {
  .c-slider-thumbnail .slick-dots > li {
    width: 13.4%;
    padding-top: 7.8%;
  }
}

.c-slider-thumbnail .slick-dots > li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
}

.c-slider-thumbnail .slick-dots > li.slick-active:before, .c-slider-thumbnail .slick-dots > li:hover:before {
  visibility: visible;
  opacity: 1;
}

.c-slider-thumbnail .slick-dots img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 Tab
========================================================== */
/* c-tabs-wrap
---------------------------------------------------------- */
.c-tabs-wrap {
  margin-bottom: 15px;
}

.c-tabs-wrap:last-child {
  margin-bottom: 0;
}

@media (max-width: 896px) {
  .c-tabs-wrap {
    margin-bottom: 30px;
  }
}

/* c-tabs-nav
---------------------------------------------------------- */
.c-tabs-nav {
  margin-bottom: 20px;
}

@media (max-width: 896px) {
  .c-tabs-nav {
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 15px;
  }
  .c-tabs-nav::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px;
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .c-tabs-nav::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #a0a0a0;
  }
  .c-tabs-nav .c-tabs {
    margin-bottom: 15px;
  }
}

.c-tabs-nav .c-tabs {
  max-width: 1170px;
}

.c-tabs-nav .c-tabs.-nest {
  margin-bottom: 65px;
}

@media (max-width: 896px) {
  .c-tabs-nav .c-tabs.-nest {
    margin-bottom: 15px;
  }
}

/* c-tabs
---------------------------------------------------------- */
.c-tabs {
  display: flex;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 70px;
}

.c-tabs > li {
  align-items: baseline;
  width: 10%;
  flex: 1 1 auto;
  height: 100%;
  border-right: 1px solid #ecedef;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #fff;
  color: #2157a7;
}

@media (max-width: 896px) {
  .c-tabs > li {
    flex: 0 0 95px;
  }
}

.c-tabs > li .label {
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 700;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}

@media (max-width: 896px) {
  .c-tabs > li .label {
    font-size: 1.4rem;
  }
}

.c-tabs > li:last-child {
  border-right: none;
}

.c-tabs > li.-active {
  background: #2157a7;
  color: #fff;
}

.c-tabs > li.-disable {
  color: rgba(33, 87, 167, 0.3);
  cursor: not-allowed;
}

.c-tabs > li.-disable:hover {
  opacity: 1;
}

.c-tabs > li .notice {
  font-size: 1.2rem;
  display: block;
}

.c-tabs.-nest .tab-nest {
  background: #2157a7;
  width: 100%;
  position: absolute;
  top: 70px;
  left: 0;
  display: flex;
  padding: 10px;
  visibility: hidden;
}

.c-tabs.-nest .tab-nest > .item {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  margin: 0 5px;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #fff;
  cursor: pointer;
}

@media (min-width: 897px) {
  .c-tabs.-nest .tab-nest > .item {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .c-tabs.-nest .tab-nest > .item:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.c-tabs.-nest .tab-nest > .item:first-child {
  margin-left: 0;
}

.c-tabs.-nest .tab-nest > .item:last-child {
  margin-right: 0;
}

.c-tabs.-nest .tab-nest > .item.-active {
  border: 1px solid #fff;
  background: #fff;
  color: #2157a7;
  cursor: default;
}

.c-tabs.-nest .tab-nest > .item.-active:hover {
  opacity: 1;
}

@media (max-width: 896px) {
  .c-tabs.-nest .tab-nest {
    display: none;
  }
}

/* c-tabs-contents
---------------------------------------------------------- */
.c-tabs-contents.-nest .tab-content {
  /*>div {
        display: none;
        &:first-child{
          display: block;
        }
      }*/
}

/* tab-nest-select
---------------------------------------------------------- */
.tab-nest-select {
  position: relative;
}

@media (min-width: 897px) {
  .tab-nest-select {
    display: none;
  }
}

@media (max-width: 896px) {
  .tab-nest-select {
    display: block;
    margin-bottom: 35px;
    padding: 17px 50px 17px 20px;
    border: 2px solid #e0e0e0;
    border-radius: 5px;
    background-color: #fff;
  }
  .tab-nest-select:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    width: 11px;
    height: 7px;
    background: url("/assets/images/common/icon_arrow_selectbox.png") 0 0 no-repeat;
    background-size: 100% auto;
  }
  .tab-nest-select .txt-selected {
    font-size: 1.4rem;
    line-height: 1;
  }
  .tab-nest-select select {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 0;
    appearance: none;
    opacity: 0;
  }
}

/* ==========================================================
 Tab JS
========================================================== */
.js-tab-module .js-tab-content {
  display: none;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 table
========================================================== */
/* c-table-wrap
---------------------------------------------------------- */
.c-table-wrap {
  margin-bottom: 15px;
}

@media (max-width: 896px) {
  .c-table-wrap {
    overflow-y: hidden;
    overflow-x: auto;
  }
  .c-table-wrap::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px;
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .c-table-wrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #a0a0a0;
  }
}

@media (max-width: 896px) {
  .c-table-wrap .c-table {
    width: 980px;
  }
}

@media (max-width: 896px) {
  .c-table-wrap.-no_scroll {
    overflow-x: unset;
  }
}

@media (max-width: 896px) {
  .c-table-wrap.-no_scroll .c-table {
    width: 100%;
  }
}

/* c-table
---------------------------------------------------------- */
.c-table {
  width: 100%;
  border: 1px solid #ebebeb;
}

.c-table.-layout_fixed {
  table-layout: fixed;
}

.c-table > thead > tr > th,
.c-table > thead > tr > td,
.c-table > tbody > tr > th,
.c-table > tbody > tr > td {
  padding: 15px 10px;
  border-bottom: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  text-align: left;
  line-height: 1.3;
}

@media (max-width: 896px) {
  .c-table > thead > tr > th,
  .c-table > thead > tr > td,
  .c-table > tbody > tr > th,
  .c-table > tbody > tr > td {
    padding: 12px 8px;
    font-size: 1.1rem;
  }
}

.c-table > thead > tr > th.v-top,
.c-table > thead > tr > td.v-top,
.c-table > tbody > tr > th.v-top,
.c-table > tbody > tr > td.v-top {
  vertical-align: top;
}

.c-table > thead > tr > th.v-mid,
.c-table > thead > tr > td.v-mid,
.c-table > tbody > tr > th.v-mid,
.c-table > tbody > tr > td.v-mid {
  vertical-align: middle;
}

.c-table > thead > tr > th.v-bottom,
.c-table > thead > tr > td.v-bottom,
.c-table > tbody > tr > th.v-bottom,
.c-table > tbody > tr > td.v-bottom {
  vertical-align: bottom;
}

.c-table > thead > tr > th,
.c-table > tbody > tr > th {
  background-color: #f6f7f9;
  font-weight: bold;
  text-align: center;
}

.c-table > thead > tr > th.bg-none,
.c-table > tbody > tr > th.bg-none {
  background: none;
}

.c-table > thead > tr > th.bg-orange,
.c-table > tbody > tr > th.bg-orange {
  color: #fff;
  background: #f93b00;
}

.c-table > thead > tr > th.bg-blue,
.c-table > tbody > tr > th.bg-blue {
  color: #fff;
  background: #2157a7;
}

.c-table > thead > tr > th.bg-grey,
.c-table > tbody > tr > th.bg-grey {
  color: #fff;
  background: #888;
}

.c-table > thead > tr > td,
.c-table > tbody > tr > td {
  background-color: #fff;
}

/* c-table-list
---------------------------------------------------------- */
.c-table-list {
  margin-bottom: 35px;
}

@media (min-width: 897px) {
  .c-table-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px 40px;
  }
}

@media (min-width: 897px) {
  .c-table-list .item {
    width: 50%;
    padding: 0 25px;
  }
}

.c-table-list .item:nth-child(1) dl, .c-table-list .item:nth-child(2) dl {
  border-top: 1px solid #ebebeb;
}

@media (max-width: 896px) {
  .c-table-list .item:nth-child(2) dl {
    border-top: none;
  }
}

.c-table-list .item dl {
  padding: 17px 0;
  border-bottom: 1px solid #ebebeb;
}

@media (min-width: 897px) {
  .c-table-list .item dl {
    display: flex;
    align-items: center;
    padding: 25px 0;
  }
}

@media (min-width: 897px) {
  .c-table-list .item dl dt {
    width: 155px;
    flex: 1 0 auto;
  }
}

.c-table-list .item dl dd {
  width: 100%;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 text
========================================================== */
/* c-txt-paragraph
---------------------------------------------------------- */
.c-txt-paragraph {
  margin-bottom: 30px;
}

.c-txt-paragraph:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .c-txt-paragraph {
    margin-bottom: 35px;
  }
}

/* c-txt-semi
---------------------------------------------------------- */
.c-txt-semi {
  font-size: 1.3rem;
  line-height: 1.77;
}

@media (min-width: 897px) {
  .c-txt-semi {
    font-size: 1.4rem;
    line-height: 2;
  }
}

/* c-txt-small
---------------------------------------------------------- */
.c-txt-small {
  font-size: 1.2rem;
  line-height: 1.75;
}

@media (min-width: 897px) {
  .c-txt-small {
    line-height: 2.2;
  }
}

/* ==========================================================
 pages
========================================================== */
/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
.mainvisual-about {
  position: relative;
  visibility: hidden;
  height: 500px;
}

.mainvisual-about .list-slider {
  overflow: hidden;
}

.mainvisual-about .list-slider .item .image {
  transition: transform 25s;
}

.mainvisual-about .list-slider .item .image.-animate {
  transform: scale(1.5, 1.5);
}

.mainvisual-about .list-slider .item .image img {
  width: 100%;
}

.mainvisual-about .list-slider .slick-slide {
  transition: opacity 1000ms ease 0s !important;
}

@media (min-width: 897px) {
  .mainvisual-about .box-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 1230px;
    padding: 0 30px;
    color: #fff;
  }
}

@media (max-width: 1230px) {
  .mainvisual-about .box-text {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 896px) {
  .mainvisual-about .box-text {
    padding: 15px;
    background-color: #c6c8cd;
    color: #111;
  }
}

.mainvisual-about .box-text .inner {
  opacity: 0;
  visibility: hidden;
  transition: all 1s;
}

.mainvisual-about .box-text .inner.-show {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 896px) {
  .mainvisual-about .box-text .inner {
    padding: 30px 20px;
    background-color: #fff;
  }
}

.mainvisual-about .box-text .title {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 2.3rem;
  line-height: 1.5;
}

@media (min-width: 897px) {
  .mainvisual-about .box-text .title {
    margin-bottom: 25px;
    font-size: 4.2rem;
  }
}

.mainvisual-about .box-text .text {
  font-size: 1.4rem;
  line-height: 1.78;
}

@media (min-width: 897px) {
  .mainvisual-about .box-text .text {
    font-size: 1.6rem;
    line-height: 2.25;
  }
}

.contents.-about .container {
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.sec-about-in {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 897px) {
  .sec-about-in {
    max-width: 1170px;
    padding: 0;
  }
}

.ttl-about-01 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  line-height: 1.4;
  font-weight: bold;
}

@media (min-width: 897px) {
  .ttl-about-01 {
    margin-bottom: 35px;
    font-size: 3.2rem;
  }
}

.ttl-about-01.-white {
  color: #fff;
}

.ttl-about-01.-letter1 {
  letter-spacing: 0.08em;
}

.txt-about-01 {
  margin-bottom: 15px;
  display: inline-block;
  border-top: 1px solid #385199;
  border-bottom: 1px solid #385199;
  color: #385199;
  font-weight: bold;
  padding: 5px 10px;
}

@media (min-width: 897px) {
  .txt-about-01 {
    margin-bottom: 28px;
    padding: 7px 15px;
  }
}

.txt-about-01.-white {
  color: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.txt-about-02 {
  color: #fff;
}

.txt-about-02.-letter1 {
  letter-spacing: 0.12em;
}

.sec-about-01 {
  padding: 60px 0 62px;
}

@media (min-width: 897px) {
  .sec-about-01 {
    padding: 100px 0 90px;
  }
}

.sec-about-02 {
  position: relative;
  min-height: 100vh;
  padding: 60px 0 30px;
}

@media (min-width: 897px) {
  .sec-about-02 {
    padding: 105px 0 20px;
    background-image: url(/assets/images/about/bg_about_01_pc.jpg);
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 896px) {
  .sec-about-02 .bg-sp-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip: rect(0, auto, auto, 0);
  }
  .sec-about-02 .bg-sp-wrap .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/assets/images/about/bg_about_01_sp.jpg);
    background-size: cover;
    background-position: center;
    transform: translateZ(0);
    will-change: transform;
  }
}

.sec-about-03 {
  position: relative;
  padding: 60px 0 60px;
  min-height: 100vh;
}

@media (min-width: 897px) {
  .sec-about-03 {
    padding: 115px 0 100px;
    background-image: url(/assets/images/about/bg_about_02_pc.jpg);
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 896px) {
  .sec-about-03 .bg-sp-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip: rect(0, auto, auto, 0);
  }
  .sec-about-03 .bg-sp-wrap .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/assets/images/about/bg_about_02_sp.jpg);
    background-size: cover;
    background-position: center;
    transform: translateZ(0);
    will-change: transform;
  }
}

.sec-about-03 .c-btn-group {
  width: 100%;
  padding: 0;
}

@media (min-width: 897px) {
  .sec-about-03 .c-btn-group {
    width: 610px;
  }
}

.sec-about-03 .c-btn-group .c-btn {
  padding: 20px;
}

@media (min-width: 897px) {
  .sec-about-03 .c-btn-group .c-btn {
    padding: 15px;
  }
}

.sec-about-04 {
  position: relative;
  padding: 77px 0 66px;
  min-height: 100vh;
}

@media (min-width: 897px) {
  .sec-about-04 {
    padding: 100px 0 100px;
    background-image: url(/assets/images/about/bg_about_03_pc.jpg);
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 896px) {
  .sec-about-04 .bg-sp-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip: rect(0, auto, auto, 0);
  }
  .sec-about-04 .bg-sp-wrap .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/assets/images/about/bg_about_03_sp.jpg);
    background-size: cover;
    background-position: center;
    transform: translateZ(0);
    will-change: transform;
  }
}

.sec-about-04 .c-btn-group {
  width: 100%;
}

@media (min-width: 897px) {
  .sec-about-04 .c-btn-group {
    width: 610px;
  }
}

.sec-about-04 .c-btn-group .c-btn {
  padding: 20px;
}

@media (min-width: 897px) {
  .sec-about-04 .c-btn-group .c-btn {
    padding: 15px;
  }
}

.sec-about-05 {
  position: relative;
  padding: 67px 0 48px;
  min-height: 100vh;
}

@media (min-width: 897px) {
  .sec-about-05 {
    padding: 100px 0 75px;
    background-image: url(/assets/images/about/bg_about_04_pc.jpg);
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 896px) {
  .sec-about-05 .bg-sp-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip: rect(0, auto, auto, 0);
  }
  .sec-about-05 .bg-sp-wrap .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/assets/images/about/bg_about_04_sp.jpg);
    background-size: cover;
    background-position: center;
    transform: translateZ(0);
    will-change: transform;
  }
}

.sec-about-06 {
  padding: 70px 0 192px;
  background: url(/assets/images/about/bg_about_05_sp.jpg) center top no-repeat;
  background-size: cover;
}

@media (min-width: 897px) {
  .sec-about-06 {
    padding: 100px 0 447px;
    background: url(/assets/images/about/bg_about_05_pc.jpg) center top no-repeat;
    background-size: cover;
  }
}

.tag-about-01 {
  display: inline-block;
  padding: 7px 15px;
  width: 135px;
  height: 45px;
  font-size: 2.1rem;
  font-weight: bold;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  border-radius: 40px;
  border: 1px solid #2157a7;
  margin: 0 0 0 -68px;
  background-color: #2157a7;
}

@media (min-width: 897px) {
  .tag-about-01 {
    padding: 12px 15px;
    width: 180px;
    height: 58px;
    font-size: 2.4rem;
    margin: 0 0 0 -90px;
  }
}

.list-about-01 > .item {
  padding: 45px 28px 30px;
  margin-bottom: 40px;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

@media (min-width: 897px) {
  .list-about-01 > .item {
    margin-bottom: 60px;
    padding: 80px 80px 75px;
  }
}

.list-about-01 > .item.-style1 .box-left-in {
  width: 50%;
}

@media (max-width: 896px) {
  .list-about-01 > .item.-style1 .box-left-in {
    width: 100%;
  }
}

.list-about-01 > .item .tag-about-01 {
  position: absolute;
  top: -28px;
  left: 50%;
  z-index: 4;
}

.list-about-01 > .item .c-box-dark {
  background: #edeff3;
  padding: 12px 16px;
  margin-bottom: 15px;
}

@media (min-width: 897px) {
  .list-about-01 > .item .c-box-dark {
    padding: 25px 32px;
    margin-bottom: -20px;
  }
}

.list-about-01 > .item .image {
  position: static;
  z-index: 3;
}

@media (min-width: 897px) {
  .list-about-01 > .item .image {
    position: absolute;
    z-index: 3;
  }
}

.list-about-01 > .item .image.-image1 {
  top: 12px;
  right: 110px;
  width: 233px;
  height: inherit;
  margin: 0 auto;
}

@media (min-width: 897px) {
  .list-about-01 > .item .image.-image1 {
    width: 331px;
    height: 306px;
  }
}

.list-about-01 > .item .image.-image2 {
  top: 72px;
  right: 67px;
  width: 260px;
  height: inherit;
  margin: 0 0 0 auto;
  position: relative;
  top: 0;
  right: -10px;
}

@media (min-width: 897px) {
  .list-about-01 > .item .image.-image2 {
    width: 369px;
    height: 304px;
    margin: 0;
    position: absolute;
    top: 75px;
    right: 66px;
  }
}

.list-about-01 > .item .image.-image3 {
  top: 0;
  right: 0;
  width: auto;
  margin: 0 -28px -30px;
  overflow: hidden;
}

@media (min-width: 897px) {
  .list-about-01 > .item .image.-image3 {
    margin: 0;
    height: 293px;
    max-width: 50%;
  }
}

.list-about-01 > .item .image.-image3 img {
  width: 100%;
}

@media (min-width: 897px) {
  .list-about-01 > .item .image.-image3 img {
    width: inherit;
  }
}

.list-about-01 > .item .box-left-in {
  width: 100%;
}

@media (min-width: 897px) {
  .list-about-01 > .item .box-left-in {
    width: 535px;
  }
}

.list-about-01 > .item .box-dark-about {
  width: 152px;
  height: 95px;
  position: absolute;
  bottom: 133px;
  left: 28px;
  z-index: 2;
  background-color: #edeff3;
  border-radius: 10px;
  color: #385199;
  font-size: 1.4rem;
  line-height: 1.45;
  font-weight: bold;
  padding: 17px 20px;
}

@media (min-width: 897px) {
  .list-about-01 > .item .box-dark-about {
    width: 215px;
    height: 135px;
    top: 96px;
    right: 273px;
    bottom: inherit;
    left: inherit;
    font-size: 2rem;
    padding: 26px 27px;
  }
}

.list-about-02 {
  counter-reset: my-sec-counter;
}

.list-about-02 > .item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px 0 30px 0;
  border-bottom: 1px solid #ebebeb;
}

@media (min-width: 897px) {
  .list-about-02 > .item {
    padding: 45px 0 40px 0;
  }
}

.list-about-02 > .item:last-child {
  border-bottom: none;
}

.list-about-02 > .item .title {
  width: 100%;
  font-size: 2rem;
  color: #385199;
  line-height: 1.3;
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
  font-weight: bold;
}

@media (min-width: 897px) {
  .list-about-02 > .item .title {
    font-size: 2.4rem;
    width: 47%;
    padding-left: 50px;
    margin-bottom: 0;
  }
}

.list-about-02 > .item .title:before {
  counter-increment: my-sec-counter;
  content: counter(my-sec-counter);
  font-family: "Roboto Condensed";
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 4.5rem;
  font-style: italic;
  margin-top: -30px;
}

@media (min-width: 897px) {
  .list-about-02 > .item .title:before {
    font-size: 5.6rem;
    margin-top: -35px;
  }
}

.list-about-02 > .item .details {
  width: 100%;
}

@media (min-width: 897px) {
  .list-about-02 > .item .details {
    width: 50%;
  }
}

.list-about-03 .c-btn {
  min-width: 100%;
  height: 60px;
  padding: 20px;
}

@media (min-width: 897px) {
  .list-about-03 .c-btn {
    min-width: 310px;
    height: inherit;
    padding: 15px;
  }
}

.list-about-03 > .item {
  padding: 25px 25px 40px;
  margin-bottom: 15px;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

@media (min-width: 897px) {
  .list-about-03 > .item {
    margin-bottom: 30px;
    padding: 70px 80px 30px;
  }
}

.list-about-03 > .item .title {
  font-size: 2.2rem;
}

@media (min-width: 897px) {
  .list-about-03 > .item .title {
    font-size: 2.8rem;
  }
}

.list-about-03 > .item .title1 {
  font-size: 2.2rem;
}

@media (min-width: 897px) {
  .list-about-03 > .item .title1 {
    font-size: 2.8rem;
  }
}

.list-about-04 {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  flex-wrap: wrap;
}

@media (min-width: 897px) {
  .list-about-04 {
    margin-bottom: 35px;
  }
}

.list-about-04 > .item {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

@media (min-width: 897px) {
  .list-about-04 > .item {
    margin-bottom: 10px;
    width: 225px;
    margin: 0 30px;
    display: block;
  }
}

.list-about-04 > .item .icon {
  flex-shrink: 0;
  width: 112px;
}

@media (min-width: 897px) {
  .list-about-04 > .item .icon {
    width: inherit;
    margin-bottom: 16px;
  }
}

.list-about-04 > .item .text {
  line-height: 1.4;
  margin-left: 20px;
  text-align: left;
}

@media (min-width: 897px) {
  .list-about-04 > .item .text {
    width: inherit;
    margin-left: 0;
    text-align: center;
  }
}

.box-about-01 {
  margin-bottom: 40px;
  position: relative;
  background-color: #fff;
  padding: 25px 25px 0;
}

@media (min-width: 897px) {
  .box-about-01 {
    margin-bottom: 60px;
    padding: 25px 80px;
  }
}

.box-about-01 > .title {
  position: absolute;
  top: -16px;
  left: 47%;
  display: inline-block;
  padding: 4px 15px;
  width: 206px;
  height: 34px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  border-radius: 40px;
  margin: 0 0 0 -90px;
  background-color: #000;
}

@media (min-width: 897px) {
  .box-about-01 > .title {
    padding: 12px 15px;
    top: -25px;
    width: 242px;
    height: 50px;
  }
}

.card-about-01 {
  background-color: #fff;
  padding: 25px;
  height: 367px;
}

@media (min-width: 897px) {
  .card-about-01 {
    height: inherit;
    padding: 40px;
  }
}

.card-about-01.-blackground1 {
  background: #fff url(/assets/images/about/bg_list_about_01_sp.jpg) center bottom no-repeat;
  background-size: 100% auto;
}

@media (min-width: 897px) {
  .card-about-01.-blackground1 {
    background: #fff url(/assets/images/about/bg_list_about_01_pc.jpg) 0 0 no-repeat;
    background-size: auto auto;
  }
}

.card-about-01.-blackground2 {
  background: #fff url(/assets/images/about/bg_list_about_02_sp.jpg) center bottom no-repeat;
  background-size: 100% auto;
  height: 411px;
}

@media (min-width: 897px) {
  .card-about-01.-blackground2 {
    background: #fff url(/assets/images/about/bg_list_about_02_pc.jpg) 0 0 no-repeat;
    background-size: auto auto;
    height: inherit;
  }
}

.card-about-01.-blackground3 {
  background: #fff url(/assets/images/about/bg_list_about_03_sp.jpg) center 88% no-repeat;
  background-size: 121px auto;
  height: 368px;
}

@media (min-width: 897px) {
  .card-about-01.-blackground3 {
    height: 417px;
    background: #fff url(/assets/images/about/bg_list_about_03_pc.jpg) center 88% no-repeat;
    background-size: inherit;
  }
}

.card-about-01.-blackground4 {
  background: #fff url(/assets/images/about/bg_list_about_04_sp.jpg) center bottom no-repeat;
  height: 367px;
  background-size: 100% auto;
}

@media (min-width: 897px) {
  .card-about-01.-blackground4 {
    background: #fff url(/assets/images/about/bg_list_about_04_pc.jpg) center bottom no-repeat;
    height: 417px;
  }
}

.card-about-01.-blackground5 {
  background: #fff url(/assets/images/about/bg_list_about_05_sp.jpg) center bottom no-repeat;
  height: 367px;
  background-size: 100% auto;
}

@media (min-width: 897px) {
  .card-about-01.-blackground5 {
    height: 417px;
    background: #fff url(/assets/images/about/bg_list_about_05_pc.jpg) center bottom no-repeat;
  }
}

.card-about-01 .title {
  margin-bottom: 7px;
  font-size: 2.2rem;
  font-weight: bold;
}

@media (min-width: 897px) {
  .card-about-01 .title {
    margin-bottom: 12px;
    font-size: 2.8rem;
  }
}

.card-about-01 .text {
  letter-spacing: 0.06em;
  margin-bottom: 10px;
}

@media (min-width: 897px) {
  .card-about-01 .text {
    margin-bottom: 0;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* box-border-bottom
---------------------------------------------------------- */
.box-border-bottom {
  padding-bottom: 40px;
  border-bottom: 1px solid #e0e0e0;
}

@media (min-width: 897px) {
  .box-border-bottom {
    padding-bottom: 80px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
.c-list-fag {
  margin-bottom: 40px;
}

@media (min-width: 897px) {
  .c-list-fag {
    margin-bottom: 50px;
  }
}

.c-list-fag li {
  margin-bottom: 40px;
}

.c-list-fag li:last-child {
  margin-bottom: 0;
}

/* ttl-question
---------------------------------------------------------- */
.ttl-question {
  position: relative;
  padding: 0 20px 22px 20px;
  font-size: 1.5rem;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 30px;
  font-weight: bold;
}

@media (min-width: 897px) {
  .ttl-question {
    font-size: 2.2rem;
    padding: 0 20px 29px 43px;
    margin-bottom: 0;
  }
}

.ttl-question:before {
  content: 'Q';
  position: absolute;
  left: 0;
  top: -6px;
  color: #2157a7;
  font-weight: bold;
  font-family: 'Roboto Condensed';
  font-size: 2rem;
}

@media (min-width: 897px) {
  .ttl-question:before {
    font-size: 2.8rem;
  }
}

.ttl-question:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -20px;
  background: url(/assets/images/common/ico_acc_01.png) no-repeat;
  background-size: 100%;
  width: 21px;
  height: 21px;
}

@media (min-width: 897px) {
  .ttl-question:after {
    right: 20px;
    margin-top: -29px;
  }
}

.box-answer {
  display: none;
  padding: 5px 0;
  margin-bottom: 20px;
}

@media (min-width: 897px) {
  .box-answer {
    padding: 10px 0;
  }
}

/* ist-ask-sns
---------------------------------------------------------- */
@media (max-width: 896px) {
  .list-ask-sns .list {
    margin: -40px -40px 0;
  }
}

@media (min-width: 897px) {
  .list-ask-sns .list .item {
    padding: 0 35px;
  }
}

@media (max-width: 896px) {
  .list-ask-sns .list .item {
    padding: 0 30px;
    margin-top: 40px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* sec-flow
---------------------------------------------------------- */
.ttl-flow {
  font-size: 2rem;
  font-weight: 700;
  color: #111;
  text-align: center;
  margin-bottom: 25px;
}

@media (min-width: 897px) {
  .ttl-flow {
    font-size: 2.4rem;
    margin-bottom: 50px;
  }
}

/* list-flow
---------------------------------------------------------- */
.card-flow {
  position: relative;
  border: 1px solid #ebebeb;
  padding: 28px 25px 30px;
}

@media (min-width: 897px) {
  .card-flow {
    padding: 40px 50px 45px;
    height: 100%;
  }
}

.card-flow .label {
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 35px;
  color: #fff;
  text-align: center;
  background-color: #111;
}

@media (min-width: 897px) {
  .card-flow .label {
    width: 40px;
    height: 40px;
    font-size: 2rem;
    line-height: 40px;
  }
}

.card-flow .title {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

@media (min-width: 897px) {
  .card-flow .title {
    font-size: 2rem;
  }
}

.card-flow .text {
  font-size: 1.3rem;
  margin-top: 18px;
}

@media (min-width: 897px) {
  .card-flow .text {
    font-size: 1.4rem;
    margin-top: 22px;
  }
}

.card-flow .image {
  text-align: center;
  margin-top: 12px;
}

@media (min-width: 897px) {
  .card-flow .image {
    margin-top: 17px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
.c-card-moshimo-gas {
  border: 1px solid #ebebeb;
  padding: 0 25px 30px;
}

@media (min-width: 897px) {
  .c-card-moshimo-gas {
    padding: 0 50px 40px;
  }
}

.c-card-moshimo-gas .image {
  text-align: center;
  height: 100px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 897px) {
  .c-card-moshimo-gas .image {
    height: 165px;
    margin-top: 0;
  }
}

.c-card-moshimo-gas .image.-image3 {
  height: 92px;
}

@media (min-width: 897px) {
  .c-card-moshimo-gas .image.-image3 {
    height: 190px;
  }
}

.c-card-moshimo-gas .image.-image4 {
  height: 120px;
}

@media (min-width: 897px) {
  .c-card-moshimo-gas .image.-image4 {
    height: 190px;
  }
}

.txt-moshimo-gas {
  font-size: 12px;
  line-height: 1.5;
  position: relative;
  padding-left: 10px;
}

.txt-moshimo-gas:before {
  content: '＊';
  position: absolute;
  left: 0;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* gas-equation
---------------------------------------------------------- */
.gas-equation {
  display: flex;
  font-size: 26px;
  justify-content: center;
  margin: 0 -28px;
}

@media (max-width: 896px) {
  .gas-equation {
    margin: 0;
    flex-direction: column;
    font-size: 16px;
  }
}

.gas-equation > div {
  padding: 17px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  margin: 0 28px;
}

@media (max-width: 896px) {
  .gas-equation > div {
    margin: 0 0 40px 0;
    padding: 25px;
  }
}

.gas-equation > div.result {
  background: #f93b00;
  width: 280px;
  position: relative;
}

.gas-equation > div.result::after {
  content: '';
  background: url(/assets/images/common/ico_equa_02.png) no-repeat center;
  background-size: 100%;
  position: absolute;
}

@media (min-width: 897px) {
  .gas-equation > div.result::after {
    top: calc(50% - 13px);
    right: -24px;
    width: 16px;
    height: 26px;
    transform: rotate(90deg) translateY(-50%);
  }
}

@media (max-width: 896px) {
  .gas-equation > div.result {
    width: 100%;
  }
  .gas-equation > div.result::after {
    bottom: -30px;
    width: 13px;
    height: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.gas-equation > div.var1 {
  background: #888;
  width: 280px;
  position: relative;
}

.gas-equation > div.var1::after {
  content: '';
  position: absolute;
  background: url(/assets/images/common/ico_equa_01.png) no-repeat center;
  background-size: 100%;
}

@media (min-width: 897px) {
  .gas-equation > div.var1::after {
    top: 50%;
    right: -41px;
    width: 26px;
    height: 26px;
    transform: translateY(-50%);
  }
}

@media (max-width: 896px) {
  .gas-equation > div.var1 {
    width: 100%;
  }
  .gas-equation > div.var1::after {
    bottom: -30px;
    width: 20px;
    height: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.gas-equation > div.var2 {
  background: #2157a7;
  width: 397px;
  flex-direction: column;
}

@media (max-width: 896px) {
  .gas-equation > div.var2 {
    width: 100%;
    margin-bottom: 0;
  }
}

.gas-equation span {
  font-size: 13px;
  display: block;
}

@media (min-width: 897px) {
  .gas-equation span {
    font-size: 20px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* sec-musubi-intro
---------------------------------------------------------- */
.sec-musubi-intro {
  padding-top: 50px;
  padding-bottom: 140px;
  background: url("/assets/images/musubi-project/bg_musubi.jpg") center no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 897px) {
  .sec-musubi-intro {
    padding-top: 160px;
    padding-bottom: 360px;
  }
}

/* sec-musubi-logo
---------------------------------------------------------- */
.sec-musubi-logo {
  position: relative;
  margin-top: -90px;
  padding-bottom: 10px;
}

@media (min-width: 897px) {
  .sec-musubi-logo {
    margin-top: -215px;
  }
}

/* box-musubi-case
---------------------------------------------------------- */
.box-musubi-case {
  padding: 27px 24px;
  background-color: #f93b00;
  text-align: center;
}

@media (min-width: 897px) {
  .box-musubi-case {
    padding: 45px 30px;
  }
}

.box-musubi-case .heading {
  display: inline-block;
  padding: 6px 25px;
  margin-bottom: 15px;
  border: 2px solid #fff;
  border-radius: 100px;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.3;
  color: #fff;
  text-align: center;
}

@media (min-width: 897px) {
  .box-musubi-case .heading {
    padding: 12px 50px;
    font-size: 2.2rem;
  }
}

.box-musubi-case .heading .price {
  position: relative;
  margin-left: 10px;
  font-size: 1.5rem;
}

@media (min-width: 897px) {
  .box-musubi-case .heading .price {
    top: 2px;
    font-size: 2.6rem;
  }
}

.box-musubi-case .text {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.3;
  color: #fff;
}

@media (min-width: 897px) {
  .box-musubi-case .text {
    font-size: 3rem;
  }
}

.box-musubi-case .text .price {
  position: relative;
  margin-right: 10px;
  font-size: 4rem;
}

@media (min-width: 897px) {
  .box-musubi-case .text .price {
    top: 5px;
    margin: 0 10px;
    font-size: 6.4rem;
  }
}

/* mod-musubi-case-accd
---------------------------------------------------------- */
.mod-musubi-case-accd .issue {
  display: none;
}

.mod-musubi-case-accd .c-box-dark {
  position: relative;
  padding: 70px 20px 40px;
  margin-bottom: 40px;
}

@media (min-width: 897px) {
  .mod-musubi-case-accd .c-box-dark {
    padding: 90px 7.5% 60px;
    margin-bottom: 60px;
  }
}

.mod-musubi-case-accd .c-box-dark .heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.mod-musubi-case-accd .c-box-dark .heading .text {
  display: inline-block;
  padding: 7px 25px;
  background-color: #111;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.4;
}

@media (min-width: 897px) {
  .mod-musubi-case-accd .c-box-dark .heading .text {
    padding: 10px 35px;
    font-size: 2rem;
  }
}

.mod-musubi-case-accd .c-box-dark:after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-image: url("/assets/images/common/icon_plus.png");
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
}

@media (min-width: 897px) {
  .mod-musubi-case-accd .c-box-dark:after {
    bottom: -40px;
  }
}

.mod-musubi-case-accd .c-box-dark.-equal:after {
  width: 13px;
  height: 20px;
  background-image: url("/assets/images/common/ico_equa_02.png");
}

.mod-musubi-case-accd .c-box-dark.-nosign:after {
  content: none;
}

.mod-musubi-case-accd .cont {
  line-height: 1.4;
}

@media (min-width: 897px) {
  .c-list-qa {
    margin: 0 auto;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
.nav-news {
  padding-top: 45px;
}

@media (min-width: 897px) {
  .nav-news {
    padding-top: 67px;
  }
}

@media (min-width: 897px) {
  .nav-news > .list-news {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.nav-news > .list-news:not(:first-of-type) {
  margin-top: 10px;
}

@media (min-width: 897px) {
  .nav-news > .list-news:not(:first-of-type) {
    margin-top: 0;
    line-height: 1.7;
  }
}

.nav-news > .list-news > dt {
  flex: 0 0 100%;
  font-size: 1.4rem;
  font-weight: bold;
  color: #111111;
}

@media (min-width: 897px) {
  .nav-news > .list-news > dt {
    flex: 0 0 78px;
    font-size: 1.6rem;
    display: block;
  }
}

.nav-news > .list-news > dd {
  flex: 1;
}

@media (min-width: 897px) {
  .nav-news > .list-news > dd {
    flex: 0 0 auto;
    display: block;
  }
}

.list-archive {
  display: flex;
  flex-wrap: wrap;
  margin: 5px -5px 0;
}

.list-archive > .item {
  margin: 0;
  width: 25%;
  padding: 7px 5px;
}

@media (min-width: 897px) {
  .list-archive > .item {
    width: auto;
  }
}

.list-archive > .item > .link {
  display: inline-flex;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 8px 12px;
  font-size: 1.2rem;
  border-radius: 50px;
  height: 100%;
  background: #fff;
  vertical-align: middle;
  color: #385199;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: background 0.2s;
}

@media (min-width: 897px) {
  .list-archive > .item > .link {
    font-size: 1.4rem;
    padding: 8px 21px;
  }
}

.list-archive > .item > .link:hover, .list-archive > .item > .link.is-active {
  background: #385199;
  color: #fff;
}

.list-archive.u-2-column-sp {
  margin-top: 0;
}

.list-archive.u-2-column-sp > .item {
  width: 50%;
  padding: 10px 5px;
}

@media (min-width: 897px) {
  .list-archive.u-2-column-sp > .item {
    width: auto;
  }
}

.list-archive.u-2-column-sp > .item > .link {
  width: 100%;
}

.box-news {
  padding: 33px 0 180px;
}

@media (min-width: 897px) {
  .box-news {
    padding-bottom: 185px;
  }
}

.box-news > .item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 26px 0 20px;
}

@media (min-width: 897px) {
  .box-news > .item {
    flex-wrap: nowrap;
    padding: 26px 0 30px;
  }
}

.box-news > .item:not(:first-of-type) {
  border-top: 1px solid #ebebeb;
}

@media (min-width: 897px) {
  .box-news > .item:last-of-type {
    border-bottom: 1px solid #ebebeb;
  }
}

.box-news > .item > .date {
  position: relative;
  top: 9px;
  flex: 0 1 auto;
  font-size: 1.4rem;
  font-weight: bold;
  color: #333333;
  line-height: 1.1;
}

@media (min-width: 897px) {
  .box-news > .item > .date {
    flex: 0 0 135px;
    font-size: 1.6rem;
  }
}

.box-news > .item > .category {
  flex: 0 1 auto;
  display: inline-flex;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 5px 28px;
  font-size: 1rem;
  border-radius: 50px;
  min-width: 135px;
  height: 100%;
  background: #fff;
  vertical-align: middle;
  color: #385199;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  transition: background 0.2s;
}

@media (min-width: 897px) {
  .box-news > .item > .category {
    font-size: 1.2rem;
    padding: 5px 18px;
  }
}

.box-news > .item > .category:hover, .box-news > .item > .category.is-active {
  background: #385199;
  color: #fff;
}

.box-news > .item > .category:hover .link, .box-news > .item > .category.is-active .link {
  color: #fff;
}

.box-news > .item > .category .link {
  color: #385199;
}

.box-news > .item > .title {
  flex: 1 1 100%;
  margin-top: 10px;
}

@media (min-width: 897px) {
  .box-news > .item > .title {
    margin-top: 0;
    font-size: 1.6rem;
    margin-left: 25px;
  }
}

.box-news > .item > .title .link {
  font-size: 1.4rem;
  font-weight: 500;
  color: #333333;
  letter-spacing: -0.02em;
}

@media (min-width: 897px) {
  .box-news > .item > .title .link {
    font-size: 1.6rem;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* text-price
---------------------------------------------------------- */
.txt-price {
  display: inline-block;
  position: relative;
  font-size: 2rem;
  font-weight: 700;
  color: #111;
  line-height: 1;
}

@media (min-width: 897px) {
  .txt-price {
    font-size: 2.6rem;
  }
}

.txt-price .number {
  font-size: 6rem;
}

@media (min-width: 897px) {
  .txt-price .number {
    font-size: 8rem;
  }
}

.txt-price .txt-note {
  font-size: 1.2rem;
  text-align: right;
  position: absolute;
  right: 15px;
  bottom: -15px;
}

.txt-price-wrap {
  text-align: center;
}

/* box-okaimono-about
---------------------------------------------------------- */
.box-okaimono-about {
  background-color: #f6f7f9;
  padding: 35px 25px;
  margin-top: 45px;
}

@media (min-width: 897px) {
  .box-okaimono-about {
    padding: 70px 80px 65px;
  }
}

.box-okaimono-about .title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #2157a7;
  margin-bottom: 12px;
}

@media (min-width: 897px) {
  .box-okaimono-about .title {
    font-size: 2rem;
  }
}

.box-okaimono-about .c-list-note > .item {
  font-size: 1.1rem;
  margin-bottom: 2px;
}

@media (min-width: 897px) {
  .box-okaimono-about .c-list-note > .item {
    font-size: 1.4rem;
  }
}

.slider {
  padding: 0 115px;
  margin-right: auto;
  margin-left: auto;
  /* Arrows */
}

@media (max-width: 896px) {
  .slider {
    padding: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.slider .box-okaimono-slider {
  text-align: center;
  outline: none;
}

@media (max-width: 896px) {
  .slider .box-okaimono-slider {
    width: 194px;
    padding: 0 18px;
  }
}

.slider .box-okaimono-slider .link {
  display: block;
}

@media (min-width: 897px) {
  .slider .box-okaimono-slider .link {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .slider .box-okaimono-slider .link:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.slider .box-okaimono-slider img {
  padding: 0 10px;
  margin: 0 auto;
}

@media (max-width: 896px) {
  .slider .box-okaimono-slider img {
    width: 100%;
    padding: 0;
  }
}

.slider .box-okaimono-slider .text {
  margin-top: 15px;
}

@media (max-width: 896px) {
  .slider .box-okaimono-slider .text {
    margin-top: 10px;
  }
}

.slider .slick-prev,
.slider .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 45%;
  display: block;
  width: 60px;
  height: 60px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

@media (min-width: 897px) {
  .slider .slick-prev:hover,
  .slider .slick-prev:focus,
  .slider .slick-next:hover,
  .slider .slick-next:focus {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .slider .slick-prev:hover:hover,
  .slider .slick-prev:focus:hover,
  .slider .slick-next:hover:hover,
  .slider .slick-next:focus:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .slider .slick-prev:hover:before,
  .slider .slick-prev:focus:before,
  .slider .slick-next:hover:before,
  .slider .slick-next:focus:before {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .slider .slick-prev:hover:before:hover,
  .slider .slick-prev:focus:before:hover,
  .slider .slick-next:hover:before:hover,
  .slider .slick-next:focus:before:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.slider .slick-prev:before,
.slider .slick-next:before {
  width: 60px;
  height: 60px;
  display: block;
}

.slider .slick-prev {
  left: 40px;
}

.slider [dir='rtl'] .slick-prev {
  right: 40px;
  left: auto;
}

.slider .slick-prev:before {
  content: '';
  background: url(/assets/images/okaimono/ico_arrow_left.png) no-repeat;
}

.slider [dir='rtl'] .slick-prev:before {
  content: '';
  background: url(/assets/images/okaimono/ico_arrow_left.png) no-repeat;
}

.slider .slick-next {
  right: 40px;
}

.slider [dir='rtl'] .slick-next {
  right: auto;
  left: 40px;
}

.slider .slick-next:before {
  content: '';
  background: url(/assets/images/okaimono/ico_arrow_right.png) no-repeat;
}

.slider [dir='rtl'] .slick-next:before {
  content: '';
  background: url(/assets/images/okaimono/ico_arrow_right.png) no-repeat;
}

@media (max-width: 896px) {
  .c-card-service-wrap {
    overflow: hidden;
    padding-top: 30px;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* img-payment-01
---------------------------------------------------------- */
.img-payment-01 {
  max-width: 748px;
  margin: 0 auto 32px;
}

.img-payment-01 figcaption {
  color: #ff0000;
  font-size: 1.2rem;
  font-weight: bold;
}

.img-payment-01 img {
  max-width: 100%;
}

.list-icon-prime {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: -35px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}

@media (min-width: 897px) {
  .list-icon-prime {
    padding-bottom: 50px;
  }
}

.list-icon-prime:after {
  content: "";
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
  width: 21px;
  height: 11px;
  background: #fff url("/assets/images/prime/arrow_down.png") 0 0 no-repeat;
  background-size: 100% auto;
}

@media (min-width: 897px) {
  .list-icon-prime:after {
    bottom: -22px;
    width: 42px;
    height: 22px;
  }
}

@media (min-width: 897px) {
  .list-icon-prime {
    margin-top: -40px;
  }
}

.list-icon-prime .item {
  width: 50%;
  padding: 0 15px;
  margin-top: 35px;
}

@media (min-width: 897px) {
  .list-icon-prime .item {
    width: 16.66%;
    margin-top: 40px;
    padding: 0 10px;
  }
}

.list-icon-prime .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
}

@media (min-width: 897px) {
  .list-icon-prime .icon {
    margin-bottom: 20px;
  }
}

.list-icon-prime .text {
  display: block;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.4;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ttl-qa
---------------------------------------------------------- */
.ttl-qa {
  margin-bottom: 35px;
}

@media (min-width: 897px) {
  .ttl-qa {
    display: flex;
    align-items: center;
    margin-bottom: 70px;
  }
}

.ttl-qa .num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 33px;
  margin-bottom: 13px;
  background-color: #000;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

@media (min-width: 897px) {
  .ttl-qa .num {
    width: 80px;
    height: 47px;
    margin: 0 22px 0 0;
    font-size: 2rem;
  }
}

.ttl-qa .text {
  font-size: 2rem;
  font-weight: bold;
}

@media (min-width: 897px) {
  .ttl-qa .text {
    font-size: 2.4rem;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* btn-list-recruit
---------------------------------------------------------- */
.btn-list-recruit {
  max-width: 770px;
  margin: 0 auto;
}

.btn-list-recruit .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.btn-list-recruit .list .item {
  width: 100%;
  margin-bottom: 20px;
}

.btn-list-recruit .list .item:last-child {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .btn-list-recruit .list .item {
    width: 370px;
    margin-bottom: 0;
  }
}

.btn-list-recruit .list .item a {
  position: relative;
  display: block;
  padding: 23px 10px;
  background-color: #2157a7;
  font-size: 1.65rem;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  appearance: none;
}

@media (min-width: 897px) {
  .btn-list-recruit .list .item a {
    font-size: 2rem;
    padding: 30px 10px;
  }
}

@media (min-width: 897px) and (min-width: 897px) {
  .btn-list-recruit .list .item a {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .btn-list-recruit .list .item a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.btn-list-recruit .list .item a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 7px;
  height: 11px;
  background: url("/assets/images/common/icon_arrow_right_white.png") 0 0 no-repeat;
  background-size: 100% auto;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* margin-col
---------------------------------------------------------- */
/* grid-sitemap
---------------------------------------------------------- */
.grid-sitemap {
  display: flex;
  flex-wrap: wrap;
}

.grid-sitemap > .col {
  width: 100%;
}

@media (max-width: 896px) {
  .grid-sitemap > .col {
    margin-bottom: 20px;
    width: 100%;
  }
  .grid-sitemap > .col:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 897px) {
  .grid-sitemap > .col {
    width: 25%;
  }
  .grid-sitemap > .col:nth-child(4n) {
    margin-bottom: 25px;
  }
  .grid-sitemap > .col:last-child {
    margin-bottom: 0;
  }
}

/* grid-custom-sitemap
---------------------------------------------------------- */
.grid-custom-sitemap {
  display: flex;
  flex-wrap: wrap;
}

.grid-custom-sitemap .box-1 {
  width: 25%;
}

@media (max-width: 896px) {
  .grid-custom-sitemap .box-1 {
    width: 100%;
    margin-bottom: 20px;
  }
}

.grid-custom-sitemap .box-3 {
  width: 66.6%;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 896px) {
  .grid-custom-sitemap .box-3 {
    width: 100%;
  }
}

.grid-custom-sitemap .box-4 {
  width: 33.3%;
}

@media (max-width: 896px) {
  .grid-custom-sitemap .box-4 {
    width: 100%;
    margin-bottom: 20px;
  }
}

.grid-custom-sitemap .box-2 {
  width: 75%;
}

@media (max-width: 896px) {
  .grid-custom-sitemap .box-2 {
    width: 100%;
  }
}

.grid-custom-sitemap .box-2 .box-list {
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 896px) {
  .grid-custom-sitemap .box-2 .box-list {
    margin-bottom: 40px;
  }
}

.grid-custom-sitemap .box-2 .box-list .col-1 {
  width: 100%;
}

@media (max-width: 896px) {
  .grid-custom-sitemap .box-2 .box-list .col-2 {
    margin-bottom: 20px;
    width: 100%;
  }
  .grid-custom-sitemap .box-2 .box-list .col-2:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 897px) {
  .grid-custom-sitemap .box-2 .box-list .col-2 {
    width: 33.3%;
  }
  .grid-custom-sitemap .box-2 .box-list .col-2:nth-child(3n) {
    margin-bottom: 25px;
  }
  .grid-custom-sitemap .box-2 .box-list .col-2:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 896px) {
  .grid-custom-sitemap .box-2 .box-list .col-3 {
    margin-bottom: 20px;
    width: 100%;
  }
  .grid-custom-sitemap .box-2 .box-list .col-3:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 897px) {
  .grid-custom-sitemap .box-2 .box-list .col-3 {
    width: 50%;
  }
  .grid-custom-sitemap .box-2 .box-list .col-3:nth-child(2n) {
    margin-bottom: 25px;
  }
  .grid-custom-sitemap .box-2 .box-list .col-3:last-child {
    margin-bottom: 0;
  }
}

.grid-custom-sitemap .box-2 .box-list:last-child {
  margin-bottom: 0;
}

@media (max-width: 896px) {
  .grid-custom-sitemap .box-2 .box-list {
    margin-bottom: 20px;
  }
  .grid-custom-sitemap .box-2 .box-list:nth-child(3) {
    flex-direction: column-reverse;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* box-btn-teiden
---------------------------------------------------------- */
@media (min-width: 897px) {
  .box-btn-teiden > .item {
    width: calc(50% - 1px);
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 Toppage
========================================================== */
.mainvisual-top {
  position: relative;
  height: 900px;
  visibility: hidden;
  overflow: hidden;
}

.mainvisual-top.-loaded {
  height: auto;
  visibility: visible;
}

.mainvisual-top .scroll-guide {
  position: absolute;
  left: 26px;
  bottom: 140px;
  letter-spacing: .1em;
  font-size: 1.2rem;
  color: #fff;
  line-height: 1;
  transform: translateZ(0) rotate(90deg);
}

.mainvisual-top .scroll-guide:after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 68px;
  height: 1px;
  width: 100px;
  background: #fff;
  animation: scroll_effect 2s 2s cubic-bezier(0.3, 0.4, 0.1, 1) infinite;
}

.mainvisual-top .list-slider .item {
  position: relative;
  outline: none;
  overflow: hidden;
}

@media (min-width: 897px) {
  .mainvisual-top .list-slider .item {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 896px) {
  .mainvisual-top .list-slider .item {
    height: auto;
  }
}

@media (min-width: 897px) {
  .mainvisual-top .list-slider .item .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .mainvisual-top .list-slider .item .image img.only-pc {
    opacity: 0;
  }
}

@media (max-width: 896px) {
  .mainvisual-top .list-slider .item .image img {
    width: 100%;
  }
}

.mainvisual-top .list-slider .item .bg-switch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.mainvisual-top .list-slider .item .bg-switch > img {
  max-width: none;
}

.mainvisual-top .list-slider .item .bg-switch > img.ww {
  width: 100%;
}

.mainvisual-top .list-slider .item .bg-switch > img.wh {
  height: 100%;
}

.mainvisual-top .list-slider .item .bg-switch .box-switch {
  position: absolute;
  top: 0;
  left: 20%;
  display: block;
  width: 16.5%;
  margin-top: 18.2%;
  z-index: 2;
  outline: none;
}

.mainvisual-top .list-slider .item .bg-switch .box-switch img {
  width: 100%;
}

.mainvisual-top .list-slider .item .btn-click {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/top/btn_click.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation: bounce 1s infinite;
  pointer-events: none;
}

@media (max-width: 896px) {
  .mainvisual-top .list-slider .item .btn-click {
    background-image: url("/assets/images/top/btn_touch.png");
    background-position: 0 0;
    background-size: 100% auto;
  }
}

.mainvisual-top .list-slider .item.item01 {
  position: relative;
}

.mainvisual-top .list-slider .item.item01:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 0;
  padding-top: 33.25%;
  background: url("/assets/images/top/light_hover.png") center top no-repeat;
  background-size: auto 100%;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  pointer-events: none;
}

.mainvisual-top .list-slider .item.item01.-hover:before {
  opacity: 1;
  visibility: visible;
}

.mainvisual-top .list-slider .item.item01.-hover .box-text .title {
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
  transition: text-shadow .3s;
}

@media (max-width: 896px) {
  .mainvisual-top .list-slider .item.item01 .image {
    position: relative;
  }
  .mainvisual-top .list-slider .item.item01 .box-switch {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 35%;
    margin-top: 87%;
    z-index: 1;
    outline: none;
  }
  .mainvisual-top .list-slider .item.item01 .box-switch img {
    width: 100%;
  }
}

@media (min-width: 897px) {
  .mainvisual-top .list-thumbnails {
    position: absolute;
    right: 40px;
    bottom: 20px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
  }
  .mainvisual-top .list-thumbnails .item {
    position: relative;
    cursor: pointer;
    margin-left: 1px;
    outline: none;
  }
  .mainvisual-top .list-thumbnails .item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity .4s;
  }
  .mainvisual-top .list-thumbnails .item:hover:before, .mainvisual-top .list-thumbnails .item.-active:before {
    opacity: 1;
  }
  .mainvisual-top .list-thumbnails .item img {
    width: 100%;
  }
}

@media (max-width: 896px) {
  .mainvisual-top .list-thumbnails {
    display: none;
  }
}

.mainvisual-top .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.mainvisual-top .slick-dots li {
  padding: 0 5px;
  outline: none;
  line-height: 1;
}

.mainvisual-top .slick-dots li button {
  width: 8px;
  height: 8px;
  border: 1px solid #ccc;
  border-radius: 50%;
  appearance: none;
  text-indent: -9999px;
  overflow: hidden;
  outline: none;
}

.mainvisual-top .slick-dots li.slick-active button {
  background-color: #ccc;
}

@media (min-width: 897px) {
  .mainvisual-top .box-text {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 3;
    transform: translateY(-50%);
    padding-left: 50%;
    width: 100%;
  }
  .mainvisual-top .box-text .c-link-arrow {
    transform: translateZ(0);
    backface-visibility: hidden;
  }
}

@media (max-width: 896px) {
  .mainvisual-top .box-text {
    height: 260px;
    background-color: #fff;
    padding: 20px 25px;
  }
}

.mainvisual-top .box-text .title {
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 4.4rem;
  line-height: 1.5;
  color: #111;
}

@media (max-width: 896px) {
  .mainvisual-top .box-text .title {
    margin-bottom: 15px;
    font-size: 2.7rem;
  }
}

.mainvisual-top .box-text .text {
  font-size: 1.8rem;
  line-height: 2.1;
  color: #111;
}

@media (max-width: 896px) {
  .mainvisual-top .box-text .text {
    font-size: 1.4rem;
    line-height: 1.78;
  }
}

.mainvisual-top .box-text .link {
  display: inline-block;
}

.mainvisual-top .box-text .link:hover {
  opacity: .7;
}

.mainvisual-top .box-text .link a {
  display: inline-block;
  transition: none;
}

.mainvisual-top .box-text .link a:hover {
  opacity: 1;
}

.mainvisual-top .box-text.box01 .title, .mainvisual-top .box-text.box01 .text {
  color: #fff;
}

@media (min-width: 897px) {
  .mainvisual-top .box-text.box01 {
    padding-left: 0;
    margin-left: 41%;
  }
}

@media (max-width: 896px) {
  .mainvisual-top .box-text.box01 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    padding: 40px 20px;
    background-color: transparent;
  }
  .mainvisual-top .box-text.box01 .image {
    position: relative;
  }
}

@media (min-width: 897px) {
  .mainvisual-top .box-text.box02 {
    padding-left: 20%;
  }
}

@media (min-width: 897px) {
  .mainvisual-top .box-text.box03 {
    padding-left: 20%;
  }
  .mainvisual-top .box-text.box03 .title, .mainvisual-top .box-text.box03 .text, .mainvisual-top .box-text.box03 a {
    color: #fff;
  }
}

@media (min-width: 897px) {
  .mainvisual-top .box-text.box04 {
    padding-left: 20%;
  }
  .mainvisual-top .box-text.box04 .title, .mainvisual-top .box-text.box04 .text, .mainvisual-top .box-text.box04 a {
    color: #fff;
  }
}

@media (min-width: 897px) {
  .mainvisual-top .box-text.box05 {
    padding-left: 47%;
  }
}

@media (min-width: 897px) {
  .mainvisual-top .box-text.box06 .title, .mainvisual-top .box-text.box06 .text, .mainvisual-top .box-text.box06 a {
    color: #fff;
  }
}

@keyframes scroll_effect {
  0% {
    width: 0;
  }
  50% {
    width: 100px;
  }
  75% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* sec-top
---------------------------------------------------------- */
.sec-top {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 897px) {
  .sec-top {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

/* ttl-top
---------------------------------------------------------- */
.ttl-top {
  margin-bottom: 35px;
  color: #111;
}

@media (min-width: 897px) {
  .ttl-top {
    margin-bottom: 50px;
  }
}

.ttl-top .en {
  display: block;
  font-family: "Roboto Condensed";
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}

@media (min-width: 897px) {
  .ttl-top .en {
    font-size: 1.8rem;
  }
}

.ttl-top .jp {
  display: block;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.4;
}

@media (min-width: 897px) {
  .ttl-top .jp {
    font-size: 3.6rem;
  }
}

.ttl-top.-white {
  color: #fff;
}

/* box-main-cate
---------------------------------------------------------- */
.box-main-cate {
  padding: 60px 25px;
  background-size: 100% auto;
  background-position: right top;
  background-repeat: no-repeat;
}

@media (min-width: 897px) {
  .box-main-cate {
    position: relative;
    height: 515px;
    background-size: auto 125%;
    background-position: center;
    padding: 90px 5.5%;
  }
}

@media (max-width: 1230px) {
  .box-main-cate {
    padding-left: 3.5%;
    padding-right: 3.5%;
  }
}

.box-main-cate.-denki {
  background-color: #160b00;
  background-image: url("/assets/images/top/bg_denki_sp.jpg");
}

@media (min-width: 897px) {
  .box-main-cate.-denki {
    background-image: url("/assets/images/top/bg_denki_pc.jpg");
  }
}

.box-main-cate.-gas {
  background-color: #000d18;
  background-image: url("/assets/images/top/bg_gas_sp.jpg");
}

@media (min-width: 897px) {
  .box-main-cate.-gas {
    background-image: url("/assets/images/top/bg_gas_pc.jpg");
  }
}

.box-main-cate .list-link {
  display: flex;
  flex-wrap: wrap;
  margin-top: -30px;
}

@media (min-width: 897px) {
  .box-main-cate .list-link {
    width: 400px;
    margin-top: -20px;
  }
}

.box-main-cate .list-link .item {
  width: 50%;
  margin-top: 30px;
  padding-right: 10px;
  line-height: 1;
}

@media (min-width: 897px) {
  .box-main-cate .list-link .item {
    padding-right: 15px;
    margin-top: 20px;
  }
}

.box-main-cate .list-link a {
  position: relative;
  display: inline-block;
  padding-left: 12px;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: -0.05em;
}

.box-main-cate .list-link a:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  width: 5px;
  height: 1px;
  background-color: #fff;
}

.box-main-cate .list-link a:hover {
  text-decoration: underline;
}

@media (min-width: 897px) {
  .box-main-cate .list-link a {
    font-size: 1.4rem;
  }
}

/* list-top-banner
---------------------------------------------------------- */
.list-top-banner .list .item {
  outline: none;
}

@media (min-width: 897px) {
  .list-top-banner .list .item {
    margin-bottom: 30px;
  }
  .list-top-banner .list .item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 896px) {
  .list-top-banner .list .item {
    padding: 0 8px;
  }
}

.list-top-banner .list a {
  display: block;
  outline: none;
}

@media (min-width: 897px) {
  .list-top-banner .list a {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .list-top-banner .list a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.list-top-banner .list a img {
  width: 100%;
}

/* sec-top-news
---------------------------------------------------------- */
.sec-top-news {
  background-color: #fff;
}

@media (min-width: 897px) {
  .sec-top-news {
    padding-bottom: 130px;
  }
}

@media (min-width: 897px) {
  .sec-top-news .col:first-child {
    width: 74%;
    padding-right: 3%;
  }
}

@media (max-width: 896px) {
  .sec-top-news .col:first-child {
    margin-bottom: 60px;
  }
}

@media (min-width: 897px) {
  .sec-top-news .col:last-child {
    width: 26%;
    padding-left: 3%;
  }
}

.sec-top-news .ttl-top {
  margin-bottom: 0;
}

@media (min-width: 897px) {
  .sec-top-news .ttl-top {
    margin-bottom: 28px;
  }
}

.sec-top-news .box-news-wrap .box-news {
  padding-bottom: 0;
  padding-top: 0;
}

@media (max-width: 896px) {
  .sec-top-news .box-news-wrap .box-news {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 0;
  }
}

@media (min-width: 897px) {
  .sec-top-news .box-news-wrap .box-news .item {
    padding-top: 15px;
    padding-bottom: 15px;
    border: none;
  }
}

.sec-top-news .box-news-wrap .box-news .date {
  font-weight: normal;
}

.sec-top-news .box-news-wrap .box-news .category {
  background-color: #f2f5f8;
}

@media (min-width: 897px) {
  .sec-top-news .box-news-wrap .box-news .category {
    margin-left: 0;
  }
  .sec-top-news .box-news-wrap .box-news .category:hover {
    background-color: #385199;
  }
  .sec-top-news .box-news-wrap .box-news .category:hover a {
    color: #f2f5f8;
  }
}

/* sec-top-pickup
---------------------------------------------------------- */
.sec-top-pickup {
  position: relative;
}

.sec-top-pickup .navi-panel {
  content: "";
  position: absolute;
  top: 158px;
  right: 0;
  width: 36%;
  height: 100%;
  background-color: #262d40;
}

@media (max-width: 896px) {
  .sec-top-pickup .navi-panel {
    right: inherit;
    left: 0;
    width: 100%;
  }
}

.sec-top-pickup .link-group {
  position: relative;
  z-index: 1;
}

/* slider-pickup
---------------------------------------------------------- */
.slider-pickup-wrap {
  position: relative;
  z-index: 1;
}

@media (min-width: 897px) {
  .slider-pickup-wrap {
    display: flex;
  }
}

@media (min-width: 897px) {
  .slider-pickup {
    position: relative;
    width: 69%;
    display: flex;
    justify-content: flex-end;
  }
  .slider-pickup:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    z-index: 1;
    height: 100%;
    padding-left: 47%;
    background: url("/assets/images/top/img_shadow_white.png") 0 0 no-repeat;
    background-size: auto 100%;
    pointer-events: none;
  }
}

@media (min-width: 897px) {
  .slider-pickup .list-slider {
    width: 416px;
    margin: 0 -16px;
  }
}

.slider-pickup .list-slider .item {
  outline: none;
  padding: 0 10px;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

@media (min-width: 897px) {
  .slider-pickup .list-slider .item {
    width: 416px;
    padding: 0 16px;
  }
}

@media (min-width: 897px) {
  .slider-pickup .list-slider .slick-list {
    overflow: visible;
  }
}

.slider-pickup .list-slider .slick-slide {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.slider-pickup a {
  display: block;
  outline: none;
  transform: translateZ(0);
  backface-visibility: hidden;
}

@media (min-width: 897px) {
  .slider-pickup a {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .slider-pickup a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.slider-pickup .card {
  background-color: #fff;
  box-shadow: 0 10px 25px 0px rgba(0, 0, 0, 0.05);
}

.slider-pickup .card .image {
  position: relative;
}

.slider-pickup .card .image img {
  position: relative;
  width: 100%;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.slider-pickup .card .detail {
  padding: 35px 40px;
  min-height: 160px;
  text-align: left;
}

@media (max-width: 896px) {
  .slider-pickup .card .detail {
    padding: 20px;
  }
}

.slider-pickup .card .detail .title {
  margin-bottom: 10px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.3;
}

@media (max-width: 896px) {
  .slider-pickup .card .detail .title {
    font-size: 1.9rem;
  }
}

.slider-pickup .card .detail .text {
  font-size: 1.4rem;
  line-height: 1.78;
}

@media (max-width: 896px) {
  .slider-pickup .card .detail .text {
    font-size: 1.3rem;
  }
}

.slider-pickup .slick-dots {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.slider-pickup .slick-dots li {
  padding: 0 5px;
}

.slider-pickup .slick-dots li button {
  width: 8px;
  height: 8px;
  border: 1px solid #ccc;
  border-radius: 50%;
  appearance: none;
  text-indent: -9999px;
  overflow: hidden;
}

.slider-pickup .slick-dots li.slick-active button {
  background-color: #ccc;
}

.slider-pickup-nav {
  position: relative;
  z-index: 1;
  padding-top: 35px;
}

@media (min-width: 897px) {
  .slider-pickup-nav {
    width: 32%;
    padding-left: 6.25%;
    background-color: #262d40;
  }
}

@media (max-width: 896px) {
  .slider-pickup-nav {
    visibility: hidden;
    height: 0;
    padding-top: 0;
  }
}

.slider-pickup-nav .list-nav {
  position: relative;
  height: 210px;
  overflow: hidden;
}

.slider-pickup-nav .list-nav .item {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2.2;
  color: #fff;
  cursor: pointer;
  user-select: none;
  transition: all .3s;
}

.slider-pickup-nav .list-nav .item:first-child, .slider-pickup-nav .list-nav .item:last-child {
  opacity: 0;
}

.slider-pickup-nav .list-nav .item:nth-child(2) {
  transform: translateY(30px);
}

.slider-pickup-nav .list-nav .item:nth-child(3) {
  transform: translateY(60px);
}

.slider-pickup-nav .list-nav .item:nth-child(4) {
  transform: translateY(90px);
}

.slider-pickup-nav .list-nav .item:nth-child(5) {
  transform: translateY(120px);
}

.slider-pickup-nav .list-nav .item:nth-child(6) {
  transform: translateY(150px);
}

.slider-pickup-nav .list-nav .item:nth-child(7) {
  transform: translateY(180px);
}

.slider-pickup-nav .list-nav .item:nth-child(2), .slider-pickup-nav .list-nav .item:nth-child(6) {
  opacity: .1;
}

.slider-pickup-nav .list-nav .item:nth-child(3), .slider-pickup-nav .list-nav .item:nth-child(5) {
  opacity: .3;
}

.slider-pickup-arrows {
  display: flex;
}

.slider-pickup-arrows .slick-arrow {
  width: 60px;
  height: 60px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -999px;
  overflow: hidden;
  outline: none;
}

@media (min-width: 897px) {
  .slider-pickup-arrows .slick-arrow {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .slider-pickup-arrows .slick-arrow:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.slider-pickup-arrows .slick-arrow.slick-prev {
  margin-right: 30px;
  background-image: url("/assets/images/top/arrow_prev.png");
}

.slider-pickup-arrows .slick-arrow.slick-next {
  background-image: url("/assets/images/top/arrow_next.png");
}

@media (max-width: 896px) {
  .slider-pickup-arrows {
    display: none;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* card-customer
---------------------------------------------------------- */
.card-customer {
  padding: 30px 25px;
}

@media (min-width: 897px) {
  .card-customer {
    padding: 50px;
  }
}

.card-customer .c-ttl-02 .text {
  font-size: 2rem;
}

@media (min-width: 897px) {
  .card-customer .c-ttl-02 .text {
    font-size: 2.4rem;
  }
}

/* list-card-customer
---------------------------------------------------------- */
.list-card-customer .card-customer {
  height: 100%;
}

/* ==========================================================
 utils
========================================================== */
/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
.bg-white {
  background-color: #fff !important;
}

.bg-black {
  background-color: #111 !important;
}

.bg-blue {
  background: #2157a7;
}

.bg-orange {
  background: #f93b00;
}

.bg-orange-light {
  background: #f98700;
}

/* border side
---------------------------------------------------------- */
.bd-top {
  border-top: 1px solid #e0e0e0 !important;
}

.bd-bottom {
  border-bottom: 1px solid #e0e0e0 !important;
}

.bd-left {
  border-left: 1px solid #e0e0e0 !important;
}

.bd-right {
  border-right: 1px solid #e0e0e0 !important;
}

@media (min-width: 897px) {
  .bd-pc-top {
    border-top: 1px solid #e0e0e0 !important;
  }
  .bd-pc-bottom {
    border-bottom: 1px solid #e0e0e0 !important;
  }
  .bd-pc-left {
    border-left: 1px solid #e0e0e0 !important;
  }
  .bd-pc-right {
    border-right: 1px solid #e0e0e0 !important;
  }
}

@media (max-width: 896px) {
  .bd-sp-top {
    border-top: 1px solid #e0e0e0 !important;
  }
  .bd-sp-bottom {
    border-bottom: 1px solid #e0e0e0 !important;
  }
  .bd-sp-left {
    border-left: 1px solid #e0e0e0 !important;
  }
  .bd-sp-right {
    border-right: 1px solid #e0e0e0 !important;
  }
}

/* border width
---------------------------------------------------------- */
.bd-width-0 {
  border-width: 0px !important;
}

.bd-width-1 {
  border-width: 1px !important;
}

.bd-width-2 {
  border-width: 2px !important;
}

.bd-width-3 {
  border-width: 3px !important;
}

.bd-width-4 {
  border-width: 4px !important;
}

.bd-width-5 {
  border-width: 5px !important;
}

.bd-width-6 {
  border-width: 6px !important;
}

.bd-width-7 {
  border-width: 7px !important;
}

.bd-width-8 {
  border-width: 8px !important;
}

.bd-width-9 {
  border-width: 9px !important;
}

.bd-width-10 {
  border-width: 10px !important;
}

@media (min-width: 897px) {
  .bd-width-pc-0 {
    border-width: 0px !important;
  }
  .bd-width-pc-1 {
    border-width: 1px !important;
  }
  .bd-width-pc-2 {
    border-width: 2px !important;
  }
  .bd-width-pc-3 {
    border-width: 3px !important;
  }
  .bd-width-pc-4 {
    border-width: 4px !important;
  }
  .bd-width-pc-5 {
    border-width: 5px !important;
  }
  .bd-width-pc-6 {
    border-width: 6px !important;
  }
  .bd-width-pc-7 {
    border-width: 7px !important;
  }
  .bd-width-pc-8 {
    border-width: 8px !important;
  }
  .bd-width-pc-9 {
    border-width: 9px !important;
  }
  .bd-width-pc-10 {
    border-width: 10px !important;
  }
}

@media (max-width: 896px) {
  .bd-width-sp-0 {
    border-width: 0px !important;
  }
  .bd-width-sp-1 {
    border-width: 1px !important;
  }
  .bd-width-sp-2 {
    border-width: 2px !important;
  }
  .bd-width-sp-3 {
    border-width: 3px !important;
  }
  .bd-width-sp-4 {
    border-width: 4px !important;
  }
  .bd-width-sp-5 {
    border-width: 5px !important;
  }
  .bd-width-sp-6 {
    border-width: 6px !important;
  }
  .bd-width-sp-7 {
    border-width: 7px !important;
  }
  .bd-width-sp-8 {
    border-width: 8px !important;
  }
  .bd-width-sp-9 {
    border-width: 9px !important;
  }
  .bd-width-sp-10 {
    border-width: 10px !important;
  }
}

/* border color
---------------------------------------------------------- */
.bd-white {
  border-color: #fff !important;
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.visible-hid {
  visibility: hidden;
}

@media (min-width: 897px) {
  .d-pc-none {
    display: none !important;
  }
  .d-pc-inline {
    display: inline !important;
  }
  .d-pc-inline-block {
    display: inline-block !important;
  }
  .d-pc-block {
    display: block !important;
  }
  .d-pc-table {
    display: table !important;
  }
  .d-pc-table-row {
    display: table-row !important;
  }
  .d-pc-table-cell {
    display: table-cell !important;
  }
  .d-pc-flex {
    display: flex !important;
  }
  .d-pc-inline-flex {
    display: inline-flex !important;
  }
}

@media (max-width: 896px) {
  .d-sp-none {
    display: none !important;
  }
  .d-sp-inline {
    display: inline !important;
  }
  .d-sp-inline-block {
    display: inline-block !important;
  }
  .d-sp-block {
    display: block !important;
  }
  .d-sp-table {
    display: table !important;
  }
  .d-sp-table-row {
    display: table-row !important;
  }
  .d-sp-table-cell {
    display: table-cell !important;
  }
  .d-sp-flex {
    display: flex !important;
  }
  .d-sp-inline-flex {
    display: inline-flex !important;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 897px) {
  .flex-pc-row {
    flex-direction: row !important;
  }
  .flex-pc-column {
    flex-direction: column !important;
  }
  .flex-pc-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-pc-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-pc-wrap {
    flex-wrap: wrap !important;
  }
  .flex-pc-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-pc-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-pc-fill {
    flex: 1 1 auto !important;
  }
  .flex-pc-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-pc-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-pc-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-pc-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-pc-start {
    justify-content: flex-start !important;
  }
  .justify-content-pc-end {
    justify-content: flex-end !important;
  }
  .justify-content-pc-center {
    justify-content: center !important;
  }
  .justify-content-pc-between {
    justify-content: space-between !important;
  }
  .justify-content-pc-around {
    justify-content: space-around !important;
  }
  .align-items-pc-start {
    align-items: flex-start !important;
  }
  .align-items-pc-end {
    align-items: flex-end !important;
  }
  .align-items-pc-center {
    align-items: center !important;
  }
  .align-items-pc-baseline {
    align-items: baseline !important;
  }
  .align-items-pc-stretch {
    align-items: stretch !important;
  }
  .align-content-pc-start {
    align-content: flex-start !important;
  }
  .align-content-pc-end {
    align-content: flex-end !important;
  }
  .align-content-pc-center {
    align-content: center !important;
  }
  .align-content-pc-between {
    align-content: space-between !important;
  }
  .align-content-pc-around {
    align-content: space-around !important;
  }
  .align-content-pc-stretch {
    align-content: stretch !important;
  }
  .align-self-pc-auto {
    align-self: auto !important;
  }
  .align-self-pc-start {
    align-self: flex-start !important;
  }
  .align-self-pc-end {
    align-self: flex-end !important;
  }
  .align-self-pc-center {
    align-self: center !important;
  }
  .align-self-pc-baseline {
    align-self: baseline !important;
  }
  .align-self-pc-stretch {
    align-self: stretch !important;
  }
  .flex-grow-pc-auto {
    flex: 0 0 auto !important;
  }
}

@media (max-width: 896px) {
  .flex-sp-row {
    flex-direction: row !important;
  }
  .flex-sp-column {
    flex-direction: column !important;
  }
  .flex-sp-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sp-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sp-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sp-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sp-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sp-fill {
    flex: 1 1 auto !important;
  }
  .flex-sp-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sp-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sp-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sp-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sp-start {
    justify-content: flex-start !important;
  }
  .justify-content-sp-end {
    justify-content: flex-end !important;
  }
  .justify-content-sp-center {
    justify-content: center !important;
  }
  .justify-content-sp-between {
    justify-content: space-between !important;
  }
  .justify-content-sp-around {
    justify-content: space-around !important;
  }
  .align-items-sp-start {
    align-items: flex-start !important;
  }
  .align-items-sp-end {
    align-items: flex-end !important;
  }
  .align-items-sp-center {
    align-items: center !important;
  }
  .align-items-sp-baseline {
    align-items: baseline !important;
  }
  .align-items-sp-stretch {
    align-items: stretch !important;
  }
  .align-content-sp-start {
    align-content: flex-start !important;
  }
  .align-content-sp-end {
    align-content: flex-end !important;
  }
  .align-content-sp-center {
    align-content: center !important;
  }
  .align-content-sp-between {
    align-content: space-between !important;
  }
  .align-content-sp-around {
    align-content: space-around !important;
  }
  .align-content-sp-stretch {
    align-content: stretch !important;
  }
  .align-self-sp-auto {
    align-self: auto !important;
  }
  .align-self-sp-start {
    align-self: flex-start !important;
  }
  .align-self-sp-end {
    align-self: flex-end !important;
  }
  .align-self-sp-center {
    align-self: center !important;
  }
  .align-self-sp-baseline {
    align-self: baseline !important;
  }
  .align-self-sp-stretch {
    align-self: stretch !important;
  }
  .flex-grow-sp-auto {
    flex: 0 0 auto !important;
  }
}

.lineheight-0 {
  line-height: 0 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-0 {
    line-height: 0 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-0 {
    line-height: 0 !important;
  }
}

.lineheight-0_1 {
  line-height: 0.1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-0_1 {
    line-height: 0.1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-0_1 {
    line-height: 0.1 !important;
  }
}

.lineheight-0_2 {
  line-height: 0.2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-0_2 {
    line-height: 0.2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-0_2 {
    line-height: 0.2 !important;
  }
}

.lineheight-0_3 {
  line-height: 0.3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-0_3 {
    line-height: 0.3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-0_3 {
    line-height: 0.3 !important;
  }
}

.lineheight-0_4 {
  line-height: 0.4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-0_4 {
    line-height: 0.4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-0_4 {
    line-height: 0.4 !important;
  }
}

.lineheight-0_5 {
  line-height: 0.5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-0_5 {
    line-height: 0.5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-0_5 {
    line-height: 0.5 !important;
  }
}

.lineheight-0_6 {
  line-height: 0.6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-0_6 {
    line-height: 0.6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-0_6 {
    line-height: 0.6 !important;
  }
}

.lineheight-0_7 {
  line-height: 0.7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-0_7 {
    line-height: 0.7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-0_7 {
    line-height: 0.7 !important;
  }
}

.lineheight-0_8 {
  line-height: 0.8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-0_8 {
    line-height: 0.8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-0_8 {
    line-height: 0.8 !important;
  }
}

.lineheight-0_9 {
  line-height: 0.9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-0_9 {
    line-height: 0.9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-0_9 {
    line-height: 0.9 !important;
  }
}

.lineheight-1 {
  line-height: 1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-1 {
    line-height: 1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-1 {
    line-height: 1 !important;
  }
}

.lineheight-1_1 {
  line-height: 1.1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-1_1 {
    line-height: 1.1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-1_1 {
    line-height: 1.1 !important;
  }
}

.lineheight-1_2 {
  line-height: 1.2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-1_2 {
    line-height: 1.2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-1_2 {
    line-height: 1.2 !important;
  }
}

.lineheight-1_3 {
  line-height: 1.3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-1_3 {
    line-height: 1.3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-1_3 {
    line-height: 1.3 !important;
  }
}

.lineheight-1_4 {
  line-height: 1.4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-1_4 {
    line-height: 1.4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-1_4 {
    line-height: 1.4 !important;
  }
}

.lineheight-1_5 {
  line-height: 1.5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-1_5 {
    line-height: 1.5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-1_5 {
    line-height: 1.5 !important;
  }
}

.lineheight-1_6 {
  line-height: 1.6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-1_6 {
    line-height: 1.6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-1_6 {
    line-height: 1.6 !important;
  }
}

.lineheight-1_7 {
  line-height: 1.7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-1_7 {
    line-height: 1.7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-1_7 {
    line-height: 1.7 !important;
  }
}

.lineheight-1_8 {
  line-height: 1.8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-1_8 {
    line-height: 1.8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-1_8 {
    line-height: 1.8 !important;
  }
}

.lineheight-1_9 {
  line-height: 1.9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-1_9 {
    line-height: 1.9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-1_9 {
    line-height: 1.9 !important;
  }
}

.lineheight-2 {
  line-height: 2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-2 {
    line-height: 2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-2 {
    line-height: 2 !important;
  }
}

.lineheight-2_1 {
  line-height: 2.1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-2_1 {
    line-height: 2.1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-2_1 {
    line-height: 2.1 !important;
  }
}

.lineheight-2_2 {
  line-height: 2.2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-2_2 {
    line-height: 2.2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-2_2 {
    line-height: 2.2 !important;
  }
}

.lineheight-2_3 {
  line-height: 2.3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-2_3 {
    line-height: 2.3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-2_3 {
    line-height: 2.3 !important;
  }
}

.lineheight-2_4 {
  line-height: 2.4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-2_4 {
    line-height: 2.4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-2_4 {
    line-height: 2.4 !important;
  }
}

.lineheight-2_5 {
  line-height: 2.5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-2_5 {
    line-height: 2.5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-2_5 {
    line-height: 2.5 !important;
  }
}

.lineheight-2_6 {
  line-height: 2.6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-2_6 {
    line-height: 2.6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-2_6 {
    line-height: 2.6 !important;
  }
}

.lineheight-2_7 {
  line-height: 2.7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-2_7 {
    line-height: 2.7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-2_7 {
    line-height: 2.7 !important;
  }
}

.lineheight-2_8 {
  line-height: 2.8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-2_8 {
    line-height: 2.8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-2_8 {
    line-height: 2.8 !important;
  }
}

.lineheight-2_9 {
  line-height: 2.9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-2_9 {
    line-height: 2.9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-2_9 {
    line-height: 2.9 !important;
  }
}

.lineheight-3 {
  line-height: 3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-3 {
    line-height: 3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-3 {
    line-height: 3 !important;
  }
}

.lineheight-3_1 {
  line-height: 3.1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-3_1 {
    line-height: 3.1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-3_1 {
    line-height: 3.1 !important;
  }
}

.lineheight-3_2 {
  line-height: 3.2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-3_2 {
    line-height: 3.2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-3_2 {
    line-height: 3.2 !important;
  }
}

.lineheight-3_3 {
  line-height: 3.3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-3_3 {
    line-height: 3.3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-3_3 {
    line-height: 3.3 !important;
  }
}

.lineheight-3_4 {
  line-height: 3.4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-3_4 {
    line-height: 3.4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-3_4 {
    line-height: 3.4 !important;
  }
}

.lineheight-3_5 {
  line-height: 3.5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-3_5 {
    line-height: 3.5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-3_5 {
    line-height: 3.5 !important;
  }
}

.lineheight-3_6 {
  line-height: 3.6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-3_6 {
    line-height: 3.6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-3_6 {
    line-height: 3.6 !important;
  }
}

.lineheight-3_7 {
  line-height: 3.7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-3_7 {
    line-height: 3.7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-3_7 {
    line-height: 3.7 !important;
  }
}

.lineheight-3_8 {
  line-height: 3.8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-3_8 {
    line-height: 3.8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-3_8 {
    line-height: 3.8 !important;
  }
}

.lineheight-3_9 {
  line-height: 3.9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-3_9 {
    line-height: 3.9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-3_9 {
    line-height: 3.9 !important;
  }
}

.lineheight-4 {
  line-height: 4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-4 {
    line-height: 4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-4 {
    line-height: 4 !important;
  }
}

.lineheight-4_1 {
  line-height: 4.1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-4_1 {
    line-height: 4.1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-4_1 {
    line-height: 4.1 !important;
  }
}

.lineheight-4_2 {
  line-height: 4.2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-4_2 {
    line-height: 4.2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-4_2 {
    line-height: 4.2 !important;
  }
}

.lineheight-4_3 {
  line-height: 4.3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-4_3 {
    line-height: 4.3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-4_3 {
    line-height: 4.3 !important;
  }
}

.lineheight-4_4 {
  line-height: 4.4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-4_4 {
    line-height: 4.4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-4_4 {
    line-height: 4.4 !important;
  }
}

.lineheight-4_5 {
  line-height: 4.5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-4_5 {
    line-height: 4.5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-4_5 {
    line-height: 4.5 !important;
  }
}

.lineheight-4_6 {
  line-height: 4.6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-4_6 {
    line-height: 4.6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-4_6 {
    line-height: 4.6 !important;
  }
}

.lineheight-4_7 {
  line-height: 4.7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-4_7 {
    line-height: 4.7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-4_7 {
    line-height: 4.7 !important;
  }
}

.lineheight-4_8 {
  line-height: 4.8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-4_8 {
    line-height: 4.8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-4_8 {
    line-height: 4.8 !important;
  }
}

.lineheight-4_9 {
  line-height: 4.9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-4_9 {
    line-height: 4.9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-4_9 {
    line-height: 4.9 !important;
  }
}

.lineheight-5 {
  line-height: 5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-5 {
    line-height: 5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-5 {
    line-height: 5 !important;
  }
}

.lineheight-5_1 {
  line-height: 5.1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-5_1 {
    line-height: 5.1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-5_1 {
    line-height: 5.1 !important;
  }
}

.lineheight-5_2 {
  line-height: 5.2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-5_2 {
    line-height: 5.2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-5_2 {
    line-height: 5.2 !important;
  }
}

.lineheight-5_3 {
  line-height: 5.3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-5_3 {
    line-height: 5.3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-5_3 {
    line-height: 5.3 !important;
  }
}

.lineheight-5_4 {
  line-height: 5.4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-5_4 {
    line-height: 5.4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-5_4 {
    line-height: 5.4 !important;
  }
}

.lineheight-5_5 {
  line-height: 5.5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-5_5 {
    line-height: 5.5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-5_5 {
    line-height: 5.5 !important;
  }
}

.lineheight-5_6 {
  line-height: 5.6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-5_6 {
    line-height: 5.6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-5_6 {
    line-height: 5.6 !important;
  }
}

.lineheight-5_7 {
  line-height: 5.7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-5_7 {
    line-height: 5.7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-5_7 {
    line-height: 5.7 !important;
  }
}

.lineheight-5_8 {
  line-height: 5.8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-5_8 {
    line-height: 5.8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-5_8 {
    line-height: 5.8 !important;
  }
}

.lineheight-5_9 {
  line-height: 5.9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-5_9 {
    line-height: 5.9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-5_9 {
    line-height: 5.9 !important;
  }
}

.lineheight-6 {
  line-height: 6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-6 {
    line-height: 6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-6 {
    line-height: 6 !important;
  }
}

.lineheight-6_1 {
  line-height: 6.1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-6_1 {
    line-height: 6.1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-6_1 {
    line-height: 6.1 !important;
  }
}

.lineheight-6_2 {
  line-height: 6.2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-6_2 {
    line-height: 6.2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-6_2 {
    line-height: 6.2 !important;
  }
}

.lineheight-6_3 {
  line-height: 6.3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-6_3 {
    line-height: 6.3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-6_3 {
    line-height: 6.3 !important;
  }
}

.lineheight-6_4 {
  line-height: 6.4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-6_4 {
    line-height: 6.4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-6_4 {
    line-height: 6.4 !important;
  }
}

.lineheight-6_5 {
  line-height: 6.5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-6_5 {
    line-height: 6.5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-6_5 {
    line-height: 6.5 !important;
  }
}

.lineheight-6_6 {
  line-height: 6.6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-6_6 {
    line-height: 6.6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-6_6 {
    line-height: 6.6 !important;
  }
}

.lineheight-6_7 {
  line-height: 6.7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-6_7 {
    line-height: 6.7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-6_7 {
    line-height: 6.7 !important;
  }
}

.lineheight-6_8 {
  line-height: 6.8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-6_8 {
    line-height: 6.8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-6_8 {
    line-height: 6.8 !important;
  }
}

.lineheight-6_9 {
  line-height: 6.9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-6_9 {
    line-height: 6.9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-6_9 {
    line-height: 6.9 !important;
  }
}

.lineheight-7 {
  line-height: 7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-7 {
    line-height: 7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-7 {
    line-height: 7 !important;
  }
}

.lineheight-7_1 {
  line-height: 7.1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-7_1 {
    line-height: 7.1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-7_1 {
    line-height: 7.1 !important;
  }
}

.lineheight-7_2 {
  line-height: 7.2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-7_2 {
    line-height: 7.2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-7_2 {
    line-height: 7.2 !important;
  }
}

.lineheight-7_3 {
  line-height: 7.3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-7_3 {
    line-height: 7.3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-7_3 {
    line-height: 7.3 !important;
  }
}

.lineheight-7_4 {
  line-height: 7.4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-7_4 {
    line-height: 7.4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-7_4 {
    line-height: 7.4 !important;
  }
}

.lineheight-7_5 {
  line-height: 7.5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-7_5 {
    line-height: 7.5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-7_5 {
    line-height: 7.5 !important;
  }
}

.lineheight-7_6 {
  line-height: 7.6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-7_6 {
    line-height: 7.6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-7_6 {
    line-height: 7.6 !important;
  }
}

.lineheight-7_7 {
  line-height: 7.7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-7_7 {
    line-height: 7.7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-7_7 {
    line-height: 7.7 !important;
  }
}

.lineheight-7_8 {
  line-height: 7.8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-7_8 {
    line-height: 7.8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-7_8 {
    line-height: 7.8 !important;
  }
}

.lineheight-7_9 {
  line-height: 7.9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-7_9 {
    line-height: 7.9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-7_9 {
    line-height: 7.9 !important;
  }
}

.lineheight-8 {
  line-height: 8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-8 {
    line-height: 8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-8 {
    line-height: 8 !important;
  }
}

.lineheight-8_1 {
  line-height: 8.1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-8_1 {
    line-height: 8.1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-8_1 {
    line-height: 8.1 !important;
  }
}

.lineheight-8_2 {
  line-height: 8.2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-8_2 {
    line-height: 8.2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-8_2 {
    line-height: 8.2 !important;
  }
}

.lineheight-8_3 {
  line-height: 8.3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-8_3 {
    line-height: 8.3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-8_3 {
    line-height: 8.3 !important;
  }
}

.lineheight-8_4 {
  line-height: 8.4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-8_4 {
    line-height: 8.4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-8_4 {
    line-height: 8.4 !important;
  }
}

.lineheight-8_5 {
  line-height: 8.5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-8_5 {
    line-height: 8.5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-8_5 {
    line-height: 8.5 !important;
  }
}

.lineheight-8_6 {
  line-height: 8.6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-8_6 {
    line-height: 8.6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-8_6 {
    line-height: 8.6 !important;
  }
}

.lineheight-8_7 {
  line-height: 8.7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-8_7 {
    line-height: 8.7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-8_7 {
    line-height: 8.7 !important;
  }
}

.lineheight-8_8 {
  line-height: 8.8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-8_8 {
    line-height: 8.8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-8_8 {
    line-height: 8.8 !important;
  }
}

.lineheight-8_9 {
  line-height: 8.9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-8_9 {
    line-height: 8.9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-8_9 {
    line-height: 8.9 !important;
  }
}

.lineheight-9 {
  line-height: 9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-9 {
    line-height: 9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-9 {
    line-height: 9 !important;
  }
}

.lineheight-9_1 {
  line-height: 9.1 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-9_1 {
    line-height: 9.1 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-9_1 {
    line-height: 9.1 !important;
  }
}

.lineheight-9_2 {
  line-height: 9.2 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-9_2 {
    line-height: 9.2 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-9_2 {
    line-height: 9.2 !important;
  }
}

.lineheight-9_3 {
  line-height: 9.3 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-9_3 {
    line-height: 9.3 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-9_3 {
    line-height: 9.3 !important;
  }
}

.lineheight-9_4 {
  line-height: 9.4 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-9_4 {
    line-height: 9.4 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-9_4 {
    line-height: 9.4 !important;
  }
}

.lineheight-9_5 {
  line-height: 9.5 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-9_5 {
    line-height: 9.5 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-9_5 {
    line-height: 9.5 !important;
  }
}

.lineheight-9_6 {
  line-height: 9.6 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-9_6 {
    line-height: 9.6 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-9_6 {
    line-height: 9.6 !important;
  }
}

.lineheight-9_7 {
  line-height: 9.7 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-9_7 {
    line-height: 9.7 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-9_7 {
    line-height: 9.7 !important;
  }
}

.lineheight-9_8 {
  line-height: 9.8 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-9_8 {
    line-height: 9.8 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-9_8 {
    line-height: 9.8 !important;
  }
}

.lineheight-9_9 {
  line-height: 9.9 !important;
}

@media (max-width: 896px) {
  .lineheight-sp-9_9 {
    line-height: 9.9 !important;
  }
}

@media (min-width: 897px) {
  .lineheight-pc-9_9 {
    line-height: 9.9 !important;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
@media (min-width: 897px) {
  .only-sp {
    display: none !important;
  }
}

@media (max-width: 896px) {
  .only-pc {
    display: none !important;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
.mt-0 {
  margin-top: 0px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ma-0 {
  margin: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pa-0 {
  padding: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ma-0 {
  margin: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pa-0 {
  padding: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ma-0 {
  margin: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pa-0 {
  padding: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ma-0 {
  margin: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pa-0 {
  padding: 0px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mx-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.my-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.ma-1 {
  margin: 1px !important;
}

.pt-1 {
  padding-top: 1px !important;
}

.px-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.py-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pa-1 {
  padding: 1px !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.mx-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.my-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.ma-1 {
  margin: 1px !important;
}

.pb-1 {
  padding-bottom: 1px !important;
}

.px-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.py-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pa-1 {
  padding: 1px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.mx-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.my-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.ma-1 {
  margin: 1px !important;
}

.pl-1 {
  padding-left: 1px !important;
}

.px-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.py-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pa-1 {
  padding: 1px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mx-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.my-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.ma-1 {
  margin: 1px !important;
}

.pr-1 {
  padding-right: 1px !important;
}

.px-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.py-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pa-1 {
  padding: 1px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ma-5 {
  margin: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pa-5 {
  padding: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ma-5 {
  margin: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pa-5 {
  padding: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ma-5 {
  margin: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pa-5 {
  padding: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ma-5 {
  margin: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pa-5 {
  padding: 5px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.ma-8 {
  margin: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pa-8 {
  padding: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.ma-8 {
  margin: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pa-8 {
  padding: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.ma-8 {
  margin: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pa-8 {
  padding: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.ma-8 {
  margin: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pa-8 {
  padding: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.ma-10 {
  margin: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pa-10 {
  padding: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.ma-10 {
  margin: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pa-10 {
  padding: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.ma-10 {
  margin: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pa-10 {
  padding: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.ma-10 {
  margin: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pa-10 {
  padding: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.ma-12 {
  margin: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.pa-12 {
  padding: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.ma-12 {
  margin: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.pa-12 {
  padding: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.ma-12 {
  margin: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.pa-12 {
  padding: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.ma-12 {
  margin: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.pa-12 {
  padding: 12px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.mx-13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.my-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.ma-13 {
  margin: 13px !important;
}

.pt-13 {
  padding-top: 13px !important;
}

.px-13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.py-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.pa-13 {
  padding: 13px !important;
}

.mb-13 {
  margin-bottom: 13px !important;
}

.mx-13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.my-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.ma-13 {
  margin: 13px !important;
}

.pb-13 {
  padding-bottom: 13px !important;
}

.px-13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.py-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.pa-13 {
  padding: 13px !important;
}

.ml-13 {
  margin-left: 13px !important;
}

.mx-13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.my-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.ma-13 {
  margin: 13px !important;
}

.pl-13 {
  padding-left: 13px !important;
}

.px-13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.py-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.pa-13 {
  padding: 13px !important;
}

.mr-13 {
  margin-right: 13px !important;
}

.mx-13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.my-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.ma-13 {
  margin: 13px !important;
}

.pr-13 {
  padding-right: 13px !important;
}

.px-13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.py-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.pa-13 {
  padding: 13px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mx-14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.my-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.ma-14 {
  margin: 14px !important;
}

.pt-14 {
  padding-top: 14px !important;
}

.px-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.py-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.pa-14 {
  padding: 14px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.mx-14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.my-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.ma-14 {
  margin: 14px !important;
}

.pb-14 {
  padding-bottom: 14px !important;
}

.px-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.py-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.pa-14 {
  padding: 14px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.mx-14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.my-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.ma-14 {
  margin: 14px !important;
}

.pl-14 {
  padding-left: 14px !important;
}

.px-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.py-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.pa-14 {
  padding: 14px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.mx-14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.my-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.ma-14 {
  margin: 14px !important;
}

.pr-14 {
  padding-right: 14px !important;
}

.px-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.py-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.pa-14 {
  padding: 14px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.ma-15 {
  margin: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pa-15 {
  padding: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.ma-15 {
  margin: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pa-15 {
  padding: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.ma-15 {
  margin: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pa-15 {
  padding: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.ma-15 {
  margin: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pa-15 {
  padding: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.ma-16 {
  margin: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.pa-16 {
  padding: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.ma-16 {
  margin: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.pa-16 {
  padding: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.ma-16 {
  margin: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.pa-16 {
  padding: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.ma-16 {
  margin: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.pa-16 {
  padding: 16px !important;
}

.mt-17 {
  margin-top: 17px !important;
}

.mx-17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.my-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.ma-17 {
  margin: 17px !important;
}

.pt-17 {
  padding-top: 17px !important;
}

.px-17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.py-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.pa-17 {
  padding: 17px !important;
}

.mb-17 {
  margin-bottom: 17px !important;
}

.mx-17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.my-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.ma-17 {
  margin: 17px !important;
}

.pb-17 {
  padding-bottom: 17px !important;
}

.px-17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.py-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.pa-17 {
  padding: 17px !important;
}

.ml-17 {
  margin-left: 17px !important;
}

.mx-17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.my-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.ma-17 {
  margin: 17px !important;
}

.pl-17 {
  padding-left: 17px !important;
}

.px-17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.py-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.pa-17 {
  padding: 17px !important;
}

.mr-17 {
  margin-right: 17px !important;
}

.mx-17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.my-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.ma-17 {
  margin: 17px !important;
}

.pr-17 {
  padding-right: 17px !important;
}

.px-17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.py-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.pa-17 {
  padding: 17px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.ma-20 {
  margin: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pa-20 {
  padding: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.ma-20 {
  margin: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pa-20 {
  padding: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.ma-20 {
  margin: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pa-20 {
  padding: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.ma-20 {
  margin: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pa-20 {
  padding: 20px !important;
}

.mt-22 {
  margin-top: 22px !important;
}

.mx-22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.my-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.ma-22 {
  margin: 22px !important;
}

.pt-22 {
  padding-top: 22px !important;
}

.px-22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.py-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.pa-22 {
  padding: 22px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.mx-22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.my-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.ma-22 {
  margin: 22px !important;
}

.pb-22 {
  padding-bottom: 22px !important;
}

.px-22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.py-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.pa-22 {
  padding: 22px !important;
}

.ml-22 {
  margin-left: 22px !important;
}

.mx-22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.my-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.ma-22 {
  margin: 22px !important;
}

.pl-22 {
  padding-left: 22px !important;
}

.px-22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.py-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.pa-22 {
  padding: 22px !important;
}

.mr-22 {
  margin-right: 22px !important;
}

.mx-22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.my-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.ma-22 {
  margin: 22px !important;
}

.pr-22 {
  padding-right: 22px !important;
}

.px-22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.py-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.pa-22 {
  padding: 22px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.ma-25 {
  margin: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pa-25 {
  padding: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.ma-25 {
  margin: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pa-25 {
  padding: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.ma-25 {
  margin: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pa-25 {
  padding: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.ma-25 {
  margin: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pa-25 {
  padding: 25px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mx-28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.ma-28 {
  margin: 28px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.pa-28 {
  padding: 28px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.mx-28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.ma-28 {
  margin: 28px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.pa-28 {
  padding: 28px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.mx-28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.ma-28 {
  margin: 28px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.pa-28 {
  padding: 28px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.mx-28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.ma-28 {
  margin: 28px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.pa-28 {
  padding: 28px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.ma-30 {
  margin: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pa-30 {
  padding: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.ma-30 {
  margin: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pa-30 {
  padding: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.ma-30 {
  margin: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pa-30 {
  padding: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.ma-30 {
  margin: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pa-30 {
  padding: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.ma-35 {
  margin: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pa-35 {
  padding: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.ma-35 {
  margin: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pa-35 {
  padding: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.ma-35 {
  margin: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pa-35 {
  padding: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.ma-35 {
  margin: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pa-35 {
  padding: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.ma-40 {
  margin: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pa-40 {
  padding: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.ma-40 {
  margin: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pa-40 {
  padding: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.ma-40 {
  margin: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pa-40 {
  padding: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.ma-40 {
  margin: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pa-40 {
  padding: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.ma-45 {
  margin: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.pa-45 {
  padding: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.ma-45 {
  margin: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.pa-45 {
  padding: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.ma-45 {
  margin: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.pa-45 {
  padding: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.ma-45 {
  margin: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.pa-45 {
  padding: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.ma-50 {
  margin: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pa-50 {
  padding: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.ma-50 {
  margin: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pa-50 {
  padding: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.ma-50 {
  margin: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pa-50 {
  padding: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.ma-50 {
  margin: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pa-50 {
  padding: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mx-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.ma-55 {
  margin: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.pa-55 {
  padding: 55px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mx-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.ma-55 {
  margin: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.pa-55 {
  padding: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.mx-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.ma-55 {
  margin: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.pa-55 {
  padding: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mx-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.ma-55 {
  margin: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.pa-55 {
  padding: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.ma-60 {
  margin: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pa-60 {
  padding: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.ma-60 {
  margin: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pa-60 {
  padding: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.ma-60 {
  margin: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pa-60 {
  padding: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.ma-60 {
  margin: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pa-60 {
  padding: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mx-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.ma-65 {
  margin: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.pa-65 {
  padding: 65px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mx-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.ma-65 {
  margin: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.pa-65 {
  padding: 65px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.mx-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.ma-65 {
  margin: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.pa-65 {
  padding: 65px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mx-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.ma-65 {
  margin: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.pa-65 {
  padding: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.ma-70 {
  margin: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pa-70 {
  padding: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.ma-70 {
  margin: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pa-70 {
  padding: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.ma-70 {
  margin: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pa-70 {
  padding: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.ma-70 {
  margin: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pa-70 {
  padding: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.ma-75 {
  margin: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.pa-75 {
  padding: 75px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.ma-75 {
  margin: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.pa-75 {
  padding: 75px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.ma-75 {
  margin: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.pa-75 {
  padding: 75px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.ma-75 {
  margin: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.pa-75 {
  padding: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.ma-80 {
  margin: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pa-80 {
  padding: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.ma-80 {
  margin: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pa-80 {
  padding: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.ma-80 {
  margin: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pa-80 {
  padding: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.ma-80 {
  margin: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pa-80 {
  padding: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mx-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.ma-85 {
  margin: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.pa-85 {
  padding: 85px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mx-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.ma-85 {
  margin: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.pa-85 {
  padding: 85px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.mx-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.ma-85 {
  margin: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.pa-85 {
  padding: 85px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mx-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.ma-85 {
  margin: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.pa-85 {
  padding: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.ma-90 {
  margin: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pa-90 {
  padding: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.ma-90 {
  margin: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pa-90 {
  padding: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.ma-90 {
  margin: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pa-90 {
  padding: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.ma-90 {
  margin: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pa-90 {
  padding: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mx-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.ma-95 {
  margin: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.pa-95 {
  padding: 95px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mx-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.ma-95 {
  margin: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.pa-95 {
  padding: 95px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.mx-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.ma-95 {
  margin: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.pa-95 {
  padding: 95px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mx-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.ma-95 {
  margin: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.pa-95 {
  padding: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.ma-100 {
  margin: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pa-100 {
  padding: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.ma-100 {
  margin: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pa-100 {
  padding: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.ma-100 {
  margin: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pa-100 {
  padding: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.ma-100 {
  margin: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pa-100 {
  padding: 100px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mx-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.my-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.ma-110 {
  margin: 110px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.px-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.py-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pa-110 {
  padding: 110px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mx-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.my-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.ma-110 {
  margin: 110px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.px-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.py-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pa-110 {
  padding: 110px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.mx-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.my-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.ma-110 {
  margin: 110px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.px-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.py-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pa-110 {
  padding: 110px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.mx-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.my-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.ma-110 {
  margin: 110px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.px-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.py-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pa-110 {
  padding: 110px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.mx-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.my-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.ma-115 {
  margin: 115px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.px-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.py-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.pa-115 {
  padding: 115px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.mx-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.my-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.ma-115 {
  margin: 115px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.px-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.py-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.pa-115 {
  padding: 115px !important;
}

.ml-115 {
  margin-left: 115px !important;
}

.mx-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.my-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.ma-115 {
  margin: 115px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.px-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.py-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.pa-115 {
  padding: 115px !important;
}

.mr-115 {
  margin-right: 115px !important;
}

.mx-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.my-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.ma-115 {
  margin: 115px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.px-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.py-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.pa-115 {
  padding: 115px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mx-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.ma-120 {
  margin: 120px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pa-120 {
  padding: 120px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mx-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.ma-120 {
  margin: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pa-120 {
  padding: 120px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.mx-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.ma-120 {
  margin: 120px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pa-120 {
  padding: 120px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mx-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.ma-120 {
  margin: 120px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pa-120 {
  padding: 120px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mx-150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

.my-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.ma-150 {
  margin: 150px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.px-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.py-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.pa-150 {
  padding: 150px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mx-150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

.my-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.ma-150 {
  margin: 150px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.px-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.py-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.pa-150 {
  padding: 150px !important;
}

.ml-150 {
  margin-left: 150px !important;
}

.mx-150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

.my-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.ma-150 {
  margin: 150px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.px-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.py-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.pa-150 {
  padding: 150px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.mx-150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

.my-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.ma-150 {
  margin: 150px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.px-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.py-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.pa-150 {
  padding: 150px !important;
}

.mt-305 {
  margin-top: 305px !important;
}

.mx-305 {
  margin-left: 305px !important;
  margin-right: 305px !important;
}

.my-305 {
  margin-top: 305px !important;
  margin-bottom: 305px !important;
}

.ma-305 {
  margin: 305px !important;
}

.pt-305 {
  padding-top: 305px !important;
}

.px-305 {
  padding-left: 305px !important;
  padding-right: 305px !important;
}

.py-305 {
  padding-top: 305px !important;
  padding-bottom: 305px !important;
}

.pa-305 {
  padding: 305px !important;
}

.mb-305 {
  margin-bottom: 305px !important;
}

.mx-305 {
  margin-left: 305px !important;
  margin-right: 305px !important;
}

.my-305 {
  margin-top: 305px !important;
  margin-bottom: 305px !important;
}

.ma-305 {
  margin: 305px !important;
}

.pb-305 {
  padding-bottom: 305px !important;
}

.px-305 {
  padding-left: 305px !important;
  padding-right: 305px !important;
}

.py-305 {
  padding-top: 305px !important;
  padding-bottom: 305px !important;
}

.pa-305 {
  padding: 305px !important;
}

.ml-305 {
  margin-left: 305px !important;
}

.mx-305 {
  margin-left: 305px !important;
  margin-right: 305px !important;
}

.my-305 {
  margin-top: 305px !important;
  margin-bottom: 305px !important;
}

.ma-305 {
  margin: 305px !important;
}

.pl-305 {
  padding-left: 305px !important;
}

.px-305 {
  padding-left: 305px !important;
  padding-right: 305px !important;
}

.py-305 {
  padding-top: 305px !important;
  padding-bottom: 305px !important;
}

.pa-305 {
  padding: 305px !important;
}

.mr-305 {
  margin-right: 305px !important;
}

.mx-305 {
  margin-left: 305px !important;
  margin-right: 305px !important;
}

.my-305 {
  margin-top: 305px !important;
  margin-bottom: 305px !important;
}

.ma-305 {
  margin: 305px !important;
}

.pr-305 {
  padding-right: 305px !important;
}

.px-305 {
  padding-left: 305px !important;
  padding-right: 305px !important;
}

.py-305 {
  padding-top: 305px !important;
  padding-bottom: 305px !important;
}

.pa-305 {
  padding: 305px !important;
}

@media (min-width: 897px) {
  .mt-pc-0 {
    margin-top: 0px !important;
  }
  .mx-pc-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .my-pc-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ma-pc-0 {
    margin: 0px !important;
  }
  .pt-pc-0 {
    padding-top: 0px !important;
  }
  .px-pc-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-pc-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pa-pc-0 {
    padding: 0px !important;
  }
  .mb-pc-0 {
    margin-bottom: 0px !important;
  }
  .mx-pc-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .my-pc-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ma-pc-0 {
    margin: 0px !important;
  }
  .pb-pc-0 {
    padding-bottom: 0px !important;
  }
  .px-pc-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-pc-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pa-pc-0 {
    padding: 0px !important;
  }
  .ml-pc-0 {
    margin-left: 0px !important;
  }
  .mx-pc-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .my-pc-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ma-pc-0 {
    margin: 0px !important;
  }
  .pl-pc-0 {
    padding-left: 0px !important;
  }
  .px-pc-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-pc-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pa-pc-0 {
    padding: 0px !important;
  }
  .mr-pc-0 {
    margin-right: 0px !important;
  }
  .mx-pc-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .my-pc-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ma-pc-0 {
    margin: 0px !important;
  }
  .pr-pc-0 {
    padding-right: 0px !important;
  }
  .px-pc-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-pc-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pa-pc-0 {
    padding: 0px !important;
  }
  .mt-pc-1 {
    margin-top: 1px !important;
  }
  .mx-pc-1 {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .my-pc-1 {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .ma-pc-1 {
    margin: 1px !important;
  }
  .pt-pc-1 {
    padding-top: 1px !important;
  }
  .px-pc-1 {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .py-pc-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pa-pc-1 {
    padding: 1px !important;
  }
  .mb-pc-1 {
    margin-bottom: 1px !important;
  }
  .mx-pc-1 {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .my-pc-1 {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .ma-pc-1 {
    margin: 1px !important;
  }
  .pb-pc-1 {
    padding-bottom: 1px !important;
  }
  .px-pc-1 {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .py-pc-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pa-pc-1 {
    padding: 1px !important;
  }
  .ml-pc-1 {
    margin-left: 1px !important;
  }
  .mx-pc-1 {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .my-pc-1 {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .ma-pc-1 {
    margin: 1px !important;
  }
  .pl-pc-1 {
    padding-left: 1px !important;
  }
  .px-pc-1 {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .py-pc-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pa-pc-1 {
    padding: 1px !important;
  }
  .mr-pc-1 {
    margin-right: 1px !important;
  }
  .mx-pc-1 {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .my-pc-1 {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .ma-pc-1 {
    margin: 1px !important;
  }
  .pr-pc-1 {
    padding-right: 1px !important;
  }
  .px-pc-1 {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .py-pc-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pa-pc-1 {
    padding: 1px !important;
  }
  .mt-pc-5 {
    margin-top: 5px !important;
  }
  .mx-pc-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-pc-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .ma-pc-5 {
    margin: 5px !important;
  }
  .pt-pc-5 {
    padding-top: 5px !important;
  }
  .px-pc-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-pc-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pa-pc-5 {
    padding: 5px !important;
  }
  .mb-pc-5 {
    margin-bottom: 5px !important;
  }
  .mx-pc-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-pc-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .ma-pc-5 {
    margin: 5px !important;
  }
  .pb-pc-5 {
    padding-bottom: 5px !important;
  }
  .px-pc-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-pc-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pa-pc-5 {
    padding: 5px !important;
  }
  .ml-pc-5 {
    margin-left: 5px !important;
  }
  .mx-pc-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-pc-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .ma-pc-5 {
    margin: 5px !important;
  }
  .pl-pc-5 {
    padding-left: 5px !important;
  }
  .px-pc-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-pc-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pa-pc-5 {
    padding: 5px !important;
  }
  .mr-pc-5 {
    margin-right: 5px !important;
  }
  .mx-pc-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-pc-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .ma-pc-5 {
    margin: 5px !important;
  }
  .pr-pc-5 {
    padding-right: 5px !important;
  }
  .px-pc-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-pc-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pa-pc-5 {
    padding: 5px !important;
  }
  .mt-pc-8 {
    margin-top: 8px !important;
  }
  .mx-pc-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .my-pc-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .ma-pc-8 {
    margin: 8px !important;
  }
  .pt-pc-8 {
    padding-top: 8px !important;
  }
  .px-pc-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-pc-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pa-pc-8 {
    padding: 8px !important;
  }
  .mb-pc-8 {
    margin-bottom: 8px !important;
  }
  .mx-pc-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .my-pc-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .ma-pc-8 {
    margin: 8px !important;
  }
  .pb-pc-8 {
    padding-bottom: 8px !important;
  }
  .px-pc-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-pc-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pa-pc-8 {
    padding: 8px !important;
  }
  .ml-pc-8 {
    margin-left: 8px !important;
  }
  .mx-pc-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .my-pc-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .ma-pc-8 {
    margin: 8px !important;
  }
  .pl-pc-8 {
    padding-left: 8px !important;
  }
  .px-pc-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-pc-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pa-pc-8 {
    padding: 8px !important;
  }
  .mr-pc-8 {
    margin-right: 8px !important;
  }
  .mx-pc-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .my-pc-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .ma-pc-8 {
    margin: 8px !important;
  }
  .pr-pc-8 {
    padding-right: 8px !important;
  }
  .px-pc-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-pc-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pa-pc-8 {
    padding: 8px !important;
  }
  .mt-pc-10 {
    margin-top: 10px !important;
  }
  .mx-pc-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-pc-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .ma-pc-10 {
    margin: 10px !important;
  }
  .pt-pc-10 {
    padding-top: 10px !important;
  }
  .px-pc-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-pc-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pa-pc-10 {
    padding: 10px !important;
  }
  .mb-pc-10 {
    margin-bottom: 10px !important;
  }
  .mx-pc-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-pc-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .ma-pc-10 {
    margin: 10px !important;
  }
  .pb-pc-10 {
    padding-bottom: 10px !important;
  }
  .px-pc-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-pc-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pa-pc-10 {
    padding: 10px !important;
  }
  .ml-pc-10 {
    margin-left: 10px !important;
  }
  .mx-pc-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-pc-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .ma-pc-10 {
    margin: 10px !important;
  }
  .pl-pc-10 {
    padding-left: 10px !important;
  }
  .px-pc-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-pc-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pa-pc-10 {
    padding: 10px !important;
  }
  .mr-pc-10 {
    margin-right: 10px !important;
  }
  .mx-pc-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-pc-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .ma-pc-10 {
    margin: 10px !important;
  }
  .pr-pc-10 {
    padding-right: 10px !important;
  }
  .px-pc-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-pc-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pa-pc-10 {
    padding: 10px !important;
  }
  .mt-pc-12 {
    margin-top: 12px !important;
  }
  .mx-pc-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .my-pc-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .ma-pc-12 {
    margin: 12px !important;
  }
  .pt-pc-12 {
    padding-top: 12px !important;
  }
  .px-pc-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .py-pc-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .pa-pc-12 {
    padding: 12px !important;
  }
  .mb-pc-12 {
    margin-bottom: 12px !important;
  }
  .mx-pc-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .my-pc-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .ma-pc-12 {
    margin: 12px !important;
  }
  .pb-pc-12 {
    padding-bottom: 12px !important;
  }
  .px-pc-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .py-pc-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .pa-pc-12 {
    padding: 12px !important;
  }
  .ml-pc-12 {
    margin-left: 12px !important;
  }
  .mx-pc-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .my-pc-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .ma-pc-12 {
    margin: 12px !important;
  }
  .pl-pc-12 {
    padding-left: 12px !important;
  }
  .px-pc-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .py-pc-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .pa-pc-12 {
    padding: 12px !important;
  }
  .mr-pc-12 {
    margin-right: 12px !important;
  }
  .mx-pc-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .my-pc-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .ma-pc-12 {
    margin: 12px !important;
  }
  .pr-pc-12 {
    padding-right: 12px !important;
  }
  .px-pc-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .py-pc-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .pa-pc-12 {
    padding: 12px !important;
  }
  .mt-pc-13 {
    margin-top: 13px !important;
  }
  .mx-pc-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .my-pc-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .ma-pc-13 {
    margin: 13px !important;
  }
  .pt-pc-13 {
    padding-top: 13px !important;
  }
  .px-pc-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .py-pc-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .pa-pc-13 {
    padding: 13px !important;
  }
  .mb-pc-13 {
    margin-bottom: 13px !important;
  }
  .mx-pc-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .my-pc-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .ma-pc-13 {
    margin: 13px !important;
  }
  .pb-pc-13 {
    padding-bottom: 13px !important;
  }
  .px-pc-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .py-pc-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .pa-pc-13 {
    padding: 13px !important;
  }
  .ml-pc-13 {
    margin-left: 13px !important;
  }
  .mx-pc-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .my-pc-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .ma-pc-13 {
    margin: 13px !important;
  }
  .pl-pc-13 {
    padding-left: 13px !important;
  }
  .px-pc-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .py-pc-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .pa-pc-13 {
    padding: 13px !important;
  }
  .mr-pc-13 {
    margin-right: 13px !important;
  }
  .mx-pc-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .my-pc-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .ma-pc-13 {
    margin: 13px !important;
  }
  .pr-pc-13 {
    padding-right: 13px !important;
  }
  .px-pc-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .py-pc-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .pa-pc-13 {
    padding: 13px !important;
  }
  .mt-pc-14 {
    margin-top: 14px !important;
  }
  .mx-pc-14 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .my-pc-14 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .ma-pc-14 {
    margin: 14px !important;
  }
  .pt-pc-14 {
    padding-top: 14px !important;
  }
  .px-pc-14 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .py-pc-14 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .pa-pc-14 {
    padding: 14px !important;
  }
  .mb-pc-14 {
    margin-bottom: 14px !important;
  }
  .mx-pc-14 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .my-pc-14 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .ma-pc-14 {
    margin: 14px !important;
  }
  .pb-pc-14 {
    padding-bottom: 14px !important;
  }
  .px-pc-14 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .py-pc-14 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .pa-pc-14 {
    padding: 14px !important;
  }
  .ml-pc-14 {
    margin-left: 14px !important;
  }
  .mx-pc-14 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .my-pc-14 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .ma-pc-14 {
    margin: 14px !important;
  }
  .pl-pc-14 {
    padding-left: 14px !important;
  }
  .px-pc-14 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .py-pc-14 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .pa-pc-14 {
    padding: 14px !important;
  }
  .mr-pc-14 {
    margin-right: 14px !important;
  }
  .mx-pc-14 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .my-pc-14 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .ma-pc-14 {
    margin: 14px !important;
  }
  .pr-pc-14 {
    padding-right: 14px !important;
  }
  .px-pc-14 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .py-pc-14 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .pa-pc-14 {
    padding: 14px !important;
  }
  .mt-pc-15 {
    margin-top: 15px !important;
  }
  .mx-pc-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-pc-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .ma-pc-15 {
    margin: 15px !important;
  }
  .pt-pc-15 {
    padding-top: 15px !important;
  }
  .px-pc-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-pc-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pa-pc-15 {
    padding: 15px !important;
  }
  .mb-pc-15 {
    margin-bottom: 15px !important;
  }
  .mx-pc-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-pc-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .ma-pc-15 {
    margin: 15px !important;
  }
  .pb-pc-15 {
    padding-bottom: 15px !important;
  }
  .px-pc-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-pc-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pa-pc-15 {
    padding: 15px !important;
  }
  .ml-pc-15 {
    margin-left: 15px !important;
  }
  .mx-pc-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-pc-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .ma-pc-15 {
    margin: 15px !important;
  }
  .pl-pc-15 {
    padding-left: 15px !important;
  }
  .px-pc-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-pc-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pa-pc-15 {
    padding: 15px !important;
  }
  .mr-pc-15 {
    margin-right: 15px !important;
  }
  .mx-pc-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-pc-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .ma-pc-15 {
    margin: 15px !important;
  }
  .pr-pc-15 {
    padding-right: 15px !important;
  }
  .px-pc-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-pc-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pa-pc-15 {
    padding: 15px !important;
  }
  .mt-pc-16 {
    margin-top: 16px !important;
  }
  .mx-pc-16 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .my-pc-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .ma-pc-16 {
    margin: 16px !important;
  }
  .pt-pc-16 {
    padding-top: 16px !important;
  }
  .px-pc-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-pc-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .pa-pc-16 {
    padding: 16px !important;
  }
  .mb-pc-16 {
    margin-bottom: 16px !important;
  }
  .mx-pc-16 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .my-pc-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .ma-pc-16 {
    margin: 16px !important;
  }
  .pb-pc-16 {
    padding-bottom: 16px !important;
  }
  .px-pc-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-pc-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .pa-pc-16 {
    padding: 16px !important;
  }
  .ml-pc-16 {
    margin-left: 16px !important;
  }
  .mx-pc-16 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .my-pc-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .ma-pc-16 {
    margin: 16px !important;
  }
  .pl-pc-16 {
    padding-left: 16px !important;
  }
  .px-pc-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-pc-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .pa-pc-16 {
    padding: 16px !important;
  }
  .mr-pc-16 {
    margin-right: 16px !important;
  }
  .mx-pc-16 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .my-pc-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .ma-pc-16 {
    margin: 16px !important;
  }
  .pr-pc-16 {
    padding-right: 16px !important;
  }
  .px-pc-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-pc-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .pa-pc-16 {
    padding: 16px !important;
  }
  .mt-pc-17 {
    margin-top: 17px !important;
  }
  .mx-pc-17 {
    margin-left: 17px !important;
    margin-right: 17px !important;
  }
  .my-pc-17 {
    margin-top: 17px !important;
    margin-bottom: 17px !important;
  }
  .ma-pc-17 {
    margin: 17px !important;
  }
  .pt-pc-17 {
    padding-top: 17px !important;
  }
  .px-pc-17 {
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
  .py-pc-17 {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
  .pa-pc-17 {
    padding: 17px !important;
  }
  .mb-pc-17 {
    margin-bottom: 17px !important;
  }
  .mx-pc-17 {
    margin-left: 17px !important;
    margin-right: 17px !important;
  }
  .my-pc-17 {
    margin-top: 17px !important;
    margin-bottom: 17px !important;
  }
  .ma-pc-17 {
    margin: 17px !important;
  }
  .pb-pc-17 {
    padding-bottom: 17px !important;
  }
  .px-pc-17 {
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
  .py-pc-17 {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
  .pa-pc-17 {
    padding: 17px !important;
  }
  .ml-pc-17 {
    margin-left: 17px !important;
  }
  .mx-pc-17 {
    margin-left: 17px !important;
    margin-right: 17px !important;
  }
  .my-pc-17 {
    margin-top: 17px !important;
    margin-bottom: 17px !important;
  }
  .ma-pc-17 {
    margin: 17px !important;
  }
  .pl-pc-17 {
    padding-left: 17px !important;
  }
  .px-pc-17 {
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
  .py-pc-17 {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
  .pa-pc-17 {
    padding: 17px !important;
  }
  .mr-pc-17 {
    margin-right: 17px !important;
  }
  .mx-pc-17 {
    margin-left: 17px !important;
    margin-right: 17px !important;
  }
  .my-pc-17 {
    margin-top: 17px !important;
    margin-bottom: 17px !important;
  }
  .ma-pc-17 {
    margin: 17px !important;
  }
  .pr-pc-17 {
    padding-right: 17px !important;
  }
  .px-pc-17 {
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
  .py-pc-17 {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
  .pa-pc-17 {
    padding: 17px !important;
  }
  .mt-pc-20 {
    margin-top: 20px !important;
  }
  .mx-pc-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-pc-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .ma-pc-20 {
    margin: 20px !important;
  }
  .pt-pc-20 {
    padding-top: 20px !important;
  }
  .px-pc-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-pc-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pa-pc-20 {
    padding: 20px !important;
  }
  .mb-pc-20 {
    margin-bottom: 20px !important;
  }
  .mx-pc-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-pc-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .ma-pc-20 {
    margin: 20px !important;
  }
  .pb-pc-20 {
    padding-bottom: 20px !important;
  }
  .px-pc-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-pc-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pa-pc-20 {
    padding: 20px !important;
  }
  .ml-pc-20 {
    margin-left: 20px !important;
  }
  .mx-pc-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-pc-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .ma-pc-20 {
    margin: 20px !important;
  }
  .pl-pc-20 {
    padding-left: 20px !important;
  }
  .px-pc-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-pc-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pa-pc-20 {
    padding: 20px !important;
  }
  .mr-pc-20 {
    margin-right: 20px !important;
  }
  .mx-pc-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-pc-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .ma-pc-20 {
    margin: 20px !important;
  }
  .pr-pc-20 {
    padding-right: 20px !important;
  }
  .px-pc-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-pc-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pa-pc-20 {
    padding: 20px !important;
  }
  .mt-pc-22 {
    margin-top: 22px !important;
  }
  .mx-pc-22 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .my-pc-22 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .ma-pc-22 {
    margin: 22px !important;
  }
  .pt-pc-22 {
    padding-top: 22px !important;
  }
  .px-pc-22 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .py-pc-22 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .pa-pc-22 {
    padding: 22px !important;
  }
  .mb-pc-22 {
    margin-bottom: 22px !important;
  }
  .mx-pc-22 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .my-pc-22 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .ma-pc-22 {
    margin: 22px !important;
  }
  .pb-pc-22 {
    padding-bottom: 22px !important;
  }
  .px-pc-22 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .py-pc-22 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .pa-pc-22 {
    padding: 22px !important;
  }
  .ml-pc-22 {
    margin-left: 22px !important;
  }
  .mx-pc-22 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .my-pc-22 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .ma-pc-22 {
    margin: 22px !important;
  }
  .pl-pc-22 {
    padding-left: 22px !important;
  }
  .px-pc-22 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .py-pc-22 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .pa-pc-22 {
    padding: 22px !important;
  }
  .mr-pc-22 {
    margin-right: 22px !important;
  }
  .mx-pc-22 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .my-pc-22 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .ma-pc-22 {
    margin: 22px !important;
  }
  .pr-pc-22 {
    padding-right: 22px !important;
  }
  .px-pc-22 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .py-pc-22 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .pa-pc-22 {
    padding: 22px !important;
  }
  .mt-pc-25 {
    margin-top: 25px !important;
  }
  .mx-pc-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-pc-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .ma-pc-25 {
    margin: 25px !important;
  }
  .pt-pc-25 {
    padding-top: 25px !important;
  }
  .px-pc-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-pc-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pa-pc-25 {
    padding: 25px !important;
  }
  .mb-pc-25 {
    margin-bottom: 25px !important;
  }
  .mx-pc-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-pc-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .ma-pc-25 {
    margin: 25px !important;
  }
  .pb-pc-25 {
    padding-bottom: 25px !important;
  }
  .px-pc-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-pc-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pa-pc-25 {
    padding: 25px !important;
  }
  .ml-pc-25 {
    margin-left: 25px !important;
  }
  .mx-pc-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-pc-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .ma-pc-25 {
    margin: 25px !important;
  }
  .pl-pc-25 {
    padding-left: 25px !important;
  }
  .px-pc-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-pc-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pa-pc-25 {
    padding: 25px !important;
  }
  .mr-pc-25 {
    margin-right: 25px !important;
  }
  .mx-pc-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-pc-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .ma-pc-25 {
    margin: 25px !important;
  }
  .pr-pc-25 {
    padding-right: 25px !important;
  }
  .px-pc-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-pc-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pa-pc-25 {
    padding: 25px !important;
  }
  .mt-pc-28 {
    margin-top: 28px !important;
  }
  .mx-pc-28 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .my-pc-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .ma-pc-28 {
    margin: 28px !important;
  }
  .pt-pc-28 {
    padding-top: 28px !important;
  }
  .px-pc-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .py-pc-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pa-pc-28 {
    padding: 28px !important;
  }
  .mb-pc-28 {
    margin-bottom: 28px !important;
  }
  .mx-pc-28 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .my-pc-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .ma-pc-28 {
    margin: 28px !important;
  }
  .pb-pc-28 {
    padding-bottom: 28px !important;
  }
  .px-pc-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .py-pc-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pa-pc-28 {
    padding: 28px !important;
  }
  .ml-pc-28 {
    margin-left: 28px !important;
  }
  .mx-pc-28 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .my-pc-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .ma-pc-28 {
    margin: 28px !important;
  }
  .pl-pc-28 {
    padding-left: 28px !important;
  }
  .px-pc-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .py-pc-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pa-pc-28 {
    padding: 28px !important;
  }
  .mr-pc-28 {
    margin-right: 28px !important;
  }
  .mx-pc-28 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .my-pc-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .ma-pc-28 {
    margin: 28px !important;
  }
  .pr-pc-28 {
    padding-right: 28px !important;
  }
  .px-pc-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .py-pc-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pa-pc-28 {
    padding: 28px !important;
  }
  .mt-pc-30 {
    margin-top: 30px !important;
  }
  .mx-pc-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-pc-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .ma-pc-30 {
    margin: 30px !important;
  }
  .pt-pc-30 {
    padding-top: 30px !important;
  }
  .px-pc-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-pc-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pa-pc-30 {
    padding: 30px !important;
  }
  .mb-pc-30 {
    margin-bottom: 30px !important;
  }
  .mx-pc-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-pc-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .ma-pc-30 {
    margin: 30px !important;
  }
  .pb-pc-30 {
    padding-bottom: 30px !important;
  }
  .px-pc-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-pc-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pa-pc-30 {
    padding: 30px !important;
  }
  .ml-pc-30 {
    margin-left: 30px !important;
  }
  .mx-pc-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-pc-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .ma-pc-30 {
    margin: 30px !important;
  }
  .pl-pc-30 {
    padding-left: 30px !important;
  }
  .px-pc-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-pc-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pa-pc-30 {
    padding: 30px !important;
  }
  .mr-pc-30 {
    margin-right: 30px !important;
  }
  .mx-pc-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-pc-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .ma-pc-30 {
    margin: 30px !important;
  }
  .pr-pc-30 {
    padding-right: 30px !important;
  }
  .px-pc-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-pc-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pa-pc-30 {
    padding: 30px !important;
  }
  .mt-pc-35 {
    margin-top: 35px !important;
  }
  .mx-pc-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .my-pc-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .ma-pc-35 {
    margin: 35px !important;
  }
  .pt-pc-35 {
    padding-top: 35px !important;
  }
  .px-pc-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .py-pc-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pa-pc-35 {
    padding: 35px !important;
  }
  .mb-pc-35 {
    margin-bottom: 35px !important;
  }
  .mx-pc-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .my-pc-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .ma-pc-35 {
    margin: 35px !important;
  }
  .pb-pc-35 {
    padding-bottom: 35px !important;
  }
  .px-pc-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .py-pc-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pa-pc-35 {
    padding: 35px !important;
  }
  .ml-pc-35 {
    margin-left: 35px !important;
  }
  .mx-pc-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .my-pc-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .ma-pc-35 {
    margin: 35px !important;
  }
  .pl-pc-35 {
    padding-left: 35px !important;
  }
  .px-pc-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .py-pc-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pa-pc-35 {
    padding: 35px !important;
  }
  .mr-pc-35 {
    margin-right: 35px !important;
  }
  .mx-pc-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .my-pc-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .ma-pc-35 {
    margin: 35px !important;
  }
  .pr-pc-35 {
    padding-right: 35px !important;
  }
  .px-pc-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .py-pc-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pa-pc-35 {
    padding: 35px !important;
  }
  .mt-pc-40 {
    margin-top: 40px !important;
  }
  .mx-pc-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-pc-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .ma-pc-40 {
    margin: 40px !important;
  }
  .pt-pc-40 {
    padding-top: 40px !important;
  }
  .px-pc-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-pc-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pa-pc-40 {
    padding: 40px !important;
  }
  .mb-pc-40 {
    margin-bottom: 40px !important;
  }
  .mx-pc-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-pc-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .ma-pc-40 {
    margin: 40px !important;
  }
  .pb-pc-40 {
    padding-bottom: 40px !important;
  }
  .px-pc-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-pc-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pa-pc-40 {
    padding: 40px !important;
  }
  .ml-pc-40 {
    margin-left: 40px !important;
  }
  .mx-pc-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-pc-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .ma-pc-40 {
    margin: 40px !important;
  }
  .pl-pc-40 {
    padding-left: 40px !important;
  }
  .px-pc-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-pc-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pa-pc-40 {
    padding: 40px !important;
  }
  .mr-pc-40 {
    margin-right: 40px !important;
  }
  .mx-pc-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-pc-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .ma-pc-40 {
    margin: 40px !important;
  }
  .pr-pc-40 {
    padding-right: 40px !important;
  }
  .px-pc-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-pc-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pa-pc-40 {
    padding: 40px !important;
  }
  .mt-pc-45 {
    margin-top: 45px !important;
  }
  .mx-pc-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .my-pc-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .ma-pc-45 {
    margin: 45px !important;
  }
  .pt-pc-45 {
    padding-top: 45px !important;
  }
  .px-pc-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .py-pc-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pa-pc-45 {
    padding: 45px !important;
  }
  .mb-pc-45 {
    margin-bottom: 45px !important;
  }
  .mx-pc-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .my-pc-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .ma-pc-45 {
    margin: 45px !important;
  }
  .pb-pc-45 {
    padding-bottom: 45px !important;
  }
  .px-pc-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .py-pc-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pa-pc-45 {
    padding: 45px !important;
  }
  .ml-pc-45 {
    margin-left: 45px !important;
  }
  .mx-pc-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .my-pc-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .ma-pc-45 {
    margin: 45px !important;
  }
  .pl-pc-45 {
    padding-left: 45px !important;
  }
  .px-pc-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .py-pc-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pa-pc-45 {
    padding: 45px !important;
  }
  .mr-pc-45 {
    margin-right: 45px !important;
  }
  .mx-pc-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .my-pc-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .ma-pc-45 {
    margin: 45px !important;
  }
  .pr-pc-45 {
    padding-right: 45px !important;
  }
  .px-pc-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .py-pc-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pa-pc-45 {
    padding: 45px !important;
  }
  .mt-pc-50 {
    margin-top: 50px !important;
  }
  .mx-pc-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-pc-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .ma-pc-50 {
    margin: 50px !important;
  }
  .pt-pc-50 {
    padding-top: 50px !important;
  }
  .px-pc-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-pc-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pa-pc-50 {
    padding: 50px !important;
  }
  .mb-pc-50 {
    margin-bottom: 50px !important;
  }
  .mx-pc-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-pc-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .ma-pc-50 {
    margin: 50px !important;
  }
  .pb-pc-50 {
    padding-bottom: 50px !important;
  }
  .px-pc-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-pc-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pa-pc-50 {
    padding: 50px !important;
  }
  .ml-pc-50 {
    margin-left: 50px !important;
  }
  .mx-pc-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-pc-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .ma-pc-50 {
    margin: 50px !important;
  }
  .pl-pc-50 {
    padding-left: 50px !important;
  }
  .px-pc-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-pc-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pa-pc-50 {
    padding: 50px !important;
  }
  .mr-pc-50 {
    margin-right: 50px !important;
  }
  .mx-pc-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-pc-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .ma-pc-50 {
    margin: 50px !important;
  }
  .pr-pc-50 {
    padding-right: 50px !important;
  }
  .px-pc-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-pc-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pa-pc-50 {
    padding: 50px !important;
  }
  .mt-pc-55 {
    margin-top: 55px !important;
  }
  .mx-pc-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .my-pc-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .ma-pc-55 {
    margin: 55px !important;
  }
  .pt-pc-55 {
    padding-top: 55px !important;
  }
  .px-pc-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .py-pc-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pa-pc-55 {
    padding: 55px !important;
  }
  .mb-pc-55 {
    margin-bottom: 55px !important;
  }
  .mx-pc-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .my-pc-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .ma-pc-55 {
    margin: 55px !important;
  }
  .pb-pc-55 {
    padding-bottom: 55px !important;
  }
  .px-pc-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .py-pc-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pa-pc-55 {
    padding: 55px !important;
  }
  .ml-pc-55 {
    margin-left: 55px !important;
  }
  .mx-pc-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .my-pc-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .ma-pc-55 {
    margin: 55px !important;
  }
  .pl-pc-55 {
    padding-left: 55px !important;
  }
  .px-pc-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .py-pc-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pa-pc-55 {
    padding: 55px !important;
  }
  .mr-pc-55 {
    margin-right: 55px !important;
  }
  .mx-pc-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .my-pc-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .ma-pc-55 {
    margin: 55px !important;
  }
  .pr-pc-55 {
    padding-right: 55px !important;
  }
  .px-pc-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .py-pc-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pa-pc-55 {
    padding: 55px !important;
  }
  .mt-pc-60 {
    margin-top: 60px !important;
  }
  .mx-pc-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-pc-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .ma-pc-60 {
    margin: 60px !important;
  }
  .pt-pc-60 {
    padding-top: 60px !important;
  }
  .px-pc-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-pc-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pa-pc-60 {
    padding: 60px !important;
  }
  .mb-pc-60 {
    margin-bottom: 60px !important;
  }
  .mx-pc-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-pc-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .ma-pc-60 {
    margin: 60px !important;
  }
  .pb-pc-60 {
    padding-bottom: 60px !important;
  }
  .px-pc-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-pc-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pa-pc-60 {
    padding: 60px !important;
  }
  .ml-pc-60 {
    margin-left: 60px !important;
  }
  .mx-pc-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-pc-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .ma-pc-60 {
    margin: 60px !important;
  }
  .pl-pc-60 {
    padding-left: 60px !important;
  }
  .px-pc-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-pc-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pa-pc-60 {
    padding: 60px !important;
  }
  .mr-pc-60 {
    margin-right: 60px !important;
  }
  .mx-pc-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-pc-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .ma-pc-60 {
    margin: 60px !important;
  }
  .pr-pc-60 {
    padding-right: 60px !important;
  }
  .px-pc-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-pc-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pa-pc-60 {
    padding: 60px !important;
  }
  .mt-pc-65 {
    margin-top: 65px !important;
  }
  .mx-pc-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .my-pc-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .ma-pc-65 {
    margin: 65px !important;
  }
  .pt-pc-65 {
    padding-top: 65px !important;
  }
  .px-pc-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .py-pc-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pa-pc-65 {
    padding: 65px !important;
  }
  .mb-pc-65 {
    margin-bottom: 65px !important;
  }
  .mx-pc-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .my-pc-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .ma-pc-65 {
    margin: 65px !important;
  }
  .pb-pc-65 {
    padding-bottom: 65px !important;
  }
  .px-pc-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .py-pc-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pa-pc-65 {
    padding: 65px !important;
  }
  .ml-pc-65 {
    margin-left: 65px !important;
  }
  .mx-pc-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .my-pc-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .ma-pc-65 {
    margin: 65px !important;
  }
  .pl-pc-65 {
    padding-left: 65px !important;
  }
  .px-pc-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .py-pc-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pa-pc-65 {
    padding: 65px !important;
  }
  .mr-pc-65 {
    margin-right: 65px !important;
  }
  .mx-pc-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .my-pc-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .ma-pc-65 {
    margin: 65px !important;
  }
  .pr-pc-65 {
    padding-right: 65px !important;
  }
  .px-pc-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .py-pc-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pa-pc-65 {
    padding: 65px !important;
  }
  .mt-pc-70 {
    margin-top: 70px !important;
  }
  .mx-pc-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-pc-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .ma-pc-70 {
    margin: 70px !important;
  }
  .pt-pc-70 {
    padding-top: 70px !important;
  }
  .px-pc-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-pc-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pa-pc-70 {
    padding: 70px !important;
  }
  .mb-pc-70 {
    margin-bottom: 70px !important;
  }
  .mx-pc-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-pc-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .ma-pc-70 {
    margin: 70px !important;
  }
  .pb-pc-70 {
    padding-bottom: 70px !important;
  }
  .px-pc-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-pc-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pa-pc-70 {
    padding: 70px !important;
  }
  .ml-pc-70 {
    margin-left: 70px !important;
  }
  .mx-pc-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-pc-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .ma-pc-70 {
    margin: 70px !important;
  }
  .pl-pc-70 {
    padding-left: 70px !important;
  }
  .px-pc-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-pc-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pa-pc-70 {
    padding: 70px !important;
  }
  .mr-pc-70 {
    margin-right: 70px !important;
  }
  .mx-pc-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-pc-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .ma-pc-70 {
    margin: 70px !important;
  }
  .pr-pc-70 {
    padding-right: 70px !important;
  }
  .px-pc-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-pc-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pa-pc-70 {
    padding: 70px !important;
  }
  .mt-pc-75 {
    margin-top: 75px !important;
  }
  .mx-pc-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .my-pc-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .ma-pc-75 {
    margin: 75px !important;
  }
  .pt-pc-75 {
    padding-top: 75px !important;
  }
  .px-pc-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .py-pc-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pa-pc-75 {
    padding: 75px !important;
  }
  .mb-pc-75 {
    margin-bottom: 75px !important;
  }
  .mx-pc-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .my-pc-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .ma-pc-75 {
    margin: 75px !important;
  }
  .pb-pc-75 {
    padding-bottom: 75px !important;
  }
  .px-pc-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .py-pc-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pa-pc-75 {
    padding: 75px !important;
  }
  .ml-pc-75 {
    margin-left: 75px !important;
  }
  .mx-pc-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .my-pc-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .ma-pc-75 {
    margin: 75px !important;
  }
  .pl-pc-75 {
    padding-left: 75px !important;
  }
  .px-pc-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .py-pc-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pa-pc-75 {
    padding: 75px !important;
  }
  .mr-pc-75 {
    margin-right: 75px !important;
  }
  .mx-pc-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .my-pc-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .ma-pc-75 {
    margin: 75px !important;
  }
  .pr-pc-75 {
    padding-right: 75px !important;
  }
  .px-pc-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .py-pc-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pa-pc-75 {
    padding: 75px !important;
  }
  .mt-pc-80 {
    margin-top: 80px !important;
  }
  .mx-pc-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-pc-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .ma-pc-80 {
    margin: 80px !important;
  }
  .pt-pc-80 {
    padding-top: 80px !important;
  }
  .px-pc-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-pc-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pa-pc-80 {
    padding: 80px !important;
  }
  .mb-pc-80 {
    margin-bottom: 80px !important;
  }
  .mx-pc-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-pc-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .ma-pc-80 {
    margin: 80px !important;
  }
  .pb-pc-80 {
    padding-bottom: 80px !important;
  }
  .px-pc-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-pc-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pa-pc-80 {
    padding: 80px !important;
  }
  .ml-pc-80 {
    margin-left: 80px !important;
  }
  .mx-pc-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-pc-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .ma-pc-80 {
    margin: 80px !important;
  }
  .pl-pc-80 {
    padding-left: 80px !important;
  }
  .px-pc-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-pc-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pa-pc-80 {
    padding: 80px !important;
  }
  .mr-pc-80 {
    margin-right: 80px !important;
  }
  .mx-pc-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-pc-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .ma-pc-80 {
    margin: 80px !important;
  }
  .pr-pc-80 {
    padding-right: 80px !important;
  }
  .px-pc-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-pc-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pa-pc-80 {
    padding: 80px !important;
  }
  .mt-pc-85 {
    margin-top: 85px !important;
  }
  .mx-pc-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .my-pc-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .ma-pc-85 {
    margin: 85px !important;
  }
  .pt-pc-85 {
    padding-top: 85px !important;
  }
  .px-pc-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .py-pc-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pa-pc-85 {
    padding: 85px !important;
  }
  .mb-pc-85 {
    margin-bottom: 85px !important;
  }
  .mx-pc-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .my-pc-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .ma-pc-85 {
    margin: 85px !important;
  }
  .pb-pc-85 {
    padding-bottom: 85px !important;
  }
  .px-pc-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .py-pc-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pa-pc-85 {
    padding: 85px !important;
  }
  .ml-pc-85 {
    margin-left: 85px !important;
  }
  .mx-pc-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .my-pc-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .ma-pc-85 {
    margin: 85px !important;
  }
  .pl-pc-85 {
    padding-left: 85px !important;
  }
  .px-pc-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .py-pc-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pa-pc-85 {
    padding: 85px !important;
  }
  .mr-pc-85 {
    margin-right: 85px !important;
  }
  .mx-pc-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .my-pc-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .ma-pc-85 {
    margin: 85px !important;
  }
  .pr-pc-85 {
    padding-right: 85px !important;
  }
  .px-pc-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .py-pc-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pa-pc-85 {
    padding: 85px !important;
  }
  .mt-pc-90 {
    margin-top: 90px !important;
  }
  .mx-pc-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-pc-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .ma-pc-90 {
    margin: 90px !important;
  }
  .pt-pc-90 {
    padding-top: 90px !important;
  }
  .px-pc-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-pc-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pa-pc-90 {
    padding: 90px !important;
  }
  .mb-pc-90 {
    margin-bottom: 90px !important;
  }
  .mx-pc-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-pc-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .ma-pc-90 {
    margin: 90px !important;
  }
  .pb-pc-90 {
    padding-bottom: 90px !important;
  }
  .px-pc-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-pc-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pa-pc-90 {
    padding: 90px !important;
  }
  .ml-pc-90 {
    margin-left: 90px !important;
  }
  .mx-pc-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-pc-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .ma-pc-90 {
    margin: 90px !important;
  }
  .pl-pc-90 {
    padding-left: 90px !important;
  }
  .px-pc-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-pc-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pa-pc-90 {
    padding: 90px !important;
  }
  .mr-pc-90 {
    margin-right: 90px !important;
  }
  .mx-pc-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-pc-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .ma-pc-90 {
    margin: 90px !important;
  }
  .pr-pc-90 {
    padding-right: 90px !important;
  }
  .px-pc-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-pc-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pa-pc-90 {
    padding: 90px !important;
  }
  .mt-pc-95 {
    margin-top: 95px !important;
  }
  .mx-pc-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .my-pc-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .ma-pc-95 {
    margin: 95px !important;
  }
  .pt-pc-95 {
    padding-top: 95px !important;
  }
  .px-pc-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .py-pc-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pa-pc-95 {
    padding: 95px !important;
  }
  .mb-pc-95 {
    margin-bottom: 95px !important;
  }
  .mx-pc-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .my-pc-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .ma-pc-95 {
    margin: 95px !important;
  }
  .pb-pc-95 {
    padding-bottom: 95px !important;
  }
  .px-pc-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .py-pc-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pa-pc-95 {
    padding: 95px !important;
  }
  .ml-pc-95 {
    margin-left: 95px !important;
  }
  .mx-pc-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .my-pc-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .ma-pc-95 {
    margin: 95px !important;
  }
  .pl-pc-95 {
    padding-left: 95px !important;
  }
  .px-pc-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .py-pc-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pa-pc-95 {
    padding: 95px !important;
  }
  .mr-pc-95 {
    margin-right: 95px !important;
  }
  .mx-pc-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .my-pc-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .ma-pc-95 {
    margin: 95px !important;
  }
  .pr-pc-95 {
    padding-right: 95px !important;
  }
  .px-pc-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .py-pc-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pa-pc-95 {
    padding: 95px !important;
  }
  .mt-pc-100 {
    margin-top: 100px !important;
  }
  .mx-pc-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-pc-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .ma-pc-100 {
    margin: 100px !important;
  }
  .pt-pc-100 {
    padding-top: 100px !important;
  }
  .px-pc-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-pc-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pa-pc-100 {
    padding: 100px !important;
  }
  .mb-pc-100 {
    margin-bottom: 100px !important;
  }
  .mx-pc-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-pc-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .ma-pc-100 {
    margin: 100px !important;
  }
  .pb-pc-100 {
    padding-bottom: 100px !important;
  }
  .px-pc-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-pc-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pa-pc-100 {
    padding: 100px !important;
  }
  .ml-pc-100 {
    margin-left: 100px !important;
  }
  .mx-pc-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-pc-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .ma-pc-100 {
    margin: 100px !important;
  }
  .pl-pc-100 {
    padding-left: 100px !important;
  }
  .px-pc-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-pc-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pa-pc-100 {
    padding: 100px !important;
  }
  .mr-pc-100 {
    margin-right: 100px !important;
  }
  .mx-pc-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-pc-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .ma-pc-100 {
    margin: 100px !important;
  }
  .pr-pc-100 {
    padding-right: 100px !important;
  }
  .px-pc-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-pc-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pa-pc-100 {
    padding: 100px !important;
  }
  .mt-pc-110 {
    margin-top: 110px !important;
  }
  .mx-pc-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .my-pc-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .ma-pc-110 {
    margin: 110px !important;
  }
  .pt-pc-110 {
    padding-top: 110px !important;
  }
  .px-pc-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .py-pc-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pa-pc-110 {
    padding: 110px !important;
  }
  .mb-pc-110 {
    margin-bottom: 110px !important;
  }
  .mx-pc-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .my-pc-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .ma-pc-110 {
    margin: 110px !important;
  }
  .pb-pc-110 {
    padding-bottom: 110px !important;
  }
  .px-pc-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .py-pc-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pa-pc-110 {
    padding: 110px !important;
  }
  .ml-pc-110 {
    margin-left: 110px !important;
  }
  .mx-pc-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .my-pc-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .ma-pc-110 {
    margin: 110px !important;
  }
  .pl-pc-110 {
    padding-left: 110px !important;
  }
  .px-pc-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .py-pc-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pa-pc-110 {
    padding: 110px !important;
  }
  .mr-pc-110 {
    margin-right: 110px !important;
  }
  .mx-pc-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .my-pc-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .ma-pc-110 {
    margin: 110px !important;
  }
  .pr-pc-110 {
    padding-right: 110px !important;
  }
  .px-pc-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .py-pc-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pa-pc-110 {
    padding: 110px !important;
  }
  .mt-pc-115 {
    margin-top: 115px !important;
  }
  .mx-pc-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .my-pc-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .ma-pc-115 {
    margin: 115px !important;
  }
  .pt-pc-115 {
    padding-top: 115px !important;
  }
  .px-pc-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .py-pc-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .pa-pc-115 {
    padding: 115px !important;
  }
  .mb-pc-115 {
    margin-bottom: 115px !important;
  }
  .mx-pc-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .my-pc-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .ma-pc-115 {
    margin: 115px !important;
  }
  .pb-pc-115 {
    padding-bottom: 115px !important;
  }
  .px-pc-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .py-pc-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .pa-pc-115 {
    padding: 115px !important;
  }
  .ml-pc-115 {
    margin-left: 115px !important;
  }
  .mx-pc-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .my-pc-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .ma-pc-115 {
    margin: 115px !important;
  }
  .pl-pc-115 {
    padding-left: 115px !important;
  }
  .px-pc-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .py-pc-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .pa-pc-115 {
    padding: 115px !important;
  }
  .mr-pc-115 {
    margin-right: 115px !important;
  }
  .mx-pc-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .my-pc-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .ma-pc-115 {
    margin: 115px !important;
  }
  .pr-pc-115 {
    padding-right: 115px !important;
  }
  .px-pc-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .py-pc-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .pa-pc-115 {
    padding: 115px !important;
  }
  .mt-pc-120 {
    margin-top: 120px !important;
  }
  .mx-pc-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-pc-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .ma-pc-120 {
    margin: 120px !important;
  }
  .pt-pc-120 {
    padding-top: 120px !important;
  }
  .px-pc-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-pc-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pa-pc-120 {
    padding: 120px !important;
  }
  .mb-pc-120 {
    margin-bottom: 120px !important;
  }
  .mx-pc-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-pc-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .ma-pc-120 {
    margin: 120px !important;
  }
  .pb-pc-120 {
    padding-bottom: 120px !important;
  }
  .px-pc-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-pc-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pa-pc-120 {
    padding: 120px !important;
  }
  .ml-pc-120 {
    margin-left: 120px !important;
  }
  .mx-pc-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-pc-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .ma-pc-120 {
    margin: 120px !important;
  }
  .pl-pc-120 {
    padding-left: 120px !important;
  }
  .px-pc-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-pc-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pa-pc-120 {
    padding: 120px !important;
  }
  .mr-pc-120 {
    margin-right: 120px !important;
  }
  .mx-pc-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-pc-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .ma-pc-120 {
    margin: 120px !important;
  }
  .pr-pc-120 {
    padding-right: 120px !important;
  }
  .px-pc-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-pc-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pa-pc-120 {
    padding: 120px !important;
  }
  .mt-pc-150 {
    margin-top: 150px !important;
  }
  .mx-pc-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .my-pc-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .ma-pc-150 {
    margin: 150px !important;
  }
  .pt-pc-150 {
    padding-top: 150px !important;
  }
  .px-pc-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .py-pc-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pa-pc-150 {
    padding: 150px !important;
  }
  .mb-pc-150 {
    margin-bottom: 150px !important;
  }
  .mx-pc-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .my-pc-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .ma-pc-150 {
    margin: 150px !important;
  }
  .pb-pc-150 {
    padding-bottom: 150px !important;
  }
  .px-pc-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .py-pc-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pa-pc-150 {
    padding: 150px !important;
  }
  .ml-pc-150 {
    margin-left: 150px !important;
  }
  .mx-pc-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .my-pc-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .ma-pc-150 {
    margin: 150px !important;
  }
  .pl-pc-150 {
    padding-left: 150px !important;
  }
  .px-pc-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .py-pc-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pa-pc-150 {
    padding: 150px !important;
  }
  .mr-pc-150 {
    margin-right: 150px !important;
  }
  .mx-pc-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .my-pc-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .ma-pc-150 {
    margin: 150px !important;
  }
  .pr-pc-150 {
    padding-right: 150px !important;
  }
  .px-pc-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .py-pc-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pa-pc-150 {
    padding: 150px !important;
  }
  .mt-pc-305 {
    margin-top: 305px !important;
  }
  .mx-pc-305 {
    margin-left: 305px !important;
    margin-right: 305px !important;
  }
  .my-pc-305 {
    margin-top: 305px !important;
    margin-bottom: 305px !important;
  }
  .ma-pc-305 {
    margin: 305px !important;
  }
  .pt-pc-305 {
    padding-top: 305px !important;
  }
  .px-pc-305 {
    padding-left: 305px !important;
    padding-right: 305px !important;
  }
  .py-pc-305 {
    padding-top: 305px !important;
    padding-bottom: 305px !important;
  }
  .pa-pc-305 {
    padding: 305px !important;
  }
  .mb-pc-305 {
    margin-bottom: 305px !important;
  }
  .mx-pc-305 {
    margin-left: 305px !important;
    margin-right: 305px !important;
  }
  .my-pc-305 {
    margin-top: 305px !important;
    margin-bottom: 305px !important;
  }
  .ma-pc-305 {
    margin: 305px !important;
  }
  .pb-pc-305 {
    padding-bottom: 305px !important;
  }
  .px-pc-305 {
    padding-left: 305px !important;
    padding-right: 305px !important;
  }
  .py-pc-305 {
    padding-top: 305px !important;
    padding-bottom: 305px !important;
  }
  .pa-pc-305 {
    padding: 305px !important;
  }
  .ml-pc-305 {
    margin-left: 305px !important;
  }
  .mx-pc-305 {
    margin-left: 305px !important;
    margin-right: 305px !important;
  }
  .my-pc-305 {
    margin-top: 305px !important;
    margin-bottom: 305px !important;
  }
  .ma-pc-305 {
    margin: 305px !important;
  }
  .pl-pc-305 {
    padding-left: 305px !important;
  }
  .px-pc-305 {
    padding-left: 305px !important;
    padding-right: 305px !important;
  }
  .py-pc-305 {
    padding-top: 305px !important;
    padding-bottom: 305px !important;
  }
  .pa-pc-305 {
    padding: 305px !important;
  }
  .mr-pc-305 {
    margin-right: 305px !important;
  }
  .mx-pc-305 {
    margin-left: 305px !important;
    margin-right: 305px !important;
  }
  .my-pc-305 {
    margin-top: 305px !important;
    margin-bottom: 305px !important;
  }
  .ma-pc-305 {
    margin: 305px !important;
  }
  .pr-pc-305 {
    padding-right: 305px !important;
  }
  .px-pc-305 {
    padding-left: 305px !important;
    padding-right: 305px !important;
  }
  .py-pc-305 {
    padding-top: 305px !important;
    padding-bottom: 305px !important;
  }
  .pa-pc-305 {
    padding: 305px !important;
  }
}

@media (max-width: 896px) {
  .mt-sp-0 {
    margin-top: 0px !important;
  }
  .ma-sp-0 {
    margin: 0px !important;
  }
  .mx-sp-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .my-sp-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .pt-sp-0 {
    padding-top: 0px !important;
  }
  .px-sp-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-sp-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pa-sp-0 {
    padding: 0px !important;
  }
  .mb-sp-0 {
    margin-bottom: 0px !important;
  }
  .ma-sp-0 {
    margin: 0px !important;
  }
  .mx-sp-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .my-sp-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .pb-sp-0 {
    padding-bottom: 0px !important;
  }
  .px-sp-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-sp-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pa-sp-0 {
    padding: 0px !important;
  }
  .ml-sp-0 {
    margin-left: 0px !important;
  }
  .ma-sp-0 {
    margin: 0px !important;
  }
  .mx-sp-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .my-sp-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .pl-sp-0 {
    padding-left: 0px !important;
  }
  .px-sp-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-sp-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pa-sp-0 {
    padding: 0px !important;
  }
  .mr-sp-0 {
    margin-right: 0px !important;
  }
  .ma-sp-0 {
    margin: 0px !important;
  }
  .mx-sp-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .my-sp-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .pr-sp-0 {
    padding-right: 0px !important;
  }
  .px-sp-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-sp-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pa-sp-0 {
    padding: 0px !important;
  }
  .mt-sp-1 {
    margin-top: 1px !important;
  }
  .ma-sp-1 {
    margin: 1px !important;
  }
  .mx-sp-1 {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .my-sp-1 {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .pt-sp-1 {
    padding-top: 1px !important;
  }
  .px-sp-1 {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .py-sp-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pa-sp-1 {
    padding: 1px !important;
  }
  .mb-sp-1 {
    margin-bottom: 1px !important;
  }
  .ma-sp-1 {
    margin: 1px !important;
  }
  .mx-sp-1 {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .my-sp-1 {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .pb-sp-1 {
    padding-bottom: 1px !important;
  }
  .px-sp-1 {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .py-sp-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pa-sp-1 {
    padding: 1px !important;
  }
  .ml-sp-1 {
    margin-left: 1px !important;
  }
  .ma-sp-1 {
    margin: 1px !important;
  }
  .mx-sp-1 {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .my-sp-1 {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .pl-sp-1 {
    padding-left: 1px !important;
  }
  .px-sp-1 {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .py-sp-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pa-sp-1 {
    padding: 1px !important;
  }
  .mr-sp-1 {
    margin-right: 1px !important;
  }
  .ma-sp-1 {
    margin: 1px !important;
  }
  .mx-sp-1 {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .my-sp-1 {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .pr-sp-1 {
    padding-right: 1px !important;
  }
  .px-sp-1 {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .py-sp-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pa-sp-1 {
    padding: 1px !important;
  }
  .mt-sp-5 {
    margin-top: 5px !important;
  }
  .ma-sp-5 {
    margin: 5px !important;
  }
  .mx-sp-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-sp-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .pt-sp-5 {
    padding-top: 5px !important;
  }
  .px-sp-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-sp-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pa-sp-5 {
    padding: 5px !important;
  }
  .mb-sp-5 {
    margin-bottom: 5px !important;
  }
  .ma-sp-5 {
    margin: 5px !important;
  }
  .mx-sp-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-sp-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .pb-sp-5 {
    padding-bottom: 5px !important;
  }
  .px-sp-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-sp-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pa-sp-5 {
    padding: 5px !important;
  }
  .ml-sp-5 {
    margin-left: 5px !important;
  }
  .ma-sp-5 {
    margin: 5px !important;
  }
  .mx-sp-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-sp-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .pl-sp-5 {
    padding-left: 5px !important;
  }
  .px-sp-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-sp-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pa-sp-5 {
    padding: 5px !important;
  }
  .mr-sp-5 {
    margin-right: 5px !important;
  }
  .ma-sp-5 {
    margin: 5px !important;
  }
  .mx-sp-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-sp-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .pr-sp-5 {
    padding-right: 5px !important;
  }
  .px-sp-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-sp-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pa-sp-5 {
    padding: 5px !important;
  }
  .mt-sp-8 {
    margin-top: 8px !important;
  }
  .ma-sp-8 {
    margin: 8px !important;
  }
  .mx-sp-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .my-sp-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .pt-sp-8 {
    padding-top: 8px !important;
  }
  .px-sp-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-sp-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pa-sp-8 {
    padding: 8px !important;
  }
  .mb-sp-8 {
    margin-bottom: 8px !important;
  }
  .ma-sp-8 {
    margin: 8px !important;
  }
  .mx-sp-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .my-sp-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .pb-sp-8 {
    padding-bottom: 8px !important;
  }
  .px-sp-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-sp-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pa-sp-8 {
    padding: 8px !important;
  }
  .ml-sp-8 {
    margin-left: 8px !important;
  }
  .ma-sp-8 {
    margin: 8px !important;
  }
  .mx-sp-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .my-sp-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .pl-sp-8 {
    padding-left: 8px !important;
  }
  .px-sp-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-sp-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pa-sp-8 {
    padding: 8px !important;
  }
  .mr-sp-8 {
    margin-right: 8px !important;
  }
  .ma-sp-8 {
    margin: 8px !important;
  }
  .mx-sp-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .my-sp-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .pr-sp-8 {
    padding-right: 8px !important;
  }
  .px-sp-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-sp-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pa-sp-8 {
    padding: 8px !important;
  }
  .mt-sp-10 {
    margin-top: 10px !important;
  }
  .ma-sp-10 {
    margin: 10px !important;
  }
  .mx-sp-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-sp-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .pt-sp-10 {
    padding-top: 10px !important;
  }
  .px-sp-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-sp-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pa-sp-10 {
    padding: 10px !important;
  }
  .mb-sp-10 {
    margin-bottom: 10px !important;
  }
  .ma-sp-10 {
    margin: 10px !important;
  }
  .mx-sp-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-sp-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .pb-sp-10 {
    padding-bottom: 10px !important;
  }
  .px-sp-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-sp-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pa-sp-10 {
    padding: 10px !important;
  }
  .ml-sp-10 {
    margin-left: 10px !important;
  }
  .ma-sp-10 {
    margin: 10px !important;
  }
  .mx-sp-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-sp-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .pl-sp-10 {
    padding-left: 10px !important;
  }
  .px-sp-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-sp-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pa-sp-10 {
    padding: 10px !important;
  }
  .mr-sp-10 {
    margin-right: 10px !important;
  }
  .ma-sp-10 {
    margin: 10px !important;
  }
  .mx-sp-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-sp-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .pr-sp-10 {
    padding-right: 10px !important;
  }
  .px-sp-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-sp-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pa-sp-10 {
    padding: 10px !important;
  }
  .mt-sp-12 {
    margin-top: 12px !important;
  }
  .ma-sp-12 {
    margin: 12px !important;
  }
  .mx-sp-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .my-sp-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .pt-sp-12 {
    padding-top: 12px !important;
  }
  .px-sp-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .py-sp-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .pa-sp-12 {
    padding: 12px !important;
  }
  .mb-sp-12 {
    margin-bottom: 12px !important;
  }
  .ma-sp-12 {
    margin: 12px !important;
  }
  .mx-sp-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .my-sp-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .pb-sp-12 {
    padding-bottom: 12px !important;
  }
  .px-sp-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .py-sp-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .pa-sp-12 {
    padding: 12px !important;
  }
  .ml-sp-12 {
    margin-left: 12px !important;
  }
  .ma-sp-12 {
    margin: 12px !important;
  }
  .mx-sp-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .my-sp-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .pl-sp-12 {
    padding-left: 12px !important;
  }
  .px-sp-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .py-sp-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .pa-sp-12 {
    padding: 12px !important;
  }
  .mr-sp-12 {
    margin-right: 12px !important;
  }
  .ma-sp-12 {
    margin: 12px !important;
  }
  .mx-sp-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .my-sp-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .pr-sp-12 {
    padding-right: 12px !important;
  }
  .px-sp-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .py-sp-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .pa-sp-12 {
    padding: 12px !important;
  }
  .mt-sp-13 {
    margin-top: 13px !important;
  }
  .ma-sp-13 {
    margin: 13px !important;
  }
  .mx-sp-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .my-sp-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .pt-sp-13 {
    padding-top: 13px !important;
  }
  .px-sp-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .py-sp-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .pa-sp-13 {
    padding: 13px !important;
  }
  .mb-sp-13 {
    margin-bottom: 13px !important;
  }
  .ma-sp-13 {
    margin: 13px !important;
  }
  .mx-sp-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .my-sp-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .pb-sp-13 {
    padding-bottom: 13px !important;
  }
  .px-sp-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .py-sp-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .pa-sp-13 {
    padding: 13px !important;
  }
  .ml-sp-13 {
    margin-left: 13px !important;
  }
  .ma-sp-13 {
    margin: 13px !important;
  }
  .mx-sp-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .my-sp-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .pl-sp-13 {
    padding-left: 13px !important;
  }
  .px-sp-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .py-sp-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .pa-sp-13 {
    padding: 13px !important;
  }
  .mr-sp-13 {
    margin-right: 13px !important;
  }
  .ma-sp-13 {
    margin: 13px !important;
  }
  .mx-sp-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .my-sp-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .pr-sp-13 {
    padding-right: 13px !important;
  }
  .px-sp-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .py-sp-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .pa-sp-13 {
    padding: 13px !important;
  }
  .mt-sp-14 {
    margin-top: 14px !important;
  }
  .ma-sp-14 {
    margin: 14px !important;
  }
  .mx-sp-14 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .my-sp-14 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .pt-sp-14 {
    padding-top: 14px !important;
  }
  .px-sp-14 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .py-sp-14 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .pa-sp-14 {
    padding: 14px !important;
  }
  .mb-sp-14 {
    margin-bottom: 14px !important;
  }
  .ma-sp-14 {
    margin: 14px !important;
  }
  .mx-sp-14 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .my-sp-14 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .pb-sp-14 {
    padding-bottom: 14px !important;
  }
  .px-sp-14 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .py-sp-14 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .pa-sp-14 {
    padding: 14px !important;
  }
  .ml-sp-14 {
    margin-left: 14px !important;
  }
  .ma-sp-14 {
    margin: 14px !important;
  }
  .mx-sp-14 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .my-sp-14 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .pl-sp-14 {
    padding-left: 14px !important;
  }
  .px-sp-14 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .py-sp-14 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .pa-sp-14 {
    padding: 14px !important;
  }
  .mr-sp-14 {
    margin-right: 14px !important;
  }
  .ma-sp-14 {
    margin: 14px !important;
  }
  .mx-sp-14 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .my-sp-14 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .pr-sp-14 {
    padding-right: 14px !important;
  }
  .px-sp-14 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .py-sp-14 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .pa-sp-14 {
    padding: 14px !important;
  }
  .mt-sp-15 {
    margin-top: 15px !important;
  }
  .ma-sp-15 {
    margin: 15px !important;
  }
  .mx-sp-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-sp-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .pt-sp-15 {
    padding-top: 15px !important;
  }
  .px-sp-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-sp-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pa-sp-15 {
    padding: 15px !important;
  }
  .mb-sp-15 {
    margin-bottom: 15px !important;
  }
  .ma-sp-15 {
    margin: 15px !important;
  }
  .mx-sp-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-sp-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .pb-sp-15 {
    padding-bottom: 15px !important;
  }
  .px-sp-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-sp-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pa-sp-15 {
    padding: 15px !important;
  }
  .ml-sp-15 {
    margin-left: 15px !important;
  }
  .ma-sp-15 {
    margin: 15px !important;
  }
  .mx-sp-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-sp-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .pl-sp-15 {
    padding-left: 15px !important;
  }
  .px-sp-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-sp-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pa-sp-15 {
    padding: 15px !important;
  }
  .mr-sp-15 {
    margin-right: 15px !important;
  }
  .ma-sp-15 {
    margin: 15px !important;
  }
  .mx-sp-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-sp-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .pr-sp-15 {
    padding-right: 15px !important;
  }
  .px-sp-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-sp-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pa-sp-15 {
    padding: 15px !important;
  }
  .mt-sp-16 {
    margin-top: 16px !important;
  }
  .ma-sp-16 {
    margin: 16px !important;
  }
  .mx-sp-16 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .my-sp-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .pt-sp-16 {
    padding-top: 16px !important;
  }
  .px-sp-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-sp-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .pa-sp-16 {
    padding: 16px !important;
  }
  .mb-sp-16 {
    margin-bottom: 16px !important;
  }
  .ma-sp-16 {
    margin: 16px !important;
  }
  .mx-sp-16 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .my-sp-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .pb-sp-16 {
    padding-bottom: 16px !important;
  }
  .px-sp-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-sp-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .pa-sp-16 {
    padding: 16px !important;
  }
  .ml-sp-16 {
    margin-left: 16px !important;
  }
  .ma-sp-16 {
    margin: 16px !important;
  }
  .mx-sp-16 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .my-sp-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .pl-sp-16 {
    padding-left: 16px !important;
  }
  .px-sp-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-sp-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .pa-sp-16 {
    padding: 16px !important;
  }
  .mr-sp-16 {
    margin-right: 16px !important;
  }
  .ma-sp-16 {
    margin: 16px !important;
  }
  .mx-sp-16 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .my-sp-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .pr-sp-16 {
    padding-right: 16px !important;
  }
  .px-sp-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-sp-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .pa-sp-16 {
    padding: 16px !important;
  }
  .mt-sp-17 {
    margin-top: 17px !important;
  }
  .ma-sp-17 {
    margin: 17px !important;
  }
  .mx-sp-17 {
    margin-left: 17px !important;
    margin-right: 17px !important;
  }
  .my-sp-17 {
    margin-top: 17px !important;
    margin-bottom: 17px !important;
  }
  .pt-sp-17 {
    padding-top: 17px !important;
  }
  .px-sp-17 {
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
  .py-sp-17 {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
  .pa-sp-17 {
    padding: 17px !important;
  }
  .mb-sp-17 {
    margin-bottom: 17px !important;
  }
  .ma-sp-17 {
    margin: 17px !important;
  }
  .mx-sp-17 {
    margin-left: 17px !important;
    margin-right: 17px !important;
  }
  .my-sp-17 {
    margin-top: 17px !important;
    margin-bottom: 17px !important;
  }
  .pb-sp-17 {
    padding-bottom: 17px !important;
  }
  .px-sp-17 {
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
  .py-sp-17 {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
  .pa-sp-17 {
    padding: 17px !important;
  }
  .ml-sp-17 {
    margin-left: 17px !important;
  }
  .ma-sp-17 {
    margin: 17px !important;
  }
  .mx-sp-17 {
    margin-left: 17px !important;
    margin-right: 17px !important;
  }
  .my-sp-17 {
    margin-top: 17px !important;
    margin-bottom: 17px !important;
  }
  .pl-sp-17 {
    padding-left: 17px !important;
  }
  .px-sp-17 {
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
  .py-sp-17 {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
  .pa-sp-17 {
    padding: 17px !important;
  }
  .mr-sp-17 {
    margin-right: 17px !important;
  }
  .ma-sp-17 {
    margin: 17px !important;
  }
  .mx-sp-17 {
    margin-left: 17px !important;
    margin-right: 17px !important;
  }
  .my-sp-17 {
    margin-top: 17px !important;
    margin-bottom: 17px !important;
  }
  .pr-sp-17 {
    padding-right: 17px !important;
  }
  .px-sp-17 {
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
  .py-sp-17 {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
  .pa-sp-17 {
    padding: 17px !important;
  }
  .mt-sp-20 {
    margin-top: 20px !important;
  }
  .ma-sp-20 {
    margin: 20px !important;
  }
  .mx-sp-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-sp-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .pt-sp-20 {
    padding-top: 20px !important;
  }
  .px-sp-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-sp-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pa-sp-20 {
    padding: 20px !important;
  }
  .mb-sp-20 {
    margin-bottom: 20px !important;
  }
  .ma-sp-20 {
    margin: 20px !important;
  }
  .mx-sp-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-sp-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .pb-sp-20 {
    padding-bottom: 20px !important;
  }
  .px-sp-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-sp-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pa-sp-20 {
    padding: 20px !important;
  }
  .ml-sp-20 {
    margin-left: 20px !important;
  }
  .ma-sp-20 {
    margin: 20px !important;
  }
  .mx-sp-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-sp-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .pl-sp-20 {
    padding-left: 20px !important;
  }
  .px-sp-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-sp-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pa-sp-20 {
    padding: 20px !important;
  }
  .mr-sp-20 {
    margin-right: 20px !important;
  }
  .ma-sp-20 {
    margin: 20px !important;
  }
  .mx-sp-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-sp-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .pr-sp-20 {
    padding-right: 20px !important;
  }
  .px-sp-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-sp-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pa-sp-20 {
    padding: 20px !important;
  }
  .mt-sp-22 {
    margin-top: 22px !important;
  }
  .ma-sp-22 {
    margin: 22px !important;
  }
  .mx-sp-22 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .my-sp-22 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .pt-sp-22 {
    padding-top: 22px !important;
  }
  .px-sp-22 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .py-sp-22 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .pa-sp-22 {
    padding: 22px !important;
  }
  .mb-sp-22 {
    margin-bottom: 22px !important;
  }
  .ma-sp-22 {
    margin: 22px !important;
  }
  .mx-sp-22 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .my-sp-22 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .pb-sp-22 {
    padding-bottom: 22px !important;
  }
  .px-sp-22 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .py-sp-22 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .pa-sp-22 {
    padding: 22px !important;
  }
  .ml-sp-22 {
    margin-left: 22px !important;
  }
  .ma-sp-22 {
    margin: 22px !important;
  }
  .mx-sp-22 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .my-sp-22 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .pl-sp-22 {
    padding-left: 22px !important;
  }
  .px-sp-22 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .py-sp-22 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .pa-sp-22 {
    padding: 22px !important;
  }
  .mr-sp-22 {
    margin-right: 22px !important;
  }
  .ma-sp-22 {
    margin: 22px !important;
  }
  .mx-sp-22 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .my-sp-22 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .pr-sp-22 {
    padding-right: 22px !important;
  }
  .px-sp-22 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .py-sp-22 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .pa-sp-22 {
    padding: 22px !important;
  }
  .mt-sp-25 {
    margin-top: 25px !important;
  }
  .ma-sp-25 {
    margin: 25px !important;
  }
  .mx-sp-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-sp-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .pt-sp-25 {
    padding-top: 25px !important;
  }
  .px-sp-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-sp-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pa-sp-25 {
    padding: 25px !important;
  }
  .mb-sp-25 {
    margin-bottom: 25px !important;
  }
  .ma-sp-25 {
    margin: 25px !important;
  }
  .mx-sp-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-sp-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .pb-sp-25 {
    padding-bottom: 25px !important;
  }
  .px-sp-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-sp-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pa-sp-25 {
    padding: 25px !important;
  }
  .ml-sp-25 {
    margin-left: 25px !important;
  }
  .ma-sp-25 {
    margin: 25px !important;
  }
  .mx-sp-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-sp-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .pl-sp-25 {
    padding-left: 25px !important;
  }
  .px-sp-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-sp-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pa-sp-25 {
    padding: 25px !important;
  }
  .mr-sp-25 {
    margin-right: 25px !important;
  }
  .ma-sp-25 {
    margin: 25px !important;
  }
  .mx-sp-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-sp-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .pr-sp-25 {
    padding-right: 25px !important;
  }
  .px-sp-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-sp-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pa-sp-25 {
    padding: 25px !important;
  }
  .mt-sp-28 {
    margin-top: 28px !important;
  }
  .ma-sp-28 {
    margin: 28px !important;
  }
  .mx-sp-28 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .my-sp-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .pt-sp-28 {
    padding-top: 28px !important;
  }
  .px-sp-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .py-sp-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pa-sp-28 {
    padding: 28px !important;
  }
  .mb-sp-28 {
    margin-bottom: 28px !important;
  }
  .ma-sp-28 {
    margin: 28px !important;
  }
  .mx-sp-28 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .my-sp-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .pb-sp-28 {
    padding-bottom: 28px !important;
  }
  .px-sp-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .py-sp-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pa-sp-28 {
    padding: 28px !important;
  }
  .ml-sp-28 {
    margin-left: 28px !important;
  }
  .ma-sp-28 {
    margin: 28px !important;
  }
  .mx-sp-28 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .my-sp-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .pl-sp-28 {
    padding-left: 28px !important;
  }
  .px-sp-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .py-sp-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pa-sp-28 {
    padding: 28px !important;
  }
  .mr-sp-28 {
    margin-right: 28px !important;
  }
  .ma-sp-28 {
    margin: 28px !important;
  }
  .mx-sp-28 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .my-sp-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .pr-sp-28 {
    padding-right: 28px !important;
  }
  .px-sp-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .py-sp-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pa-sp-28 {
    padding: 28px !important;
  }
  .mt-sp-30 {
    margin-top: 30px !important;
  }
  .ma-sp-30 {
    margin: 30px !important;
  }
  .mx-sp-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-sp-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .pt-sp-30 {
    padding-top: 30px !important;
  }
  .px-sp-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-sp-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pa-sp-30 {
    padding: 30px !important;
  }
  .mb-sp-30 {
    margin-bottom: 30px !important;
  }
  .ma-sp-30 {
    margin: 30px !important;
  }
  .mx-sp-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-sp-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .pb-sp-30 {
    padding-bottom: 30px !important;
  }
  .px-sp-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-sp-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pa-sp-30 {
    padding: 30px !important;
  }
  .ml-sp-30 {
    margin-left: 30px !important;
  }
  .ma-sp-30 {
    margin: 30px !important;
  }
  .mx-sp-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-sp-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .pl-sp-30 {
    padding-left: 30px !important;
  }
  .px-sp-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-sp-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pa-sp-30 {
    padding: 30px !important;
  }
  .mr-sp-30 {
    margin-right: 30px !important;
  }
  .ma-sp-30 {
    margin: 30px !important;
  }
  .mx-sp-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-sp-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .pr-sp-30 {
    padding-right: 30px !important;
  }
  .px-sp-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-sp-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pa-sp-30 {
    padding: 30px !important;
  }
  .mt-sp-35 {
    margin-top: 35px !important;
  }
  .ma-sp-35 {
    margin: 35px !important;
  }
  .mx-sp-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .my-sp-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .pt-sp-35 {
    padding-top: 35px !important;
  }
  .px-sp-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .py-sp-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pa-sp-35 {
    padding: 35px !important;
  }
  .mb-sp-35 {
    margin-bottom: 35px !important;
  }
  .ma-sp-35 {
    margin: 35px !important;
  }
  .mx-sp-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .my-sp-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .pb-sp-35 {
    padding-bottom: 35px !important;
  }
  .px-sp-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .py-sp-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pa-sp-35 {
    padding: 35px !important;
  }
  .ml-sp-35 {
    margin-left: 35px !important;
  }
  .ma-sp-35 {
    margin: 35px !important;
  }
  .mx-sp-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .my-sp-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .pl-sp-35 {
    padding-left: 35px !important;
  }
  .px-sp-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .py-sp-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pa-sp-35 {
    padding: 35px !important;
  }
  .mr-sp-35 {
    margin-right: 35px !important;
  }
  .ma-sp-35 {
    margin: 35px !important;
  }
  .mx-sp-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .my-sp-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .pr-sp-35 {
    padding-right: 35px !important;
  }
  .px-sp-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .py-sp-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pa-sp-35 {
    padding: 35px !important;
  }
  .mt-sp-40 {
    margin-top: 40px !important;
  }
  .ma-sp-40 {
    margin: 40px !important;
  }
  .mx-sp-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-sp-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .pt-sp-40 {
    padding-top: 40px !important;
  }
  .px-sp-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-sp-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pa-sp-40 {
    padding: 40px !important;
  }
  .mb-sp-40 {
    margin-bottom: 40px !important;
  }
  .ma-sp-40 {
    margin: 40px !important;
  }
  .mx-sp-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-sp-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .pb-sp-40 {
    padding-bottom: 40px !important;
  }
  .px-sp-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-sp-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pa-sp-40 {
    padding: 40px !important;
  }
  .ml-sp-40 {
    margin-left: 40px !important;
  }
  .ma-sp-40 {
    margin: 40px !important;
  }
  .mx-sp-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-sp-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .pl-sp-40 {
    padding-left: 40px !important;
  }
  .px-sp-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-sp-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pa-sp-40 {
    padding: 40px !important;
  }
  .mr-sp-40 {
    margin-right: 40px !important;
  }
  .ma-sp-40 {
    margin: 40px !important;
  }
  .mx-sp-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-sp-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .pr-sp-40 {
    padding-right: 40px !important;
  }
  .px-sp-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-sp-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pa-sp-40 {
    padding: 40px !important;
  }
  .mt-sp-45 {
    margin-top: 45px !important;
  }
  .ma-sp-45 {
    margin: 45px !important;
  }
  .mx-sp-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .my-sp-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .pt-sp-45 {
    padding-top: 45px !important;
  }
  .px-sp-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .py-sp-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pa-sp-45 {
    padding: 45px !important;
  }
  .mb-sp-45 {
    margin-bottom: 45px !important;
  }
  .ma-sp-45 {
    margin: 45px !important;
  }
  .mx-sp-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .my-sp-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .pb-sp-45 {
    padding-bottom: 45px !important;
  }
  .px-sp-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .py-sp-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pa-sp-45 {
    padding: 45px !important;
  }
  .ml-sp-45 {
    margin-left: 45px !important;
  }
  .ma-sp-45 {
    margin: 45px !important;
  }
  .mx-sp-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .my-sp-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .pl-sp-45 {
    padding-left: 45px !important;
  }
  .px-sp-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .py-sp-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pa-sp-45 {
    padding: 45px !important;
  }
  .mr-sp-45 {
    margin-right: 45px !important;
  }
  .ma-sp-45 {
    margin: 45px !important;
  }
  .mx-sp-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .my-sp-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .pr-sp-45 {
    padding-right: 45px !important;
  }
  .px-sp-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .py-sp-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pa-sp-45 {
    padding: 45px !important;
  }
  .mt-sp-50 {
    margin-top: 50px !important;
  }
  .ma-sp-50 {
    margin: 50px !important;
  }
  .mx-sp-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-sp-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .pt-sp-50 {
    padding-top: 50px !important;
  }
  .px-sp-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-sp-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pa-sp-50 {
    padding: 50px !important;
  }
  .mb-sp-50 {
    margin-bottom: 50px !important;
  }
  .ma-sp-50 {
    margin: 50px !important;
  }
  .mx-sp-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-sp-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .pb-sp-50 {
    padding-bottom: 50px !important;
  }
  .px-sp-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-sp-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pa-sp-50 {
    padding: 50px !important;
  }
  .ml-sp-50 {
    margin-left: 50px !important;
  }
  .ma-sp-50 {
    margin: 50px !important;
  }
  .mx-sp-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-sp-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .pl-sp-50 {
    padding-left: 50px !important;
  }
  .px-sp-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-sp-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pa-sp-50 {
    padding: 50px !important;
  }
  .mr-sp-50 {
    margin-right: 50px !important;
  }
  .ma-sp-50 {
    margin: 50px !important;
  }
  .mx-sp-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-sp-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .pr-sp-50 {
    padding-right: 50px !important;
  }
  .px-sp-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-sp-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pa-sp-50 {
    padding: 50px !important;
  }
  .mt-sp-55 {
    margin-top: 55px !important;
  }
  .ma-sp-55 {
    margin: 55px !important;
  }
  .mx-sp-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .my-sp-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .pt-sp-55 {
    padding-top: 55px !important;
  }
  .px-sp-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .py-sp-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pa-sp-55 {
    padding: 55px !important;
  }
  .mb-sp-55 {
    margin-bottom: 55px !important;
  }
  .ma-sp-55 {
    margin: 55px !important;
  }
  .mx-sp-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .my-sp-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .pb-sp-55 {
    padding-bottom: 55px !important;
  }
  .px-sp-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .py-sp-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pa-sp-55 {
    padding: 55px !important;
  }
  .ml-sp-55 {
    margin-left: 55px !important;
  }
  .ma-sp-55 {
    margin: 55px !important;
  }
  .mx-sp-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .my-sp-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .pl-sp-55 {
    padding-left: 55px !important;
  }
  .px-sp-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .py-sp-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pa-sp-55 {
    padding: 55px !important;
  }
  .mr-sp-55 {
    margin-right: 55px !important;
  }
  .ma-sp-55 {
    margin: 55px !important;
  }
  .mx-sp-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .my-sp-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .pr-sp-55 {
    padding-right: 55px !important;
  }
  .px-sp-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .py-sp-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pa-sp-55 {
    padding: 55px !important;
  }
  .mt-sp-60 {
    margin-top: 60px !important;
  }
  .ma-sp-60 {
    margin: 60px !important;
  }
  .mx-sp-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-sp-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .pt-sp-60 {
    padding-top: 60px !important;
  }
  .px-sp-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-sp-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pa-sp-60 {
    padding: 60px !important;
  }
  .mb-sp-60 {
    margin-bottom: 60px !important;
  }
  .ma-sp-60 {
    margin: 60px !important;
  }
  .mx-sp-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-sp-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .pb-sp-60 {
    padding-bottom: 60px !important;
  }
  .px-sp-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-sp-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pa-sp-60 {
    padding: 60px !important;
  }
  .ml-sp-60 {
    margin-left: 60px !important;
  }
  .ma-sp-60 {
    margin: 60px !important;
  }
  .mx-sp-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-sp-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .pl-sp-60 {
    padding-left: 60px !important;
  }
  .px-sp-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-sp-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pa-sp-60 {
    padding: 60px !important;
  }
  .mr-sp-60 {
    margin-right: 60px !important;
  }
  .ma-sp-60 {
    margin: 60px !important;
  }
  .mx-sp-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-sp-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .pr-sp-60 {
    padding-right: 60px !important;
  }
  .px-sp-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-sp-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pa-sp-60 {
    padding: 60px !important;
  }
  .mt-sp-65 {
    margin-top: 65px !important;
  }
  .ma-sp-65 {
    margin: 65px !important;
  }
  .mx-sp-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .my-sp-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .pt-sp-65 {
    padding-top: 65px !important;
  }
  .px-sp-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .py-sp-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pa-sp-65 {
    padding: 65px !important;
  }
  .mb-sp-65 {
    margin-bottom: 65px !important;
  }
  .ma-sp-65 {
    margin: 65px !important;
  }
  .mx-sp-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .my-sp-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .pb-sp-65 {
    padding-bottom: 65px !important;
  }
  .px-sp-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .py-sp-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pa-sp-65 {
    padding: 65px !important;
  }
  .ml-sp-65 {
    margin-left: 65px !important;
  }
  .ma-sp-65 {
    margin: 65px !important;
  }
  .mx-sp-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .my-sp-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .pl-sp-65 {
    padding-left: 65px !important;
  }
  .px-sp-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .py-sp-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pa-sp-65 {
    padding: 65px !important;
  }
  .mr-sp-65 {
    margin-right: 65px !important;
  }
  .ma-sp-65 {
    margin: 65px !important;
  }
  .mx-sp-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .my-sp-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .pr-sp-65 {
    padding-right: 65px !important;
  }
  .px-sp-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .py-sp-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pa-sp-65 {
    padding: 65px !important;
  }
  .mt-sp-70 {
    margin-top: 70px !important;
  }
  .ma-sp-70 {
    margin: 70px !important;
  }
  .mx-sp-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-sp-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .pt-sp-70 {
    padding-top: 70px !important;
  }
  .px-sp-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-sp-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pa-sp-70 {
    padding: 70px !important;
  }
  .mb-sp-70 {
    margin-bottom: 70px !important;
  }
  .ma-sp-70 {
    margin: 70px !important;
  }
  .mx-sp-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-sp-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .pb-sp-70 {
    padding-bottom: 70px !important;
  }
  .px-sp-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-sp-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pa-sp-70 {
    padding: 70px !important;
  }
  .ml-sp-70 {
    margin-left: 70px !important;
  }
  .ma-sp-70 {
    margin: 70px !important;
  }
  .mx-sp-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-sp-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .pl-sp-70 {
    padding-left: 70px !important;
  }
  .px-sp-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-sp-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pa-sp-70 {
    padding: 70px !important;
  }
  .mr-sp-70 {
    margin-right: 70px !important;
  }
  .ma-sp-70 {
    margin: 70px !important;
  }
  .mx-sp-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-sp-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .pr-sp-70 {
    padding-right: 70px !important;
  }
  .px-sp-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-sp-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pa-sp-70 {
    padding: 70px !important;
  }
  .mt-sp-75 {
    margin-top: 75px !important;
  }
  .ma-sp-75 {
    margin: 75px !important;
  }
  .mx-sp-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .my-sp-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .pt-sp-75 {
    padding-top: 75px !important;
  }
  .px-sp-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .py-sp-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pa-sp-75 {
    padding: 75px !important;
  }
  .mb-sp-75 {
    margin-bottom: 75px !important;
  }
  .ma-sp-75 {
    margin: 75px !important;
  }
  .mx-sp-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .my-sp-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .pb-sp-75 {
    padding-bottom: 75px !important;
  }
  .px-sp-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .py-sp-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pa-sp-75 {
    padding: 75px !important;
  }
  .ml-sp-75 {
    margin-left: 75px !important;
  }
  .ma-sp-75 {
    margin: 75px !important;
  }
  .mx-sp-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .my-sp-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .pl-sp-75 {
    padding-left: 75px !important;
  }
  .px-sp-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .py-sp-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pa-sp-75 {
    padding: 75px !important;
  }
  .mr-sp-75 {
    margin-right: 75px !important;
  }
  .ma-sp-75 {
    margin: 75px !important;
  }
  .mx-sp-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .my-sp-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .pr-sp-75 {
    padding-right: 75px !important;
  }
  .px-sp-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .py-sp-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pa-sp-75 {
    padding: 75px !important;
  }
  .mt-sp-80 {
    margin-top: 80px !important;
  }
  .ma-sp-80 {
    margin: 80px !important;
  }
  .mx-sp-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-sp-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .pt-sp-80 {
    padding-top: 80px !important;
  }
  .px-sp-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-sp-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pa-sp-80 {
    padding: 80px !important;
  }
  .mb-sp-80 {
    margin-bottom: 80px !important;
  }
  .ma-sp-80 {
    margin: 80px !important;
  }
  .mx-sp-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-sp-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .pb-sp-80 {
    padding-bottom: 80px !important;
  }
  .px-sp-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-sp-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pa-sp-80 {
    padding: 80px !important;
  }
  .ml-sp-80 {
    margin-left: 80px !important;
  }
  .ma-sp-80 {
    margin: 80px !important;
  }
  .mx-sp-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-sp-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .pl-sp-80 {
    padding-left: 80px !important;
  }
  .px-sp-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-sp-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pa-sp-80 {
    padding: 80px !important;
  }
  .mr-sp-80 {
    margin-right: 80px !important;
  }
  .ma-sp-80 {
    margin: 80px !important;
  }
  .mx-sp-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-sp-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .pr-sp-80 {
    padding-right: 80px !important;
  }
  .px-sp-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-sp-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pa-sp-80 {
    padding: 80px !important;
  }
  .mt-sp-85 {
    margin-top: 85px !important;
  }
  .ma-sp-85 {
    margin: 85px !important;
  }
  .mx-sp-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .my-sp-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .pt-sp-85 {
    padding-top: 85px !important;
  }
  .px-sp-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .py-sp-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pa-sp-85 {
    padding: 85px !important;
  }
  .mb-sp-85 {
    margin-bottom: 85px !important;
  }
  .ma-sp-85 {
    margin: 85px !important;
  }
  .mx-sp-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .my-sp-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .pb-sp-85 {
    padding-bottom: 85px !important;
  }
  .px-sp-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .py-sp-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pa-sp-85 {
    padding: 85px !important;
  }
  .ml-sp-85 {
    margin-left: 85px !important;
  }
  .ma-sp-85 {
    margin: 85px !important;
  }
  .mx-sp-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .my-sp-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .pl-sp-85 {
    padding-left: 85px !important;
  }
  .px-sp-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .py-sp-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pa-sp-85 {
    padding: 85px !important;
  }
  .mr-sp-85 {
    margin-right: 85px !important;
  }
  .ma-sp-85 {
    margin: 85px !important;
  }
  .mx-sp-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .my-sp-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .pr-sp-85 {
    padding-right: 85px !important;
  }
  .px-sp-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .py-sp-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pa-sp-85 {
    padding: 85px !important;
  }
  .mt-sp-90 {
    margin-top: 90px !important;
  }
  .ma-sp-90 {
    margin: 90px !important;
  }
  .mx-sp-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-sp-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .pt-sp-90 {
    padding-top: 90px !important;
  }
  .px-sp-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-sp-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pa-sp-90 {
    padding: 90px !important;
  }
  .mb-sp-90 {
    margin-bottom: 90px !important;
  }
  .ma-sp-90 {
    margin: 90px !important;
  }
  .mx-sp-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-sp-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .pb-sp-90 {
    padding-bottom: 90px !important;
  }
  .px-sp-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-sp-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pa-sp-90 {
    padding: 90px !important;
  }
  .ml-sp-90 {
    margin-left: 90px !important;
  }
  .ma-sp-90 {
    margin: 90px !important;
  }
  .mx-sp-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-sp-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .pl-sp-90 {
    padding-left: 90px !important;
  }
  .px-sp-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-sp-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pa-sp-90 {
    padding: 90px !important;
  }
  .mr-sp-90 {
    margin-right: 90px !important;
  }
  .ma-sp-90 {
    margin: 90px !important;
  }
  .mx-sp-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-sp-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .pr-sp-90 {
    padding-right: 90px !important;
  }
  .px-sp-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-sp-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pa-sp-90 {
    padding: 90px !important;
  }
  .mt-sp-95 {
    margin-top: 95px !important;
  }
  .ma-sp-95 {
    margin: 95px !important;
  }
  .mx-sp-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .my-sp-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .pt-sp-95 {
    padding-top: 95px !important;
  }
  .px-sp-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .py-sp-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pa-sp-95 {
    padding: 95px !important;
  }
  .mb-sp-95 {
    margin-bottom: 95px !important;
  }
  .ma-sp-95 {
    margin: 95px !important;
  }
  .mx-sp-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .my-sp-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .pb-sp-95 {
    padding-bottom: 95px !important;
  }
  .px-sp-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .py-sp-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pa-sp-95 {
    padding: 95px !important;
  }
  .ml-sp-95 {
    margin-left: 95px !important;
  }
  .ma-sp-95 {
    margin: 95px !important;
  }
  .mx-sp-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .my-sp-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .pl-sp-95 {
    padding-left: 95px !important;
  }
  .px-sp-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .py-sp-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pa-sp-95 {
    padding: 95px !important;
  }
  .mr-sp-95 {
    margin-right: 95px !important;
  }
  .ma-sp-95 {
    margin: 95px !important;
  }
  .mx-sp-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .my-sp-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .pr-sp-95 {
    padding-right: 95px !important;
  }
  .px-sp-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .py-sp-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pa-sp-95 {
    padding: 95px !important;
  }
  .mt-sp-100 {
    margin-top: 100px !important;
  }
  .ma-sp-100 {
    margin: 100px !important;
  }
  .mx-sp-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-sp-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .pt-sp-100 {
    padding-top: 100px !important;
  }
  .px-sp-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-sp-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pa-sp-100 {
    padding: 100px !important;
  }
  .mb-sp-100 {
    margin-bottom: 100px !important;
  }
  .ma-sp-100 {
    margin: 100px !important;
  }
  .mx-sp-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-sp-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .pb-sp-100 {
    padding-bottom: 100px !important;
  }
  .px-sp-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-sp-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pa-sp-100 {
    padding: 100px !important;
  }
  .ml-sp-100 {
    margin-left: 100px !important;
  }
  .ma-sp-100 {
    margin: 100px !important;
  }
  .mx-sp-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-sp-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .pl-sp-100 {
    padding-left: 100px !important;
  }
  .px-sp-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-sp-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pa-sp-100 {
    padding: 100px !important;
  }
  .mr-sp-100 {
    margin-right: 100px !important;
  }
  .ma-sp-100 {
    margin: 100px !important;
  }
  .mx-sp-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-sp-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .pr-sp-100 {
    padding-right: 100px !important;
  }
  .px-sp-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-sp-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pa-sp-100 {
    padding: 100px !important;
  }
  .mt-sp-110 {
    margin-top: 110px !important;
  }
  .ma-sp-110 {
    margin: 110px !important;
  }
  .mx-sp-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .my-sp-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .pt-sp-110 {
    padding-top: 110px !important;
  }
  .px-sp-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .py-sp-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pa-sp-110 {
    padding: 110px !important;
  }
  .mb-sp-110 {
    margin-bottom: 110px !important;
  }
  .ma-sp-110 {
    margin: 110px !important;
  }
  .mx-sp-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .my-sp-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .pb-sp-110 {
    padding-bottom: 110px !important;
  }
  .px-sp-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .py-sp-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pa-sp-110 {
    padding: 110px !important;
  }
  .ml-sp-110 {
    margin-left: 110px !important;
  }
  .ma-sp-110 {
    margin: 110px !important;
  }
  .mx-sp-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .my-sp-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .pl-sp-110 {
    padding-left: 110px !important;
  }
  .px-sp-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .py-sp-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pa-sp-110 {
    padding: 110px !important;
  }
  .mr-sp-110 {
    margin-right: 110px !important;
  }
  .ma-sp-110 {
    margin: 110px !important;
  }
  .mx-sp-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .my-sp-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .pr-sp-110 {
    padding-right: 110px !important;
  }
  .px-sp-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .py-sp-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pa-sp-110 {
    padding: 110px !important;
  }
  .mt-sp-115 {
    margin-top: 115px !important;
  }
  .ma-sp-115 {
    margin: 115px !important;
  }
  .mx-sp-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .my-sp-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .pt-sp-115 {
    padding-top: 115px !important;
  }
  .px-sp-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .py-sp-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .pa-sp-115 {
    padding: 115px !important;
  }
  .mb-sp-115 {
    margin-bottom: 115px !important;
  }
  .ma-sp-115 {
    margin: 115px !important;
  }
  .mx-sp-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .my-sp-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .pb-sp-115 {
    padding-bottom: 115px !important;
  }
  .px-sp-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .py-sp-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .pa-sp-115 {
    padding: 115px !important;
  }
  .ml-sp-115 {
    margin-left: 115px !important;
  }
  .ma-sp-115 {
    margin: 115px !important;
  }
  .mx-sp-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .my-sp-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .pl-sp-115 {
    padding-left: 115px !important;
  }
  .px-sp-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .py-sp-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .pa-sp-115 {
    padding: 115px !important;
  }
  .mr-sp-115 {
    margin-right: 115px !important;
  }
  .ma-sp-115 {
    margin: 115px !important;
  }
  .mx-sp-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .my-sp-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .pr-sp-115 {
    padding-right: 115px !important;
  }
  .px-sp-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .py-sp-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .pa-sp-115 {
    padding: 115px !important;
  }
  .mt-sp-120 {
    margin-top: 120px !important;
  }
  .ma-sp-120 {
    margin: 120px !important;
  }
  .mx-sp-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-sp-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .pt-sp-120 {
    padding-top: 120px !important;
  }
  .px-sp-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-sp-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pa-sp-120 {
    padding: 120px !important;
  }
  .mb-sp-120 {
    margin-bottom: 120px !important;
  }
  .ma-sp-120 {
    margin: 120px !important;
  }
  .mx-sp-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-sp-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .pb-sp-120 {
    padding-bottom: 120px !important;
  }
  .px-sp-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-sp-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pa-sp-120 {
    padding: 120px !important;
  }
  .ml-sp-120 {
    margin-left: 120px !important;
  }
  .ma-sp-120 {
    margin: 120px !important;
  }
  .mx-sp-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-sp-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .pl-sp-120 {
    padding-left: 120px !important;
  }
  .px-sp-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-sp-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pa-sp-120 {
    padding: 120px !important;
  }
  .mr-sp-120 {
    margin-right: 120px !important;
  }
  .ma-sp-120 {
    margin: 120px !important;
  }
  .mx-sp-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-sp-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .pr-sp-120 {
    padding-right: 120px !important;
  }
  .px-sp-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-sp-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pa-sp-120 {
    padding: 120px !important;
  }
  .mt-sp-150 {
    margin-top: 150px !important;
  }
  .ma-sp-150 {
    margin: 150px !important;
  }
  .mx-sp-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .my-sp-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .pt-sp-150 {
    padding-top: 150px !important;
  }
  .px-sp-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .py-sp-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pa-sp-150 {
    padding: 150px !important;
  }
  .mb-sp-150 {
    margin-bottom: 150px !important;
  }
  .ma-sp-150 {
    margin: 150px !important;
  }
  .mx-sp-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .my-sp-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .pb-sp-150 {
    padding-bottom: 150px !important;
  }
  .px-sp-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .py-sp-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pa-sp-150 {
    padding: 150px !important;
  }
  .ml-sp-150 {
    margin-left: 150px !important;
  }
  .ma-sp-150 {
    margin: 150px !important;
  }
  .mx-sp-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .my-sp-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .pl-sp-150 {
    padding-left: 150px !important;
  }
  .px-sp-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .py-sp-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pa-sp-150 {
    padding: 150px !important;
  }
  .mr-sp-150 {
    margin-right: 150px !important;
  }
  .ma-sp-150 {
    margin: 150px !important;
  }
  .mx-sp-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .my-sp-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .pr-sp-150 {
    padding-right: 150px !important;
  }
  .px-sp-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .py-sp-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pa-sp-150 {
    padding: 150px !important;
  }
  .mt-sp-305 {
    margin-top: 305px !important;
  }
  .ma-sp-305 {
    margin: 305px !important;
  }
  .mx-sp-305 {
    margin-left: 305px !important;
    margin-right: 305px !important;
  }
  .my-sp-305 {
    margin-top: 305px !important;
    margin-bottom: 305px !important;
  }
  .pt-sp-305 {
    padding-top: 305px !important;
  }
  .px-sp-305 {
    padding-left: 305px !important;
    padding-right: 305px !important;
  }
  .py-sp-305 {
    padding-top: 305px !important;
    padding-bottom: 305px !important;
  }
  .pa-sp-305 {
    padding: 305px !important;
  }
  .mb-sp-305 {
    margin-bottom: 305px !important;
  }
  .ma-sp-305 {
    margin: 305px !important;
  }
  .mx-sp-305 {
    margin-left: 305px !important;
    margin-right: 305px !important;
  }
  .my-sp-305 {
    margin-top: 305px !important;
    margin-bottom: 305px !important;
  }
  .pb-sp-305 {
    padding-bottom: 305px !important;
  }
  .px-sp-305 {
    padding-left: 305px !important;
    padding-right: 305px !important;
  }
  .py-sp-305 {
    padding-top: 305px !important;
    padding-bottom: 305px !important;
  }
  .pa-sp-305 {
    padding: 305px !important;
  }
  .ml-sp-305 {
    margin-left: 305px !important;
  }
  .ma-sp-305 {
    margin: 305px !important;
  }
  .mx-sp-305 {
    margin-left: 305px !important;
    margin-right: 305px !important;
  }
  .my-sp-305 {
    margin-top: 305px !important;
    margin-bottom: 305px !important;
  }
  .pl-sp-305 {
    padding-left: 305px !important;
  }
  .px-sp-305 {
    padding-left: 305px !important;
    padding-right: 305px !important;
  }
  .py-sp-305 {
    padding-top: 305px !important;
    padding-bottom: 305px !important;
  }
  .pa-sp-305 {
    padding: 305px !important;
  }
  .mr-sp-305 {
    margin-right: 305px !important;
  }
  .ma-sp-305 {
    margin: 305px !important;
  }
  .mx-sp-305 {
    margin-left: 305px !important;
    margin-right: 305px !important;
  }
  .my-sp-305 {
    margin-top: 305px !important;
    margin-bottom: 305px !important;
  }
  .pr-sp-305 {
    padding-right: 305px !important;
  }
  .px-sp-305 {
    padding-left: 305px !important;
    padding-right: 305px !important;
  }
  .py-sp-305 {
    padding-top: 305px !important;
    padding-bottom: 305px !important;
  }
  .pa-sp-305 {
    padding: 305px !important;
  }
}

.ma-0 {
  margin: 0 auto !important;
}

@media (min-width: 897px) {
  .ma-pc-0 {
    margin: 0 auto !important;
  }
}

@media (max-width: 896px) {
  .ma-sp-0 {
    margin: 0 auto !important;
  }
}

/* ==========================================================
 color
========================================================== */
/* ==========================================================
 breakpoint
========================================================== */
/* ==========================================================
 font
========================================================== */
/* ==========================================================
 Text align
========================================================== */
.txt-left {
  text-align: left !important;
}

.txt-center {
  text-align: center !important;
}

.txt-right {
  text-align: right !important;
}

@media (min-width: 897px) {
  .txt-pc-left {
    text-align: left !important;
  }
  .txt-pc-center {
    text-align: center !important;
  }
  .txt-pc-right {
    text-align: right !important;
  }
}

@media (max-width: 896px) {
  .txt-sp-left {
    text-align: left !important;
  }
  .txt-sp-center {
    text-align: center !important;
  }
  .txt-sp-right {
    text-align: right !important;
  }
}

/* ==========================================================
 Text weight
========================================================== */
.txt-normal {
  font-weight: normal !important;
}

.txt-bold {
  font-weight: bold !important;
}

/* ==========================================================
 Text color
========================================================== */
.txt-blue {
  color: #2157a7 !important;
}

.txt-red {
  color: #ff0000 !important;
}

.txt-orange {
  color: #f93b00;
}

.txt-black {
  color: #111 !important;
}

.txt-white {
  color: #fff !important;
}

/* ==========================================================
 Text size
========================================================== */
.txt-size-8 {
  font-size: 0.8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-8 {
    font-size: 0.8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-8 {
    font-size: 0.8rem !important;
  }
}

.txt-size-9 {
  font-size: 0.9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-9 {
    font-size: 0.9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-9 {
    font-size: 0.9rem !important;
  }
}

.txt-size-10 {
  font-size: 1rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-10 {
    font-size: 1rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-10 {
    font-size: 1rem !important;
  }
}

.txt-size-11 {
  font-size: 1.1rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-11 {
    font-size: 1.1rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-11 {
    font-size: 1.1rem !important;
  }
}

.txt-size-12 {
  font-size: 1.2rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-12 {
    font-size: 1.2rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-12 {
    font-size: 1.2rem !important;
  }
}

.txt-size-13 {
  font-size: 1.3rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-13 {
    font-size: 1.3rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-13 {
    font-size: 1.3rem !important;
  }
}

.txt-size-14 {
  font-size: 1.4rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-14 {
    font-size: 1.4rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-14 {
    font-size: 1.4rem !important;
  }
}

.txt-size-15 {
  font-size: 1.5rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-15 {
    font-size: 1.5rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-15 {
    font-size: 1.5rem !important;
  }
}

.txt-size-16 {
  font-size: 1.6rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-16 {
    font-size: 1.6rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-16 {
    font-size: 1.6rem !important;
  }
}

.txt-size-17 {
  font-size: 1.7rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-17 {
    font-size: 1.7rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-17 {
    font-size: 1.7rem !important;
  }
}

.txt-size-18 {
  font-size: 1.8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-18 {
    font-size: 1.8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-18 {
    font-size: 1.8rem !important;
  }
}

.txt-size-19 {
  font-size: 1.9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-19 {
    font-size: 1.9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-19 {
    font-size: 1.9rem !important;
  }
}

.txt-size-20 {
  font-size: 2rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-20 {
    font-size: 2rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-20 {
    font-size: 2rem !important;
  }
}

.txt-size-21 {
  font-size: 2.1rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-21 {
    font-size: 2.1rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-21 {
    font-size: 2.1rem !important;
  }
}

.txt-size-22 {
  font-size: 2.2rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-22 {
    font-size: 2.2rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-22 {
    font-size: 2.2rem !important;
  }
}

.txt-size-23 {
  font-size: 2.3rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-23 {
    font-size: 2.3rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-23 {
    font-size: 2.3rem !important;
  }
}

.txt-size-24 {
  font-size: 2.4rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-24 {
    font-size: 2.4rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-24 {
    font-size: 2.4rem !important;
  }
}

.txt-size-25 {
  font-size: 2.5rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-25 {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-25 {
    font-size: 2.5rem !important;
  }
}

.txt-size-26 {
  font-size: 2.6rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-26 {
    font-size: 2.6rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-26 {
    font-size: 2.6rem !important;
  }
}

.txt-size-27 {
  font-size: 2.7rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-27 {
    font-size: 2.7rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-27 {
    font-size: 2.7rem !important;
  }
}

.txt-size-28 {
  font-size: 2.8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-28 {
    font-size: 2.8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-28 {
    font-size: 2.8rem !important;
  }
}

.txt-size-29 {
  font-size: 2.9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-29 {
    font-size: 2.9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-29 {
    font-size: 2.9rem !important;
  }
}

.txt-size-30 {
  font-size: 3rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-30 {
    font-size: 3rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-30 {
    font-size: 3rem !important;
  }
}

.txt-size-31 {
  font-size: 3.1rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-31 {
    font-size: 3.1rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-31 {
    font-size: 3.1rem !important;
  }
}

.txt-size-32 {
  font-size: 3.2rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-32 {
    font-size: 3.2rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-32 {
    font-size: 3.2rem !important;
  }
}

.txt-size-33 {
  font-size: 3.3rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-33 {
    font-size: 3.3rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-33 {
    font-size: 3.3rem !important;
  }
}

.txt-size-34 {
  font-size: 3.4rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-34 {
    font-size: 3.4rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-34 {
    font-size: 3.4rem !important;
  }
}

.txt-size-35 {
  font-size: 3.5rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-35 {
    font-size: 3.5rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-35 {
    font-size: 3.5rem !important;
  }
}

.txt-size-36 {
  font-size: 3.6rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-36 {
    font-size: 3.6rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-36 {
    font-size: 3.6rem !important;
  }
}

.txt-size-37 {
  font-size: 3.7rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-37 {
    font-size: 3.7rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-37 {
    font-size: 3.7rem !important;
  }
}

.txt-size-38 {
  font-size: 3.8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-38 {
    font-size: 3.8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-38 {
    font-size: 3.8rem !important;
  }
}

.txt-size-39 {
  font-size: 3.9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-39 {
    font-size: 3.9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-39 {
    font-size: 3.9rem !important;
  }
}

.txt-size-40 {
  font-size: 4rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-40 {
    font-size: 4rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-40 {
    font-size: 4rem !important;
  }
}

.txt-size-41 {
  font-size: 4.1rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-41 {
    font-size: 4.1rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-41 {
    font-size: 4.1rem !important;
  }
}

.txt-size-42 {
  font-size: 4.2rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-42 {
    font-size: 4.2rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-42 {
    font-size: 4.2rem !important;
  }
}

.txt-size-43 {
  font-size: 4.3rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-43 {
    font-size: 4.3rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-43 {
    font-size: 4.3rem !important;
  }
}

.txt-size-44 {
  font-size: 4.4rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-44 {
    font-size: 4.4rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-44 {
    font-size: 4.4rem !important;
  }
}

.txt-size-45 {
  font-size: 4.5rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-45 {
    font-size: 4.5rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-45 {
    font-size: 4.5rem !important;
  }
}

.txt-size-46 {
  font-size: 4.6rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-46 {
    font-size: 4.6rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-46 {
    font-size: 4.6rem !important;
  }
}

.txt-size-47 {
  font-size: 4.7rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-47 {
    font-size: 4.7rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-47 {
    font-size: 4.7rem !important;
  }
}

.txt-size-48 {
  font-size: 4.8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-48 {
    font-size: 4.8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-48 {
    font-size: 4.8rem !important;
  }
}

.txt-size-49 {
  font-size: 4.9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-49 {
    font-size: 4.9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-49 {
    font-size: 4.9rem !important;
  }
}

.txt-size-50 {
  font-size: 5rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-50 {
    font-size: 5rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-50 {
    font-size: 5rem !important;
  }
}

.txt-size-51 {
  font-size: 5.1rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-51 {
    font-size: 5.1rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-51 {
    font-size: 5.1rem !important;
  }
}

.txt-size-52 {
  font-size: 5.2rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-52 {
    font-size: 5.2rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-52 {
    font-size: 5.2rem !important;
  }
}

.txt-size-53 {
  font-size: 5.3rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-53 {
    font-size: 5.3rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-53 {
    font-size: 5.3rem !important;
  }
}

.txt-size-54 {
  font-size: 5.4rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-54 {
    font-size: 5.4rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-54 {
    font-size: 5.4rem !important;
  }
}

.txt-size-55 {
  font-size: 5.5rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-55 {
    font-size: 5.5rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-55 {
    font-size: 5.5rem !important;
  }
}

.txt-size-56 {
  font-size: 5.6rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-56 {
    font-size: 5.6rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-56 {
    font-size: 5.6rem !important;
  }
}

.txt-size-57 {
  font-size: 5.7rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-57 {
    font-size: 5.7rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-57 {
    font-size: 5.7rem !important;
  }
}

.txt-size-58 {
  font-size: 5.8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-58 {
    font-size: 5.8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-58 {
    font-size: 5.8rem !important;
  }
}

.txt-size-59 {
  font-size: 5.9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-59 {
    font-size: 5.9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-59 {
    font-size: 5.9rem !important;
  }
}

.txt-size-60 {
  font-size: 6rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-60 {
    font-size: 6rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-60 {
    font-size: 6rem !important;
  }
}

.txt-size-61 {
  font-size: 6.1rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-61 {
    font-size: 6.1rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-61 {
    font-size: 6.1rem !important;
  }
}

.txt-size-62 {
  font-size: 6.2rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-62 {
    font-size: 6.2rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-62 {
    font-size: 6.2rem !important;
  }
}

.txt-size-63 {
  font-size: 6.3rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-63 {
    font-size: 6.3rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-63 {
    font-size: 6.3rem !important;
  }
}

.txt-size-64 {
  font-size: 6.4rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-64 {
    font-size: 6.4rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-64 {
    font-size: 6.4rem !important;
  }
}

.txt-size-65 {
  font-size: 6.5rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-65 {
    font-size: 6.5rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-65 {
    font-size: 6.5rem !important;
  }
}

.txt-size-66 {
  font-size: 6.6rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-66 {
    font-size: 6.6rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-66 {
    font-size: 6.6rem !important;
  }
}

.txt-size-67 {
  font-size: 6.7rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-67 {
    font-size: 6.7rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-67 {
    font-size: 6.7rem !important;
  }
}

.txt-size-68 {
  font-size: 6.8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-68 {
    font-size: 6.8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-68 {
    font-size: 6.8rem !important;
  }
}

.txt-size-69 {
  font-size: 6.9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-69 {
    font-size: 6.9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-69 {
    font-size: 6.9rem !important;
  }
}

.txt-size-70 {
  font-size: 7rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-70 {
    font-size: 7rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-70 {
    font-size: 7rem !important;
  }
}

.txt-size-71 {
  font-size: 7.1rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-71 {
    font-size: 7.1rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-71 {
    font-size: 7.1rem !important;
  }
}

.txt-size-72 {
  font-size: 7.2rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-72 {
    font-size: 7.2rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-72 {
    font-size: 7.2rem !important;
  }
}

.txt-size-73 {
  font-size: 7.3rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-73 {
    font-size: 7.3rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-73 {
    font-size: 7.3rem !important;
  }
}

.txt-size-74 {
  font-size: 7.4rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-74 {
    font-size: 7.4rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-74 {
    font-size: 7.4rem !important;
  }
}

.txt-size-75 {
  font-size: 7.5rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-75 {
    font-size: 7.5rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-75 {
    font-size: 7.5rem !important;
  }
}

.txt-size-76 {
  font-size: 7.6rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-76 {
    font-size: 7.6rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-76 {
    font-size: 7.6rem !important;
  }
}

.txt-size-77 {
  font-size: 7.7rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-77 {
    font-size: 7.7rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-77 {
    font-size: 7.7rem !important;
  }
}

.txt-size-78 {
  font-size: 7.8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-78 {
    font-size: 7.8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-78 {
    font-size: 7.8rem !important;
  }
}

.txt-size-79 {
  font-size: 7.9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-79 {
    font-size: 7.9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-79 {
    font-size: 7.9rem !important;
  }
}

.txt-size-80 {
  font-size: 8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-80 {
    font-size: 8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-80 {
    font-size: 8rem !important;
  }
}

.txt-size-81 {
  font-size: 8.1rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-81 {
    font-size: 8.1rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-81 {
    font-size: 8.1rem !important;
  }
}

.txt-size-82 {
  font-size: 8.2rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-82 {
    font-size: 8.2rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-82 {
    font-size: 8.2rem !important;
  }
}

.txt-size-83 {
  font-size: 8.3rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-83 {
    font-size: 8.3rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-83 {
    font-size: 8.3rem !important;
  }
}

.txt-size-84 {
  font-size: 8.4rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-84 {
    font-size: 8.4rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-84 {
    font-size: 8.4rem !important;
  }
}

.txt-size-85 {
  font-size: 8.5rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-85 {
    font-size: 8.5rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-85 {
    font-size: 8.5rem !important;
  }
}

.txt-size-86 {
  font-size: 8.6rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-86 {
    font-size: 8.6rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-86 {
    font-size: 8.6rem !important;
  }
}

.txt-size-87 {
  font-size: 8.7rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-87 {
    font-size: 8.7rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-87 {
    font-size: 8.7rem !important;
  }
}

.txt-size-88 {
  font-size: 8.8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-88 {
    font-size: 8.8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-88 {
    font-size: 8.8rem !important;
  }
}

.txt-size-89 {
  font-size: 8.9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-89 {
    font-size: 8.9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-89 {
    font-size: 8.9rem !important;
  }
}

.txt-size-90 {
  font-size: 9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-90 {
    font-size: 9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-90 {
    font-size: 9rem !important;
  }
}

.txt-size-91 {
  font-size: 9.1rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-91 {
    font-size: 9.1rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-91 {
    font-size: 9.1rem !important;
  }
}

.txt-size-92 {
  font-size: 9.2rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-92 {
    font-size: 9.2rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-92 {
    font-size: 9.2rem !important;
  }
}

.txt-size-93 {
  font-size: 9.3rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-93 {
    font-size: 9.3rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-93 {
    font-size: 9.3rem !important;
  }
}

.txt-size-94 {
  font-size: 9.4rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-94 {
    font-size: 9.4rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-94 {
    font-size: 9.4rem !important;
  }
}

.txt-size-95 {
  font-size: 9.5rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-95 {
    font-size: 9.5rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-95 {
    font-size: 9.5rem !important;
  }
}

.txt-size-96 {
  font-size: 9.6rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-96 {
    font-size: 9.6rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-96 {
    font-size: 9.6rem !important;
  }
}

.txt-size-97 {
  font-size: 9.7rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-97 {
    font-size: 9.7rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-97 {
    font-size: 9.7rem !important;
  }
}

.txt-size-98 {
  font-size: 9.8rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-98 {
    font-size: 9.8rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-98 {
    font-size: 9.8rem !important;
  }
}

.txt-size-99 {
  font-size: 9.9rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-99 {
    font-size: 9.9rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-99 {
    font-size: 9.9rem !important;
  }
}

.txt-size-100 {
  font-size: 10rem !important;
}

@media (max-width: 896px) {
  .txt-size-sp-100 {
    font-size: 10rem !important;
  }
}

@media (min-width: 897px) {
  .txt-size-pc-100 {
    font-size: 10rem !important;
  }
}

/* ==========================================================
 Width size
========================================================== */
/* Width pixel
---------------------------------------------------------- */
.width-px-10 {
  width: 10px !important;
}

@media (min-width: 897px) {
  .width-px-pc-10 {
    width: 10px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-10 {
    width: 10px !important;
  }
}

.width-px-11 {
  width: 11px !important;
}

@media (min-width: 897px) {
  .width-px-pc-11 {
    width: 11px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-11 {
    width: 11px !important;
  }
}

.width-px-12 {
  width: 12px !important;
}

@media (min-width: 897px) {
  .width-px-pc-12 {
    width: 12px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-12 {
    width: 12px !important;
  }
}

.width-px-13 {
  width: 13px !important;
}

@media (min-width: 897px) {
  .width-px-pc-13 {
    width: 13px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-13 {
    width: 13px !important;
  }
}

.width-px-14 {
  width: 14px !important;
}

@media (min-width: 897px) {
  .width-px-pc-14 {
    width: 14px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-14 {
    width: 14px !important;
  }
}

.width-px-15 {
  width: 15px !important;
}

@media (min-width: 897px) {
  .width-px-pc-15 {
    width: 15px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-15 {
    width: 15px !important;
  }
}

.width-px-16 {
  width: 16px !important;
}

@media (min-width: 897px) {
  .width-px-pc-16 {
    width: 16px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-16 {
    width: 16px !important;
  }
}

.width-px-17 {
  width: 17px !important;
}

@media (min-width: 897px) {
  .width-px-pc-17 {
    width: 17px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-17 {
    width: 17px !important;
  }
}

.width-px-18 {
  width: 18px !important;
}

@media (min-width: 897px) {
  .width-px-pc-18 {
    width: 18px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-18 {
    width: 18px !important;
  }
}

.width-px-19 {
  width: 19px !important;
}

@media (min-width: 897px) {
  .width-px-pc-19 {
    width: 19px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-19 {
    width: 19px !important;
  }
}

.width-px-20 {
  width: 20px !important;
}

@media (min-width: 897px) {
  .width-px-pc-20 {
    width: 20px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-20 {
    width: 20px !important;
  }
}

.width-px-21 {
  width: 21px !important;
}

@media (min-width: 897px) {
  .width-px-pc-21 {
    width: 21px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-21 {
    width: 21px !important;
  }
}

.width-px-22 {
  width: 22px !important;
}

@media (min-width: 897px) {
  .width-px-pc-22 {
    width: 22px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-22 {
    width: 22px !important;
  }
}

.width-px-23 {
  width: 23px !important;
}

@media (min-width: 897px) {
  .width-px-pc-23 {
    width: 23px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-23 {
    width: 23px !important;
  }
}

.width-px-24 {
  width: 24px !important;
}

@media (min-width: 897px) {
  .width-px-pc-24 {
    width: 24px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-24 {
    width: 24px !important;
  }
}

.width-px-25 {
  width: 25px !important;
}

@media (min-width: 897px) {
  .width-px-pc-25 {
    width: 25px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-25 {
    width: 25px !important;
  }
}

.width-px-26 {
  width: 26px !important;
}

@media (min-width: 897px) {
  .width-px-pc-26 {
    width: 26px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-26 {
    width: 26px !important;
  }
}

.width-px-27 {
  width: 27px !important;
}

@media (min-width: 897px) {
  .width-px-pc-27 {
    width: 27px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-27 {
    width: 27px !important;
  }
}

.width-px-28 {
  width: 28px !important;
}

@media (min-width: 897px) {
  .width-px-pc-28 {
    width: 28px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-28 {
    width: 28px !important;
  }
}

.width-px-29 {
  width: 29px !important;
}

@media (min-width: 897px) {
  .width-px-pc-29 {
    width: 29px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-29 {
    width: 29px !important;
  }
}

.width-px-30 {
  width: 30px !important;
}

@media (min-width: 897px) {
  .width-px-pc-30 {
    width: 30px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-30 {
    width: 30px !important;
  }
}

.width-px-31 {
  width: 31px !important;
}

@media (min-width: 897px) {
  .width-px-pc-31 {
    width: 31px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-31 {
    width: 31px !important;
  }
}

.width-px-32 {
  width: 32px !important;
}

@media (min-width: 897px) {
  .width-px-pc-32 {
    width: 32px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-32 {
    width: 32px !important;
  }
}

.width-px-33 {
  width: 33px !important;
}

@media (min-width: 897px) {
  .width-px-pc-33 {
    width: 33px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-33 {
    width: 33px !important;
  }
}

.width-px-34 {
  width: 34px !important;
}

@media (min-width: 897px) {
  .width-px-pc-34 {
    width: 34px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-34 {
    width: 34px !important;
  }
}

.width-px-35 {
  width: 35px !important;
}

@media (min-width: 897px) {
  .width-px-pc-35 {
    width: 35px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-35 {
    width: 35px !important;
  }
}

.width-px-36 {
  width: 36px !important;
}

@media (min-width: 897px) {
  .width-px-pc-36 {
    width: 36px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-36 {
    width: 36px !important;
  }
}

.width-px-37 {
  width: 37px !important;
}

@media (min-width: 897px) {
  .width-px-pc-37 {
    width: 37px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-37 {
    width: 37px !important;
  }
}

.width-px-38 {
  width: 38px !important;
}

@media (min-width: 897px) {
  .width-px-pc-38 {
    width: 38px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-38 {
    width: 38px !important;
  }
}

.width-px-39 {
  width: 39px !important;
}

@media (min-width: 897px) {
  .width-px-pc-39 {
    width: 39px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-39 {
    width: 39px !important;
  }
}

.width-px-40 {
  width: 40px !important;
}

@media (min-width: 897px) {
  .width-px-pc-40 {
    width: 40px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-40 {
    width: 40px !important;
  }
}

.width-px-41 {
  width: 41px !important;
}

@media (min-width: 897px) {
  .width-px-pc-41 {
    width: 41px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-41 {
    width: 41px !important;
  }
}

.width-px-42 {
  width: 42px !important;
}

@media (min-width: 897px) {
  .width-px-pc-42 {
    width: 42px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-42 {
    width: 42px !important;
  }
}

.width-px-43 {
  width: 43px !important;
}

@media (min-width: 897px) {
  .width-px-pc-43 {
    width: 43px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-43 {
    width: 43px !important;
  }
}

.width-px-44 {
  width: 44px !important;
}

@media (min-width: 897px) {
  .width-px-pc-44 {
    width: 44px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-44 {
    width: 44px !important;
  }
}

.width-px-45 {
  width: 45px !important;
}

@media (min-width: 897px) {
  .width-px-pc-45 {
    width: 45px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-45 {
    width: 45px !important;
  }
}

.width-px-46 {
  width: 46px !important;
}

@media (min-width: 897px) {
  .width-px-pc-46 {
    width: 46px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-46 {
    width: 46px !important;
  }
}

.width-px-47 {
  width: 47px !important;
}

@media (min-width: 897px) {
  .width-px-pc-47 {
    width: 47px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-47 {
    width: 47px !important;
  }
}

.width-px-48 {
  width: 48px !important;
}

@media (min-width: 897px) {
  .width-px-pc-48 {
    width: 48px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-48 {
    width: 48px !important;
  }
}

.width-px-49 {
  width: 49px !important;
}

@media (min-width: 897px) {
  .width-px-pc-49 {
    width: 49px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-49 {
    width: 49px !important;
  }
}

.width-px-50 {
  width: 50px !important;
}

@media (min-width: 897px) {
  .width-px-pc-50 {
    width: 50px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-50 {
    width: 50px !important;
  }
}

.width-px-51 {
  width: 51px !important;
}

@media (min-width: 897px) {
  .width-px-pc-51 {
    width: 51px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-51 {
    width: 51px !important;
  }
}

.width-px-52 {
  width: 52px !important;
}

@media (min-width: 897px) {
  .width-px-pc-52 {
    width: 52px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-52 {
    width: 52px !important;
  }
}

.width-px-53 {
  width: 53px !important;
}

@media (min-width: 897px) {
  .width-px-pc-53 {
    width: 53px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-53 {
    width: 53px !important;
  }
}

.width-px-54 {
  width: 54px !important;
}

@media (min-width: 897px) {
  .width-px-pc-54 {
    width: 54px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-54 {
    width: 54px !important;
  }
}

.width-px-55 {
  width: 55px !important;
}

@media (min-width: 897px) {
  .width-px-pc-55 {
    width: 55px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-55 {
    width: 55px !important;
  }
}

.width-px-56 {
  width: 56px !important;
}

@media (min-width: 897px) {
  .width-px-pc-56 {
    width: 56px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-56 {
    width: 56px !important;
  }
}

.width-px-57 {
  width: 57px !important;
}

@media (min-width: 897px) {
  .width-px-pc-57 {
    width: 57px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-57 {
    width: 57px !important;
  }
}

.width-px-58 {
  width: 58px !important;
}

@media (min-width: 897px) {
  .width-px-pc-58 {
    width: 58px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-58 {
    width: 58px !important;
  }
}

.width-px-59 {
  width: 59px !important;
}

@media (min-width: 897px) {
  .width-px-pc-59 {
    width: 59px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-59 {
    width: 59px !important;
  }
}

.width-px-60 {
  width: 60px !important;
}

@media (min-width: 897px) {
  .width-px-pc-60 {
    width: 60px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-60 {
    width: 60px !important;
  }
}

.width-px-61 {
  width: 61px !important;
}

@media (min-width: 897px) {
  .width-px-pc-61 {
    width: 61px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-61 {
    width: 61px !important;
  }
}

.width-px-62 {
  width: 62px !important;
}

@media (min-width: 897px) {
  .width-px-pc-62 {
    width: 62px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-62 {
    width: 62px !important;
  }
}

.width-px-63 {
  width: 63px !important;
}

@media (min-width: 897px) {
  .width-px-pc-63 {
    width: 63px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-63 {
    width: 63px !important;
  }
}

.width-px-64 {
  width: 64px !important;
}

@media (min-width: 897px) {
  .width-px-pc-64 {
    width: 64px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-64 {
    width: 64px !important;
  }
}

.width-px-65 {
  width: 65px !important;
}

@media (min-width: 897px) {
  .width-px-pc-65 {
    width: 65px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-65 {
    width: 65px !important;
  }
}

.width-px-66 {
  width: 66px !important;
}

@media (min-width: 897px) {
  .width-px-pc-66 {
    width: 66px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-66 {
    width: 66px !important;
  }
}

.width-px-67 {
  width: 67px !important;
}

@media (min-width: 897px) {
  .width-px-pc-67 {
    width: 67px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-67 {
    width: 67px !important;
  }
}

.width-px-68 {
  width: 68px !important;
}

@media (min-width: 897px) {
  .width-px-pc-68 {
    width: 68px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-68 {
    width: 68px !important;
  }
}

.width-px-69 {
  width: 69px !important;
}

@media (min-width: 897px) {
  .width-px-pc-69 {
    width: 69px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-69 {
    width: 69px !important;
  }
}

.width-px-70 {
  width: 70px !important;
}

@media (min-width: 897px) {
  .width-px-pc-70 {
    width: 70px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-70 {
    width: 70px !important;
  }
}

.width-px-71 {
  width: 71px !important;
}

@media (min-width: 897px) {
  .width-px-pc-71 {
    width: 71px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-71 {
    width: 71px !important;
  }
}

.width-px-72 {
  width: 72px !important;
}

@media (min-width: 897px) {
  .width-px-pc-72 {
    width: 72px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-72 {
    width: 72px !important;
  }
}

.width-px-73 {
  width: 73px !important;
}

@media (min-width: 897px) {
  .width-px-pc-73 {
    width: 73px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-73 {
    width: 73px !important;
  }
}

.width-px-74 {
  width: 74px !important;
}

@media (min-width: 897px) {
  .width-px-pc-74 {
    width: 74px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-74 {
    width: 74px !important;
  }
}

.width-px-75 {
  width: 75px !important;
}

@media (min-width: 897px) {
  .width-px-pc-75 {
    width: 75px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-75 {
    width: 75px !important;
  }
}

.width-px-76 {
  width: 76px !important;
}

@media (min-width: 897px) {
  .width-px-pc-76 {
    width: 76px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-76 {
    width: 76px !important;
  }
}

.width-px-77 {
  width: 77px !important;
}

@media (min-width: 897px) {
  .width-px-pc-77 {
    width: 77px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-77 {
    width: 77px !important;
  }
}

.width-px-78 {
  width: 78px !important;
}

@media (min-width: 897px) {
  .width-px-pc-78 {
    width: 78px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-78 {
    width: 78px !important;
  }
}

.width-px-79 {
  width: 79px !important;
}

@media (min-width: 897px) {
  .width-px-pc-79 {
    width: 79px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-79 {
    width: 79px !important;
  }
}

.width-px-80 {
  width: 80px !important;
}

@media (min-width: 897px) {
  .width-px-pc-80 {
    width: 80px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-80 {
    width: 80px !important;
  }
}

.width-px-81 {
  width: 81px !important;
}

@media (min-width: 897px) {
  .width-px-pc-81 {
    width: 81px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-81 {
    width: 81px !important;
  }
}

.width-px-82 {
  width: 82px !important;
}

@media (min-width: 897px) {
  .width-px-pc-82 {
    width: 82px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-82 {
    width: 82px !important;
  }
}

.width-px-83 {
  width: 83px !important;
}

@media (min-width: 897px) {
  .width-px-pc-83 {
    width: 83px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-83 {
    width: 83px !important;
  }
}

.width-px-84 {
  width: 84px !important;
}

@media (min-width: 897px) {
  .width-px-pc-84 {
    width: 84px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-84 {
    width: 84px !important;
  }
}

.width-px-85 {
  width: 85px !important;
}

@media (min-width: 897px) {
  .width-px-pc-85 {
    width: 85px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-85 {
    width: 85px !important;
  }
}

.width-px-86 {
  width: 86px !important;
}

@media (min-width: 897px) {
  .width-px-pc-86 {
    width: 86px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-86 {
    width: 86px !important;
  }
}

.width-px-87 {
  width: 87px !important;
}

@media (min-width: 897px) {
  .width-px-pc-87 {
    width: 87px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-87 {
    width: 87px !important;
  }
}

.width-px-88 {
  width: 88px !important;
}

@media (min-width: 897px) {
  .width-px-pc-88 {
    width: 88px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-88 {
    width: 88px !important;
  }
}

.width-px-89 {
  width: 89px !important;
}

@media (min-width: 897px) {
  .width-px-pc-89 {
    width: 89px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-89 {
    width: 89px !important;
  }
}

.width-px-90 {
  width: 90px !important;
}

@media (min-width: 897px) {
  .width-px-pc-90 {
    width: 90px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-90 {
    width: 90px !important;
  }
}

.width-px-91 {
  width: 91px !important;
}

@media (min-width: 897px) {
  .width-px-pc-91 {
    width: 91px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-91 {
    width: 91px !important;
  }
}

.width-px-92 {
  width: 92px !important;
}

@media (min-width: 897px) {
  .width-px-pc-92 {
    width: 92px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-92 {
    width: 92px !important;
  }
}

.width-px-93 {
  width: 93px !important;
}

@media (min-width: 897px) {
  .width-px-pc-93 {
    width: 93px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-93 {
    width: 93px !important;
  }
}

.width-px-94 {
  width: 94px !important;
}

@media (min-width: 897px) {
  .width-px-pc-94 {
    width: 94px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-94 {
    width: 94px !important;
  }
}

.width-px-95 {
  width: 95px !important;
}

@media (min-width: 897px) {
  .width-px-pc-95 {
    width: 95px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-95 {
    width: 95px !important;
  }
}

.width-px-96 {
  width: 96px !important;
}

@media (min-width: 897px) {
  .width-px-pc-96 {
    width: 96px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-96 {
    width: 96px !important;
  }
}

.width-px-97 {
  width: 97px !important;
}

@media (min-width: 897px) {
  .width-px-pc-97 {
    width: 97px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-97 {
    width: 97px !important;
  }
}

.width-px-98 {
  width: 98px !important;
}

@media (min-width: 897px) {
  .width-px-pc-98 {
    width: 98px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-98 {
    width: 98px !important;
  }
}

.width-px-99 {
  width: 99px !important;
}

@media (min-width: 897px) {
  .width-px-pc-99 {
    width: 99px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-99 {
    width: 99px !important;
  }
}

.width-px-100 {
  width: 100px !important;
}

@media (min-width: 897px) {
  .width-px-pc-100 {
    width: 100px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-100 {
    width: 100px !important;
  }
}

.width-px-101 {
  width: 101px !important;
}

@media (min-width: 897px) {
  .width-px-pc-101 {
    width: 101px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-101 {
    width: 101px !important;
  }
}

.width-px-102 {
  width: 102px !important;
}

@media (min-width: 897px) {
  .width-px-pc-102 {
    width: 102px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-102 {
    width: 102px !important;
  }
}

.width-px-103 {
  width: 103px !important;
}

@media (min-width: 897px) {
  .width-px-pc-103 {
    width: 103px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-103 {
    width: 103px !important;
  }
}

.width-px-104 {
  width: 104px !important;
}

@media (min-width: 897px) {
  .width-px-pc-104 {
    width: 104px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-104 {
    width: 104px !important;
  }
}

.width-px-105 {
  width: 105px !important;
}

@media (min-width: 897px) {
  .width-px-pc-105 {
    width: 105px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-105 {
    width: 105px !important;
  }
}

.width-px-106 {
  width: 106px !important;
}

@media (min-width: 897px) {
  .width-px-pc-106 {
    width: 106px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-106 {
    width: 106px !important;
  }
}

.width-px-107 {
  width: 107px !important;
}

@media (min-width: 897px) {
  .width-px-pc-107 {
    width: 107px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-107 {
    width: 107px !important;
  }
}

.width-px-108 {
  width: 108px !important;
}

@media (min-width: 897px) {
  .width-px-pc-108 {
    width: 108px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-108 {
    width: 108px !important;
  }
}

.width-px-109 {
  width: 109px !important;
}

@media (min-width: 897px) {
  .width-px-pc-109 {
    width: 109px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-109 {
    width: 109px !important;
  }
}

.width-px-110 {
  width: 110px !important;
}

@media (min-width: 897px) {
  .width-px-pc-110 {
    width: 110px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-110 {
    width: 110px !important;
  }
}

.width-px-111 {
  width: 111px !important;
}

@media (min-width: 897px) {
  .width-px-pc-111 {
    width: 111px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-111 {
    width: 111px !important;
  }
}

.width-px-112 {
  width: 112px !important;
}

@media (min-width: 897px) {
  .width-px-pc-112 {
    width: 112px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-112 {
    width: 112px !important;
  }
}

.width-px-113 {
  width: 113px !important;
}

@media (min-width: 897px) {
  .width-px-pc-113 {
    width: 113px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-113 {
    width: 113px !important;
  }
}

.width-px-114 {
  width: 114px !important;
}

@media (min-width: 897px) {
  .width-px-pc-114 {
    width: 114px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-114 {
    width: 114px !important;
  }
}

.width-px-115 {
  width: 115px !important;
}

@media (min-width: 897px) {
  .width-px-pc-115 {
    width: 115px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-115 {
    width: 115px !important;
  }
}

.width-px-116 {
  width: 116px !important;
}

@media (min-width: 897px) {
  .width-px-pc-116 {
    width: 116px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-116 {
    width: 116px !important;
  }
}

.width-px-117 {
  width: 117px !important;
}

@media (min-width: 897px) {
  .width-px-pc-117 {
    width: 117px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-117 {
    width: 117px !important;
  }
}

.width-px-118 {
  width: 118px !important;
}

@media (min-width: 897px) {
  .width-px-pc-118 {
    width: 118px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-118 {
    width: 118px !important;
  }
}

.width-px-119 {
  width: 119px !important;
}

@media (min-width: 897px) {
  .width-px-pc-119 {
    width: 119px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-119 {
    width: 119px !important;
  }
}

.width-px-120 {
  width: 120px !important;
}

@media (min-width: 897px) {
  .width-px-pc-120 {
    width: 120px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-120 {
    width: 120px !important;
  }
}

.width-px-121 {
  width: 121px !important;
}

@media (min-width: 897px) {
  .width-px-pc-121 {
    width: 121px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-121 {
    width: 121px !important;
  }
}

.width-px-122 {
  width: 122px !important;
}

@media (min-width: 897px) {
  .width-px-pc-122 {
    width: 122px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-122 {
    width: 122px !important;
  }
}

.width-px-123 {
  width: 123px !important;
}

@media (min-width: 897px) {
  .width-px-pc-123 {
    width: 123px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-123 {
    width: 123px !important;
  }
}

.width-px-124 {
  width: 124px !important;
}

@media (min-width: 897px) {
  .width-px-pc-124 {
    width: 124px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-124 {
    width: 124px !important;
  }
}

.width-px-125 {
  width: 125px !important;
}

@media (min-width: 897px) {
  .width-px-pc-125 {
    width: 125px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-125 {
    width: 125px !important;
  }
}

.width-px-126 {
  width: 126px !important;
}

@media (min-width: 897px) {
  .width-px-pc-126 {
    width: 126px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-126 {
    width: 126px !important;
  }
}

.width-px-127 {
  width: 127px !important;
}

@media (min-width: 897px) {
  .width-px-pc-127 {
    width: 127px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-127 {
    width: 127px !important;
  }
}

.width-px-128 {
  width: 128px !important;
}

@media (min-width: 897px) {
  .width-px-pc-128 {
    width: 128px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-128 {
    width: 128px !important;
  }
}

.width-px-129 {
  width: 129px !important;
}

@media (min-width: 897px) {
  .width-px-pc-129 {
    width: 129px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-129 {
    width: 129px !important;
  }
}

.width-px-130 {
  width: 130px !important;
}

@media (min-width: 897px) {
  .width-px-pc-130 {
    width: 130px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-130 {
    width: 130px !important;
  }
}

.width-px-131 {
  width: 131px !important;
}

@media (min-width: 897px) {
  .width-px-pc-131 {
    width: 131px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-131 {
    width: 131px !important;
  }
}

.width-px-132 {
  width: 132px !important;
}

@media (min-width: 897px) {
  .width-px-pc-132 {
    width: 132px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-132 {
    width: 132px !important;
  }
}

.width-px-133 {
  width: 133px !important;
}

@media (min-width: 897px) {
  .width-px-pc-133 {
    width: 133px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-133 {
    width: 133px !important;
  }
}

.width-px-134 {
  width: 134px !important;
}

@media (min-width: 897px) {
  .width-px-pc-134 {
    width: 134px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-134 {
    width: 134px !important;
  }
}

.width-px-135 {
  width: 135px !important;
}

@media (min-width: 897px) {
  .width-px-pc-135 {
    width: 135px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-135 {
    width: 135px !important;
  }
}

.width-px-136 {
  width: 136px !important;
}

@media (min-width: 897px) {
  .width-px-pc-136 {
    width: 136px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-136 {
    width: 136px !important;
  }
}

.width-px-137 {
  width: 137px !important;
}

@media (min-width: 897px) {
  .width-px-pc-137 {
    width: 137px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-137 {
    width: 137px !important;
  }
}

.width-px-138 {
  width: 138px !important;
}

@media (min-width: 897px) {
  .width-px-pc-138 {
    width: 138px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-138 {
    width: 138px !important;
  }
}

.width-px-139 {
  width: 139px !important;
}

@media (min-width: 897px) {
  .width-px-pc-139 {
    width: 139px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-139 {
    width: 139px !important;
  }
}

.width-px-140 {
  width: 140px !important;
}

@media (min-width: 897px) {
  .width-px-pc-140 {
    width: 140px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-140 {
    width: 140px !important;
  }
}

.width-px-141 {
  width: 141px !important;
}

@media (min-width: 897px) {
  .width-px-pc-141 {
    width: 141px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-141 {
    width: 141px !important;
  }
}

.width-px-142 {
  width: 142px !important;
}

@media (min-width: 897px) {
  .width-px-pc-142 {
    width: 142px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-142 {
    width: 142px !important;
  }
}

.width-px-143 {
  width: 143px !important;
}

@media (min-width: 897px) {
  .width-px-pc-143 {
    width: 143px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-143 {
    width: 143px !important;
  }
}

.width-px-144 {
  width: 144px !important;
}

@media (min-width: 897px) {
  .width-px-pc-144 {
    width: 144px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-144 {
    width: 144px !important;
  }
}

.width-px-145 {
  width: 145px !important;
}

@media (min-width: 897px) {
  .width-px-pc-145 {
    width: 145px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-145 {
    width: 145px !important;
  }
}

.width-px-146 {
  width: 146px !important;
}

@media (min-width: 897px) {
  .width-px-pc-146 {
    width: 146px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-146 {
    width: 146px !important;
  }
}

.width-px-147 {
  width: 147px !important;
}

@media (min-width: 897px) {
  .width-px-pc-147 {
    width: 147px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-147 {
    width: 147px !important;
  }
}

.width-px-148 {
  width: 148px !important;
}

@media (min-width: 897px) {
  .width-px-pc-148 {
    width: 148px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-148 {
    width: 148px !important;
  }
}

.width-px-149 {
  width: 149px !important;
}

@media (min-width: 897px) {
  .width-px-pc-149 {
    width: 149px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-149 {
    width: 149px !important;
  }
}

.width-px-150 {
  width: 150px !important;
}

@media (min-width: 897px) {
  .width-px-pc-150 {
    width: 150px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-150 {
    width: 150px !important;
  }
}

.width-px-151 {
  width: 151px !important;
}

@media (min-width: 897px) {
  .width-px-pc-151 {
    width: 151px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-151 {
    width: 151px !important;
  }
}

.width-px-152 {
  width: 152px !important;
}

@media (min-width: 897px) {
  .width-px-pc-152 {
    width: 152px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-152 {
    width: 152px !important;
  }
}

.width-px-153 {
  width: 153px !important;
}

@media (min-width: 897px) {
  .width-px-pc-153 {
    width: 153px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-153 {
    width: 153px !important;
  }
}

.width-px-154 {
  width: 154px !important;
}

@media (min-width: 897px) {
  .width-px-pc-154 {
    width: 154px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-154 {
    width: 154px !important;
  }
}

.width-px-155 {
  width: 155px !important;
}

@media (min-width: 897px) {
  .width-px-pc-155 {
    width: 155px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-155 {
    width: 155px !important;
  }
}

.width-px-156 {
  width: 156px !important;
}

@media (min-width: 897px) {
  .width-px-pc-156 {
    width: 156px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-156 {
    width: 156px !important;
  }
}

.width-px-157 {
  width: 157px !important;
}

@media (min-width: 897px) {
  .width-px-pc-157 {
    width: 157px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-157 {
    width: 157px !important;
  }
}

.width-px-158 {
  width: 158px !important;
}

@media (min-width: 897px) {
  .width-px-pc-158 {
    width: 158px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-158 {
    width: 158px !important;
  }
}

.width-px-159 {
  width: 159px !important;
}

@media (min-width: 897px) {
  .width-px-pc-159 {
    width: 159px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-159 {
    width: 159px !important;
  }
}

.width-px-160 {
  width: 160px !important;
}

@media (min-width: 897px) {
  .width-px-pc-160 {
    width: 160px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-160 {
    width: 160px !important;
  }
}

.width-px-161 {
  width: 161px !important;
}

@media (min-width: 897px) {
  .width-px-pc-161 {
    width: 161px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-161 {
    width: 161px !important;
  }
}

.width-px-162 {
  width: 162px !important;
}

@media (min-width: 897px) {
  .width-px-pc-162 {
    width: 162px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-162 {
    width: 162px !important;
  }
}

.width-px-163 {
  width: 163px !important;
}

@media (min-width: 897px) {
  .width-px-pc-163 {
    width: 163px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-163 {
    width: 163px !important;
  }
}

.width-px-164 {
  width: 164px !important;
}

@media (min-width: 897px) {
  .width-px-pc-164 {
    width: 164px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-164 {
    width: 164px !important;
  }
}

.width-px-165 {
  width: 165px !important;
}

@media (min-width: 897px) {
  .width-px-pc-165 {
    width: 165px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-165 {
    width: 165px !important;
  }
}

.width-px-166 {
  width: 166px !important;
}

@media (min-width: 897px) {
  .width-px-pc-166 {
    width: 166px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-166 {
    width: 166px !important;
  }
}

.width-px-167 {
  width: 167px !important;
}

@media (min-width: 897px) {
  .width-px-pc-167 {
    width: 167px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-167 {
    width: 167px !important;
  }
}

.width-px-168 {
  width: 168px !important;
}

@media (min-width: 897px) {
  .width-px-pc-168 {
    width: 168px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-168 {
    width: 168px !important;
  }
}

.width-px-169 {
  width: 169px !important;
}

@media (min-width: 897px) {
  .width-px-pc-169 {
    width: 169px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-169 {
    width: 169px !important;
  }
}

.width-px-170 {
  width: 170px !important;
}

@media (min-width: 897px) {
  .width-px-pc-170 {
    width: 170px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-170 {
    width: 170px !important;
  }
}

.width-px-171 {
  width: 171px !important;
}

@media (min-width: 897px) {
  .width-px-pc-171 {
    width: 171px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-171 {
    width: 171px !important;
  }
}

.width-px-172 {
  width: 172px !important;
}

@media (min-width: 897px) {
  .width-px-pc-172 {
    width: 172px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-172 {
    width: 172px !important;
  }
}

.width-px-173 {
  width: 173px !important;
}

@media (min-width: 897px) {
  .width-px-pc-173 {
    width: 173px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-173 {
    width: 173px !important;
  }
}

.width-px-174 {
  width: 174px !important;
}

@media (min-width: 897px) {
  .width-px-pc-174 {
    width: 174px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-174 {
    width: 174px !important;
  }
}

.width-px-175 {
  width: 175px !important;
}

@media (min-width: 897px) {
  .width-px-pc-175 {
    width: 175px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-175 {
    width: 175px !important;
  }
}

.width-px-176 {
  width: 176px !important;
}

@media (min-width: 897px) {
  .width-px-pc-176 {
    width: 176px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-176 {
    width: 176px !important;
  }
}

.width-px-177 {
  width: 177px !important;
}

@media (min-width: 897px) {
  .width-px-pc-177 {
    width: 177px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-177 {
    width: 177px !important;
  }
}

.width-px-178 {
  width: 178px !important;
}

@media (min-width: 897px) {
  .width-px-pc-178 {
    width: 178px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-178 {
    width: 178px !important;
  }
}

.width-px-179 {
  width: 179px !important;
}

@media (min-width: 897px) {
  .width-px-pc-179 {
    width: 179px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-179 {
    width: 179px !important;
  }
}

.width-px-180 {
  width: 180px !important;
}

@media (min-width: 897px) {
  .width-px-pc-180 {
    width: 180px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-180 {
    width: 180px !important;
  }
}

.width-px-181 {
  width: 181px !important;
}

@media (min-width: 897px) {
  .width-px-pc-181 {
    width: 181px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-181 {
    width: 181px !important;
  }
}

.width-px-182 {
  width: 182px !important;
}

@media (min-width: 897px) {
  .width-px-pc-182 {
    width: 182px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-182 {
    width: 182px !important;
  }
}

.width-px-183 {
  width: 183px !important;
}

@media (min-width: 897px) {
  .width-px-pc-183 {
    width: 183px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-183 {
    width: 183px !important;
  }
}

.width-px-184 {
  width: 184px !important;
}

@media (min-width: 897px) {
  .width-px-pc-184 {
    width: 184px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-184 {
    width: 184px !important;
  }
}

.width-px-185 {
  width: 185px !important;
}

@media (min-width: 897px) {
  .width-px-pc-185 {
    width: 185px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-185 {
    width: 185px !important;
  }
}

.width-px-186 {
  width: 186px !important;
}

@media (min-width: 897px) {
  .width-px-pc-186 {
    width: 186px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-186 {
    width: 186px !important;
  }
}

.width-px-187 {
  width: 187px !important;
}

@media (min-width: 897px) {
  .width-px-pc-187 {
    width: 187px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-187 {
    width: 187px !important;
  }
}

.width-px-188 {
  width: 188px !important;
}

@media (min-width: 897px) {
  .width-px-pc-188 {
    width: 188px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-188 {
    width: 188px !important;
  }
}

.width-px-189 {
  width: 189px !important;
}

@media (min-width: 897px) {
  .width-px-pc-189 {
    width: 189px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-189 {
    width: 189px !important;
  }
}

.width-px-190 {
  width: 190px !important;
}

@media (min-width: 897px) {
  .width-px-pc-190 {
    width: 190px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-190 {
    width: 190px !important;
  }
}

.width-px-191 {
  width: 191px !important;
}

@media (min-width: 897px) {
  .width-px-pc-191 {
    width: 191px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-191 {
    width: 191px !important;
  }
}

.width-px-192 {
  width: 192px !important;
}

@media (min-width: 897px) {
  .width-px-pc-192 {
    width: 192px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-192 {
    width: 192px !important;
  }
}

.width-px-193 {
  width: 193px !important;
}

@media (min-width: 897px) {
  .width-px-pc-193 {
    width: 193px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-193 {
    width: 193px !important;
  }
}

.width-px-194 {
  width: 194px !important;
}

@media (min-width: 897px) {
  .width-px-pc-194 {
    width: 194px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-194 {
    width: 194px !important;
  }
}

.width-px-195 {
  width: 195px !important;
}

@media (min-width: 897px) {
  .width-px-pc-195 {
    width: 195px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-195 {
    width: 195px !important;
  }
}

.width-px-196 {
  width: 196px !important;
}

@media (min-width: 897px) {
  .width-px-pc-196 {
    width: 196px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-196 {
    width: 196px !important;
  }
}

.width-px-197 {
  width: 197px !important;
}

@media (min-width: 897px) {
  .width-px-pc-197 {
    width: 197px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-197 {
    width: 197px !important;
  }
}

.width-px-198 {
  width: 198px !important;
}

@media (min-width: 897px) {
  .width-px-pc-198 {
    width: 198px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-198 {
    width: 198px !important;
  }
}

.width-px-199 {
  width: 199px !important;
}

@media (min-width: 897px) {
  .width-px-pc-199 {
    width: 199px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-199 {
    width: 199px !important;
  }
}

.width-px-200 {
  width: 200px !important;
}

@media (min-width: 897px) {
  .width-px-pc-200 {
    width: 200px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-200 {
    width: 200px !important;
  }
}

.width-px-201 {
  width: 201px !important;
}

@media (min-width: 897px) {
  .width-px-pc-201 {
    width: 201px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-201 {
    width: 201px !important;
  }
}

.width-px-202 {
  width: 202px !important;
}

@media (min-width: 897px) {
  .width-px-pc-202 {
    width: 202px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-202 {
    width: 202px !important;
  }
}

.width-px-203 {
  width: 203px !important;
}

@media (min-width: 897px) {
  .width-px-pc-203 {
    width: 203px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-203 {
    width: 203px !important;
  }
}

.width-px-204 {
  width: 204px !important;
}

@media (min-width: 897px) {
  .width-px-pc-204 {
    width: 204px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-204 {
    width: 204px !important;
  }
}

.width-px-205 {
  width: 205px !important;
}

@media (min-width: 897px) {
  .width-px-pc-205 {
    width: 205px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-205 {
    width: 205px !important;
  }
}

.width-px-206 {
  width: 206px !important;
}

@media (min-width: 897px) {
  .width-px-pc-206 {
    width: 206px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-206 {
    width: 206px !important;
  }
}

.width-px-207 {
  width: 207px !important;
}

@media (min-width: 897px) {
  .width-px-pc-207 {
    width: 207px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-207 {
    width: 207px !important;
  }
}

.width-px-208 {
  width: 208px !important;
}

@media (min-width: 897px) {
  .width-px-pc-208 {
    width: 208px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-208 {
    width: 208px !important;
  }
}

.width-px-209 {
  width: 209px !important;
}

@media (min-width: 897px) {
  .width-px-pc-209 {
    width: 209px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-209 {
    width: 209px !important;
  }
}

.width-px-210 {
  width: 210px !important;
}

@media (min-width: 897px) {
  .width-px-pc-210 {
    width: 210px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-210 {
    width: 210px !important;
  }
}

.width-px-211 {
  width: 211px !important;
}

@media (min-width: 897px) {
  .width-px-pc-211 {
    width: 211px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-211 {
    width: 211px !important;
  }
}

.width-px-212 {
  width: 212px !important;
}

@media (min-width: 897px) {
  .width-px-pc-212 {
    width: 212px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-212 {
    width: 212px !important;
  }
}

.width-px-213 {
  width: 213px !important;
}

@media (min-width: 897px) {
  .width-px-pc-213 {
    width: 213px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-213 {
    width: 213px !important;
  }
}

.width-px-214 {
  width: 214px !important;
}

@media (min-width: 897px) {
  .width-px-pc-214 {
    width: 214px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-214 {
    width: 214px !important;
  }
}

.width-px-215 {
  width: 215px !important;
}

@media (min-width: 897px) {
  .width-px-pc-215 {
    width: 215px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-215 {
    width: 215px !important;
  }
}

.width-px-216 {
  width: 216px !important;
}

@media (min-width: 897px) {
  .width-px-pc-216 {
    width: 216px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-216 {
    width: 216px !important;
  }
}

.width-px-217 {
  width: 217px !important;
}

@media (min-width: 897px) {
  .width-px-pc-217 {
    width: 217px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-217 {
    width: 217px !important;
  }
}

.width-px-218 {
  width: 218px !important;
}

@media (min-width: 897px) {
  .width-px-pc-218 {
    width: 218px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-218 {
    width: 218px !important;
  }
}

.width-px-219 {
  width: 219px !important;
}

@media (min-width: 897px) {
  .width-px-pc-219 {
    width: 219px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-219 {
    width: 219px !important;
  }
}

.width-px-220 {
  width: 220px !important;
}

@media (min-width: 897px) {
  .width-px-pc-220 {
    width: 220px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-220 {
    width: 220px !important;
  }
}

.width-px-221 {
  width: 221px !important;
}

@media (min-width: 897px) {
  .width-px-pc-221 {
    width: 221px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-221 {
    width: 221px !important;
  }
}

.width-px-222 {
  width: 222px !important;
}

@media (min-width: 897px) {
  .width-px-pc-222 {
    width: 222px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-222 {
    width: 222px !important;
  }
}

.width-px-223 {
  width: 223px !important;
}

@media (min-width: 897px) {
  .width-px-pc-223 {
    width: 223px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-223 {
    width: 223px !important;
  }
}

.width-px-224 {
  width: 224px !important;
}

@media (min-width: 897px) {
  .width-px-pc-224 {
    width: 224px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-224 {
    width: 224px !important;
  }
}

.width-px-225 {
  width: 225px !important;
}

@media (min-width: 897px) {
  .width-px-pc-225 {
    width: 225px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-225 {
    width: 225px !important;
  }
}

.width-px-226 {
  width: 226px !important;
}

@media (min-width: 897px) {
  .width-px-pc-226 {
    width: 226px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-226 {
    width: 226px !important;
  }
}

.width-px-227 {
  width: 227px !important;
}

@media (min-width: 897px) {
  .width-px-pc-227 {
    width: 227px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-227 {
    width: 227px !important;
  }
}

.width-px-228 {
  width: 228px !important;
}

@media (min-width: 897px) {
  .width-px-pc-228 {
    width: 228px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-228 {
    width: 228px !important;
  }
}

.width-px-229 {
  width: 229px !important;
}

@media (min-width: 897px) {
  .width-px-pc-229 {
    width: 229px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-229 {
    width: 229px !important;
  }
}

.width-px-230 {
  width: 230px !important;
}

@media (min-width: 897px) {
  .width-px-pc-230 {
    width: 230px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-230 {
    width: 230px !important;
  }
}

.width-px-231 {
  width: 231px !important;
}

@media (min-width: 897px) {
  .width-px-pc-231 {
    width: 231px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-231 {
    width: 231px !important;
  }
}

.width-px-232 {
  width: 232px !important;
}

@media (min-width: 897px) {
  .width-px-pc-232 {
    width: 232px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-232 {
    width: 232px !important;
  }
}

.width-px-233 {
  width: 233px !important;
}

@media (min-width: 897px) {
  .width-px-pc-233 {
    width: 233px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-233 {
    width: 233px !important;
  }
}

.width-px-234 {
  width: 234px !important;
}

@media (min-width: 897px) {
  .width-px-pc-234 {
    width: 234px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-234 {
    width: 234px !important;
  }
}

.width-px-235 {
  width: 235px !important;
}

@media (min-width: 897px) {
  .width-px-pc-235 {
    width: 235px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-235 {
    width: 235px !important;
  }
}

.width-px-236 {
  width: 236px !important;
}

@media (min-width: 897px) {
  .width-px-pc-236 {
    width: 236px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-236 {
    width: 236px !important;
  }
}

.width-px-237 {
  width: 237px !important;
}

@media (min-width: 897px) {
  .width-px-pc-237 {
    width: 237px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-237 {
    width: 237px !important;
  }
}

.width-px-238 {
  width: 238px !important;
}

@media (min-width: 897px) {
  .width-px-pc-238 {
    width: 238px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-238 {
    width: 238px !important;
  }
}

.width-px-239 {
  width: 239px !important;
}

@media (min-width: 897px) {
  .width-px-pc-239 {
    width: 239px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-239 {
    width: 239px !important;
  }
}

.width-px-240 {
  width: 240px !important;
}

@media (min-width: 897px) {
  .width-px-pc-240 {
    width: 240px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-240 {
    width: 240px !important;
  }
}

.width-px-241 {
  width: 241px !important;
}

@media (min-width: 897px) {
  .width-px-pc-241 {
    width: 241px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-241 {
    width: 241px !important;
  }
}

.width-px-242 {
  width: 242px !important;
}

@media (min-width: 897px) {
  .width-px-pc-242 {
    width: 242px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-242 {
    width: 242px !important;
  }
}

.width-px-243 {
  width: 243px !important;
}

@media (min-width: 897px) {
  .width-px-pc-243 {
    width: 243px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-243 {
    width: 243px !important;
  }
}

.width-px-244 {
  width: 244px !important;
}

@media (min-width: 897px) {
  .width-px-pc-244 {
    width: 244px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-244 {
    width: 244px !important;
  }
}

.width-px-245 {
  width: 245px !important;
}

@media (min-width: 897px) {
  .width-px-pc-245 {
    width: 245px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-245 {
    width: 245px !important;
  }
}

.width-px-246 {
  width: 246px !important;
}

@media (min-width: 897px) {
  .width-px-pc-246 {
    width: 246px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-246 {
    width: 246px !important;
  }
}

.width-px-247 {
  width: 247px !important;
}

@media (min-width: 897px) {
  .width-px-pc-247 {
    width: 247px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-247 {
    width: 247px !important;
  }
}

.width-px-248 {
  width: 248px !important;
}

@media (min-width: 897px) {
  .width-px-pc-248 {
    width: 248px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-248 {
    width: 248px !important;
  }
}

.width-px-249 {
  width: 249px !important;
}

@media (min-width: 897px) {
  .width-px-pc-249 {
    width: 249px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-249 {
    width: 249px !important;
  }
}

.width-px-250 {
  width: 250px !important;
}

@media (min-width: 897px) {
  .width-px-pc-250 {
    width: 250px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-250 {
    width: 250px !important;
  }
}

.width-px-251 {
  width: 251px !important;
}

@media (min-width: 897px) {
  .width-px-pc-251 {
    width: 251px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-251 {
    width: 251px !important;
  }
}

.width-px-252 {
  width: 252px !important;
}

@media (min-width: 897px) {
  .width-px-pc-252 {
    width: 252px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-252 {
    width: 252px !important;
  }
}

.width-px-253 {
  width: 253px !important;
}

@media (min-width: 897px) {
  .width-px-pc-253 {
    width: 253px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-253 {
    width: 253px !important;
  }
}

.width-px-254 {
  width: 254px !important;
}

@media (min-width: 897px) {
  .width-px-pc-254 {
    width: 254px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-254 {
    width: 254px !important;
  }
}

.width-px-255 {
  width: 255px !important;
}

@media (min-width: 897px) {
  .width-px-pc-255 {
    width: 255px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-255 {
    width: 255px !important;
  }
}

.width-px-256 {
  width: 256px !important;
}

@media (min-width: 897px) {
  .width-px-pc-256 {
    width: 256px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-256 {
    width: 256px !important;
  }
}

.width-px-257 {
  width: 257px !important;
}

@media (min-width: 897px) {
  .width-px-pc-257 {
    width: 257px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-257 {
    width: 257px !important;
  }
}

.width-px-258 {
  width: 258px !important;
}

@media (min-width: 897px) {
  .width-px-pc-258 {
    width: 258px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-258 {
    width: 258px !important;
  }
}

.width-px-259 {
  width: 259px !important;
}

@media (min-width: 897px) {
  .width-px-pc-259 {
    width: 259px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-259 {
    width: 259px !important;
  }
}

.width-px-260 {
  width: 260px !important;
}

@media (min-width: 897px) {
  .width-px-pc-260 {
    width: 260px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-260 {
    width: 260px !important;
  }
}

.width-px-261 {
  width: 261px !important;
}

@media (min-width: 897px) {
  .width-px-pc-261 {
    width: 261px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-261 {
    width: 261px !important;
  }
}

.width-px-262 {
  width: 262px !important;
}

@media (min-width: 897px) {
  .width-px-pc-262 {
    width: 262px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-262 {
    width: 262px !important;
  }
}

.width-px-263 {
  width: 263px !important;
}

@media (min-width: 897px) {
  .width-px-pc-263 {
    width: 263px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-263 {
    width: 263px !important;
  }
}

.width-px-264 {
  width: 264px !important;
}

@media (min-width: 897px) {
  .width-px-pc-264 {
    width: 264px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-264 {
    width: 264px !important;
  }
}

.width-px-265 {
  width: 265px !important;
}

@media (min-width: 897px) {
  .width-px-pc-265 {
    width: 265px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-265 {
    width: 265px !important;
  }
}

.width-px-266 {
  width: 266px !important;
}

@media (min-width: 897px) {
  .width-px-pc-266 {
    width: 266px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-266 {
    width: 266px !important;
  }
}

.width-px-267 {
  width: 267px !important;
}

@media (min-width: 897px) {
  .width-px-pc-267 {
    width: 267px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-267 {
    width: 267px !important;
  }
}

.width-px-268 {
  width: 268px !important;
}

@media (min-width: 897px) {
  .width-px-pc-268 {
    width: 268px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-268 {
    width: 268px !important;
  }
}

.width-px-269 {
  width: 269px !important;
}

@media (min-width: 897px) {
  .width-px-pc-269 {
    width: 269px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-269 {
    width: 269px !important;
  }
}

.width-px-270 {
  width: 270px !important;
}

@media (min-width: 897px) {
  .width-px-pc-270 {
    width: 270px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-270 {
    width: 270px !important;
  }
}

.width-px-271 {
  width: 271px !important;
}

@media (min-width: 897px) {
  .width-px-pc-271 {
    width: 271px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-271 {
    width: 271px !important;
  }
}

.width-px-272 {
  width: 272px !important;
}

@media (min-width: 897px) {
  .width-px-pc-272 {
    width: 272px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-272 {
    width: 272px !important;
  }
}

.width-px-273 {
  width: 273px !important;
}

@media (min-width: 897px) {
  .width-px-pc-273 {
    width: 273px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-273 {
    width: 273px !important;
  }
}

.width-px-274 {
  width: 274px !important;
}

@media (min-width: 897px) {
  .width-px-pc-274 {
    width: 274px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-274 {
    width: 274px !important;
  }
}

.width-px-275 {
  width: 275px !important;
}

@media (min-width: 897px) {
  .width-px-pc-275 {
    width: 275px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-275 {
    width: 275px !important;
  }
}

.width-px-276 {
  width: 276px !important;
}

@media (min-width: 897px) {
  .width-px-pc-276 {
    width: 276px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-276 {
    width: 276px !important;
  }
}

.width-px-277 {
  width: 277px !important;
}

@media (min-width: 897px) {
  .width-px-pc-277 {
    width: 277px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-277 {
    width: 277px !important;
  }
}

.width-px-278 {
  width: 278px !important;
}

@media (min-width: 897px) {
  .width-px-pc-278 {
    width: 278px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-278 {
    width: 278px !important;
  }
}

.width-px-279 {
  width: 279px !important;
}

@media (min-width: 897px) {
  .width-px-pc-279 {
    width: 279px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-279 {
    width: 279px !important;
  }
}

.width-px-280 {
  width: 280px !important;
}

@media (min-width: 897px) {
  .width-px-pc-280 {
    width: 280px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-280 {
    width: 280px !important;
  }
}

.width-px-281 {
  width: 281px !important;
}

@media (min-width: 897px) {
  .width-px-pc-281 {
    width: 281px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-281 {
    width: 281px !important;
  }
}

.width-px-282 {
  width: 282px !important;
}

@media (min-width: 897px) {
  .width-px-pc-282 {
    width: 282px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-282 {
    width: 282px !important;
  }
}

.width-px-283 {
  width: 283px !important;
}

@media (min-width: 897px) {
  .width-px-pc-283 {
    width: 283px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-283 {
    width: 283px !important;
  }
}

.width-px-284 {
  width: 284px !important;
}

@media (min-width: 897px) {
  .width-px-pc-284 {
    width: 284px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-284 {
    width: 284px !important;
  }
}

.width-px-285 {
  width: 285px !important;
}

@media (min-width: 897px) {
  .width-px-pc-285 {
    width: 285px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-285 {
    width: 285px !important;
  }
}

.width-px-286 {
  width: 286px !important;
}

@media (min-width: 897px) {
  .width-px-pc-286 {
    width: 286px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-286 {
    width: 286px !important;
  }
}

.width-px-287 {
  width: 287px !important;
}

@media (min-width: 897px) {
  .width-px-pc-287 {
    width: 287px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-287 {
    width: 287px !important;
  }
}

.width-px-288 {
  width: 288px !important;
}

@media (min-width: 897px) {
  .width-px-pc-288 {
    width: 288px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-288 {
    width: 288px !important;
  }
}

.width-px-289 {
  width: 289px !important;
}

@media (min-width: 897px) {
  .width-px-pc-289 {
    width: 289px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-289 {
    width: 289px !important;
  }
}

.width-px-290 {
  width: 290px !important;
}

@media (min-width: 897px) {
  .width-px-pc-290 {
    width: 290px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-290 {
    width: 290px !important;
  }
}

.width-px-291 {
  width: 291px !important;
}

@media (min-width: 897px) {
  .width-px-pc-291 {
    width: 291px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-291 {
    width: 291px !important;
  }
}

.width-px-292 {
  width: 292px !important;
}

@media (min-width: 897px) {
  .width-px-pc-292 {
    width: 292px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-292 {
    width: 292px !important;
  }
}

.width-px-293 {
  width: 293px !important;
}

@media (min-width: 897px) {
  .width-px-pc-293 {
    width: 293px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-293 {
    width: 293px !important;
  }
}

.width-px-294 {
  width: 294px !important;
}

@media (min-width: 897px) {
  .width-px-pc-294 {
    width: 294px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-294 {
    width: 294px !important;
  }
}

.width-px-295 {
  width: 295px !important;
}

@media (min-width: 897px) {
  .width-px-pc-295 {
    width: 295px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-295 {
    width: 295px !important;
  }
}

.width-px-296 {
  width: 296px !important;
}

@media (min-width: 897px) {
  .width-px-pc-296 {
    width: 296px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-296 {
    width: 296px !important;
  }
}

.width-px-297 {
  width: 297px !important;
}

@media (min-width: 897px) {
  .width-px-pc-297 {
    width: 297px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-297 {
    width: 297px !important;
  }
}

.width-px-298 {
  width: 298px !important;
}

@media (min-width: 897px) {
  .width-px-pc-298 {
    width: 298px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-298 {
    width: 298px !important;
  }
}

.width-px-299 {
  width: 299px !important;
}

@media (min-width: 897px) {
  .width-px-pc-299 {
    width: 299px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-299 {
    width: 299px !important;
  }
}

.width-px-300 {
  width: 300px !important;
}

@media (min-width: 897px) {
  .width-px-pc-300 {
    width: 300px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-300 {
    width: 300px !important;
  }
}

.width-px-301 {
  width: 301px !important;
}

@media (min-width: 897px) {
  .width-px-pc-301 {
    width: 301px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-301 {
    width: 301px !important;
  }
}

.width-px-302 {
  width: 302px !important;
}

@media (min-width: 897px) {
  .width-px-pc-302 {
    width: 302px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-302 {
    width: 302px !important;
  }
}

.width-px-303 {
  width: 303px !important;
}

@media (min-width: 897px) {
  .width-px-pc-303 {
    width: 303px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-303 {
    width: 303px !important;
  }
}

.width-px-304 {
  width: 304px !important;
}

@media (min-width: 897px) {
  .width-px-pc-304 {
    width: 304px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-304 {
    width: 304px !important;
  }
}

.width-px-305 {
  width: 305px !important;
}

@media (min-width: 897px) {
  .width-px-pc-305 {
    width: 305px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-305 {
    width: 305px !important;
  }
}

.width-px-306 {
  width: 306px !important;
}

@media (min-width: 897px) {
  .width-px-pc-306 {
    width: 306px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-306 {
    width: 306px !important;
  }
}

.width-px-307 {
  width: 307px !important;
}

@media (min-width: 897px) {
  .width-px-pc-307 {
    width: 307px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-307 {
    width: 307px !important;
  }
}

.width-px-308 {
  width: 308px !important;
}

@media (min-width: 897px) {
  .width-px-pc-308 {
    width: 308px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-308 {
    width: 308px !important;
  }
}

.width-px-309 {
  width: 309px !important;
}

@media (min-width: 897px) {
  .width-px-pc-309 {
    width: 309px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-309 {
    width: 309px !important;
  }
}

.width-px-310 {
  width: 310px !important;
}

@media (min-width: 897px) {
  .width-px-pc-310 {
    width: 310px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-310 {
    width: 310px !important;
  }
}

.width-px-311 {
  width: 311px !important;
}

@media (min-width: 897px) {
  .width-px-pc-311 {
    width: 311px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-311 {
    width: 311px !important;
  }
}

.width-px-312 {
  width: 312px !important;
}

@media (min-width: 897px) {
  .width-px-pc-312 {
    width: 312px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-312 {
    width: 312px !important;
  }
}

.width-px-313 {
  width: 313px !important;
}

@media (min-width: 897px) {
  .width-px-pc-313 {
    width: 313px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-313 {
    width: 313px !important;
  }
}

.width-px-314 {
  width: 314px !important;
}

@media (min-width: 897px) {
  .width-px-pc-314 {
    width: 314px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-314 {
    width: 314px !important;
  }
}

.width-px-315 {
  width: 315px !important;
}

@media (min-width: 897px) {
  .width-px-pc-315 {
    width: 315px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-315 {
    width: 315px !important;
  }
}

.width-px-316 {
  width: 316px !important;
}

@media (min-width: 897px) {
  .width-px-pc-316 {
    width: 316px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-316 {
    width: 316px !important;
  }
}

.width-px-317 {
  width: 317px !important;
}

@media (min-width: 897px) {
  .width-px-pc-317 {
    width: 317px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-317 {
    width: 317px !important;
  }
}

.width-px-318 {
  width: 318px !important;
}

@media (min-width: 897px) {
  .width-px-pc-318 {
    width: 318px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-318 {
    width: 318px !important;
  }
}

.width-px-319 {
  width: 319px !important;
}

@media (min-width: 897px) {
  .width-px-pc-319 {
    width: 319px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-319 {
    width: 319px !important;
  }
}

.width-px-320 {
  width: 320px !important;
}

@media (min-width: 897px) {
  .width-px-pc-320 {
    width: 320px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-320 {
    width: 320px !important;
  }
}

.width-px-321 {
  width: 321px !important;
}

@media (min-width: 897px) {
  .width-px-pc-321 {
    width: 321px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-321 {
    width: 321px !important;
  }
}

.width-px-322 {
  width: 322px !important;
}

@media (min-width: 897px) {
  .width-px-pc-322 {
    width: 322px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-322 {
    width: 322px !important;
  }
}

.width-px-323 {
  width: 323px !important;
}

@media (min-width: 897px) {
  .width-px-pc-323 {
    width: 323px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-323 {
    width: 323px !important;
  }
}

.width-px-324 {
  width: 324px !important;
}

@media (min-width: 897px) {
  .width-px-pc-324 {
    width: 324px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-324 {
    width: 324px !important;
  }
}

.width-px-325 {
  width: 325px !important;
}

@media (min-width: 897px) {
  .width-px-pc-325 {
    width: 325px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-325 {
    width: 325px !important;
  }
}

.width-px-326 {
  width: 326px !important;
}

@media (min-width: 897px) {
  .width-px-pc-326 {
    width: 326px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-326 {
    width: 326px !important;
  }
}

.width-px-327 {
  width: 327px !important;
}

@media (min-width: 897px) {
  .width-px-pc-327 {
    width: 327px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-327 {
    width: 327px !important;
  }
}

.width-px-328 {
  width: 328px !important;
}

@media (min-width: 897px) {
  .width-px-pc-328 {
    width: 328px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-328 {
    width: 328px !important;
  }
}

.width-px-329 {
  width: 329px !important;
}

@media (min-width: 897px) {
  .width-px-pc-329 {
    width: 329px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-329 {
    width: 329px !important;
  }
}

.width-px-330 {
  width: 330px !important;
}

@media (min-width: 897px) {
  .width-px-pc-330 {
    width: 330px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-330 {
    width: 330px !important;
  }
}

.width-px-331 {
  width: 331px !important;
}

@media (min-width: 897px) {
  .width-px-pc-331 {
    width: 331px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-331 {
    width: 331px !important;
  }
}

.width-px-332 {
  width: 332px !important;
}

@media (min-width: 897px) {
  .width-px-pc-332 {
    width: 332px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-332 {
    width: 332px !important;
  }
}

.width-px-333 {
  width: 333px !important;
}

@media (min-width: 897px) {
  .width-px-pc-333 {
    width: 333px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-333 {
    width: 333px !important;
  }
}

.width-px-334 {
  width: 334px !important;
}

@media (min-width: 897px) {
  .width-px-pc-334 {
    width: 334px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-334 {
    width: 334px !important;
  }
}

.width-px-335 {
  width: 335px !important;
}

@media (min-width: 897px) {
  .width-px-pc-335 {
    width: 335px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-335 {
    width: 335px !important;
  }
}

.width-px-336 {
  width: 336px !important;
}

@media (min-width: 897px) {
  .width-px-pc-336 {
    width: 336px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-336 {
    width: 336px !important;
  }
}

.width-px-337 {
  width: 337px !important;
}

@media (min-width: 897px) {
  .width-px-pc-337 {
    width: 337px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-337 {
    width: 337px !important;
  }
}

.width-px-338 {
  width: 338px !important;
}

@media (min-width: 897px) {
  .width-px-pc-338 {
    width: 338px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-338 {
    width: 338px !important;
  }
}

.width-px-339 {
  width: 339px !important;
}

@media (min-width: 897px) {
  .width-px-pc-339 {
    width: 339px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-339 {
    width: 339px !important;
  }
}

.width-px-340 {
  width: 340px !important;
}

@media (min-width: 897px) {
  .width-px-pc-340 {
    width: 340px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-340 {
    width: 340px !important;
  }
}

.width-px-341 {
  width: 341px !important;
}

@media (min-width: 897px) {
  .width-px-pc-341 {
    width: 341px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-341 {
    width: 341px !important;
  }
}

.width-px-342 {
  width: 342px !important;
}

@media (min-width: 897px) {
  .width-px-pc-342 {
    width: 342px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-342 {
    width: 342px !important;
  }
}

.width-px-343 {
  width: 343px !important;
}

@media (min-width: 897px) {
  .width-px-pc-343 {
    width: 343px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-343 {
    width: 343px !important;
  }
}

.width-px-344 {
  width: 344px !important;
}

@media (min-width: 897px) {
  .width-px-pc-344 {
    width: 344px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-344 {
    width: 344px !important;
  }
}

.width-px-345 {
  width: 345px !important;
}

@media (min-width: 897px) {
  .width-px-pc-345 {
    width: 345px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-345 {
    width: 345px !important;
  }
}

.width-px-346 {
  width: 346px !important;
}

@media (min-width: 897px) {
  .width-px-pc-346 {
    width: 346px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-346 {
    width: 346px !important;
  }
}

.width-px-347 {
  width: 347px !important;
}

@media (min-width: 897px) {
  .width-px-pc-347 {
    width: 347px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-347 {
    width: 347px !important;
  }
}

.width-px-348 {
  width: 348px !important;
}

@media (min-width: 897px) {
  .width-px-pc-348 {
    width: 348px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-348 {
    width: 348px !important;
  }
}

.width-px-349 {
  width: 349px !important;
}

@media (min-width: 897px) {
  .width-px-pc-349 {
    width: 349px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-349 {
    width: 349px !important;
  }
}

.width-px-350 {
  width: 350px !important;
}

@media (min-width: 897px) {
  .width-px-pc-350 {
    width: 350px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-350 {
    width: 350px !important;
  }
}

.width-px-351 {
  width: 351px !important;
}

@media (min-width: 897px) {
  .width-px-pc-351 {
    width: 351px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-351 {
    width: 351px !important;
  }
}

.width-px-352 {
  width: 352px !important;
}

@media (min-width: 897px) {
  .width-px-pc-352 {
    width: 352px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-352 {
    width: 352px !important;
  }
}

.width-px-353 {
  width: 353px !important;
}

@media (min-width: 897px) {
  .width-px-pc-353 {
    width: 353px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-353 {
    width: 353px !important;
  }
}

.width-px-354 {
  width: 354px !important;
}

@media (min-width: 897px) {
  .width-px-pc-354 {
    width: 354px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-354 {
    width: 354px !important;
  }
}

.width-px-355 {
  width: 355px !important;
}

@media (min-width: 897px) {
  .width-px-pc-355 {
    width: 355px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-355 {
    width: 355px !important;
  }
}

.width-px-356 {
  width: 356px !important;
}

@media (min-width: 897px) {
  .width-px-pc-356 {
    width: 356px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-356 {
    width: 356px !important;
  }
}

.width-px-357 {
  width: 357px !important;
}

@media (min-width: 897px) {
  .width-px-pc-357 {
    width: 357px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-357 {
    width: 357px !important;
  }
}

.width-px-358 {
  width: 358px !important;
}

@media (min-width: 897px) {
  .width-px-pc-358 {
    width: 358px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-358 {
    width: 358px !important;
  }
}

.width-px-359 {
  width: 359px !important;
}

@media (min-width: 897px) {
  .width-px-pc-359 {
    width: 359px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-359 {
    width: 359px !important;
  }
}

.width-px-360 {
  width: 360px !important;
}

@media (min-width: 897px) {
  .width-px-pc-360 {
    width: 360px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-360 {
    width: 360px !important;
  }
}

.width-px-361 {
  width: 361px !important;
}

@media (min-width: 897px) {
  .width-px-pc-361 {
    width: 361px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-361 {
    width: 361px !important;
  }
}

.width-px-362 {
  width: 362px !important;
}

@media (min-width: 897px) {
  .width-px-pc-362 {
    width: 362px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-362 {
    width: 362px !important;
  }
}

.width-px-363 {
  width: 363px !important;
}

@media (min-width: 897px) {
  .width-px-pc-363 {
    width: 363px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-363 {
    width: 363px !important;
  }
}

.width-px-364 {
  width: 364px !important;
}

@media (min-width: 897px) {
  .width-px-pc-364 {
    width: 364px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-364 {
    width: 364px !important;
  }
}

.width-px-365 {
  width: 365px !important;
}

@media (min-width: 897px) {
  .width-px-pc-365 {
    width: 365px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-365 {
    width: 365px !important;
  }
}

.width-px-366 {
  width: 366px !important;
}

@media (min-width: 897px) {
  .width-px-pc-366 {
    width: 366px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-366 {
    width: 366px !important;
  }
}

.width-px-367 {
  width: 367px !important;
}

@media (min-width: 897px) {
  .width-px-pc-367 {
    width: 367px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-367 {
    width: 367px !important;
  }
}

.width-px-368 {
  width: 368px !important;
}

@media (min-width: 897px) {
  .width-px-pc-368 {
    width: 368px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-368 {
    width: 368px !important;
  }
}

.width-px-369 {
  width: 369px !important;
}

@media (min-width: 897px) {
  .width-px-pc-369 {
    width: 369px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-369 {
    width: 369px !important;
  }
}

.width-px-370 {
  width: 370px !important;
}

@media (min-width: 897px) {
  .width-px-pc-370 {
    width: 370px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-370 {
    width: 370px !important;
  }
}

.width-px-371 {
  width: 371px !important;
}

@media (min-width: 897px) {
  .width-px-pc-371 {
    width: 371px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-371 {
    width: 371px !important;
  }
}

.width-px-372 {
  width: 372px !important;
}

@media (min-width: 897px) {
  .width-px-pc-372 {
    width: 372px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-372 {
    width: 372px !important;
  }
}

.width-px-373 {
  width: 373px !important;
}

@media (min-width: 897px) {
  .width-px-pc-373 {
    width: 373px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-373 {
    width: 373px !important;
  }
}

.width-px-374 {
  width: 374px !important;
}

@media (min-width: 897px) {
  .width-px-pc-374 {
    width: 374px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-374 {
    width: 374px !important;
  }
}

.width-px-375 {
  width: 375px !important;
}

@media (min-width: 897px) {
  .width-px-pc-375 {
    width: 375px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-375 {
    width: 375px !important;
  }
}

.width-px-376 {
  width: 376px !important;
}

@media (min-width: 897px) {
  .width-px-pc-376 {
    width: 376px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-376 {
    width: 376px !important;
  }
}

.width-px-377 {
  width: 377px !important;
}

@media (min-width: 897px) {
  .width-px-pc-377 {
    width: 377px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-377 {
    width: 377px !important;
  }
}

.width-px-378 {
  width: 378px !important;
}

@media (min-width: 897px) {
  .width-px-pc-378 {
    width: 378px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-378 {
    width: 378px !important;
  }
}

.width-px-379 {
  width: 379px !important;
}

@media (min-width: 897px) {
  .width-px-pc-379 {
    width: 379px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-379 {
    width: 379px !important;
  }
}

.width-px-380 {
  width: 380px !important;
}

@media (min-width: 897px) {
  .width-px-pc-380 {
    width: 380px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-380 {
    width: 380px !important;
  }
}

.width-px-381 {
  width: 381px !important;
}

@media (min-width: 897px) {
  .width-px-pc-381 {
    width: 381px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-381 {
    width: 381px !important;
  }
}

.width-px-382 {
  width: 382px !important;
}

@media (min-width: 897px) {
  .width-px-pc-382 {
    width: 382px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-382 {
    width: 382px !important;
  }
}

.width-px-383 {
  width: 383px !important;
}

@media (min-width: 897px) {
  .width-px-pc-383 {
    width: 383px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-383 {
    width: 383px !important;
  }
}

.width-px-384 {
  width: 384px !important;
}

@media (min-width: 897px) {
  .width-px-pc-384 {
    width: 384px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-384 {
    width: 384px !important;
  }
}

.width-px-385 {
  width: 385px !important;
}

@media (min-width: 897px) {
  .width-px-pc-385 {
    width: 385px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-385 {
    width: 385px !important;
  }
}

.width-px-386 {
  width: 386px !important;
}

@media (min-width: 897px) {
  .width-px-pc-386 {
    width: 386px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-386 {
    width: 386px !important;
  }
}

.width-px-387 {
  width: 387px !important;
}

@media (min-width: 897px) {
  .width-px-pc-387 {
    width: 387px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-387 {
    width: 387px !important;
  }
}

.width-px-388 {
  width: 388px !important;
}

@media (min-width: 897px) {
  .width-px-pc-388 {
    width: 388px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-388 {
    width: 388px !important;
  }
}

.width-px-389 {
  width: 389px !important;
}

@media (min-width: 897px) {
  .width-px-pc-389 {
    width: 389px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-389 {
    width: 389px !important;
  }
}

.width-px-390 {
  width: 390px !important;
}

@media (min-width: 897px) {
  .width-px-pc-390 {
    width: 390px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-390 {
    width: 390px !important;
  }
}

.width-px-391 {
  width: 391px !important;
}

@media (min-width: 897px) {
  .width-px-pc-391 {
    width: 391px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-391 {
    width: 391px !important;
  }
}

.width-px-392 {
  width: 392px !important;
}

@media (min-width: 897px) {
  .width-px-pc-392 {
    width: 392px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-392 {
    width: 392px !important;
  }
}

.width-px-393 {
  width: 393px !important;
}

@media (min-width: 897px) {
  .width-px-pc-393 {
    width: 393px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-393 {
    width: 393px !important;
  }
}

.width-px-394 {
  width: 394px !important;
}

@media (min-width: 897px) {
  .width-px-pc-394 {
    width: 394px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-394 {
    width: 394px !important;
  }
}

.width-px-395 {
  width: 395px !important;
}

@media (min-width: 897px) {
  .width-px-pc-395 {
    width: 395px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-395 {
    width: 395px !important;
  }
}

.width-px-396 {
  width: 396px !important;
}

@media (min-width: 897px) {
  .width-px-pc-396 {
    width: 396px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-396 {
    width: 396px !important;
  }
}

.width-px-397 {
  width: 397px !important;
}

@media (min-width: 897px) {
  .width-px-pc-397 {
    width: 397px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-397 {
    width: 397px !important;
  }
}

.width-px-398 {
  width: 398px !important;
}

@media (min-width: 897px) {
  .width-px-pc-398 {
    width: 398px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-398 {
    width: 398px !important;
  }
}

.width-px-399 {
  width: 399px !important;
}

@media (min-width: 897px) {
  .width-px-pc-399 {
    width: 399px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-399 {
    width: 399px !important;
  }
}

.width-px-400 {
  width: 400px !important;
}

@media (min-width: 897px) {
  .width-px-pc-400 {
    width: 400px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-400 {
    width: 400px !important;
  }
}

.width-px-401 {
  width: 401px !important;
}

@media (min-width: 897px) {
  .width-px-pc-401 {
    width: 401px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-401 {
    width: 401px !important;
  }
}

.width-px-402 {
  width: 402px !important;
}

@media (min-width: 897px) {
  .width-px-pc-402 {
    width: 402px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-402 {
    width: 402px !important;
  }
}

.width-px-403 {
  width: 403px !important;
}

@media (min-width: 897px) {
  .width-px-pc-403 {
    width: 403px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-403 {
    width: 403px !important;
  }
}

.width-px-404 {
  width: 404px !important;
}

@media (min-width: 897px) {
  .width-px-pc-404 {
    width: 404px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-404 {
    width: 404px !important;
  }
}

.width-px-405 {
  width: 405px !important;
}

@media (min-width: 897px) {
  .width-px-pc-405 {
    width: 405px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-405 {
    width: 405px !important;
  }
}

.width-px-406 {
  width: 406px !important;
}

@media (min-width: 897px) {
  .width-px-pc-406 {
    width: 406px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-406 {
    width: 406px !important;
  }
}

.width-px-407 {
  width: 407px !important;
}

@media (min-width: 897px) {
  .width-px-pc-407 {
    width: 407px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-407 {
    width: 407px !important;
  }
}

.width-px-408 {
  width: 408px !important;
}

@media (min-width: 897px) {
  .width-px-pc-408 {
    width: 408px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-408 {
    width: 408px !important;
  }
}

.width-px-409 {
  width: 409px !important;
}

@media (min-width: 897px) {
  .width-px-pc-409 {
    width: 409px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-409 {
    width: 409px !important;
  }
}

.width-px-410 {
  width: 410px !important;
}

@media (min-width: 897px) {
  .width-px-pc-410 {
    width: 410px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-410 {
    width: 410px !important;
  }
}

.width-px-411 {
  width: 411px !important;
}

@media (min-width: 897px) {
  .width-px-pc-411 {
    width: 411px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-411 {
    width: 411px !important;
  }
}

.width-px-412 {
  width: 412px !important;
}

@media (min-width: 897px) {
  .width-px-pc-412 {
    width: 412px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-412 {
    width: 412px !important;
  }
}

.width-px-413 {
  width: 413px !important;
}

@media (min-width: 897px) {
  .width-px-pc-413 {
    width: 413px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-413 {
    width: 413px !important;
  }
}

.width-px-414 {
  width: 414px !important;
}

@media (min-width: 897px) {
  .width-px-pc-414 {
    width: 414px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-414 {
    width: 414px !important;
  }
}

.width-px-415 {
  width: 415px !important;
}

@media (min-width: 897px) {
  .width-px-pc-415 {
    width: 415px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-415 {
    width: 415px !important;
  }
}

.width-px-416 {
  width: 416px !important;
}

@media (min-width: 897px) {
  .width-px-pc-416 {
    width: 416px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-416 {
    width: 416px !important;
  }
}

.width-px-417 {
  width: 417px !important;
}

@media (min-width: 897px) {
  .width-px-pc-417 {
    width: 417px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-417 {
    width: 417px !important;
  }
}

.width-px-418 {
  width: 418px !important;
}

@media (min-width: 897px) {
  .width-px-pc-418 {
    width: 418px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-418 {
    width: 418px !important;
  }
}

.width-px-419 {
  width: 419px !important;
}

@media (min-width: 897px) {
  .width-px-pc-419 {
    width: 419px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-419 {
    width: 419px !important;
  }
}

.width-px-420 {
  width: 420px !important;
}

@media (min-width: 897px) {
  .width-px-pc-420 {
    width: 420px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-420 {
    width: 420px !important;
  }
}

.width-px-421 {
  width: 421px !important;
}

@media (min-width: 897px) {
  .width-px-pc-421 {
    width: 421px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-421 {
    width: 421px !important;
  }
}

.width-px-422 {
  width: 422px !important;
}

@media (min-width: 897px) {
  .width-px-pc-422 {
    width: 422px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-422 {
    width: 422px !important;
  }
}

.width-px-423 {
  width: 423px !important;
}

@media (min-width: 897px) {
  .width-px-pc-423 {
    width: 423px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-423 {
    width: 423px !important;
  }
}

.width-px-424 {
  width: 424px !important;
}

@media (min-width: 897px) {
  .width-px-pc-424 {
    width: 424px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-424 {
    width: 424px !important;
  }
}

.width-px-425 {
  width: 425px !important;
}

@media (min-width: 897px) {
  .width-px-pc-425 {
    width: 425px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-425 {
    width: 425px !important;
  }
}

.width-px-426 {
  width: 426px !important;
}

@media (min-width: 897px) {
  .width-px-pc-426 {
    width: 426px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-426 {
    width: 426px !important;
  }
}

.width-px-427 {
  width: 427px !important;
}

@media (min-width: 897px) {
  .width-px-pc-427 {
    width: 427px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-427 {
    width: 427px !important;
  }
}

.width-px-428 {
  width: 428px !important;
}

@media (min-width: 897px) {
  .width-px-pc-428 {
    width: 428px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-428 {
    width: 428px !important;
  }
}

.width-px-429 {
  width: 429px !important;
}

@media (min-width: 897px) {
  .width-px-pc-429 {
    width: 429px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-429 {
    width: 429px !important;
  }
}

.width-px-430 {
  width: 430px !important;
}

@media (min-width: 897px) {
  .width-px-pc-430 {
    width: 430px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-430 {
    width: 430px !important;
  }
}

.width-px-431 {
  width: 431px !important;
}

@media (min-width: 897px) {
  .width-px-pc-431 {
    width: 431px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-431 {
    width: 431px !important;
  }
}

.width-px-432 {
  width: 432px !important;
}

@media (min-width: 897px) {
  .width-px-pc-432 {
    width: 432px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-432 {
    width: 432px !important;
  }
}

.width-px-433 {
  width: 433px !important;
}

@media (min-width: 897px) {
  .width-px-pc-433 {
    width: 433px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-433 {
    width: 433px !important;
  }
}

.width-px-434 {
  width: 434px !important;
}

@media (min-width: 897px) {
  .width-px-pc-434 {
    width: 434px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-434 {
    width: 434px !important;
  }
}

.width-px-435 {
  width: 435px !important;
}

@media (min-width: 897px) {
  .width-px-pc-435 {
    width: 435px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-435 {
    width: 435px !important;
  }
}

.width-px-436 {
  width: 436px !important;
}

@media (min-width: 897px) {
  .width-px-pc-436 {
    width: 436px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-436 {
    width: 436px !important;
  }
}

.width-px-437 {
  width: 437px !important;
}

@media (min-width: 897px) {
  .width-px-pc-437 {
    width: 437px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-437 {
    width: 437px !important;
  }
}

.width-px-438 {
  width: 438px !important;
}

@media (min-width: 897px) {
  .width-px-pc-438 {
    width: 438px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-438 {
    width: 438px !important;
  }
}

.width-px-439 {
  width: 439px !important;
}

@media (min-width: 897px) {
  .width-px-pc-439 {
    width: 439px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-439 {
    width: 439px !important;
  }
}

.width-px-440 {
  width: 440px !important;
}

@media (min-width: 897px) {
  .width-px-pc-440 {
    width: 440px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-440 {
    width: 440px !important;
  }
}

.width-px-441 {
  width: 441px !important;
}

@media (min-width: 897px) {
  .width-px-pc-441 {
    width: 441px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-441 {
    width: 441px !important;
  }
}

.width-px-442 {
  width: 442px !important;
}

@media (min-width: 897px) {
  .width-px-pc-442 {
    width: 442px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-442 {
    width: 442px !important;
  }
}

.width-px-443 {
  width: 443px !important;
}

@media (min-width: 897px) {
  .width-px-pc-443 {
    width: 443px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-443 {
    width: 443px !important;
  }
}

.width-px-444 {
  width: 444px !important;
}

@media (min-width: 897px) {
  .width-px-pc-444 {
    width: 444px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-444 {
    width: 444px !important;
  }
}

.width-px-445 {
  width: 445px !important;
}

@media (min-width: 897px) {
  .width-px-pc-445 {
    width: 445px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-445 {
    width: 445px !important;
  }
}

.width-px-446 {
  width: 446px !important;
}

@media (min-width: 897px) {
  .width-px-pc-446 {
    width: 446px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-446 {
    width: 446px !important;
  }
}

.width-px-447 {
  width: 447px !important;
}

@media (min-width: 897px) {
  .width-px-pc-447 {
    width: 447px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-447 {
    width: 447px !important;
  }
}

.width-px-448 {
  width: 448px !important;
}

@media (min-width: 897px) {
  .width-px-pc-448 {
    width: 448px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-448 {
    width: 448px !important;
  }
}

.width-px-449 {
  width: 449px !important;
}

@media (min-width: 897px) {
  .width-px-pc-449 {
    width: 449px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-449 {
    width: 449px !important;
  }
}

.width-px-450 {
  width: 450px !important;
}

@media (min-width: 897px) {
  .width-px-pc-450 {
    width: 450px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-450 {
    width: 450px !important;
  }
}

.width-px-451 {
  width: 451px !important;
}

@media (min-width: 897px) {
  .width-px-pc-451 {
    width: 451px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-451 {
    width: 451px !important;
  }
}

.width-px-452 {
  width: 452px !important;
}

@media (min-width: 897px) {
  .width-px-pc-452 {
    width: 452px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-452 {
    width: 452px !important;
  }
}

.width-px-453 {
  width: 453px !important;
}

@media (min-width: 897px) {
  .width-px-pc-453 {
    width: 453px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-453 {
    width: 453px !important;
  }
}

.width-px-454 {
  width: 454px !important;
}

@media (min-width: 897px) {
  .width-px-pc-454 {
    width: 454px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-454 {
    width: 454px !important;
  }
}

.width-px-455 {
  width: 455px !important;
}

@media (min-width: 897px) {
  .width-px-pc-455 {
    width: 455px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-455 {
    width: 455px !important;
  }
}

.width-px-456 {
  width: 456px !important;
}

@media (min-width: 897px) {
  .width-px-pc-456 {
    width: 456px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-456 {
    width: 456px !important;
  }
}

.width-px-457 {
  width: 457px !important;
}

@media (min-width: 897px) {
  .width-px-pc-457 {
    width: 457px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-457 {
    width: 457px !important;
  }
}

.width-px-458 {
  width: 458px !important;
}

@media (min-width: 897px) {
  .width-px-pc-458 {
    width: 458px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-458 {
    width: 458px !important;
  }
}

.width-px-459 {
  width: 459px !important;
}

@media (min-width: 897px) {
  .width-px-pc-459 {
    width: 459px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-459 {
    width: 459px !important;
  }
}

.width-px-460 {
  width: 460px !important;
}

@media (min-width: 897px) {
  .width-px-pc-460 {
    width: 460px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-460 {
    width: 460px !important;
  }
}

.width-px-461 {
  width: 461px !important;
}

@media (min-width: 897px) {
  .width-px-pc-461 {
    width: 461px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-461 {
    width: 461px !important;
  }
}

.width-px-462 {
  width: 462px !important;
}

@media (min-width: 897px) {
  .width-px-pc-462 {
    width: 462px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-462 {
    width: 462px !important;
  }
}

.width-px-463 {
  width: 463px !important;
}

@media (min-width: 897px) {
  .width-px-pc-463 {
    width: 463px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-463 {
    width: 463px !important;
  }
}

.width-px-464 {
  width: 464px !important;
}

@media (min-width: 897px) {
  .width-px-pc-464 {
    width: 464px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-464 {
    width: 464px !important;
  }
}

.width-px-465 {
  width: 465px !important;
}

@media (min-width: 897px) {
  .width-px-pc-465 {
    width: 465px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-465 {
    width: 465px !important;
  }
}

.width-px-466 {
  width: 466px !important;
}

@media (min-width: 897px) {
  .width-px-pc-466 {
    width: 466px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-466 {
    width: 466px !important;
  }
}

.width-px-467 {
  width: 467px !important;
}

@media (min-width: 897px) {
  .width-px-pc-467 {
    width: 467px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-467 {
    width: 467px !important;
  }
}

.width-px-468 {
  width: 468px !important;
}

@media (min-width: 897px) {
  .width-px-pc-468 {
    width: 468px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-468 {
    width: 468px !important;
  }
}

.width-px-469 {
  width: 469px !important;
}

@media (min-width: 897px) {
  .width-px-pc-469 {
    width: 469px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-469 {
    width: 469px !important;
  }
}

.width-px-470 {
  width: 470px !important;
}

@media (min-width: 897px) {
  .width-px-pc-470 {
    width: 470px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-470 {
    width: 470px !important;
  }
}

.width-px-471 {
  width: 471px !important;
}

@media (min-width: 897px) {
  .width-px-pc-471 {
    width: 471px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-471 {
    width: 471px !important;
  }
}

.width-px-472 {
  width: 472px !important;
}

@media (min-width: 897px) {
  .width-px-pc-472 {
    width: 472px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-472 {
    width: 472px !important;
  }
}

.width-px-473 {
  width: 473px !important;
}

@media (min-width: 897px) {
  .width-px-pc-473 {
    width: 473px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-473 {
    width: 473px !important;
  }
}

.width-px-474 {
  width: 474px !important;
}

@media (min-width: 897px) {
  .width-px-pc-474 {
    width: 474px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-474 {
    width: 474px !important;
  }
}

.width-px-475 {
  width: 475px !important;
}

@media (min-width: 897px) {
  .width-px-pc-475 {
    width: 475px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-475 {
    width: 475px !important;
  }
}

.width-px-476 {
  width: 476px !important;
}

@media (min-width: 897px) {
  .width-px-pc-476 {
    width: 476px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-476 {
    width: 476px !important;
  }
}

.width-px-477 {
  width: 477px !important;
}

@media (min-width: 897px) {
  .width-px-pc-477 {
    width: 477px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-477 {
    width: 477px !important;
  }
}

.width-px-478 {
  width: 478px !important;
}

@media (min-width: 897px) {
  .width-px-pc-478 {
    width: 478px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-478 {
    width: 478px !important;
  }
}

.width-px-479 {
  width: 479px !important;
}

@media (min-width: 897px) {
  .width-px-pc-479 {
    width: 479px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-479 {
    width: 479px !important;
  }
}

.width-px-480 {
  width: 480px !important;
}

@media (min-width: 897px) {
  .width-px-pc-480 {
    width: 480px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-480 {
    width: 480px !important;
  }
}

.width-px-481 {
  width: 481px !important;
}

@media (min-width: 897px) {
  .width-px-pc-481 {
    width: 481px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-481 {
    width: 481px !important;
  }
}

.width-px-482 {
  width: 482px !important;
}

@media (min-width: 897px) {
  .width-px-pc-482 {
    width: 482px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-482 {
    width: 482px !important;
  }
}

.width-px-483 {
  width: 483px !important;
}

@media (min-width: 897px) {
  .width-px-pc-483 {
    width: 483px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-483 {
    width: 483px !important;
  }
}

.width-px-484 {
  width: 484px !important;
}

@media (min-width: 897px) {
  .width-px-pc-484 {
    width: 484px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-484 {
    width: 484px !important;
  }
}

.width-px-485 {
  width: 485px !important;
}

@media (min-width: 897px) {
  .width-px-pc-485 {
    width: 485px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-485 {
    width: 485px !important;
  }
}

.width-px-486 {
  width: 486px !important;
}

@media (min-width: 897px) {
  .width-px-pc-486 {
    width: 486px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-486 {
    width: 486px !important;
  }
}

.width-px-487 {
  width: 487px !important;
}

@media (min-width: 897px) {
  .width-px-pc-487 {
    width: 487px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-487 {
    width: 487px !important;
  }
}

.width-px-488 {
  width: 488px !important;
}

@media (min-width: 897px) {
  .width-px-pc-488 {
    width: 488px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-488 {
    width: 488px !important;
  }
}

.width-px-489 {
  width: 489px !important;
}

@media (min-width: 897px) {
  .width-px-pc-489 {
    width: 489px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-489 {
    width: 489px !important;
  }
}

.width-px-490 {
  width: 490px !important;
}

@media (min-width: 897px) {
  .width-px-pc-490 {
    width: 490px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-490 {
    width: 490px !important;
  }
}

.width-px-491 {
  width: 491px !important;
}

@media (min-width: 897px) {
  .width-px-pc-491 {
    width: 491px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-491 {
    width: 491px !important;
  }
}

.width-px-492 {
  width: 492px !important;
}

@media (min-width: 897px) {
  .width-px-pc-492 {
    width: 492px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-492 {
    width: 492px !important;
  }
}

.width-px-493 {
  width: 493px !important;
}

@media (min-width: 897px) {
  .width-px-pc-493 {
    width: 493px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-493 {
    width: 493px !important;
  }
}

.width-px-494 {
  width: 494px !important;
}

@media (min-width: 897px) {
  .width-px-pc-494 {
    width: 494px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-494 {
    width: 494px !important;
  }
}

.width-px-495 {
  width: 495px !important;
}

@media (min-width: 897px) {
  .width-px-pc-495 {
    width: 495px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-495 {
    width: 495px !important;
  }
}

.width-px-496 {
  width: 496px !important;
}

@media (min-width: 897px) {
  .width-px-pc-496 {
    width: 496px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-496 {
    width: 496px !important;
  }
}

.width-px-497 {
  width: 497px !important;
}

@media (min-width: 897px) {
  .width-px-pc-497 {
    width: 497px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-497 {
    width: 497px !important;
  }
}

.width-px-498 {
  width: 498px !important;
}

@media (min-width: 897px) {
  .width-px-pc-498 {
    width: 498px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-498 {
    width: 498px !important;
  }
}

.width-px-499 {
  width: 499px !important;
}

@media (min-width: 897px) {
  .width-px-pc-499 {
    width: 499px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-499 {
    width: 499px !important;
  }
}

.width-px-500 {
  width: 500px !important;
}

@media (min-width: 897px) {
  .width-px-pc-500 {
    width: 500px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-500 {
    width: 500px !important;
  }
}

.width-px-501 {
  width: 501px !important;
}

@media (min-width: 897px) {
  .width-px-pc-501 {
    width: 501px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-501 {
    width: 501px !important;
  }
}

.width-px-502 {
  width: 502px !important;
}

@media (min-width: 897px) {
  .width-px-pc-502 {
    width: 502px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-502 {
    width: 502px !important;
  }
}

.width-px-503 {
  width: 503px !important;
}

@media (min-width: 897px) {
  .width-px-pc-503 {
    width: 503px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-503 {
    width: 503px !important;
  }
}

.width-px-504 {
  width: 504px !important;
}

@media (min-width: 897px) {
  .width-px-pc-504 {
    width: 504px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-504 {
    width: 504px !important;
  }
}

.width-px-505 {
  width: 505px !important;
}

@media (min-width: 897px) {
  .width-px-pc-505 {
    width: 505px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-505 {
    width: 505px !important;
  }
}

.width-px-506 {
  width: 506px !important;
}

@media (min-width: 897px) {
  .width-px-pc-506 {
    width: 506px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-506 {
    width: 506px !important;
  }
}

.width-px-507 {
  width: 507px !important;
}

@media (min-width: 897px) {
  .width-px-pc-507 {
    width: 507px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-507 {
    width: 507px !important;
  }
}

.width-px-508 {
  width: 508px !important;
}

@media (min-width: 897px) {
  .width-px-pc-508 {
    width: 508px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-508 {
    width: 508px !important;
  }
}

.width-px-509 {
  width: 509px !important;
}

@media (min-width: 897px) {
  .width-px-pc-509 {
    width: 509px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-509 {
    width: 509px !important;
  }
}

.width-px-510 {
  width: 510px !important;
}

@media (min-width: 897px) {
  .width-px-pc-510 {
    width: 510px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-510 {
    width: 510px !important;
  }
}

.width-px-511 {
  width: 511px !important;
}

@media (min-width: 897px) {
  .width-px-pc-511 {
    width: 511px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-511 {
    width: 511px !important;
  }
}

.width-px-512 {
  width: 512px !important;
}

@media (min-width: 897px) {
  .width-px-pc-512 {
    width: 512px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-512 {
    width: 512px !important;
  }
}

.width-px-513 {
  width: 513px !important;
}

@media (min-width: 897px) {
  .width-px-pc-513 {
    width: 513px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-513 {
    width: 513px !important;
  }
}

.width-px-514 {
  width: 514px !important;
}

@media (min-width: 897px) {
  .width-px-pc-514 {
    width: 514px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-514 {
    width: 514px !important;
  }
}

.width-px-515 {
  width: 515px !important;
}

@media (min-width: 897px) {
  .width-px-pc-515 {
    width: 515px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-515 {
    width: 515px !important;
  }
}

.width-px-516 {
  width: 516px !important;
}

@media (min-width: 897px) {
  .width-px-pc-516 {
    width: 516px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-516 {
    width: 516px !important;
  }
}

.width-px-517 {
  width: 517px !important;
}

@media (min-width: 897px) {
  .width-px-pc-517 {
    width: 517px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-517 {
    width: 517px !important;
  }
}

.width-px-518 {
  width: 518px !important;
}

@media (min-width: 897px) {
  .width-px-pc-518 {
    width: 518px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-518 {
    width: 518px !important;
  }
}

.width-px-519 {
  width: 519px !important;
}

@media (min-width: 897px) {
  .width-px-pc-519 {
    width: 519px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-519 {
    width: 519px !important;
  }
}

.width-px-520 {
  width: 520px !important;
}

@media (min-width: 897px) {
  .width-px-pc-520 {
    width: 520px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-520 {
    width: 520px !important;
  }
}

.width-px-521 {
  width: 521px !important;
}

@media (min-width: 897px) {
  .width-px-pc-521 {
    width: 521px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-521 {
    width: 521px !important;
  }
}

.width-px-522 {
  width: 522px !important;
}

@media (min-width: 897px) {
  .width-px-pc-522 {
    width: 522px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-522 {
    width: 522px !important;
  }
}

.width-px-523 {
  width: 523px !important;
}

@media (min-width: 897px) {
  .width-px-pc-523 {
    width: 523px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-523 {
    width: 523px !important;
  }
}

.width-px-524 {
  width: 524px !important;
}

@media (min-width: 897px) {
  .width-px-pc-524 {
    width: 524px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-524 {
    width: 524px !important;
  }
}

.width-px-525 {
  width: 525px !important;
}

@media (min-width: 897px) {
  .width-px-pc-525 {
    width: 525px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-525 {
    width: 525px !important;
  }
}

.width-px-526 {
  width: 526px !important;
}

@media (min-width: 897px) {
  .width-px-pc-526 {
    width: 526px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-526 {
    width: 526px !important;
  }
}

.width-px-527 {
  width: 527px !important;
}

@media (min-width: 897px) {
  .width-px-pc-527 {
    width: 527px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-527 {
    width: 527px !important;
  }
}

.width-px-528 {
  width: 528px !important;
}

@media (min-width: 897px) {
  .width-px-pc-528 {
    width: 528px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-528 {
    width: 528px !important;
  }
}

.width-px-529 {
  width: 529px !important;
}

@media (min-width: 897px) {
  .width-px-pc-529 {
    width: 529px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-529 {
    width: 529px !important;
  }
}

.width-px-530 {
  width: 530px !important;
}

@media (min-width: 897px) {
  .width-px-pc-530 {
    width: 530px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-530 {
    width: 530px !important;
  }
}

.width-px-531 {
  width: 531px !important;
}

@media (min-width: 897px) {
  .width-px-pc-531 {
    width: 531px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-531 {
    width: 531px !important;
  }
}

.width-px-532 {
  width: 532px !important;
}

@media (min-width: 897px) {
  .width-px-pc-532 {
    width: 532px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-532 {
    width: 532px !important;
  }
}

.width-px-533 {
  width: 533px !important;
}

@media (min-width: 897px) {
  .width-px-pc-533 {
    width: 533px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-533 {
    width: 533px !important;
  }
}

.width-px-534 {
  width: 534px !important;
}

@media (min-width: 897px) {
  .width-px-pc-534 {
    width: 534px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-534 {
    width: 534px !important;
  }
}

.width-px-535 {
  width: 535px !important;
}

@media (min-width: 897px) {
  .width-px-pc-535 {
    width: 535px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-535 {
    width: 535px !important;
  }
}

.width-px-536 {
  width: 536px !important;
}

@media (min-width: 897px) {
  .width-px-pc-536 {
    width: 536px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-536 {
    width: 536px !important;
  }
}

.width-px-537 {
  width: 537px !important;
}

@media (min-width: 897px) {
  .width-px-pc-537 {
    width: 537px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-537 {
    width: 537px !important;
  }
}

.width-px-538 {
  width: 538px !important;
}

@media (min-width: 897px) {
  .width-px-pc-538 {
    width: 538px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-538 {
    width: 538px !important;
  }
}

.width-px-539 {
  width: 539px !important;
}

@media (min-width: 897px) {
  .width-px-pc-539 {
    width: 539px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-539 {
    width: 539px !important;
  }
}

.width-px-540 {
  width: 540px !important;
}

@media (min-width: 897px) {
  .width-px-pc-540 {
    width: 540px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-540 {
    width: 540px !important;
  }
}

.width-px-541 {
  width: 541px !important;
}

@media (min-width: 897px) {
  .width-px-pc-541 {
    width: 541px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-541 {
    width: 541px !important;
  }
}

.width-px-542 {
  width: 542px !important;
}

@media (min-width: 897px) {
  .width-px-pc-542 {
    width: 542px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-542 {
    width: 542px !important;
  }
}

.width-px-543 {
  width: 543px !important;
}

@media (min-width: 897px) {
  .width-px-pc-543 {
    width: 543px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-543 {
    width: 543px !important;
  }
}

.width-px-544 {
  width: 544px !important;
}

@media (min-width: 897px) {
  .width-px-pc-544 {
    width: 544px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-544 {
    width: 544px !important;
  }
}

.width-px-545 {
  width: 545px !important;
}

@media (min-width: 897px) {
  .width-px-pc-545 {
    width: 545px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-545 {
    width: 545px !important;
  }
}

.width-px-546 {
  width: 546px !important;
}

@media (min-width: 897px) {
  .width-px-pc-546 {
    width: 546px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-546 {
    width: 546px !important;
  }
}

.width-px-547 {
  width: 547px !important;
}

@media (min-width: 897px) {
  .width-px-pc-547 {
    width: 547px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-547 {
    width: 547px !important;
  }
}

.width-px-548 {
  width: 548px !important;
}

@media (min-width: 897px) {
  .width-px-pc-548 {
    width: 548px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-548 {
    width: 548px !important;
  }
}

.width-px-549 {
  width: 549px !important;
}

@media (min-width: 897px) {
  .width-px-pc-549 {
    width: 549px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-549 {
    width: 549px !important;
  }
}

.width-px-550 {
  width: 550px !important;
}

@media (min-width: 897px) {
  .width-px-pc-550 {
    width: 550px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-550 {
    width: 550px !important;
  }
}

.width-px-551 {
  width: 551px !important;
}

@media (min-width: 897px) {
  .width-px-pc-551 {
    width: 551px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-551 {
    width: 551px !important;
  }
}

.width-px-552 {
  width: 552px !important;
}

@media (min-width: 897px) {
  .width-px-pc-552 {
    width: 552px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-552 {
    width: 552px !important;
  }
}

.width-px-553 {
  width: 553px !important;
}

@media (min-width: 897px) {
  .width-px-pc-553 {
    width: 553px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-553 {
    width: 553px !important;
  }
}

.width-px-554 {
  width: 554px !important;
}

@media (min-width: 897px) {
  .width-px-pc-554 {
    width: 554px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-554 {
    width: 554px !important;
  }
}

.width-px-555 {
  width: 555px !important;
}

@media (min-width: 897px) {
  .width-px-pc-555 {
    width: 555px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-555 {
    width: 555px !important;
  }
}

.width-px-556 {
  width: 556px !important;
}

@media (min-width: 897px) {
  .width-px-pc-556 {
    width: 556px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-556 {
    width: 556px !important;
  }
}

.width-px-557 {
  width: 557px !important;
}

@media (min-width: 897px) {
  .width-px-pc-557 {
    width: 557px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-557 {
    width: 557px !important;
  }
}

.width-px-558 {
  width: 558px !important;
}

@media (min-width: 897px) {
  .width-px-pc-558 {
    width: 558px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-558 {
    width: 558px !important;
  }
}

.width-px-559 {
  width: 559px !important;
}

@media (min-width: 897px) {
  .width-px-pc-559 {
    width: 559px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-559 {
    width: 559px !important;
  }
}

.width-px-560 {
  width: 560px !important;
}

@media (min-width: 897px) {
  .width-px-pc-560 {
    width: 560px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-560 {
    width: 560px !important;
  }
}

.width-px-561 {
  width: 561px !important;
}

@media (min-width: 897px) {
  .width-px-pc-561 {
    width: 561px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-561 {
    width: 561px !important;
  }
}

.width-px-562 {
  width: 562px !important;
}

@media (min-width: 897px) {
  .width-px-pc-562 {
    width: 562px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-562 {
    width: 562px !important;
  }
}

.width-px-563 {
  width: 563px !important;
}

@media (min-width: 897px) {
  .width-px-pc-563 {
    width: 563px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-563 {
    width: 563px !important;
  }
}

.width-px-564 {
  width: 564px !important;
}

@media (min-width: 897px) {
  .width-px-pc-564 {
    width: 564px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-564 {
    width: 564px !important;
  }
}

.width-px-565 {
  width: 565px !important;
}

@media (min-width: 897px) {
  .width-px-pc-565 {
    width: 565px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-565 {
    width: 565px !important;
  }
}

.width-px-566 {
  width: 566px !important;
}

@media (min-width: 897px) {
  .width-px-pc-566 {
    width: 566px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-566 {
    width: 566px !important;
  }
}

.width-px-567 {
  width: 567px !important;
}

@media (min-width: 897px) {
  .width-px-pc-567 {
    width: 567px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-567 {
    width: 567px !important;
  }
}

.width-px-568 {
  width: 568px !important;
}

@media (min-width: 897px) {
  .width-px-pc-568 {
    width: 568px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-568 {
    width: 568px !important;
  }
}

.width-px-569 {
  width: 569px !important;
}

@media (min-width: 897px) {
  .width-px-pc-569 {
    width: 569px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-569 {
    width: 569px !important;
  }
}

.width-px-570 {
  width: 570px !important;
}

@media (min-width: 897px) {
  .width-px-pc-570 {
    width: 570px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-570 {
    width: 570px !important;
  }
}

.width-px-571 {
  width: 571px !important;
}

@media (min-width: 897px) {
  .width-px-pc-571 {
    width: 571px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-571 {
    width: 571px !important;
  }
}

.width-px-572 {
  width: 572px !important;
}

@media (min-width: 897px) {
  .width-px-pc-572 {
    width: 572px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-572 {
    width: 572px !important;
  }
}

.width-px-573 {
  width: 573px !important;
}

@media (min-width: 897px) {
  .width-px-pc-573 {
    width: 573px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-573 {
    width: 573px !important;
  }
}

.width-px-574 {
  width: 574px !important;
}

@media (min-width: 897px) {
  .width-px-pc-574 {
    width: 574px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-574 {
    width: 574px !important;
  }
}

.width-px-575 {
  width: 575px !important;
}

@media (min-width: 897px) {
  .width-px-pc-575 {
    width: 575px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-575 {
    width: 575px !important;
  }
}

.width-px-576 {
  width: 576px !important;
}

@media (min-width: 897px) {
  .width-px-pc-576 {
    width: 576px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-576 {
    width: 576px !important;
  }
}

.width-px-577 {
  width: 577px !important;
}

@media (min-width: 897px) {
  .width-px-pc-577 {
    width: 577px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-577 {
    width: 577px !important;
  }
}

.width-px-578 {
  width: 578px !important;
}

@media (min-width: 897px) {
  .width-px-pc-578 {
    width: 578px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-578 {
    width: 578px !important;
  }
}

.width-px-579 {
  width: 579px !important;
}

@media (min-width: 897px) {
  .width-px-pc-579 {
    width: 579px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-579 {
    width: 579px !important;
  }
}

.width-px-580 {
  width: 580px !important;
}

@media (min-width: 897px) {
  .width-px-pc-580 {
    width: 580px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-580 {
    width: 580px !important;
  }
}

.width-px-581 {
  width: 581px !important;
}

@media (min-width: 897px) {
  .width-px-pc-581 {
    width: 581px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-581 {
    width: 581px !important;
  }
}

.width-px-582 {
  width: 582px !important;
}

@media (min-width: 897px) {
  .width-px-pc-582 {
    width: 582px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-582 {
    width: 582px !important;
  }
}

.width-px-583 {
  width: 583px !important;
}

@media (min-width: 897px) {
  .width-px-pc-583 {
    width: 583px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-583 {
    width: 583px !important;
  }
}

.width-px-584 {
  width: 584px !important;
}

@media (min-width: 897px) {
  .width-px-pc-584 {
    width: 584px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-584 {
    width: 584px !important;
  }
}

.width-px-585 {
  width: 585px !important;
}

@media (min-width: 897px) {
  .width-px-pc-585 {
    width: 585px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-585 {
    width: 585px !important;
  }
}

.width-px-586 {
  width: 586px !important;
}

@media (min-width: 897px) {
  .width-px-pc-586 {
    width: 586px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-586 {
    width: 586px !important;
  }
}

.width-px-587 {
  width: 587px !important;
}

@media (min-width: 897px) {
  .width-px-pc-587 {
    width: 587px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-587 {
    width: 587px !important;
  }
}

.width-px-588 {
  width: 588px !important;
}

@media (min-width: 897px) {
  .width-px-pc-588 {
    width: 588px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-588 {
    width: 588px !important;
  }
}

.width-px-589 {
  width: 589px !important;
}

@media (min-width: 897px) {
  .width-px-pc-589 {
    width: 589px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-589 {
    width: 589px !important;
  }
}

.width-px-590 {
  width: 590px !important;
}

@media (min-width: 897px) {
  .width-px-pc-590 {
    width: 590px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-590 {
    width: 590px !important;
  }
}

.width-px-591 {
  width: 591px !important;
}

@media (min-width: 897px) {
  .width-px-pc-591 {
    width: 591px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-591 {
    width: 591px !important;
  }
}

.width-px-592 {
  width: 592px !important;
}

@media (min-width: 897px) {
  .width-px-pc-592 {
    width: 592px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-592 {
    width: 592px !important;
  }
}

.width-px-593 {
  width: 593px !important;
}

@media (min-width: 897px) {
  .width-px-pc-593 {
    width: 593px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-593 {
    width: 593px !important;
  }
}

.width-px-594 {
  width: 594px !important;
}

@media (min-width: 897px) {
  .width-px-pc-594 {
    width: 594px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-594 {
    width: 594px !important;
  }
}

.width-px-595 {
  width: 595px !important;
}

@media (min-width: 897px) {
  .width-px-pc-595 {
    width: 595px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-595 {
    width: 595px !important;
  }
}

.width-px-596 {
  width: 596px !important;
}

@media (min-width: 897px) {
  .width-px-pc-596 {
    width: 596px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-596 {
    width: 596px !important;
  }
}

.width-px-597 {
  width: 597px !important;
}

@media (min-width: 897px) {
  .width-px-pc-597 {
    width: 597px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-597 {
    width: 597px !important;
  }
}

.width-px-598 {
  width: 598px !important;
}

@media (min-width: 897px) {
  .width-px-pc-598 {
    width: 598px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-598 {
    width: 598px !important;
  }
}

.width-px-599 {
  width: 599px !important;
}

@media (min-width: 897px) {
  .width-px-pc-599 {
    width: 599px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-599 {
    width: 599px !important;
  }
}

.width-px-600 {
  width: 600px !important;
}

@media (min-width: 897px) {
  .width-px-pc-600 {
    width: 600px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-600 {
    width: 600px !important;
  }
}

.width-px-601 {
  width: 601px !important;
}

@media (min-width: 897px) {
  .width-px-pc-601 {
    width: 601px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-601 {
    width: 601px !important;
  }
}

.width-px-602 {
  width: 602px !important;
}

@media (min-width: 897px) {
  .width-px-pc-602 {
    width: 602px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-602 {
    width: 602px !important;
  }
}

.width-px-603 {
  width: 603px !important;
}

@media (min-width: 897px) {
  .width-px-pc-603 {
    width: 603px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-603 {
    width: 603px !important;
  }
}

.width-px-604 {
  width: 604px !important;
}

@media (min-width: 897px) {
  .width-px-pc-604 {
    width: 604px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-604 {
    width: 604px !important;
  }
}

.width-px-605 {
  width: 605px !important;
}

@media (min-width: 897px) {
  .width-px-pc-605 {
    width: 605px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-605 {
    width: 605px !important;
  }
}

.width-px-606 {
  width: 606px !important;
}

@media (min-width: 897px) {
  .width-px-pc-606 {
    width: 606px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-606 {
    width: 606px !important;
  }
}

.width-px-607 {
  width: 607px !important;
}

@media (min-width: 897px) {
  .width-px-pc-607 {
    width: 607px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-607 {
    width: 607px !important;
  }
}

.width-px-608 {
  width: 608px !important;
}

@media (min-width: 897px) {
  .width-px-pc-608 {
    width: 608px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-608 {
    width: 608px !important;
  }
}

.width-px-609 {
  width: 609px !important;
}

@media (min-width: 897px) {
  .width-px-pc-609 {
    width: 609px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-609 {
    width: 609px !important;
  }
}

.width-px-610 {
  width: 610px !important;
}

@media (min-width: 897px) {
  .width-px-pc-610 {
    width: 610px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-610 {
    width: 610px !important;
  }
}

.width-px-611 {
  width: 611px !important;
}

@media (min-width: 897px) {
  .width-px-pc-611 {
    width: 611px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-611 {
    width: 611px !important;
  }
}

.width-px-612 {
  width: 612px !important;
}

@media (min-width: 897px) {
  .width-px-pc-612 {
    width: 612px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-612 {
    width: 612px !important;
  }
}

.width-px-613 {
  width: 613px !important;
}

@media (min-width: 897px) {
  .width-px-pc-613 {
    width: 613px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-613 {
    width: 613px !important;
  }
}

.width-px-614 {
  width: 614px !important;
}

@media (min-width: 897px) {
  .width-px-pc-614 {
    width: 614px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-614 {
    width: 614px !important;
  }
}

.width-px-615 {
  width: 615px !important;
}

@media (min-width: 897px) {
  .width-px-pc-615 {
    width: 615px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-615 {
    width: 615px !important;
  }
}

.width-px-616 {
  width: 616px !important;
}

@media (min-width: 897px) {
  .width-px-pc-616 {
    width: 616px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-616 {
    width: 616px !important;
  }
}

.width-px-617 {
  width: 617px !important;
}

@media (min-width: 897px) {
  .width-px-pc-617 {
    width: 617px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-617 {
    width: 617px !important;
  }
}

.width-px-618 {
  width: 618px !important;
}

@media (min-width: 897px) {
  .width-px-pc-618 {
    width: 618px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-618 {
    width: 618px !important;
  }
}

.width-px-619 {
  width: 619px !important;
}

@media (min-width: 897px) {
  .width-px-pc-619 {
    width: 619px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-619 {
    width: 619px !important;
  }
}

.width-px-620 {
  width: 620px !important;
}

@media (min-width: 897px) {
  .width-px-pc-620 {
    width: 620px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-620 {
    width: 620px !important;
  }
}

.width-px-621 {
  width: 621px !important;
}

@media (min-width: 897px) {
  .width-px-pc-621 {
    width: 621px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-621 {
    width: 621px !important;
  }
}

.width-px-622 {
  width: 622px !important;
}

@media (min-width: 897px) {
  .width-px-pc-622 {
    width: 622px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-622 {
    width: 622px !important;
  }
}

.width-px-623 {
  width: 623px !important;
}

@media (min-width: 897px) {
  .width-px-pc-623 {
    width: 623px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-623 {
    width: 623px !important;
  }
}

.width-px-624 {
  width: 624px !important;
}

@media (min-width: 897px) {
  .width-px-pc-624 {
    width: 624px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-624 {
    width: 624px !important;
  }
}

.width-px-625 {
  width: 625px !important;
}

@media (min-width: 897px) {
  .width-px-pc-625 {
    width: 625px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-625 {
    width: 625px !important;
  }
}

.width-px-626 {
  width: 626px !important;
}

@media (min-width: 897px) {
  .width-px-pc-626 {
    width: 626px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-626 {
    width: 626px !important;
  }
}

.width-px-627 {
  width: 627px !important;
}

@media (min-width: 897px) {
  .width-px-pc-627 {
    width: 627px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-627 {
    width: 627px !important;
  }
}

.width-px-628 {
  width: 628px !important;
}

@media (min-width: 897px) {
  .width-px-pc-628 {
    width: 628px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-628 {
    width: 628px !important;
  }
}

.width-px-629 {
  width: 629px !important;
}

@media (min-width: 897px) {
  .width-px-pc-629 {
    width: 629px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-629 {
    width: 629px !important;
  }
}

.width-px-630 {
  width: 630px !important;
}

@media (min-width: 897px) {
  .width-px-pc-630 {
    width: 630px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-630 {
    width: 630px !important;
  }
}

.width-px-631 {
  width: 631px !important;
}

@media (min-width: 897px) {
  .width-px-pc-631 {
    width: 631px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-631 {
    width: 631px !important;
  }
}

.width-px-632 {
  width: 632px !important;
}

@media (min-width: 897px) {
  .width-px-pc-632 {
    width: 632px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-632 {
    width: 632px !important;
  }
}

.width-px-633 {
  width: 633px !important;
}

@media (min-width: 897px) {
  .width-px-pc-633 {
    width: 633px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-633 {
    width: 633px !important;
  }
}

.width-px-634 {
  width: 634px !important;
}

@media (min-width: 897px) {
  .width-px-pc-634 {
    width: 634px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-634 {
    width: 634px !important;
  }
}

.width-px-635 {
  width: 635px !important;
}

@media (min-width: 897px) {
  .width-px-pc-635 {
    width: 635px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-635 {
    width: 635px !important;
  }
}

.width-px-636 {
  width: 636px !important;
}

@media (min-width: 897px) {
  .width-px-pc-636 {
    width: 636px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-636 {
    width: 636px !important;
  }
}

.width-px-637 {
  width: 637px !important;
}

@media (min-width: 897px) {
  .width-px-pc-637 {
    width: 637px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-637 {
    width: 637px !important;
  }
}

.width-px-638 {
  width: 638px !important;
}

@media (min-width: 897px) {
  .width-px-pc-638 {
    width: 638px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-638 {
    width: 638px !important;
  }
}

.width-px-639 {
  width: 639px !important;
}

@media (min-width: 897px) {
  .width-px-pc-639 {
    width: 639px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-639 {
    width: 639px !important;
  }
}

.width-px-640 {
  width: 640px !important;
}

@media (min-width: 897px) {
  .width-px-pc-640 {
    width: 640px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-640 {
    width: 640px !important;
  }
}

.width-px-641 {
  width: 641px !important;
}

@media (min-width: 897px) {
  .width-px-pc-641 {
    width: 641px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-641 {
    width: 641px !important;
  }
}

.width-px-642 {
  width: 642px !important;
}

@media (min-width: 897px) {
  .width-px-pc-642 {
    width: 642px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-642 {
    width: 642px !important;
  }
}

.width-px-643 {
  width: 643px !important;
}

@media (min-width: 897px) {
  .width-px-pc-643 {
    width: 643px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-643 {
    width: 643px !important;
  }
}

.width-px-644 {
  width: 644px !important;
}

@media (min-width: 897px) {
  .width-px-pc-644 {
    width: 644px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-644 {
    width: 644px !important;
  }
}

.width-px-645 {
  width: 645px !important;
}

@media (min-width: 897px) {
  .width-px-pc-645 {
    width: 645px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-645 {
    width: 645px !important;
  }
}

.width-px-646 {
  width: 646px !important;
}

@media (min-width: 897px) {
  .width-px-pc-646 {
    width: 646px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-646 {
    width: 646px !important;
  }
}

.width-px-647 {
  width: 647px !important;
}

@media (min-width: 897px) {
  .width-px-pc-647 {
    width: 647px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-647 {
    width: 647px !important;
  }
}

.width-px-648 {
  width: 648px !important;
}

@media (min-width: 897px) {
  .width-px-pc-648 {
    width: 648px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-648 {
    width: 648px !important;
  }
}

.width-px-649 {
  width: 649px !important;
}

@media (min-width: 897px) {
  .width-px-pc-649 {
    width: 649px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-649 {
    width: 649px !important;
  }
}

.width-px-650 {
  width: 650px !important;
}

@media (min-width: 897px) {
  .width-px-pc-650 {
    width: 650px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-650 {
    width: 650px !important;
  }
}

.width-px-651 {
  width: 651px !important;
}

@media (min-width: 897px) {
  .width-px-pc-651 {
    width: 651px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-651 {
    width: 651px !important;
  }
}

.width-px-652 {
  width: 652px !important;
}

@media (min-width: 897px) {
  .width-px-pc-652 {
    width: 652px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-652 {
    width: 652px !important;
  }
}

.width-px-653 {
  width: 653px !important;
}

@media (min-width: 897px) {
  .width-px-pc-653 {
    width: 653px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-653 {
    width: 653px !important;
  }
}

.width-px-654 {
  width: 654px !important;
}

@media (min-width: 897px) {
  .width-px-pc-654 {
    width: 654px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-654 {
    width: 654px !important;
  }
}

.width-px-655 {
  width: 655px !important;
}

@media (min-width: 897px) {
  .width-px-pc-655 {
    width: 655px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-655 {
    width: 655px !important;
  }
}

.width-px-656 {
  width: 656px !important;
}

@media (min-width: 897px) {
  .width-px-pc-656 {
    width: 656px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-656 {
    width: 656px !important;
  }
}

.width-px-657 {
  width: 657px !important;
}

@media (min-width: 897px) {
  .width-px-pc-657 {
    width: 657px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-657 {
    width: 657px !important;
  }
}

.width-px-658 {
  width: 658px !important;
}

@media (min-width: 897px) {
  .width-px-pc-658 {
    width: 658px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-658 {
    width: 658px !important;
  }
}

.width-px-659 {
  width: 659px !important;
}

@media (min-width: 897px) {
  .width-px-pc-659 {
    width: 659px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-659 {
    width: 659px !important;
  }
}

.width-px-660 {
  width: 660px !important;
}

@media (min-width: 897px) {
  .width-px-pc-660 {
    width: 660px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-660 {
    width: 660px !important;
  }
}

.width-px-661 {
  width: 661px !important;
}

@media (min-width: 897px) {
  .width-px-pc-661 {
    width: 661px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-661 {
    width: 661px !important;
  }
}

.width-px-662 {
  width: 662px !important;
}

@media (min-width: 897px) {
  .width-px-pc-662 {
    width: 662px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-662 {
    width: 662px !important;
  }
}

.width-px-663 {
  width: 663px !important;
}

@media (min-width: 897px) {
  .width-px-pc-663 {
    width: 663px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-663 {
    width: 663px !important;
  }
}

.width-px-664 {
  width: 664px !important;
}

@media (min-width: 897px) {
  .width-px-pc-664 {
    width: 664px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-664 {
    width: 664px !important;
  }
}

.width-px-665 {
  width: 665px !important;
}

@media (min-width: 897px) {
  .width-px-pc-665 {
    width: 665px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-665 {
    width: 665px !important;
  }
}

.width-px-666 {
  width: 666px !important;
}

@media (min-width: 897px) {
  .width-px-pc-666 {
    width: 666px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-666 {
    width: 666px !important;
  }
}

.width-px-667 {
  width: 667px !important;
}

@media (min-width: 897px) {
  .width-px-pc-667 {
    width: 667px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-667 {
    width: 667px !important;
  }
}

.width-px-668 {
  width: 668px !important;
}

@media (min-width: 897px) {
  .width-px-pc-668 {
    width: 668px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-668 {
    width: 668px !important;
  }
}

.width-px-669 {
  width: 669px !important;
}

@media (min-width: 897px) {
  .width-px-pc-669 {
    width: 669px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-669 {
    width: 669px !important;
  }
}

.width-px-670 {
  width: 670px !important;
}

@media (min-width: 897px) {
  .width-px-pc-670 {
    width: 670px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-670 {
    width: 670px !important;
  }
}

.width-px-671 {
  width: 671px !important;
}

@media (min-width: 897px) {
  .width-px-pc-671 {
    width: 671px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-671 {
    width: 671px !important;
  }
}

.width-px-672 {
  width: 672px !important;
}

@media (min-width: 897px) {
  .width-px-pc-672 {
    width: 672px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-672 {
    width: 672px !important;
  }
}

.width-px-673 {
  width: 673px !important;
}

@media (min-width: 897px) {
  .width-px-pc-673 {
    width: 673px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-673 {
    width: 673px !important;
  }
}

.width-px-674 {
  width: 674px !important;
}

@media (min-width: 897px) {
  .width-px-pc-674 {
    width: 674px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-674 {
    width: 674px !important;
  }
}

.width-px-675 {
  width: 675px !important;
}

@media (min-width: 897px) {
  .width-px-pc-675 {
    width: 675px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-675 {
    width: 675px !important;
  }
}

.width-px-676 {
  width: 676px !important;
}

@media (min-width: 897px) {
  .width-px-pc-676 {
    width: 676px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-676 {
    width: 676px !important;
  }
}

.width-px-677 {
  width: 677px !important;
}

@media (min-width: 897px) {
  .width-px-pc-677 {
    width: 677px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-677 {
    width: 677px !important;
  }
}

.width-px-678 {
  width: 678px !important;
}

@media (min-width: 897px) {
  .width-px-pc-678 {
    width: 678px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-678 {
    width: 678px !important;
  }
}

.width-px-679 {
  width: 679px !important;
}

@media (min-width: 897px) {
  .width-px-pc-679 {
    width: 679px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-679 {
    width: 679px !important;
  }
}

.width-px-680 {
  width: 680px !important;
}

@media (min-width: 897px) {
  .width-px-pc-680 {
    width: 680px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-680 {
    width: 680px !important;
  }
}

.width-px-681 {
  width: 681px !important;
}

@media (min-width: 897px) {
  .width-px-pc-681 {
    width: 681px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-681 {
    width: 681px !important;
  }
}

.width-px-682 {
  width: 682px !important;
}

@media (min-width: 897px) {
  .width-px-pc-682 {
    width: 682px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-682 {
    width: 682px !important;
  }
}

.width-px-683 {
  width: 683px !important;
}

@media (min-width: 897px) {
  .width-px-pc-683 {
    width: 683px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-683 {
    width: 683px !important;
  }
}

.width-px-684 {
  width: 684px !important;
}

@media (min-width: 897px) {
  .width-px-pc-684 {
    width: 684px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-684 {
    width: 684px !important;
  }
}

.width-px-685 {
  width: 685px !important;
}

@media (min-width: 897px) {
  .width-px-pc-685 {
    width: 685px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-685 {
    width: 685px !important;
  }
}

.width-px-686 {
  width: 686px !important;
}

@media (min-width: 897px) {
  .width-px-pc-686 {
    width: 686px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-686 {
    width: 686px !important;
  }
}

.width-px-687 {
  width: 687px !important;
}

@media (min-width: 897px) {
  .width-px-pc-687 {
    width: 687px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-687 {
    width: 687px !important;
  }
}

.width-px-688 {
  width: 688px !important;
}

@media (min-width: 897px) {
  .width-px-pc-688 {
    width: 688px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-688 {
    width: 688px !important;
  }
}

.width-px-689 {
  width: 689px !important;
}

@media (min-width: 897px) {
  .width-px-pc-689 {
    width: 689px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-689 {
    width: 689px !important;
  }
}

.width-px-690 {
  width: 690px !important;
}

@media (min-width: 897px) {
  .width-px-pc-690 {
    width: 690px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-690 {
    width: 690px !important;
  }
}

.width-px-691 {
  width: 691px !important;
}

@media (min-width: 897px) {
  .width-px-pc-691 {
    width: 691px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-691 {
    width: 691px !important;
  }
}

.width-px-692 {
  width: 692px !important;
}

@media (min-width: 897px) {
  .width-px-pc-692 {
    width: 692px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-692 {
    width: 692px !important;
  }
}

.width-px-693 {
  width: 693px !important;
}

@media (min-width: 897px) {
  .width-px-pc-693 {
    width: 693px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-693 {
    width: 693px !important;
  }
}

.width-px-694 {
  width: 694px !important;
}

@media (min-width: 897px) {
  .width-px-pc-694 {
    width: 694px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-694 {
    width: 694px !important;
  }
}

.width-px-695 {
  width: 695px !important;
}

@media (min-width: 897px) {
  .width-px-pc-695 {
    width: 695px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-695 {
    width: 695px !important;
  }
}

.width-px-696 {
  width: 696px !important;
}

@media (min-width: 897px) {
  .width-px-pc-696 {
    width: 696px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-696 {
    width: 696px !important;
  }
}

.width-px-697 {
  width: 697px !important;
}

@media (min-width: 897px) {
  .width-px-pc-697 {
    width: 697px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-697 {
    width: 697px !important;
  }
}

.width-px-698 {
  width: 698px !important;
}

@media (min-width: 897px) {
  .width-px-pc-698 {
    width: 698px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-698 {
    width: 698px !important;
  }
}

.width-px-699 {
  width: 699px !important;
}

@media (min-width: 897px) {
  .width-px-pc-699 {
    width: 699px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-699 {
    width: 699px !important;
  }
}

.width-px-700 {
  width: 700px !important;
}

@media (min-width: 897px) {
  .width-px-pc-700 {
    width: 700px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-700 {
    width: 700px !important;
  }
}

.width-px-701 {
  width: 701px !important;
}

@media (min-width: 897px) {
  .width-px-pc-701 {
    width: 701px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-701 {
    width: 701px !important;
  }
}

.width-px-702 {
  width: 702px !important;
}

@media (min-width: 897px) {
  .width-px-pc-702 {
    width: 702px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-702 {
    width: 702px !important;
  }
}

.width-px-703 {
  width: 703px !important;
}

@media (min-width: 897px) {
  .width-px-pc-703 {
    width: 703px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-703 {
    width: 703px !important;
  }
}

.width-px-704 {
  width: 704px !important;
}

@media (min-width: 897px) {
  .width-px-pc-704 {
    width: 704px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-704 {
    width: 704px !important;
  }
}

.width-px-705 {
  width: 705px !important;
}

@media (min-width: 897px) {
  .width-px-pc-705 {
    width: 705px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-705 {
    width: 705px !important;
  }
}

.width-px-706 {
  width: 706px !important;
}

@media (min-width: 897px) {
  .width-px-pc-706 {
    width: 706px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-706 {
    width: 706px !important;
  }
}

.width-px-707 {
  width: 707px !important;
}

@media (min-width: 897px) {
  .width-px-pc-707 {
    width: 707px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-707 {
    width: 707px !important;
  }
}

.width-px-708 {
  width: 708px !important;
}

@media (min-width: 897px) {
  .width-px-pc-708 {
    width: 708px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-708 {
    width: 708px !important;
  }
}

.width-px-709 {
  width: 709px !important;
}

@media (min-width: 897px) {
  .width-px-pc-709 {
    width: 709px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-709 {
    width: 709px !important;
  }
}

.width-px-710 {
  width: 710px !important;
}

@media (min-width: 897px) {
  .width-px-pc-710 {
    width: 710px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-710 {
    width: 710px !important;
  }
}

.width-px-711 {
  width: 711px !important;
}

@media (min-width: 897px) {
  .width-px-pc-711 {
    width: 711px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-711 {
    width: 711px !important;
  }
}

.width-px-712 {
  width: 712px !important;
}

@media (min-width: 897px) {
  .width-px-pc-712 {
    width: 712px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-712 {
    width: 712px !important;
  }
}

.width-px-713 {
  width: 713px !important;
}

@media (min-width: 897px) {
  .width-px-pc-713 {
    width: 713px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-713 {
    width: 713px !important;
  }
}

.width-px-714 {
  width: 714px !important;
}

@media (min-width: 897px) {
  .width-px-pc-714 {
    width: 714px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-714 {
    width: 714px !important;
  }
}

.width-px-715 {
  width: 715px !important;
}

@media (min-width: 897px) {
  .width-px-pc-715 {
    width: 715px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-715 {
    width: 715px !important;
  }
}

.width-px-716 {
  width: 716px !important;
}

@media (min-width: 897px) {
  .width-px-pc-716 {
    width: 716px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-716 {
    width: 716px !important;
  }
}

.width-px-717 {
  width: 717px !important;
}

@media (min-width: 897px) {
  .width-px-pc-717 {
    width: 717px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-717 {
    width: 717px !important;
  }
}

.width-px-718 {
  width: 718px !important;
}

@media (min-width: 897px) {
  .width-px-pc-718 {
    width: 718px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-718 {
    width: 718px !important;
  }
}

.width-px-719 {
  width: 719px !important;
}

@media (min-width: 897px) {
  .width-px-pc-719 {
    width: 719px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-719 {
    width: 719px !important;
  }
}

.width-px-720 {
  width: 720px !important;
}

@media (min-width: 897px) {
  .width-px-pc-720 {
    width: 720px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-720 {
    width: 720px !important;
  }
}

.width-px-721 {
  width: 721px !important;
}

@media (min-width: 897px) {
  .width-px-pc-721 {
    width: 721px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-721 {
    width: 721px !important;
  }
}

.width-px-722 {
  width: 722px !important;
}

@media (min-width: 897px) {
  .width-px-pc-722 {
    width: 722px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-722 {
    width: 722px !important;
  }
}

.width-px-723 {
  width: 723px !important;
}

@media (min-width: 897px) {
  .width-px-pc-723 {
    width: 723px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-723 {
    width: 723px !important;
  }
}

.width-px-724 {
  width: 724px !important;
}

@media (min-width: 897px) {
  .width-px-pc-724 {
    width: 724px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-724 {
    width: 724px !important;
  }
}

.width-px-725 {
  width: 725px !important;
}

@media (min-width: 897px) {
  .width-px-pc-725 {
    width: 725px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-725 {
    width: 725px !important;
  }
}

.width-px-726 {
  width: 726px !important;
}

@media (min-width: 897px) {
  .width-px-pc-726 {
    width: 726px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-726 {
    width: 726px !important;
  }
}

.width-px-727 {
  width: 727px !important;
}

@media (min-width: 897px) {
  .width-px-pc-727 {
    width: 727px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-727 {
    width: 727px !important;
  }
}

.width-px-728 {
  width: 728px !important;
}

@media (min-width: 897px) {
  .width-px-pc-728 {
    width: 728px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-728 {
    width: 728px !important;
  }
}

.width-px-729 {
  width: 729px !important;
}

@media (min-width: 897px) {
  .width-px-pc-729 {
    width: 729px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-729 {
    width: 729px !important;
  }
}

.width-px-730 {
  width: 730px !important;
}

@media (min-width: 897px) {
  .width-px-pc-730 {
    width: 730px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-730 {
    width: 730px !important;
  }
}

.width-px-731 {
  width: 731px !important;
}

@media (min-width: 897px) {
  .width-px-pc-731 {
    width: 731px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-731 {
    width: 731px !important;
  }
}

.width-px-732 {
  width: 732px !important;
}

@media (min-width: 897px) {
  .width-px-pc-732 {
    width: 732px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-732 {
    width: 732px !important;
  }
}

.width-px-733 {
  width: 733px !important;
}

@media (min-width: 897px) {
  .width-px-pc-733 {
    width: 733px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-733 {
    width: 733px !important;
  }
}

.width-px-734 {
  width: 734px !important;
}

@media (min-width: 897px) {
  .width-px-pc-734 {
    width: 734px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-734 {
    width: 734px !important;
  }
}

.width-px-735 {
  width: 735px !important;
}

@media (min-width: 897px) {
  .width-px-pc-735 {
    width: 735px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-735 {
    width: 735px !important;
  }
}

.width-px-736 {
  width: 736px !important;
}

@media (min-width: 897px) {
  .width-px-pc-736 {
    width: 736px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-736 {
    width: 736px !important;
  }
}

.width-px-737 {
  width: 737px !important;
}

@media (min-width: 897px) {
  .width-px-pc-737 {
    width: 737px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-737 {
    width: 737px !important;
  }
}

.width-px-738 {
  width: 738px !important;
}

@media (min-width: 897px) {
  .width-px-pc-738 {
    width: 738px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-738 {
    width: 738px !important;
  }
}

.width-px-739 {
  width: 739px !important;
}

@media (min-width: 897px) {
  .width-px-pc-739 {
    width: 739px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-739 {
    width: 739px !important;
  }
}

.width-px-740 {
  width: 740px !important;
}

@media (min-width: 897px) {
  .width-px-pc-740 {
    width: 740px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-740 {
    width: 740px !important;
  }
}

.width-px-741 {
  width: 741px !important;
}

@media (min-width: 897px) {
  .width-px-pc-741 {
    width: 741px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-741 {
    width: 741px !important;
  }
}

.width-px-742 {
  width: 742px !important;
}

@media (min-width: 897px) {
  .width-px-pc-742 {
    width: 742px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-742 {
    width: 742px !important;
  }
}

.width-px-743 {
  width: 743px !important;
}

@media (min-width: 897px) {
  .width-px-pc-743 {
    width: 743px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-743 {
    width: 743px !important;
  }
}

.width-px-744 {
  width: 744px !important;
}

@media (min-width: 897px) {
  .width-px-pc-744 {
    width: 744px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-744 {
    width: 744px !important;
  }
}

.width-px-745 {
  width: 745px !important;
}

@media (min-width: 897px) {
  .width-px-pc-745 {
    width: 745px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-745 {
    width: 745px !important;
  }
}

.width-px-746 {
  width: 746px !important;
}

@media (min-width: 897px) {
  .width-px-pc-746 {
    width: 746px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-746 {
    width: 746px !important;
  }
}

.width-px-747 {
  width: 747px !important;
}

@media (min-width: 897px) {
  .width-px-pc-747 {
    width: 747px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-747 {
    width: 747px !important;
  }
}

.width-px-748 {
  width: 748px !important;
}

@media (min-width: 897px) {
  .width-px-pc-748 {
    width: 748px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-748 {
    width: 748px !important;
  }
}

.width-px-749 {
  width: 749px !important;
}

@media (min-width: 897px) {
  .width-px-pc-749 {
    width: 749px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-749 {
    width: 749px !important;
  }
}

.width-px-750 {
  width: 750px !important;
}

@media (min-width: 897px) {
  .width-px-pc-750 {
    width: 750px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-750 {
    width: 750px !important;
  }
}

.width-px-751 {
  width: 751px !important;
}

@media (min-width: 897px) {
  .width-px-pc-751 {
    width: 751px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-751 {
    width: 751px !important;
  }
}

.width-px-752 {
  width: 752px !important;
}

@media (min-width: 897px) {
  .width-px-pc-752 {
    width: 752px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-752 {
    width: 752px !important;
  }
}

.width-px-753 {
  width: 753px !important;
}

@media (min-width: 897px) {
  .width-px-pc-753 {
    width: 753px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-753 {
    width: 753px !important;
  }
}

.width-px-754 {
  width: 754px !important;
}

@media (min-width: 897px) {
  .width-px-pc-754 {
    width: 754px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-754 {
    width: 754px !important;
  }
}

.width-px-755 {
  width: 755px !important;
}

@media (min-width: 897px) {
  .width-px-pc-755 {
    width: 755px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-755 {
    width: 755px !important;
  }
}

.width-px-756 {
  width: 756px !important;
}

@media (min-width: 897px) {
  .width-px-pc-756 {
    width: 756px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-756 {
    width: 756px !important;
  }
}

.width-px-757 {
  width: 757px !important;
}

@media (min-width: 897px) {
  .width-px-pc-757 {
    width: 757px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-757 {
    width: 757px !important;
  }
}

.width-px-758 {
  width: 758px !important;
}

@media (min-width: 897px) {
  .width-px-pc-758 {
    width: 758px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-758 {
    width: 758px !important;
  }
}

.width-px-759 {
  width: 759px !important;
}

@media (min-width: 897px) {
  .width-px-pc-759 {
    width: 759px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-759 {
    width: 759px !important;
  }
}

.width-px-760 {
  width: 760px !important;
}

@media (min-width: 897px) {
  .width-px-pc-760 {
    width: 760px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-760 {
    width: 760px !important;
  }
}

.width-px-761 {
  width: 761px !important;
}

@media (min-width: 897px) {
  .width-px-pc-761 {
    width: 761px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-761 {
    width: 761px !important;
  }
}

.width-px-762 {
  width: 762px !important;
}

@media (min-width: 897px) {
  .width-px-pc-762 {
    width: 762px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-762 {
    width: 762px !important;
  }
}

.width-px-763 {
  width: 763px !important;
}

@media (min-width: 897px) {
  .width-px-pc-763 {
    width: 763px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-763 {
    width: 763px !important;
  }
}

.width-px-764 {
  width: 764px !important;
}

@media (min-width: 897px) {
  .width-px-pc-764 {
    width: 764px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-764 {
    width: 764px !important;
  }
}

.width-px-765 {
  width: 765px !important;
}

@media (min-width: 897px) {
  .width-px-pc-765 {
    width: 765px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-765 {
    width: 765px !important;
  }
}

.width-px-766 {
  width: 766px !important;
}

@media (min-width: 897px) {
  .width-px-pc-766 {
    width: 766px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-766 {
    width: 766px !important;
  }
}

.width-px-767 {
  width: 767px !important;
}

@media (min-width: 897px) {
  .width-px-pc-767 {
    width: 767px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-767 {
    width: 767px !important;
  }
}

.width-px-768 {
  width: 768px !important;
}

@media (min-width: 897px) {
  .width-px-pc-768 {
    width: 768px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-768 {
    width: 768px !important;
  }
}

.width-px-769 {
  width: 769px !important;
}

@media (min-width: 897px) {
  .width-px-pc-769 {
    width: 769px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-769 {
    width: 769px !important;
  }
}

.width-px-770 {
  width: 770px !important;
}

@media (min-width: 897px) {
  .width-px-pc-770 {
    width: 770px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-770 {
    width: 770px !important;
  }
}

.width-px-771 {
  width: 771px !important;
}

@media (min-width: 897px) {
  .width-px-pc-771 {
    width: 771px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-771 {
    width: 771px !important;
  }
}

.width-px-772 {
  width: 772px !important;
}

@media (min-width: 897px) {
  .width-px-pc-772 {
    width: 772px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-772 {
    width: 772px !important;
  }
}

.width-px-773 {
  width: 773px !important;
}

@media (min-width: 897px) {
  .width-px-pc-773 {
    width: 773px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-773 {
    width: 773px !important;
  }
}

.width-px-774 {
  width: 774px !important;
}

@media (min-width: 897px) {
  .width-px-pc-774 {
    width: 774px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-774 {
    width: 774px !important;
  }
}

.width-px-775 {
  width: 775px !important;
}

@media (min-width: 897px) {
  .width-px-pc-775 {
    width: 775px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-775 {
    width: 775px !important;
  }
}

.width-px-776 {
  width: 776px !important;
}

@media (min-width: 897px) {
  .width-px-pc-776 {
    width: 776px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-776 {
    width: 776px !important;
  }
}

.width-px-777 {
  width: 777px !important;
}

@media (min-width: 897px) {
  .width-px-pc-777 {
    width: 777px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-777 {
    width: 777px !important;
  }
}

.width-px-778 {
  width: 778px !important;
}

@media (min-width: 897px) {
  .width-px-pc-778 {
    width: 778px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-778 {
    width: 778px !important;
  }
}

.width-px-779 {
  width: 779px !important;
}

@media (min-width: 897px) {
  .width-px-pc-779 {
    width: 779px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-779 {
    width: 779px !important;
  }
}

.width-px-780 {
  width: 780px !important;
}

@media (min-width: 897px) {
  .width-px-pc-780 {
    width: 780px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-780 {
    width: 780px !important;
  }
}

.width-px-781 {
  width: 781px !important;
}

@media (min-width: 897px) {
  .width-px-pc-781 {
    width: 781px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-781 {
    width: 781px !important;
  }
}

.width-px-782 {
  width: 782px !important;
}

@media (min-width: 897px) {
  .width-px-pc-782 {
    width: 782px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-782 {
    width: 782px !important;
  }
}

.width-px-783 {
  width: 783px !important;
}

@media (min-width: 897px) {
  .width-px-pc-783 {
    width: 783px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-783 {
    width: 783px !important;
  }
}

.width-px-784 {
  width: 784px !important;
}

@media (min-width: 897px) {
  .width-px-pc-784 {
    width: 784px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-784 {
    width: 784px !important;
  }
}

.width-px-785 {
  width: 785px !important;
}

@media (min-width: 897px) {
  .width-px-pc-785 {
    width: 785px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-785 {
    width: 785px !important;
  }
}

.width-px-786 {
  width: 786px !important;
}

@media (min-width: 897px) {
  .width-px-pc-786 {
    width: 786px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-786 {
    width: 786px !important;
  }
}

.width-px-787 {
  width: 787px !important;
}

@media (min-width: 897px) {
  .width-px-pc-787 {
    width: 787px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-787 {
    width: 787px !important;
  }
}

.width-px-788 {
  width: 788px !important;
}

@media (min-width: 897px) {
  .width-px-pc-788 {
    width: 788px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-788 {
    width: 788px !important;
  }
}

.width-px-789 {
  width: 789px !important;
}

@media (min-width: 897px) {
  .width-px-pc-789 {
    width: 789px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-789 {
    width: 789px !important;
  }
}

.width-px-790 {
  width: 790px !important;
}

@media (min-width: 897px) {
  .width-px-pc-790 {
    width: 790px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-790 {
    width: 790px !important;
  }
}

.width-px-791 {
  width: 791px !important;
}

@media (min-width: 897px) {
  .width-px-pc-791 {
    width: 791px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-791 {
    width: 791px !important;
  }
}

.width-px-792 {
  width: 792px !important;
}

@media (min-width: 897px) {
  .width-px-pc-792 {
    width: 792px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-792 {
    width: 792px !important;
  }
}

.width-px-793 {
  width: 793px !important;
}

@media (min-width: 897px) {
  .width-px-pc-793 {
    width: 793px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-793 {
    width: 793px !important;
  }
}

.width-px-794 {
  width: 794px !important;
}

@media (min-width: 897px) {
  .width-px-pc-794 {
    width: 794px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-794 {
    width: 794px !important;
  }
}

.width-px-795 {
  width: 795px !important;
}

@media (min-width: 897px) {
  .width-px-pc-795 {
    width: 795px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-795 {
    width: 795px !important;
  }
}

.width-px-796 {
  width: 796px !important;
}

@media (min-width: 897px) {
  .width-px-pc-796 {
    width: 796px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-796 {
    width: 796px !important;
  }
}

.width-px-797 {
  width: 797px !important;
}

@media (min-width: 897px) {
  .width-px-pc-797 {
    width: 797px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-797 {
    width: 797px !important;
  }
}

.width-px-798 {
  width: 798px !important;
}

@media (min-width: 897px) {
  .width-px-pc-798 {
    width: 798px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-798 {
    width: 798px !important;
  }
}

.width-px-799 {
  width: 799px !important;
}

@media (min-width: 897px) {
  .width-px-pc-799 {
    width: 799px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-799 {
    width: 799px !important;
  }
}

.width-px-800 {
  width: 800px !important;
}

@media (min-width: 897px) {
  .width-px-pc-800 {
    width: 800px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-800 {
    width: 800px !important;
  }
}

.width-px-801 {
  width: 801px !important;
}

@media (min-width: 897px) {
  .width-px-pc-801 {
    width: 801px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-801 {
    width: 801px !important;
  }
}

.width-px-802 {
  width: 802px !important;
}

@media (min-width: 897px) {
  .width-px-pc-802 {
    width: 802px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-802 {
    width: 802px !important;
  }
}

.width-px-803 {
  width: 803px !important;
}

@media (min-width: 897px) {
  .width-px-pc-803 {
    width: 803px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-803 {
    width: 803px !important;
  }
}

.width-px-804 {
  width: 804px !important;
}

@media (min-width: 897px) {
  .width-px-pc-804 {
    width: 804px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-804 {
    width: 804px !important;
  }
}

.width-px-805 {
  width: 805px !important;
}

@media (min-width: 897px) {
  .width-px-pc-805 {
    width: 805px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-805 {
    width: 805px !important;
  }
}

.width-px-806 {
  width: 806px !important;
}

@media (min-width: 897px) {
  .width-px-pc-806 {
    width: 806px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-806 {
    width: 806px !important;
  }
}

.width-px-807 {
  width: 807px !important;
}

@media (min-width: 897px) {
  .width-px-pc-807 {
    width: 807px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-807 {
    width: 807px !important;
  }
}

.width-px-808 {
  width: 808px !important;
}

@media (min-width: 897px) {
  .width-px-pc-808 {
    width: 808px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-808 {
    width: 808px !important;
  }
}

.width-px-809 {
  width: 809px !important;
}

@media (min-width: 897px) {
  .width-px-pc-809 {
    width: 809px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-809 {
    width: 809px !important;
  }
}

.width-px-810 {
  width: 810px !important;
}

@media (min-width: 897px) {
  .width-px-pc-810 {
    width: 810px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-810 {
    width: 810px !important;
  }
}

.width-px-811 {
  width: 811px !important;
}

@media (min-width: 897px) {
  .width-px-pc-811 {
    width: 811px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-811 {
    width: 811px !important;
  }
}

.width-px-812 {
  width: 812px !important;
}

@media (min-width: 897px) {
  .width-px-pc-812 {
    width: 812px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-812 {
    width: 812px !important;
  }
}

.width-px-813 {
  width: 813px !important;
}

@media (min-width: 897px) {
  .width-px-pc-813 {
    width: 813px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-813 {
    width: 813px !important;
  }
}

.width-px-814 {
  width: 814px !important;
}

@media (min-width: 897px) {
  .width-px-pc-814 {
    width: 814px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-814 {
    width: 814px !important;
  }
}

.width-px-815 {
  width: 815px !important;
}

@media (min-width: 897px) {
  .width-px-pc-815 {
    width: 815px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-815 {
    width: 815px !important;
  }
}

.width-px-816 {
  width: 816px !important;
}

@media (min-width: 897px) {
  .width-px-pc-816 {
    width: 816px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-816 {
    width: 816px !important;
  }
}

.width-px-817 {
  width: 817px !important;
}

@media (min-width: 897px) {
  .width-px-pc-817 {
    width: 817px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-817 {
    width: 817px !important;
  }
}

.width-px-818 {
  width: 818px !important;
}

@media (min-width: 897px) {
  .width-px-pc-818 {
    width: 818px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-818 {
    width: 818px !important;
  }
}

.width-px-819 {
  width: 819px !important;
}

@media (min-width: 897px) {
  .width-px-pc-819 {
    width: 819px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-819 {
    width: 819px !important;
  }
}

.width-px-820 {
  width: 820px !important;
}

@media (min-width: 897px) {
  .width-px-pc-820 {
    width: 820px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-820 {
    width: 820px !important;
  }
}

.width-px-821 {
  width: 821px !important;
}

@media (min-width: 897px) {
  .width-px-pc-821 {
    width: 821px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-821 {
    width: 821px !important;
  }
}

.width-px-822 {
  width: 822px !important;
}

@media (min-width: 897px) {
  .width-px-pc-822 {
    width: 822px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-822 {
    width: 822px !important;
  }
}

.width-px-823 {
  width: 823px !important;
}

@media (min-width: 897px) {
  .width-px-pc-823 {
    width: 823px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-823 {
    width: 823px !important;
  }
}

.width-px-824 {
  width: 824px !important;
}

@media (min-width: 897px) {
  .width-px-pc-824 {
    width: 824px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-824 {
    width: 824px !important;
  }
}

.width-px-825 {
  width: 825px !important;
}

@media (min-width: 897px) {
  .width-px-pc-825 {
    width: 825px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-825 {
    width: 825px !important;
  }
}

.width-px-826 {
  width: 826px !important;
}

@media (min-width: 897px) {
  .width-px-pc-826 {
    width: 826px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-826 {
    width: 826px !important;
  }
}

.width-px-827 {
  width: 827px !important;
}

@media (min-width: 897px) {
  .width-px-pc-827 {
    width: 827px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-827 {
    width: 827px !important;
  }
}

.width-px-828 {
  width: 828px !important;
}

@media (min-width: 897px) {
  .width-px-pc-828 {
    width: 828px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-828 {
    width: 828px !important;
  }
}

.width-px-829 {
  width: 829px !important;
}

@media (min-width: 897px) {
  .width-px-pc-829 {
    width: 829px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-829 {
    width: 829px !important;
  }
}

.width-px-830 {
  width: 830px !important;
}

@media (min-width: 897px) {
  .width-px-pc-830 {
    width: 830px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-830 {
    width: 830px !important;
  }
}

.width-px-831 {
  width: 831px !important;
}

@media (min-width: 897px) {
  .width-px-pc-831 {
    width: 831px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-831 {
    width: 831px !important;
  }
}

.width-px-832 {
  width: 832px !important;
}

@media (min-width: 897px) {
  .width-px-pc-832 {
    width: 832px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-832 {
    width: 832px !important;
  }
}

.width-px-833 {
  width: 833px !important;
}

@media (min-width: 897px) {
  .width-px-pc-833 {
    width: 833px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-833 {
    width: 833px !important;
  }
}

.width-px-834 {
  width: 834px !important;
}

@media (min-width: 897px) {
  .width-px-pc-834 {
    width: 834px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-834 {
    width: 834px !important;
  }
}

.width-px-835 {
  width: 835px !important;
}

@media (min-width: 897px) {
  .width-px-pc-835 {
    width: 835px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-835 {
    width: 835px !important;
  }
}

.width-px-836 {
  width: 836px !important;
}

@media (min-width: 897px) {
  .width-px-pc-836 {
    width: 836px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-836 {
    width: 836px !important;
  }
}

.width-px-837 {
  width: 837px !important;
}

@media (min-width: 897px) {
  .width-px-pc-837 {
    width: 837px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-837 {
    width: 837px !important;
  }
}

.width-px-838 {
  width: 838px !important;
}

@media (min-width: 897px) {
  .width-px-pc-838 {
    width: 838px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-838 {
    width: 838px !important;
  }
}

.width-px-839 {
  width: 839px !important;
}

@media (min-width: 897px) {
  .width-px-pc-839 {
    width: 839px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-839 {
    width: 839px !important;
  }
}

.width-px-840 {
  width: 840px !important;
}

@media (min-width: 897px) {
  .width-px-pc-840 {
    width: 840px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-840 {
    width: 840px !important;
  }
}

.width-px-841 {
  width: 841px !important;
}

@media (min-width: 897px) {
  .width-px-pc-841 {
    width: 841px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-841 {
    width: 841px !important;
  }
}

.width-px-842 {
  width: 842px !important;
}

@media (min-width: 897px) {
  .width-px-pc-842 {
    width: 842px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-842 {
    width: 842px !important;
  }
}

.width-px-843 {
  width: 843px !important;
}

@media (min-width: 897px) {
  .width-px-pc-843 {
    width: 843px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-843 {
    width: 843px !important;
  }
}

.width-px-844 {
  width: 844px !important;
}

@media (min-width: 897px) {
  .width-px-pc-844 {
    width: 844px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-844 {
    width: 844px !important;
  }
}

.width-px-845 {
  width: 845px !important;
}

@media (min-width: 897px) {
  .width-px-pc-845 {
    width: 845px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-845 {
    width: 845px !important;
  }
}

.width-px-846 {
  width: 846px !important;
}

@media (min-width: 897px) {
  .width-px-pc-846 {
    width: 846px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-846 {
    width: 846px !important;
  }
}

.width-px-847 {
  width: 847px !important;
}

@media (min-width: 897px) {
  .width-px-pc-847 {
    width: 847px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-847 {
    width: 847px !important;
  }
}

.width-px-848 {
  width: 848px !important;
}

@media (min-width: 897px) {
  .width-px-pc-848 {
    width: 848px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-848 {
    width: 848px !important;
  }
}

.width-px-849 {
  width: 849px !important;
}

@media (min-width: 897px) {
  .width-px-pc-849 {
    width: 849px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-849 {
    width: 849px !important;
  }
}

.width-px-850 {
  width: 850px !important;
}

@media (min-width: 897px) {
  .width-px-pc-850 {
    width: 850px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-850 {
    width: 850px !important;
  }
}

.width-px-851 {
  width: 851px !important;
}

@media (min-width: 897px) {
  .width-px-pc-851 {
    width: 851px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-851 {
    width: 851px !important;
  }
}

.width-px-852 {
  width: 852px !important;
}

@media (min-width: 897px) {
  .width-px-pc-852 {
    width: 852px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-852 {
    width: 852px !important;
  }
}

.width-px-853 {
  width: 853px !important;
}

@media (min-width: 897px) {
  .width-px-pc-853 {
    width: 853px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-853 {
    width: 853px !important;
  }
}

.width-px-854 {
  width: 854px !important;
}

@media (min-width: 897px) {
  .width-px-pc-854 {
    width: 854px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-854 {
    width: 854px !important;
  }
}

.width-px-855 {
  width: 855px !important;
}

@media (min-width: 897px) {
  .width-px-pc-855 {
    width: 855px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-855 {
    width: 855px !important;
  }
}

.width-px-856 {
  width: 856px !important;
}

@media (min-width: 897px) {
  .width-px-pc-856 {
    width: 856px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-856 {
    width: 856px !important;
  }
}

.width-px-857 {
  width: 857px !important;
}

@media (min-width: 897px) {
  .width-px-pc-857 {
    width: 857px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-857 {
    width: 857px !important;
  }
}

.width-px-858 {
  width: 858px !important;
}

@media (min-width: 897px) {
  .width-px-pc-858 {
    width: 858px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-858 {
    width: 858px !important;
  }
}

.width-px-859 {
  width: 859px !important;
}

@media (min-width: 897px) {
  .width-px-pc-859 {
    width: 859px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-859 {
    width: 859px !important;
  }
}

.width-px-860 {
  width: 860px !important;
}

@media (min-width: 897px) {
  .width-px-pc-860 {
    width: 860px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-860 {
    width: 860px !important;
  }
}

.width-px-861 {
  width: 861px !important;
}

@media (min-width: 897px) {
  .width-px-pc-861 {
    width: 861px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-861 {
    width: 861px !important;
  }
}

.width-px-862 {
  width: 862px !important;
}

@media (min-width: 897px) {
  .width-px-pc-862 {
    width: 862px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-862 {
    width: 862px !important;
  }
}

.width-px-863 {
  width: 863px !important;
}

@media (min-width: 897px) {
  .width-px-pc-863 {
    width: 863px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-863 {
    width: 863px !important;
  }
}

.width-px-864 {
  width: 864px !important;
}

@media (min-width: 897px) {
  .width-px-pc-864 {
    width: 864px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-864 {
    width: 864px !important;
  }
}

.width-px-865 {
  width: 865px !important;
}

@media (min-width: 897px) {
  .width-px-pc-865 {
    width: 865px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-865 {
    width: 865px !important;
  }
}

.width-px-866 {
  width: 866px !important;
}

@media (min-width: 897px) {
  .width-px-pc-866 {
    width: 866px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-866 {
    width: 866px !important;
  }
}

.width-px-867 {
  width: 867px !important;
}

@media (min-width: 897px) {
  .width-px-pc-867 {
    width: 867px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-867 {
    width: 867px !important;
  }
}

.width-px-868 {
  width: 868px !important;
}

@media (min-width: 897px) {
  .width-px-pc-868 {
    width: 868px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-868 {
    width: 868px !important;
  }
}

.width-px-869 {
  width: 869px !important;
}

@media (min-width: 897px) {
  .width-px-pc-869 {
    width: 869px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-869 {
    width: 869px !important;
  }
}

.width-px-870 {
  width: 870px !important;
}

@media (min-width: 897px) {
  .width-px-pc-870 {
    width: 870px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-870 {
    width: 870px !important;
  }
}

.width-px-871 {
  width: 871px !important;
}

@media (min-width: 897px) {
  .width-px-pc-871 {
    width: 871px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-871 {
    width: 871px !important;
  }
}

.width-px-872 {
  width: 872px !important;
}

@media (min-width: 897px) {
  .width-px-pc-872 {
    width: 872px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-872 {
    width: 872px !important;
  }
}

.width-px-873 {
  width: 873px !important;
}

@media (min-width: 897px) {
  .width-px-pc-873 {
    width: 873px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-873 {
    width: 873px !important;
  }
}

.width-px-874 {
  width: 874px !important;
}

@media (min-width: 897px) {
  .width-px-pc-874 {
    width: 874px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-874 {
    width: 874px !important;
  }
}

.width-px-875 {
  width: 875px !important;
}

@media (min-width: 897px) {
  .width-px-pc-875 {
    width: 875px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-875 {
    width: 875px !important;
  }
}

.width-px-876 {
  width: 876px !important;
}

@media (min-width: 897px) {
  .width-px-pc-876 {
    width: 876px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-876 {
    width: 876px !important;
  }
}

.width-px-877 {
  width: 877px !important;
}

@media (min-width: 897px) {
  .width-px-pc-877 {
    width: 877px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-877 {
    width: 877px !important;
  }
}

.width-px-878 {
  width: 878px !important;
}

@media (min-width: 897px) {
  .width-px-pc-878 {
    width: 878px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-878 {
    width: 878px !important;
  }
}

.width-px-879 {
  width: 879px !important;
}

@media (min-width: 897px) {
  .width-px-pc-879 {
    width: 879px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-879 {
    width: 879px !important;
  }
}

.width-px-880 {
  width: 880px !important;
}

@media (min-width: 897px) {
  .width-px-pc-880 {
    width: 880px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-880 {
    width: 880px !important;
  }
}

.width-px-881 {
  width: 881px !important;
}

@media (min-width: 897px) {
  .width-px-pc-881 {
    width: 881px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-881 {
    width: 881px !important;
  }
}

.width-px-882 {
  width: 882px !important;
}

@media (min-width: 897px) {
  .width-px-pc-882 {
    width: 882px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-882 {
    width: 882px !important;
  }
}

.width-px-883 {
  width: 883px !important;
}

@media (min-width: 897px) {
  .width-px-pc-883 {
    width: 883px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-883 {
    width: 883px !important;
  }
}

.width-px-884 {
  width: 884px !important;
}

@media (min-width: 897px) {
  .width-px-pc-884 {
    width: 884px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-884 {
    width: 884px !important;
  }
}

.width-px-885 {
  width: 885px !important;
}

@media (min-width: 897px) {
  .width-px-pc-885 {
    width: 885px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-885 {
    width: 885px !important;
  }
}

.width-px-886 {
  width: 886px !important;
}

@media (min-width: 897px) {
  .width-px-pc-886 {
    width: 886px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-886 {
    width: 886px !important;
  }
}

.width-px-887 {
  width: 887px !important;
}

@media (min-width: 897px) {
  .width-px-pc-887 {
    width: 887px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-887 {
    width: 887px !important;
  }
}

.width-px-888 {
  width: 888px !important;
}

@media (min-width: 897px) {
  .width-px-pc-888 {
    width: 888px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-888 {
    width: 888px !important;
  }
}

.width-px-889 {
  width: 889px !important;
}

@media (min-width: 897px) {
  .width-px-pc-889 {
    width: 889px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-889 {
    width: 889px !important;
  }
}

.width-px-890 {
  width: 890px !important;
}

@media (min-width: 897px) {
  .width-px-pc-890 {
    width: 890px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-890 {
    width: 890px !important;
  }
}

.width-px-891 {
  width: 891px !important;
}

@media (min-width: 897px) {
  .width-px-pc-891 {
    width: 891px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-891 {
    width: 891px !important;
  }
}

.width-px-892 {
  width: 892px !important;
}

@media (min-width: 897px) {
  .width-px-pc-892 {
    width: 892px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-892 {
    width: 892px !important;
  }
}

.width-px-893 {
  width: 893px !important;
}

@media (min-width: 897px) {
  .width-px-pc-893 {
    width: 893px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-893 {
    width: 893px !important;
  }
}

.width-px-894 {
  width: 894px !important;
}

@media (min-width: 897px) {
  .width-px-pc-894 {
    width: 894px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-894 {
    width: 894px !important;
  }
}

.width-px-895 {
  width: 895px !important;
}

@media (min-width: 897px) {
  .width-px-pc-895 {
    width: 895px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-895 {
    width: 895px !important;
  }
}

.width-px-896 {
  width: 896px !important;
}

@media (min-width: 897px) {
  .width-px-pc-896 {
    width: 896px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-896 {
    width: 896px !important;
  }
}

.width-px-897 {
  width: 897px !important;
}

@media (min-width: 897px) {
  .width-px-pc-897 {
    width: 897px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-897 {
    width: 897px !important;
  }
}

.width-px-898 {
  width: 898px !important;
}

@media (min-width: 897px) {
  .width-px-pc-898 {
    width: 898px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-898 {
    width: 898px !important;
  }
}

.width-px-899 {
  width: 899px !important;
}

@media (min-width: 897px) {
  .width-px-pc-899 {
    width: 899px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-899 {
    width: 899px !important;
  }
}

.width-px-900 {
  width: 900px !important;
}

@media (min-width: 897px) {
  .width-px-pc-900 {
    width: 900px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-900 {
    width: 900px !important;
  }
}

.width-px-901 {
  width: 901px !important;
}

@media (min-width: 897px) {
  .width-px-pc-901 {
    width: 901px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-901 {
    width: 901px !important;
  }
}

.width-px-902 {
  width: 902px !important;
}

@media (min-width: 897px) {
  .width-px-pc-902 {
    width: 902px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-902 {
    width: 902px !important;
  }
}

.width-px-903 {
  width: 903px !important;
}

@media (min-width: 897px) {
  .width-px-pc-903 {
    width: 903px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-903 {
    width: 903px !important;
  }
}

.width-px-904 {
  width: 904px !important;
}

@media (min-width: 897px) {
  .width-px-pc-904 {
    width: 904px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-904 {
    width: 904px !important;
  }
}

.width-px-905 {
  width: 905px !important;
}

@media (min-width: 897px) {
  .width-px-pc-905 {
    width: 905px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-905 {
    width: 905px !important;
  }
}

.width-px-906 {
  width: 906px !important;
}

@media (min-width: 897px) {
  .width-px-pc-906 {
    width: 906px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-906 {
    width: 906px !important;
  }
}

.width-px-907 {
  width: 907px !important;
}

@media (min-width: 897px) {
  .width-px-pc-907 {
    width: 907px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-907 {
    width: 907px !important;
  }
}

.width-px-908 {
  width: 908px !important;
}

@media (min-width: 897px) {
  .width-px-pc-908 {
    width: 908px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-908 {
    width: 908px !important;
  }
}

.width-px-909 {
  width: 909px !important;
}

@media (min-width: 897px) {
  .width-px-pc-909 {
    width: 909px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-909 {
    width: 909px !important;
  }
}

.width-px-910 {
  width: 910px !important;
}

@media (min-width: 897px) {
  .width-px-pc-910 {
    width: 910px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-910 {
    width: 910px !important;
  }
}

.width-px-911 {
  width: 911px !important;
}

@media (min-width: 897px) {
  .width-px-pc-911 {
    width: 911px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-911 {
    width: 911px !important;
  }
}

.width-px-912 {
  width: 912px !important;
}

@media (min-width: 897px) {
  .width-px-pc-912 {
    width: 912px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-912 {
    width: 912px !important;
  }
}

.width-px-913 {
  width: 913px !important;
}

@media (min-width: 897px) {
  .width-px-pc-913 {
    width: 913px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-913 {
    width: 913px !important;
  }
}

.width-px-914 {
  width: 914px !important;
}

@media (min-width: 897px) {
  .width-px-pc-914 {
    width: 914px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-914 {
    width: 914px !important;
  }
}

.width-px-915 {
  width: 915px !important;
}

@media (min-width: 897px) {
  .width-px-pc-915 {
    width: 915px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-915 {
    width: 915px !important;
  }
}

.width-px-916 {
  width: 916px !important;
}

@media (min-width: 897px) {
  .width-px-pc-916 {
    width: 916px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-916 {
    width: 916px !important;
  }
}

.width-px-917 {
  width: 917px !important;
}

@media (min-width: 897px) {
  .width-px-pc-917 {
    width: 917px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-917 {
    width: 917px !important;
  }
}

.width-px-918 {
  width: 918px !important;
}

@media (min-width: 897px) {
  .width-px-pc-918 {
    width: 918px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-918 {
    width: 918px !important;
  }
}

.width-px-919 {
  width: 919px !important;
}

@media (min-width: 897px) {
  .width-px-pc-919 {
    width: 919px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-919 {
    width: 919px !important;
  }
}

.width-px-920 {
  width: 920px !important;
}

@media (min-width: 897px) {
  .width-px-pc-920 {
    width: 920px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-920 {
    width: 920px !important;
  }
}

.width-px-921 {
  width: 921px !important;
}

@media (min-width: 897px) {
  .width-px-pc-921 {
    width: 921px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-921 {
    width: 921px !important;
  }
}

.width-px-922 {
  width: 922px !important;
}

@media (min-width: 897px) {
  .width-px-pc-922 {
    width: 922px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-922 {
    width: 922px !important;
  }
}

.width-px-923 {
  width: 923px !important;
}

@media (min-width: 897px) {
  .width-px-pc-923 {
    width: 923px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-923 {
    width: 923px !important;
  }
}

.width-px-924 {
  width: 924px !important;
}

@media (min-width: 897px) {
  .width-px-pc-924 {
    width: 924px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-924 {
    width: 924px !important;
  }
}

.width-px-925 {
  width: 925px !important;
}

@media (min-width: 897px) {
  .width-px-pc-925 {
    width: 925px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-925 {
    width: 925px !important;
  }
}

.width-px-926 {
  width: 926px !important;
}

@media (min-width: 897px) {
  .width-px-pc-926 {
    width: 926px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-926 {
    width: 926px !important;
  }
}

.width-px-927 {
  width: 927px !important;
}

@media (min-width: 897px) {
  .width-px-pc-927 {
    width: 927px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-927 {
    width: 927px !important;
  }
}

.width-px-928 {
  width: 928px !important;
}

@media (min-width: 897px) {
  .width-px-pc-928 {
    width: 928px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-928 {
    width: 928px !important;
  }
}

.width-px-929 {
  width: 929px !important;
}

@media (min-width: 897px) {
  .width-px-pc-929 {
    width: 929px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-929 {
    width: 929px !important;
  }
}

.width-px-930 {
  width: 930px !important;
}

@media (min-width: 897px) {
  .width-px-pc-930 {
    width: 930px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-930 {
    width: 930px !important;
  }
}

.width-px-931 {
  width: 931px !important;
}

@media (min-width: 897px) {
  .width-px-pc-931 {
    width: 931px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-931 {
    width: 931px !important;
  }
}

.width-px-932 {
  width: 932px !important;
}

@media (min-width: 897px) {
  .width-px-pc-932 {
    width: 932px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-932 {
    width: 932px !important;
  }
}

.width-px-933 {
  width: 933px !important;
}

@media (min-width: 897px) {
  .width-px-pc-933 {
    width: 933px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-933 {
    width: 933px !important;
  }
}

.width-px-934 {
  width: 934px !important;
}

@media (min-width: 897px) {
  .width-px-pc-934 {
    width: 934px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-934 {
    width: 934px !important;
  }
}

.width-px-935 {
  width: 935px !important;
}

@media (min-width: 897px) {
  .width-px-pc-935 {
    width: 935px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-935 {
    width: 935px !important;
  }
}

.width-px-936 {
  width: 936px !important;
}

@media (min-width: 897px) {
  .width-px-pc-936 {
    width: 936px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-936 {
    width: 936px !important;
  }
}

.width-px-937 {
  width: 937px !important;
}

@media (min-width: 897px) {
  .width-px-pc-937 {
    width: 937px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-937 {
    width: 937px !important;
  }
}

.width-px-938 {
  width: 938px !important;
}

@media (min-width: 897px) {
  .width-px-pc-938 {
    width: 938px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-938 {
    width: 938px !important;
  }
}

.width-px-939 {
  width: 939px !important;
}

@media (min-width: 897px) {
  .width-px-pc-939 {
    width: 939px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-939 {
    width: 939px !important;
  }
}

.width-px-940 {
  width: 940px !important;
}

@media (min-width: 897px) {
  .width-px-pc-940 {
    width: 940px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-940 {
    width: 940px !important;
  }
}

.width-px-941 {
  width: 941px !important;
}

@media (min-width: 897px) {
  .width-px-pc-941 {
    width: 941px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-941 {
    width: 941px !important;
  }
}

.width-px-942 {
  width: 942px !important;
}

@media (min-width: 897px) {
  .width-px-pc-942 {
    width: 942px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-942 {
    width: 942px !important;
  }
}

.width-px-943 {
  width: 943px !important;
}

@media (min-width: 897px) {
  .width-px-pc-943 {
    width: 943px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-943 {
    width: 943px !important;
  }
}

.width-px-944 {
  width: 944px !important;
}

@media (min-width: 897px) {
  .width-px-pc-944 {
    width: 944px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-944 {
    width: 944px !important;
  }
}

.width-px-945 {
  width: 945px !important;
}

@media (min-width: 897px) {
  .width-px-pc-945 {
    width: 945px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-945 {
    width: 945px !important;
  }
}

.width-px-946 {
  width: 946px !important;
}

@media (min-width: 897px) {
  .width-px-pc-946 {
    width: 946px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-946 {
    width: 946px !important;
  }
}

.width-px-947 {
  width: 947px !important;
}

@media (min-width: 897px) {
  .width-px-pc-947 {
    width: 947px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-947 {
    width: 947px !important;
  }
}

.width-px-948 {
  width: 948px !important;
}

@media (min-width: 897px) {
  .width-px-pc-948 {
    width: 948px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-948 {
    width: 948px !important;
  }
}

.width-px-949 {
  width: 949px !important;
}

@media (min-width: 897px) {
  .width-px-pc-949 {
    width: 949px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-949 {
    width: 949px !important;
  }
}

.width-px-950 {
  width: 950px !important;
}

@media (min-width: 897px) {
  .width-px-pc-950 {
    width: 950px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-950 {
    width: 950px !important;
  }
}

.width-px-951 {
  width: 951px !important;
}

@media (min-width: 897px) {
  .width-px-pc-951 {
    width: 951px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-951 {
    width: 951px !important;
  }
}

.width-px-952 {
  width: 952px !important;
}

@media (min-width: 897px) {
  .width-px-pc-952 {
    width: 952px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-952 {
    width: 952px !important;
  }
}

.width-px-953 {
  width: 953px !important;
}

@media (min-width: 897px) {
  .width-px-pc-953 {
    width: 953px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-953 {
    width: 953px !important;
  }
}

.width-px-954 {
  width: 954px !important;
}

@media (min-width: 897px) {
  .width-px-pc-954 {
    width: 954px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-954 {
    width: 954px !important;
  }
}

.width-px-955 {
  width: 955px !important;
}

@media (min-width: 897px) {
  .width-px-pc-955 {
    width: 955px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-955 {
    width: 955px !important;
  }
}

.width-px-956 {
  width: 956px !important;
}

@media (min-width: 897px) {
  .width-px-pc-956 {
    width: 956px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-956 {
    width: 956px !important;
  }
}

.width-px-957 {
  width: 957px !important;
}

@media (min-width: 897px) {
  .width-px-pc-957 {
    width: 957px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-957 {
    width: 957px !important;
  }
}

.width-px-958 {
  width: 958px !important;
}

@media (min-width: 897px) {
  .width-px-pc-958 {
    width: 958px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-958 {
    width: 958px !important;
  }
}

.width-px-959 {
  width: 959px !important;
}

@media (min-width: 897px) {
  .width-px-pc-959 {
    width: 959px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-959 {
    width: 959px !important;
  }
}

.width-px-960 {
  width: 960px !important;
}

@media (min-width: 897px) {
  .width-px-pc-960 {
    width: 960px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-960 {
    width: 960px !important;
  }
}

.width-px-961 {
  width: 961px !important;
}

@media (min-width: 897px) {
  .width-px-pc-961 {
    width: 961px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-961 {
    width: 961px !important;
  }
}

.width-px-962 {
  width: 962px !important;
}

@media (min-width: 897px) {
  .width-px-pc-962 {
    width: 962px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-962 {
    width: 962px !important;
  }
}

.width-px-963 {
  width: 963px !important;
}

@media (min-width: 897px) {
  .width-px-pc-963 {
    width: 963px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-963 {
    width: 963px !important;
  }
}

.width-px-964 {
  width: 964px !important;
}

@media (min-width: 897px) {
  .width-px-pc-964 {
    width: 964px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-964 {
    width: 964px !important;
  }
}

.width-px-965 {
  width: 965px !important;
}

@media (min-width: 897px) {
  .width-px-pc-965 {
    width: 965px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-965 {
    width: 965px !important;
  }
}

.width-px-966 {
  width: 966px !important;
}

@media (min-width: 897px) {
  .width-px-pc-966 {
    width: 966px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-966 {
    width: 966px !important;
  }
}

.width-px-967 {
  width: 967px !important;
}

@media (min-width: 897px) {
  .width-px-pc-967 {
    width: 967px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-967 {
    width: 967px !important;
  }
}

.width-px-968 {
  width: 968px !important;
}

@media (min-width: 897px) {
  .width-px-pc-968 {
    width: 968px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-968 {
    width: 968px !important;
  }
}

.width-px-969 {
  width: 969px !important;
}

@media (min-width: 897px) {
  .width-px-pc-969 {
    width: 969px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-969 {
    width: 969px !important;
  }
}

.width-px-970 {
  width: 970px !important;
}

@media (min-width: 897px) {
  .width-px-pc-970 {
    width: 970px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-970 {
    width: 970px !important;
  }
}

.width-px-971 {
  width: 971px !important;
}

@media (min-width: 897px) {
  .width-px-pc-971 {
    width: 971px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-971 {
    width: 971px !important;
  }
}

.width-px-972 {
  width: 972px !important;
}

@media (min-width: 897px) {
  .width-px-pc-972 {
    width: 972px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-972 {
    width: 972px !important;
  }
}

.width-px-973 {
  width: 973px !important;
}

@media (min-width: 897px) {
  .width-px-pc-973 {
    width: 973px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-973 {
    width: 973px !important;
  }
}

.width-px-974 {
  width: 974px !important;
}

@media (min-width: 897px) {
  .width-px-pc-974 {
    width: 974px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-974 {
    width: 974px !important;
  }
}

.width-px-975 {
  width: 975px !important;
}

@media (min-width: 897px) {
  .width-px-pc-975 {
    width: 975px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-975 {
    width: 975px !important;
  }
}

.width-px-976 {
  width: 976px !important;
}

@media (min-width: 897px) {
  .width-px-pc-976 {
    width: 976px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-976 {
    width: 976px !important;
  }
}

.width-px-977 {
  width: 977px !important;
}

@media (min-width: 897px) {
  .width-px-pc-977 {
    width: 977px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-977 {
    width: 977px !important;
  }
}

.width-px-978 {
  width: 978px !important;
}

@media (min-width: 897px) {
  .width-px-pc-978 {
    width: 978px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-978 {
    width: 978px !important;
  }
}

.width-px-979 {
  width: 979px !important;
}

@media (min-width: 897px) {
  .width-px-pc-979 {
    width: 979px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-979 {
    width: 979px !important;
  }
}

.width-px-980 {
  width: 980px !important;
}

@media (min-width: 897px) {
  .width-px-pc-980 {
    width: 980px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-980 {
    width: 980px !important;
  }
}

.width-px-981 {
  width: 981px !important;
}

@media (min-width: 897px) {
  .width-px-pc-981 {
    width: 981px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-981 {
    width: 981px !important;
  }
}

.width-px-982 {
  width: 982px !important;
}

@media (min-width: 897px) {
  .width-px-pc-982 {
    width: 982px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-982 {
    width: 982px !important;
  }
}

.width-px-983 {
  width: 983px !important;
}

@media (min-width: 897px) {
  .width-px-pc-983 {
    width: 983px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-983 {
    width: 983px !important;
  }
}

.width-px-984 {
  width: 984px !important;
}

@media (min-width: 897px) {
  .width-px-pc-984 {
    width: 984px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-984 {
    width: 984px !important;
  }
}

.width-px-985 {
  width: 985px !important;
}

@media (min-width: 897px) {
  .width-px-pc-985 {
    width: 985px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-985 {
    width: 985px !important;
  }
}

.width-px-986 {
  width: 986px !important;
}

@media (min-width: 897px) {
  .width-px-pc-986 {
    width: 986px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-986 {
    width: 986px !important;
  }
}

.width-px-987 {
  width: 987px !important;
}

@media (min-width: 897px) {
  .width-px-pc-987 {
    width: 987px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-987 {
    width: 987px !important;
  }
}

.width-px-988 {
  width: 988px !important;
}

@media (min-width: 897px) {
  .width-px-pc-988 {
    width: 988px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-988 {
    width: 988px !important;
  }
}

.width-px-989 {
  width: 989px !important;
}

@media (min-width: 897px) {
  .width-px-pc-989 {
    width: 989px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-989 {
    width: 989px !important;
  }
}

.width-px-990 {
  width: 990px !important;
}

@media (min-width: 897px) {
  .width-px-pc-990 {
    width: 990px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-990 {
    width: 990px !important;
  }
}

.width-px-991 {
  width: 991px !important;
}

@media (min-width: 897px) {
  .width-px-pc-991 {
    width: 991px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-991 {
    width: 991px !important;
  }
}

.width-px-992 {
  width: 992px !important;
}

@media (min-width: 897px) {
  .width-px-pc-992 {
    width: 992px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-992 {
    width: 992px !important;
  }
}

.width-px-993 {
  width: 993px !important;
}

@media (min-width: 897px) {
  .width-px-pc-993 {
    width: 993px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-993 {
    width: 993px !important;
  }
}

.width-px-994 {
  width: 994px !important;
}

@media (min-width: 897px) {
  .width-px-pc-994 {
    width: 994px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-994 {
    width: 994px !important;
  }
}

.width-px-995 {
  width: 995px !important;
}

@media (min-width: 897px) {
  .width-px-pc-995 {
    width: 995px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-995 {
    width: 995px !important;
  }
}

.width-px-996 {
  width: 996px !important;
}

@media (min-width: 897px) {
  .width-px-pc-996 {
    width: 996px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-996 {
    width: 996px !important;
  }
}

.width-px-997 {
  width: 997px !important;
}

@media (min-width: 897px) {
  .width-px-pc-997 {
    width: 997px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-997 {
    width: 997px !important;
  }
}

.width-px-998 {
  width: 998px !important;
}

@media (min-width: 897px) {
  .width-px-pc-998 {
    width: 998px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-998 {
    width: 998px !important;
  }
}

.width-px-999 {
  width: 999px !important;
}

@media (min-width: 897px) {
  .width-px-pc-999 {
    width: 999px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-999 {
    width: 999px !important;
  }
}

.width-px-1000 {
  width: 1000px !important;
}

@media (min-width: 897px) {
  .width-px-pc-1000 {
    width: 1000px !important;
  }
}

@media (max-width: 896px) {
  .width-px-sp-1000 {
    width: 1000px !important;
  }
}

/* Width percentage
---------------------------------------------------------- */
.width-pct-10 {
  width: 10% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-10 {
    width: 10% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-10 {
    width: 10% !important;
  }
}

.width-pct-11 {
  width: 11% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-11 {
    width: 11% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-11 {
    width: 11% !important;
  }
}

.width-pct-12 {
  width: 12% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-12 {
    width: 12% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-12 {
    width: 12% !important;
  }
}

.width-pct-13 {
  width: 13% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-13 {
    width: 13% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-13 {
    width: 13% !important;
  }
}

.width-pct-14 {
  width: 14% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-14 {
    width: 14% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-14 {
    width: 14% !important;
  }
}

.width-pct-15 {
  width: 15% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-15 {
    width: 15% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-15 {
    width: 15% !important;
  }
}

.width-pct-16 {
  width: 16% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-16 {
    width: 16% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-16 {
    width: 16% !important;
  }
}

.width-pct-17 {
  width: 17% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-17 {
    width: 17% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-17 {
    width: 17% !important;
  }
}

.width-pct-18 {
  width: 18% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-18 {
    width: 18% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-18 {
    width: 18% !important;
  }
}

.width-pct-19 {
  width: 19% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-19 {
    width: 19% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-19 {
    width: 19% !important;
  }
}

.width-pct-20 {
  width: 20% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-20 {
    width: 20% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-20 {
    width: 20% !important;
  }
}

.width-pct-21 {
  width: 21% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-21 {
    width: 21% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-21 {
    width: 21% !important;
  }
}

.width-pct-22 {
  width: 22% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-22 {
    width: 22% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-22 {
    width: 22% !important;
  }
}

.width-pct-23 {
  width: 23% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-23 {
    width: 23% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-23 {
    width: 23% !important;
  }
}

.width-pct-24 {
  width: 24% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-24 {
    width: 24% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-24 {
    width: 24% !important;
  }
}

.width-pct-25 {
  width: 25% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-25 {
    width: 25% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-25 {
    width: 25% !important;
  }
}

.width-pct-26 {
  width: 26% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-26 {
    width: 26% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-26 {
    width: 26% !important;
  }
}

.width-pct-27 {
  width: 27% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-27 {
    width: 27% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-27 {
    width: 27% !important;
  }
}

.width-pct-28 {
  width: 28% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-28 {
    width: 28% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-28 {
    width: 28% !important;
  }
}

.width-pct-29 {
  width: 29% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-29 {
    width: 29% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-29 {
    width: 29% !important;
  }
}

.width-pct-30 {
  width: 30% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-30 {
    width: 30% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-30 {
    width: 30% !important;
  }
}

.width-pct-31 {
  width: 31% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-31 {
    width: 31% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-31 {
    width: 31% !important;
  }
}

.width-pct-32 {
  width: 32% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-32 {
    width: 32% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-32 {
    width: 32% !important;
  }
}

.width-pct-33 {
  width: 33% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-33 {
    width: 33% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-33 {
    width: 33% !important;
  }
}

.width-pct-34 {
  width: 34% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-34 {
    width: 34% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-34 {
    width: 34% !important;
  }
}

.width-pct-35 {
  width: 35% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-35 {
    width: 35% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-35 {
    width: 35% !important;
  }
}

.width-pct-36 {
  width: 36% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-36 {
    width: 36% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-36 {
    width: 36% !important;
  }
}

.width-pct-37 {
  width: 37% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-37 {
    width: 37% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-37 {
    width: 37% !important;
  }
}

.width-pct-38 {
  width: 38% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-38 {
    width: 38% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-38 {
    width: 38% !important;
  }
}

.width-pct-39 {
  width: 39% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-39 {
    width: 39% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-39 {
    width: 39% !important;
  }
}

.width-pct-40 {
  width: 40% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-40 {
    width: 40% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-40 {
    width: 40% !important;
  }
}

.width-pct-41 {
  width: 41% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-41 {
    width: 41% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-41 {
    width: 41% !important;
  }
}

.width-pct-42 {
  width: 42% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-42 {
    width: 42% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-42 {
    width: 42% !important;
  }
}

.width-pct-43 {
  width: 43% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-43 {
    width: 43% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-43 {
    width: 43% !important;
  }
}

.width-pct-44 {
  width: 44% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-44 {
    width: 44% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-44 {
    width: 44% !important;
  }
}

.width-pct-45 {
  width: 45% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-45 {
    width: 45% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-45 {
    width: 45% !important;
  }
}

.width-pct-46 {
  width: 46% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-46 {
    width: 46% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-46 {
    width: 46% !important;
  }
}

.width-pct-47 {
  width: 47% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-47 {
    width: 47% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-47 {
    width: 47% !important;
  }
}

.width-pct-48 {
  width: 48% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-48 {
    width: 48% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-48 {
    width: 48% !important;
  }
}

.width-pct-49 {
  width: 49% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-49 {
    width: 49% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-49 {
    width: 49% !important;
  }
}

.width-pct-50 {
  width: 50% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-50 {
    width: 50% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-50 {
    width: 50% !important;
  }
}

.width-pct-51 {
  width: 51% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-51 {
    width: 51% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-51 {
    width: 51% !important;
  }
}

.width-pct-52 {
  width: 52% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-52 {
    width: 52% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-52 {
    width: 52% !important;
  }
}

.width-pct-53 {
  width: 53% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-53 {
    width: 53% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-53 {
    width: 53% !important;
  }
}

.width-pct-54 {
  width: 54% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-54 {
    width: 54% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-54 {
    width: 54% !important;
  }
}

.width-pct-55 {
  width: 55% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-55 {
    width: 55% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-55 {
    width: 55% !important;
  }
}

.width-pct-56 {
  width: 56% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-56 {
    width: 56% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-56 {
    width: 56% !important;
  }
}

.width-pct-57 {
  width: 57% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-57 {
    width: 57% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-57 {
    width: 57% !important;
  }
}

.width-pct-58 {
  width: 58% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-58 {
    width: 58% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-58 {
    width: 58% !important;
  }
}

.width-pct-59 {
  width: 59% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-59 {
    width: 59% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-59 {
    width: 59% !important;
  }
}

.width-pct-60 {
  width: 60% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-60 {
    width: 60% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-60 {
    width: 60% !important;
  }
}

.width-pct-61 {
  width: 61% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-61 {
    width: 61% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-61 {
    width: 61% !important;
  }
}

.width-pct-62 {
  width: 62% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-62 {
    width: 62% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-62 {
    width: 62% !important;
  }
}

.width-pct-63 {
  width: 63% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-63 {
    width: 63% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-63 {
    width: 63% !important;
  }
}

.width-pct-64 {
  width: 64% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-64 {
    width: 64% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-64 {
    width: 64% !important;
  }
}

.width-pct-65 {
  width: 65% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-65 {
    width: 65% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-65 {
    width: 65% !important;
  }
}

.width-pct-66 {
  width: 66% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-66 {
    width: 66% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-66 {
    width: 66% !important;
  }
}

.width-pct-67 {
  width: 67% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-67 {
    width: 67% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-67 {
    width: 67% !important;
  }
}

.width-pct-68 {
  width: 68% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-68 {
    width: 68% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-68 {
    width: 68% !important;
  }
}

.width-pct-69 {
  width: 69% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-69 {
    width: 69% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-69 {
    width: 69% !important;
  }
}

.width-pct-70 {
  width: 70% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-70 {
    width: 70% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-70 {
    width: 70% !important;
  }
}

.width-pct-71 {
  width: 71% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-71 {
    width: 71% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-71 {
    width: 71% !important;
  }
}

.width-pct-72 {
  width: 72% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-72 {
    width: 72% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-72 {
    width: 72% !important;
  }
}

.width-pct-73 {
  width: 73% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-73 {
    width: 73% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-73 {
    width: 73% !important;
  }
}

.width-pct-74 {
  width: 74% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-74 {
    width: 74% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-74 {
    width: 74% !important;
  }
}

.width-pct-75 {
  width: 75% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-75 {
    width: 75% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-75 {
    width: 75% !important;
  }
}

.width-pct-76 {
  width: 76% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-76 {
    width: 76% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-76 {
    width: 76% !important;
  }
}

.width-pct-77 {
  width: 77% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-77 {
    width: 77% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-77 {
    width: 77% !important;
  }
}

.width-pct-78 {
  width: 78% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-78 {
    width: 78% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-78 {
    width: 78% !important;
  }
}

.width-pct-79 {
  width: 79% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-79 {
    width: 79% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-79 {
    width: 79% !important;
  }
}

.width-pct-80 {
  width: 80% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-80 {
    width: 80% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-80 {
    width: 80% !important;
  }
}

.width-pct-81 {
  width: 81% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-81 {
    width: 81% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-81 {
    width: 81% !important;
  }
}

.width-pct-82 {
  width: 82% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-82 {
    width: 82% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-82 {
    width: 82% !important;
  }
}

.width-pct-83 {
  width: 83% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-83 {
    width: 83% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-83 {
    width: 83% !important;
  }
}

.width-pct-84 {
  width: 84% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-84 {
    width: 84% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-84 {
    width: 84% !important;
  }
}

.width-pct-85 {
  width: 85% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-85 {
    width: 85% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-85 {
    width: 85% !important;
  }
}

.width-pct-86 {
  width: 86% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-86 {
    width: 86% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-86 {
    width: 86% !important;
  }
}

.width-pct-87 {
  width: 87% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-87 {
    width: 87% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-87 {
    width: 87% !important;
  }
}

.width-pct-88 {
  width: 88% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-88 {
    width: 88% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-88 {
    width: 88% !important;
  }
}

.width-pct-89 {
  width: 89% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-89 {
    width: 89% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-89 {
    width: 89% !important;
  }
}

.width-pct-90 {
  width: 90% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-90 {
    width: 90% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-90 {
    width: 90% !important;
  }
}

.width-pct-91 {
  width: 91% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-91 {
    width: 91% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-91 {
    width: 91% !important;
  }
}

.width-pct-92 {
  width: 92% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-92 {
    width: 92% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-92 {
    width: 92% !important;
  }
}

.width-pct-93 {
  width: 93% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-93 {
    width: 93% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-93 {
    width: 93% !important;
  }
}

.width-pct-94 {
  width: 94% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-94 {
    width: 94% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-94 {
    width: 94% !important;
  }
}

.width-pct-95 {
  width: 95% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-95 {
    width: 95% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-95 {
    width: 95% !important;
  }
}

.width-pct-96 {
  width: 96% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-96 {
    width: 96% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-96 {
    width: 96% !important;
  }
}

.width-pct-97 {
  width: 97% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-97 {
    width: 97% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-97 {
    width: 97% !important;
  }
}

.width-pct-98 {
  width: 98% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-98 {
    width: 98% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-98 {
    width: 98% !important;
  }
}

.width-pct-99 {
  width: 99% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-99 {
    width: 99% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-99 {
    width: 99% !important;
  }
}

.width-pct-100 {
  width: 100% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-100 {
    width: 100% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-100 {
    width: 100% !important;
  }
}

.width-pct-101 {
  width: 101% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-101 {
    width: 101% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-101 {
    width: 101% !important;
  }
}

.width-pct-102 {
  width: 102% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-102 {
    width: 102% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-102 {
    width: 102% !important;
  }
}

.width-pct-103 {
  width: 103% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-103 {
    width: 103% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-103 {
    width: 103% !important;
  }
}

.width-pct-104 {
  width: 104% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-104 {
    width: 104% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-104 {
    width: 104% !important;
  }
}

.width-pct-105 {
  width: 105% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-105 {
    width: 105% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-105 {
    width: 105% !important;
  }
}

.width-pct-106 {
  width: 106% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-106 {
    width: 106% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-106 {
    width: 106% !important;
  }
}

.width-pct-107 {
  width: 107% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-107 {
    width: 107% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-107 {
    width: 107% !important;
  }
}

.width-pct-108 {
  width: 108% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-108 {
    width: 108% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-108 {
    width: 108% !important;
  }
}

.width-pct-109 {
  width: 109% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-109 {
    width: 109% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-109 {
    width: 109% !important;
  }
}

.width-pct-110 {
  width: 110% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-110 {
    width: 110% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-110 {
    width: 110% !important;
  }
}

.width-pct-111 {
  width: 111% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-111 {
    width: 111% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-111 {
    width: 111% !important;
  }
}

.width-pct-112 {
  width: 112% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-112 {
    width: 112% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-112 {
    width: 112% !important;
  }
}

.width-pct-113 {
  width: 113% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-113 {
    width: 113% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-113 {
    width: 113% !important;
  }
}

.width-pct-114 {
  width: 114% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-114 {
    width: 114% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-114 {
    width: 114% !important;
  }
}

.width-pct-115 {
  width: 115% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-115 {
    width: 115% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-115 {
    width: 115% !important;
  }
}

.width-pct-116 {
  width: 116% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-116 {
    width: 116% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-116 {
    width: 116% !important;
  }
}

.width-pct-117 {
  width: 117% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-117 {
    width: 117% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-117 {
    width: 117% !important;
  }
}

.width-pct-118 {
  width: 118% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-118 {
    width: 118% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-118 {
    width: 118% !important;
  }
}

.width-pct-119 {
  width: 119% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-119 {
    width: 119% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-119 {
    width: 119% !important;
  }
}

.width-pct-120 {
  width: 120% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-120 {
    width: 120% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-120 {
    width: 120% !important;
  }
}

.width-pct-121 {
  width: 121% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-121 {
    width: 121% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-121 {
    width: 121% !important;
  }
}

.width-pct-122 {
  width: 122% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-122 {
    width: 122% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-122 {
    width: 122% !important;
  }
}

.width-pct-123 {
  width: 123% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-123 {
    width: 123% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-123 {
    width: 123% !important;
  }
}

.width-pct-124 {
  width: 124% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-124 {
    width: 124% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-124 {
    width: 124% !important;
  }
}

.width-pct-125 {
  width: 125% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-125 {
    width: 125% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-125 {
    width: 125% !important;
  }
}

.width-pct-126 {
  width: 126% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-126 {
    width: 126% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-126 {
    width: 126% !important;
  }
}

.width-pct-127 {
  width: 127% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-127 {
    width: 127% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-127 {
    width: 127% !important;
  }
}

.width-pct-128 {
  width: 128% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-128 {
    width: 128% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-128 {
    width: 128% !important;
  }
}

.width-pct-129 {
  width: 129% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-129 {
    width: 129% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-129 {
    width: 129% !important;
  }
}

.width-pct-130 {
  width: 130% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-130 {
    width: 130% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-130 {
    width: 130% !important;
  }
}

.width-pct-131 {
  width: 131% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-131 {
    width: 131% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-131 {
    width: 131% !important;
  }
}

.width-pct-132 {
  width: 132% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-132 {
    width: 132% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-132 {
    width: 132% !important;
  }
}

.width-pct-133 {
  width: 133% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-133 {
    width: 133% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-133 {
    width: 133% !important;
  }
}

.width-pct-134 {
  width: 134% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-134 {
    width: 134% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-134 {
    width: 134% !important;
  }
}

.width-pct-135 {
  width: 135% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-135 {
    width: 135% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-135 {
    width: 135% !important;
  }
}

.width-pct-136 {
  width: 136% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-136 {
    width: 136% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-136 {
    width: 136% !important;
  }
}

.width-pct-137 {
  width: 137% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-137 {
    width: 137% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-137 {
    width: 137% !important;
  }
}

.width-pct-138 {
  width: 138% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-138 {
    width: 138% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-138 {
    width: 138% !important;
  }
}

.width-pct-139 {
  width: 139% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-139 {
    width: 139% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-139 {
    width: 139% !important;
  }
}

.width-pct-140 {
  width: 140% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-140 {
    width: 140% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-140 {
    width: 140% !important;
  }
}

.width-pct-141 {
  width: 141% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-141 {
    width: 141% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-141 {
    width: 141% !important;
  }
}

.width-pct-142 {
  width: 142% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-142 {
    width: 142% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-142 {
    width: 142% !important;
  }
}

.width-pct-143 {
  width: 143% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-143 {
    width: 143% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-143 {
    width: 143% !important;
  }
}

.width-pct-144 {
  width: 144% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-144 {
    width: 144% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-144 {
    width: 144% !important;
  }
}

.width-pct-145 {
  width: 145% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-145 {
    width: 145% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-145 {
    width: 145% !important;
  }
}

.width-pct-146 {
  width: 146% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-146 {
    width: 146% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-146 {
    width: 146% !important;
  }
}

.width-pct-147 {
  width: 147% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-147 {
    width: 147% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-147 {
    width: 147% !important;
  }
}

.width-pct-148 {
  width: 148% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-148 {
    width: 148% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-148 {
    width: 148% !important;
  }
}

.width-pct-149 {
  width: 149% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-149 {
    width: 149% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-149 {
    width: 149% !important;
  }
}

.width-pct-150 {
  width: 150% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-150 {
    width: 150% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-150 {
    width: 150% !important;
  }
}

.width-pct-151 {
  width: 151% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-151 {
    width: 151% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-151 {
    width: 151% !important;
  }
}

.width-pct-152 {
  width: 152% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-152 {
    width: 152% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-152 {
    width: 152% !important;
  }
}

.width-pct-153 {
  width: 153% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-153 {
    width: 153% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-153 {
    width: 153% !important;
  }
}

.width-pct-154 {
  width: 154% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-154 {
    width: 154% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-154 {
    width: 154% !important;
  }
}

.width-pct-155 {
  width: 155% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-155 {
    width: 155% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-155 {
    width: 155% !important;
  }
}

.width-pct-156 {
  width: 156% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-156 {
    width: 156% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-156 {
    width: 156% !important;
  }
}

.width-pct-157 {
  width: 157% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-157 {
    width: 157% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-157 {
    width: 157% !important;
  }
}

.width-pct-158 {
  width: 158% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-158 {
    width: 158% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-158 {
    width: 158% !important;
  }
}

.width-pct-159 {
  width: 159% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-159 {
    width: 159% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-159 {
    width: 159% !important;
  }
}

.width-pct-160 {
  width: 160% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-160 {
    width: 160% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-160 {
    width: 160% !important;
  }
}

.width-pct-161 {
  width: 161% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-161 {
    width: 161% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-161 {
    width: 161% !important;
  }
}

.width-pct-162 {
  width: 162% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-162 {
    width: 162% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-162 {
    width: 162% !important;
  }
}

.width-pct-163 {
  width: 163% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-163 {
    width: 163% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-163 {
    width: 163% !important;
  }
}

.width-pct-164 {
  width: 164% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-164 {
    width: 164% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-164 {
    width: 164% !important;
  }
}

.width-pct-165 {
  width: 165% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-165 {
    width: 165% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-165 {
    width: 165% !important;
  }
}

.width-pct-166 {
  width: 166% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-166 {
    width: 166% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-166 {
    width: 166% !important;
  }
}

.width-pct-167 {
  width: 167% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-167 {
    width: 167% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-167 {
    width: 167% !important;
  }
}

.width-pct-168 {
  width: 168% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-168 {
    width: 168% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-168 {
    width: 168% !important;
  }
}

.width-pct-169 {
  width: 169% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-169 {
    width: 169% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-169 {
    width: 169% !important;
  }
}

.width-pct-170 {
  width: 170% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-170 {
    width: 170% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-170 {
    width: 170% !important;
  }
}

.width-pct-171 {
  width: 171% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-171 {
    width: 171% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-171 {
    width: 171% !important;
  }
}

.width-pct-172 {
  width: 172% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-172 {
    width: 172% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-172 {
    width: 172% !important;
  }
}

.width-pct-173 {
  width: 173% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-173 {
    width: 173% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-173 {
    width: 173% !important;
  }
}

.width-pct-174 {
  width: 174% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-174 {
    width: 174% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-174 {
    width: 174% !important;
  }
}

.width-pct-175 {
  width: 175% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-175 {
    width: 175% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-175 {
    width: 175% !important;
  }
}

.width-pct-176 {
  width: 176% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-176 {
    width: 176% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-176 {
    width: 176% !important;
  }
}

.width-pct-177 {
  width: 177% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-177 {
    width: 177% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-177 {
    width: 177% !important;
  }
}

.width-pct-178 {
  width: 178% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-178 {
    width: 178% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-178 {
    width: 178% !important;
  }
}

.width-pct-179 {
  width: 179% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-179 {
    width: 179% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-179 {
    width: 179% !important;
  }
}

.width-pct-180 {
  width: 180% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-180 {
    width: 180% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-180 {
    width: 180% !important;
  }
}

.width-pct-181 {
  width: 181% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-181 {
    width: 181% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-181 {
    width: 181% !important;
  }
}

.width-pct-182 {
  width: 182% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-182 {
    width: 182% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-182 {
    width: 182% !important;
  }
}

.width-pct-183 {
  width: 183% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-183 {
    width: 183% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-183 {
    width: 183% !important;
  }
}

.width-pct-184 {
  width: 184% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-184 {
    width: 184% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-184 {
    width: 184% !important;
  }
}

.width-pct-185 {
  width: 185% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-185 {
    width: 185% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-185 {
    width: 185% !important;
  }
}

.width-pct-186 {
  width: 186% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-186 {
    width: 186% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-186 {
    width: 186% !important;
  }
}

.width-pct-187 {
  width: 187% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-187 {
    width: 187% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-187 {
    width: 187% !important;
  }
}

.width-pct-188 {
  width: 188% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-188 {
    width: 188% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-188 {
    width: 188% !important;
  }
}

.width-pct-189 {
  width: 189% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-189 {
    width: 189% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-189 {
    width: 189% !important;
  }
}

.width-pct-190 {
  width: 190% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-190 {
    width: 190% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-190 {
    width: 190% !important;
  }
}

.width-pct-191 {
  width: 191% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-191 {
    width: 191% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-191 {
    width: 191% !important;
  }
}

.width-pct-192 {
  width: 192% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-192 {
    width: 192% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-192 {
    width: 192% !important;
  }
}

.width-pct-193 {
  width: 193% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-193 {
    width: 193% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-193 {
    width: 193% !important;
  }
}

.width-pct-194 {
  width: 194% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-194 {
    width: 194% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-194 {
    width: 194% !important;
  }
}

.width-pct-195 {
  width: 195% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-195 {
    width: 195% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-195 {
    width: 195% !important;
  }
}

.width-pct-196 {
  width: 196% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-196 {
    width: 196% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-196 {
    width: 196% !important;
  }
}

.width-pct-197 {
  width: 197% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-197 {
    width: 197% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-197 {
    width: 197% !important;
  }
}

.width-pct-198 {
  width: 198% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-198 {
    width: 198% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-198 {
    width: 198% !important;
  }
}

.width-pct-199 {
  width: 199% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-199 {
    width: 199% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-199 {
    width: 199% !important;
  }
}

.width-pct-200 {
  width: 200% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-200 {
    width: 200% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-200 {
    width: 200% !important;
  }
}

.width-pct-201 {
  width: 201% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-201 {
    width: 201% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-201 {
    width: 201% !important;
  }
}

.width-pct-202 {
  width: 202% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-202 {
    width: 202% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-202 {
    width: 202% !important;
  }
}

.width-pct-203 {
  width: 203% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-203 {
    width: 203% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-203 {
    width: 203% !important;
  }
}

.width-pct-204 {
  width: 204% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-204 {
    width: 204% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-204 {
    width: 204% !important;
  }
}

.width-pct-205 {
  width: 205% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-205 {
    width: 205% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-205 {
    width: 205% !important;
  }
}

.width-pct-206 {
  width: 206% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-206 {
    width: 206% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-206 {
    width: 206% !important;
  }
}

.width-pct-207 {
  width: 207% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-207 {
    width: 207% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-207 {
    width: 207% !important;
  }
}

.width-pct-208 {
  width: 208% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-208 {
    width: 208% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-208 {
    width: 208% !important;
  }
}

.width-pct-209 {
  width: 209% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-209 {
    width: 209% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-209 {
    width: 209% !important;
  }
}

.width-pct-210 {
  width: 210% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-210 {
    width: 210% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-210 {
    width: 210% !important;
  }
}

.width-pct-211 {
  width: 211% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-211 {
    width: 211% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-211 {
    width: 211% !important;
  }
}

.width-pct-212 {
  width: 212% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-212 {
    width: 212% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-212 {
    width: 212% !important;
  }
}

.width-pct-213 {
  width: 213% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-213 {
    width: 213% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-213 {
    width: 213% !important;
  }
}

.width-pct-214 {
  width: 214% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-214 {
    width: 214% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-214 {
    width: 214% !important;
  }
}

.width-pct-215 {
  width: 215% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-215 {
    width: 215% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-215 {
    width: 215% !important;
  }
}

.width-pct-216 {
  width: 216% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-216 {
    width: 216% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-216 {
    width: 216% !important;
  }
}

.width-pct-217 {
  width: 217% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-217 {
    width: 217% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-217 {
    width: 217% !important;
  }
}

.width-pct-218 {
  width: 218% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-218 {
    width: 218% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-218 {
    width: 218% !important;
  }
}

.width-pct-219 {
  width: 219% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-219 {
    width: 219% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-219 {
    width: 219% !important;
  }
}

.width-pct-220 {
  width: 220% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-220 {
    width: 220% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-220 {
    width: 220% !important;
  }
}

.width-pct-221 {
  width: 221% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-221 {
    width: 221% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-221 {
    width: 221% !important;
  }
}

.width-pct-222 {
  width: 222% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-222 {
    width: 222% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-222 {
    width: 222% !important;
  }
}

.width-pct-223 {
  width: 223% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-223 {
    width: 223% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-223 {
    width: 223% !important;
  }
}

.width-pct-224 {
  width: 224% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-224 {
    width: 224% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-224 {
    width: 224% !important;
  }
}

.width-pct-225 {
  width: 225% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-225 {
    width: 225% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-225 {
    width: 225% !important;
  }
}

.width-pct-226 {
  width: 226% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-226 {
    width: 226% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-226 {
    width: 226% !important;
  }
}

.width-pct-227 {
  width: 227% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-227 {
    width: 227% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-227 {
    width: 227% !important;
  }
}

.width-pct-228 {
  width: 228% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-228 {
    width: 228% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-228 {
    width: 228% !important;
  }
}

.width-pct-229 {
  width: 229% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-229 {
    width: 229% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-229 {
    width: 229% !important;
  }
}

.width-pct-230 {
  width: 230% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-230 {
    width: 230% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-230 {
    width: 230% !important;
  }
}

.width-pct-231 {
  width: 231% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-231 {
    width: 231% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-231 {
    width: 231% !important;
  }
}

.width-pct-232 {
  width: 232% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-232 {
    width: 232% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-232 {
    width: 232% !important;
  }
}

.width-pct-233 {
  width: 233% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-233 {
    width: 233% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-233 {
    width: 233% !important;
  }
}

.width-pct-234 {
  width: 234% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-234 {
    width: 234% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-234 {
    width: 234% !important;
  }
}

.width-pct-235 {
  width: 235% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-235 {
    width: 235% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-235 {
    width: 235% !important;
  }
}

.width-pct-236 {
  width: 236% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-236 {
    width: 236% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-236 {
    width: 236% !important;
  }
}

.width-pct-237 {
  width: 237% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-237 {
    width: 237% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-237 {
    width: 237% !important;
  }
}

.width-pct-238 {
  width: 238% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-238 {
    width: 238% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-238 {
    width: 238% !important;
  }
}

.width-pct-239 {
  width: 239% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-239 {
    width: 239% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-239 {
    width: 239% !important;
  }
}

.width-pct-240 {
  width: 240% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-240 {
    width: 240% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-240 {
    width: 240% !important;
  }
}

.width-pct-241 {
  width: 241% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-241 {
    width: 241% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-241 {
    width: 241% !important;
  }
}

.width-pct-242 {
  width: 242% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-242 {
    width: 242% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-242 {
    width: 242% !important;
  }
}

.width-pct-243 {
  width: 243% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-243 {
    width: 243% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-243 {
    width: 243% !important;
  }
}

.width-pct-244 {
  width: 244% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-244 {
    width: 244% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-244 {
    width: 244% !important;
  }
}

.width-pct-245 {
  width: 245% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-245 {
    width: 245% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-245 {
    width: 245% !important;
  }
}

.width-pct-246 {
  width: 246% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-246 {
    width: 246% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-246 {
    width: 246% !important;
  }
}

.width-pct-247 {
  width: 247% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-247 {
    width: 247% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-247 {
    width: 247% !important;
  }
}

.width-pct-248 {
  width: 248% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-248 {
    width: 248% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-248 {
    width: 248% !important;
  }
}

.width-pct-249 {
  width: 249% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-249 {
    width: 249% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-249 {
    width: 249% !important;
  }
}

.width-pct-250 {
  width: 250% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-250 {
    width: 250% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-250 {
    width: 250% !important;
  }
}

.width-pct-251 {
  width: 251% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-251 {
    width: 251% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-251 {
    width: 251% !important;
  }
}

.width-pct-252 {
  width: 252% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-252 {
    width: 252% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-252 {
    width: 252% !important;
  }
}

.width-pct-253 {
  width: 253% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-253 {
    width: 253% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-253 {
    width: 253% !important;
  }
}

.width-pct-254 {
  width: 254% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-254 {
    width: 254% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-254 {
    width: 254% !important;
  }
}

.width-pct-255 {
  width: 255% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-255 {
    width: 255% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-255 {
    width: 255% !important;
  }
}

.width-pct-256 {
  width: 256% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-256 {
    width: 256% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-256 {
    width: 256% !important;
  }
}

.width-pct-257 {
  width: 257% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-257 {
    width: 257% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-257 {
    width: 257% !important;
  }
}

.width-pct-258 {
  width: 258% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-258 {
    width: 258% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-258 {
    width: 258% !important;
  }
}

.width-pct-259 {
  width: 259% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-259 {
    width: 259% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-259 {
    width: 259% !important;
  }
}

.width-pct-260 {
  width: 260% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-260 {
    width: 260% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-260 {
    width: 260% !important;
  }
}

.width-pct-261 {
  width: 261% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-261 {
    width: 261% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-261 {
    width: 261% !important;
  }
}

.width-pct-262 {
  width: 262% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-262 {
    width: 262% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-262 {
    width: 262% !important;
  }
}

.width-pct-263 {
  width: 263% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-263 {
    width: 263% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-263 {
    width: 263% !important;
  }
}

.width-pct-264 {
  width: 264% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-264 {
    width: 264% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-264 {
    width: 264% !important;
  }
}

.width-pct-265 {
  width: 265% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-265 {
    width: 265% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-265 {
    width: 265% !important;
  }
}

.width-pct-266 {
  width: 266% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-266 {
    width: 266% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-266 {
    width: 266% !important;
  }
}

.width-pct-267 {
  width: 267% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-267 {
    width: 267% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-267 {
    width: 267% !important;
  }
}

.width-pct-268 {
  width: 268% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-268 {
    width: 268% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-268 {
    width: 268% !important;
  }
}

.width-pct-269 {
  width: 269% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-269 {
    width: 269% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-269 {
    width: 269% !important;
  }
}

.width-pct-270 {
  width: 270% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-270 {
    width: 270% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-270 {
    width: 270% !important;
  }
}

.width-pct-271 {
  width: 271% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-271 {
    width: 271% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-271 {
    width: 271% !important;
  }
}

.width-pct-272 {
  width: 272% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-272 {
    width: 272% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-272 {
    width: 272% !important;
  }
}

.width-pct-273 {
  width: 273% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-273 {
    width: 273% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-273 {
    width: 273% !important;
  }
}

.width-pct-274 {
  width: 274% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-274 {
    width: 274% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-274 {
    width: 274% !important;
  }
}

.width-pct-275 {
  width: 275% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-275 {
    width: 275% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-275 {
    width: 275% !important;
  }
}

.width-pct-276 {
  width: 276% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-276 {
    width: 276% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-276 {
    width: 276% !important;
  }
}

.width-pct-277 {
  width: 277% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-277 {
    width: 277% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-277 {
    width: 277% !important;
  }
}

.width-pct-278 {
  width: 278% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-278 {
    width: 278% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-278 {
    width: 278% !important;
  }
}

.width-pct-279 {
  width: 279% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-279 {
    width: 279% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-279 {
    width: 279% !important;
  }
}

.width-pct-280 {
  width: 280% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-280 {
    width: 280% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-280 {
    width: 280% !important;
  }
}

.width-pct-281 {
  width: 281% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-281 {
    width: 281% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-281 {
    width: 281% !important;
  }
}

.width-pct-282 {
  width: 282% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-282 {
    width: 282% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-282 {
    width: 282% !important;
  }
}

.width-pct-283 {
  width: 283% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-283 {
    width: 283% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-283 {
    width: 283% !important;
  }
}

.width-pct-284 {
  width: 284% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-284 {
    width: 284% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-284 {
    width: 284% !important;
  }
}

.width-pct-285 {
  width: 285% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-285 {
    width: 285% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-285 {
    width: 285% !important;
  }
}

.width-pct-286 {
  width: 286% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-286 {
    width: 286% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-286 {
    width: 286% !important;
  }
}

.width-pct-287 {
  width: 287% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-287 {
    width: 287% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-287 {
    width: 287% !important;
  }
}

.width-pct-288 {
  width: 288% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-288 {
    width: 288% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-288 {
    width: 288% !important;
  }
}

.width-pct-289 {
  width: 289% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-289 {
    width: 289% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-289 {
    width: 289% !important;
  }
}

.width-pct-290 {
  width: 290% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-290 {
    width: 290% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-290 {
    width: 290% !important;
  }
}

.width-pct-291 {
  width: 291% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-291 {
    width: 291% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-291 {
    width: 291% !important;
  }
}

.width-pct-292 {
  width: 292% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-292 {
    width: 292% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-292 {
    width: 292% !important;
  }
}

.width-pct-293 {
  width: 293% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-293 {
    width: 293% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-293 {
    width: 293% !important;
  }
}

.width-pct-294 {
  width: 294% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-294 {
    width: 294% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-294 {
    width: 294% !important;
  }
}

.width-pct-295 {
  width: 295% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-295 {
    width: 295% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-295 {
    width: 295% !important;
  }
}

.width-pct-296 {
  width: 296% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-296 {
    width: 296% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-296 {
    width: 296% !important;
  }
}

.width-pct-297 {
  width: 297% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-297 {
    width: 297% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-297 {
    width: 297% !important;
  }
}

.width-pct-298 {
  width: 298% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-298 {
    width: 298% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-298 {
    width: 298% !important;
  }
}

.width-pct-299 {
  width: 299% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-299 {
    width: 299% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-299 {
    width: 299% !important;
  }
}

.width-pct-300 {
  width: 300% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-300 {
    width: 300% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-300 {
    width: 300% !important;
  }
}

.width-pct-301 {
  width: 301% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-301 {
    width: 301% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-301 {
    width: 301% !important;
  }
}

.width-pct-302 {
  width: 302% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-302 {
    width: 302% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-302 {
    width: 302% !important;
  }
}

.width-pct-303 {
  width: 303% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-303 {
    width: 303% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-303 {
    width: 303% !important;
  }
}

.width-pct-304 {
  width: 304% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-304 {
    width: 304% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-304 {
    width: 304% !important;
  }
}

.width-pct-305 {
  width: 305% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-305 {
    width: 305% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-305 {
    width: 305% !important;
  }
}

.width-pct-306 {
  width: 306% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-306 {
    width: 306% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-306 {
    width: 306% !important;
  }
}

.width-pct-307 {
  width: 307% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-307 {
    width: 307% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-307 {
    width: 307% !important;
  }
}

.width-pct-308 {
  width: 308% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-308 {
    width: 308% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-308 {
    width: 308% !important;
  }
}

.width-pct-309 {
  width: 309% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-309 {
    width: 309% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-309 {
    width: 309% !important;
  }
}

.width-pct-310 {
  width: 310% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-310 {
    width: 310% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-310 {
    width: 310% !important;
  }
}

.width-pct-311 {
  width: 311% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-311 {
    width: 311% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-311 {
    width: 311% !important;
  }
}

.width-pct-312 {
  width: 312% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-312 {
    width: 312% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-312 {
    width: 312% !important;
  }
}

.width-pct-313 {
  width: 313% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-313 {
    width: 313% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-313 {
    width: 313% !important;
  }
}

.width-pct-314 {
  width: 314% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-314 {
    width: 314% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-314 {
    width: 314% !important;
  }
}

.width-pct-315 {
  width: 315% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-315 {
    width: 315% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-315 {
    width: 315% !important;
  }
}

.width-pct-316 {
  width: 316% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-316 {
    width: 316% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-316 {
    width: 316% !important;
  }
}

.width-pct-317 {
  width: 317% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-317 {
    width: 317% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-317 {
    width: 317% !important;
  }
}

.width-pct-318 {
  width: 318% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-318 {
    width: 318% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-318 {
    width: 318% !important;
  }
}

.width-pct-319 {
  width: 319% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-319 {
    width: 319% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-319 {
    width: 319% !important;
  }
}

.width-pct-320 {
  width: 320% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-320 {
    width: 320% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-320 {
    width: 320% !important;
  }
}

.width-pct-321 {
  width: 321% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-321 {
    width: 321% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-321 {
    width: 321% !important;
  }
}

.width-pct-322 {
  width: 322% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-322 {
    width: 322% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-322 {
    width: 322% !important;
  }
}

.width-pct-323 {
  width: 323% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-323 {
    width: 323% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-323 {
    width: 323% !important;
  }
}

.width-pct-324 {
  width: 324% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-324 {
    width: 324% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-324 {
    width: 324% !important;
  }
}

.width-pct-325 {
  width: 325% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-325 {
    width: 325% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-325 {
    width: 325% !important;
  }
}

.width-pct-326 {
  width: 326% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-326 {
    width: 326% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-326 {
    width: 326% !important;
  }
}

.width-pct-327 {
  width: 327% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-327 {
    width: 327% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-327 {
    width: 327% !important;
  }
}

.width-pct-328 {
  width: 328% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-328 {
    width: 328% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-328 {
    width: 328% !important;
  }
}

.width-pct-329 {
  width: 329% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-329 {
    width: 329% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-329 {
    width: 329% !important;
  }
}

.width-pct-330 {
  width: 330% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-330 {
    width: 330% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-330 {
    width: 330% !important;
  }
}

.width-pct-331 {
  width: 331% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-331 {
    width: 331% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-331 {
    width: 331% !important;
  }
}

.width-pct-332 {
  width: 332% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-332 {
    width: 332% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-332 {
    width: 332% !important;
  }
}

.width-pct-333 {
  width: 333% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-333 {
    width: 333% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-333 {
    width: 333% !important;
  }
}

.width-pct-334 {
  width: 334% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-334 {
    width: 334% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-334 {
    width: 334% !important;
  }
}

.width-pct-335 {
  width: 335% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-335 {
    width: 335% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-335 {
    width: 335% !important;
  }
}

.width-pct-336 {
  width: 336% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-336 {
    width: 336% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-336 {
    width: 336% !important;
  }
}

.width-pct-337 {
  width: 337% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-337 {
    width: 337% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-337 {
    width: 337% !important;
  }
}

.width-pct-338 {
  width: 338% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-338 {
    width: 338% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-338 {
    width: 338% !important;
  }
}

.width-pct-339 {
  width: 339% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-339 {
    width: 339% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-339 {
    width: 339% !important;
  }
}

.width-pct-340 {
  width: 340% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-340 {
    width: 340% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-340 {
    width: 340% !important;
  }
}

.width-pct-341 {
  width: 341% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-341 {
    width: 341% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-341 {
    width: 341% !important;
  }
}

.width-pct-342 {
  width: 342% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-342 {
    width: 342% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-342 {
    width: 342% !important;
  }
}

.width-pct-343 {
  width: 343% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-343 {
    width: 343% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-343 {
    width: 343% !important;
  }
}

.width-pct-344 {
  width: 344% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-344 {
    width: 344% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-344 {
    width: 344% !important;
  }
}

.width-pct-345 {
  width: 345% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-345 {
    width: 345% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-345 {
    width: 345% !important;
  }
}

.width-pct-346 {
  width: 346% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-346 {
    width: 346% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-346 {
    width: 346% !important;
  }
}

.width-pct-347 {
  width: 347% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-347 {
    width: 347% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-347 {
    width: 347% !important;
  }
}

.width-pct-348 {
  width: 348% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-348 {
    width: 348% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-348 {
    width: 348% !important;
  }
}

.width-pct-349 {
  width: 349% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-349 {
    width: 349% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-349 {
    width: 349% !important;
  }
}

.width-pct-350 {
  width: 350% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-350 {
    width: 350% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-350 {
    width: 350% !important;
  }
}

.width-pct-351 {
  width: 351% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-351 {
    width: 351% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-351 {
    width: 351% !important;
  }
}

.width-pct-352 {
  width: 352% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-352 {
    width: 352% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-352 {
    width: 352% !important;
  }
}

.width-pct-353 {
  width: 353% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-353 {
    width: 353% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-353 {
    width: 353% !important;
  }
}

.width-pct-354 {
  width: 354% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-354 {
    width: 354% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-354 {
    width: 354% !important;
  }
}

.width-pct-355 {
  width: 355% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-355 {
    width: 355% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-355 {
    width: 355% !important;
  }
}

.width-pct-356 {
  width: 356% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-356 {
    width: 356% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-356 {
    width: 356% !important;
  }
}

.width-pct-357 {
  width: 357% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-357 {
    width: 357% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-357 {
    width: 357% !important;
  }
}

.width-pct-358 {
  width: 358% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-358 {
    width: 358% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-358 {
    width: 358% !important;
  }
}

.width-pct-359 {
  width: 359% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-359 {
    width: 359% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-359 {
    width: 359% !important;
  }
}

.width-pct-360 {
  width: 360% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-360 {
    width: 360% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-360 {
    width: 360% !important;
  }
}

.width-pct-361 {
  width: 361% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-361 {
    width: 361% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-361 {
    width: 361% !important;
  }
}

.width-pct-362 {
  width: 362% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-362 {
    width: 362% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-362 {
    width: 362% !important;
  }
}

.width-pct-363 {
  width: 363% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-363 {
    width: 363% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-363 {
    width: 363% !important;
  }
}

.width-pct-364 {
  width: 364% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-364 {
    width: 364% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-364 {
    width: 364% !important;
  }
}

.width-pct-365 {
  width: 365% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-365 {
    width: 365% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-365 {
    width: 365% !important;
  }
}

.width-pct-366 {
  width: 366% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-366 {
    width: 366% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-366 {
    width: 366% !important;
  }
}

.width-pct-367 {
  width: 367% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-367 {
    width: 367% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-367 {
    width: 367% !important;
  }
}

.width-pct-368 {
  width: 368% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-368 {
    width: 368% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-368 {
    width: 368% !important;
  }
}

.width-pct-369 {
  width: 369% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-369 {
    width: 369% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-369 {
    width: 369% !important;
  }
}

.width-pct-370 {
  width: 370% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-370 {
    width: 370% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-370 {
    width: 370% !important;
  }
}

.width-pct-371 {
  width: 371% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-371 {
    width: 371% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-371 {
    width: 371% !important;
  }
}

.width-pct-372 {
  width: 372% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-372 {
    width: 372% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-372 {
    width: 372% !important;
  }
}

.width-pct-373 {
  width: 373% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-373 {
    width: 373% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-373 {
    width: 373% !important;
  }
}

.width-pct-374 {
  width: 374% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-374 {
    width: 374% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-374 {
    width: 374% !important;
  }
}

.width-pct-375 {
  width: 375% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-375 {
    width: 375% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-375 {
    width: 375% !important;
  }
}

.width-pct-376 {
  width: 376% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-376 {
    width: 376% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-376 {
    width: 376% !important;
  }
}

.width-pct-377 {
  width: 377% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-377 {
    width: 377% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-377 {
    width: 377% !important;
  }
}

.width-pct-378 {
  width: 378% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-378 {
    width: 378% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-378 {
    width: 378% !important;
  }
}

.width-pct-379 {
  width: 379% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-379 {
    width: 379% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-379 {
    width: 379% !important;
  }
}

.width-pct-380 {
  width: 380% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-380 {
    width: 380% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-380 {
    width: 380% !important;
  }
}

.width-pct-381 {
  width: 381% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-381 {
    width: 381% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-381 {
    width: 381% !important;
  }
}

.width-pct-382 {
  width: 382% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-382 {
    width: 382% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-382 {
    width: 382% !important;
  }
}

.width-pct-383 {
  width: 383% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-383 {
    width: 383% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-383 {
    width: 383% !important;
  }
}

.width-pct-384 {
  width: 384% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-384 {
    width: 384% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-384 {
    width: 384% !important;
  }
}

.width-pct-385 {
  width: 385% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-385 {
    width: 385% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-385 {
    width: 385% !important;
  }
}

.width-pct-386 {
  width: 386% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-386 {
    width: 386% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-386 {
    width: 386% !important;
  }
}

.width-pct-387 {
  width: 387% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-387 {
    width: 387% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-387 {
    width: 387% !important;
  }
}

.width-pct-388 {
  width: 388% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-388 {
    width: 388% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-388 {
    width: 388% !important;
  }
}

.width-pct-389 {
  width: 389% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-389 {
    width: 389% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-389 {
    width: 389% !important;
  }
}

.width-pct-390 {
  width: 390% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-390 {
    width: 390% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-390 {
    width: 390% !important;
  }
}

.width-pct-391 {
  width: 391% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-391 {
    width: 391% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-391 {
    width: 391% !important;
  }
}

.width-pct-392 {
  width: 392% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-392 {
    width: 392% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-392 {
    width: 392% !important;
  }
}

.width-pct-393 {
  width: 393% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-393 {
    width: 393% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-393 {
    width: 393% !important;
  }
}

.width-pct-394 {
  width: 394% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-394 {
    width: 394% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-394 {
    width: 394% !important;
  }
}

.width-pct-395 {
  width: 395% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-395 {
    width: 395% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-395 {
    width: 395% !important;
  }
}

.width-pct-396 {
  width: 396% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-396 {
    width: 396% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-396 {
    width: 396% !important;
  }
}

.width-pct-397 {
  width: 397% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-397 {
    width: 397% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-397 {
    width: 397% !important;
  }
}

.width-pct-398 {
  width: 398% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-398 {
    width: 398% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-398 {
    width: 398% !important;
  }
}

.width-pct-399 {
  width: 399% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-399 {
    width: 399% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-399 {
    width: 399% !important;
  }
}

.width-pct-400 {
  width: 400% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-400 {
    width: 400% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-400 {
    width: 400% !important;
  }
}

.width-pct-401 {
  width: 401% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-401 {
    width: 401% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-401 {
    width: 401% !important;
  }
}

.width-pct-402 {
  width: 402% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-402 {
    width: 402% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-402 {
    width: 402% !important;
  }
}

.width-pct-403 {
  width: 403% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-403 {
    width: 403% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-403 {
    width: 403% !important;
  }
}

.width-pct-404 {
  width: 404% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-404 {
    width: 404% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-404 {
    width: 404% !important;
  }
}

.width-pct-405 {
  width: 405% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-405 {
    width: 405% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-405 {
    width: 405% !important;
  }
}

.width-pct-406 {
  width: 406% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-406 {
    width: 406% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-406 {
    width: 406% !important;
  }
}

.width-pct-407 {
  width: 407% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-407 {
    width: 407% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-407 {
    width: 407% !important;
  }
}

.width-pct-408 {
  width: 408% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-408 {
    width: 408% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-408 {
    width: 408% !important;
  }
}

.width-pct-409 {
  width: 409% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-409 {
    width: 409% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-409 {
    width: 409% !important;
  }
}

.width-pct-410 {
  width: 410% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-410 {
    width: 410% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-410 {
    width: 410% !important;
  }
}

.width-pct-411 {
  width: 411% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-411 {
    width: 411% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-411 {
    width: 411% !important;
  }
}

.width-pct-412 {
  width: 412% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-412 {
    width: 412% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-412 {
    width: 412% !important;
  }
}

.width-pct-413 {
  width: 413% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-413 {
    width: 413% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-413 {
    width: 413% !important;
  }
}

.width-pct-414 {
  width: 414% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-414 {
    width: 414% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-414 {
    width: 414% !important;
  }
}

.width-pct-415 {
  width: 415% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-415 {
    width: 415% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-415 {
    width: 415% !important;
  }
}

.width-pct-416 {
  width: 416% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-416 {
    width: 416% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-416 {
    width: 416% !important;
  }
}

.width-pct-417 {
  width: 417% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-417 {
    width: 417% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-417 {
    width: 417% !important;
  }
}

.width-pct-418 {
  width: 418% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-418 {
    width: 418% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-418 {
    width: 418% !important;
  }
}

.width-pct-419 {
  width: 419% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-419 {
    width: 419% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-419 {
    width: 419% !important;
  }
}

.width-pct-420 {
  width: 420% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-420 {
    width: 420% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-420 {
    width: 420% !important;
  }
}

.width-pct-421 {
  width: 421% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-421 {
    width: 421% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-421 {
    width: 421% !important;
  }
}

.width-pct-422 {
  width: 422% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-422 {
    width: 422% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-422 {
    width: 422% !important;
  }
}

.width-pct-423 {
  width: 423% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-423 {
    width: 423% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-423 {
    width: 423% !important;
  }
}

.width-pct-424 {
  width: 424% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-424 {
    width: 424% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-424 {
    width: 424% !important;
  }
}

.width-pct-425 {
  width: 425% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-425 {
    width: 425% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-425 {
    width: 425% !important;
  }
}

.width-pct-426 {
  width: 426% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-426 {
    width: 426% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-426 {
    width: 426% !important;
  }
}

.width-pct-427 {
  width: 427% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-427 {
    width: 427% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-427 {
    width: 427% !important;
  }
}

.width-pct-428 {
  width: 428% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-428 {
    width: 428% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-428 {
    width: 428% !important;
  }
}

.width-pct-429 {
  width: 429% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-429 {
    width: 429% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-429 {
    width: 429% !important;
  }
}

.width-pct-430 {
  width: 430% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-430 {
    width: 430% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-430 {
    width: 430% !important;
  }
}

.width-pct-431 {
  width: 431% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-431 {
    width: 431% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-431 {
    width: 431% !important;
  }
}

.width-pct-432 {
  width: 432% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-432 {
    width: 432% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-432 {
    width: 432% !important;
  }
}

.width-pct-433 {
  width: 433% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-433 {
    width: 433% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-433 {
    width: 433% !important;
  }
}

.width-pct-434 {
  width: 434% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-434 {
    width: 434% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-434 {
    width: 434% !important;
  }
}

.width-pct-435 {
  width: 435% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-435 {
    width: 435% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-435 {
    width: 435% !important;
  }
}

.width-pct-436 {
  width: 436% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-436 {
    width: 436% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-436 {
    width: 436% !important;
  }
}

.width-pct-437 {
  width: 437% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-437 {
    width: 437% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-437 {
    width: 437% !important;
  }
}

.width-pct-438 {
  width: 438% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-438 {
    width: 438% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-438 {
    width: 438% !important;
  }
}

.width-pct-439 {
  width: 439% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-439 {
    width: 439% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-439 {
    width: 439% !important;
  }
}

.width-pct-440 {
  width: 440% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-440 {
    width: 440% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-440 {
    width: 440% !important;
  }
}

.width-pct-441 {
  width: 441% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-441 {
    width: 441% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-441 {
    width: 441% !important;
  }
}

.width-pct-442 {
  width: 442% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-442 {
    width: 442% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-442 {
    width: 442% !important;
  }
}

.width-pct-443 {
  width: 443% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-443 {
    width: 443% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-443 {
    width: 443% !important;
  }
}

.width-pct-444 {
  width: 444% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-444 {
    width: 444% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-444 {
    width: 444% !important;
  }
}

.width-pct-445 {
  width: 445% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-445 {
    width: 445% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-445 {
    width: 445% !important;
  }
}

.width-pct-446 {
  width: 446% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-446 {
    width: 446% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-446 {
    width: 446% !important;
  }
}

.width-pct-447 {
  width: 447% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-447 {
    width: 447% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-447 {
    width: 447% !important;
  }
}

.width-pct-448 {
  width: 448% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-448 {
    width: 448% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-448 {
    width: 448% !important;
  }
}

.width-pct-449 {
  width: 449% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-449 {
    width: 449% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-449 {
    width: 449% !important;
  }
}

.width-pct-450 {
  width: 450% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-450 {
    width: 450% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-450 {
    width: 450% !important;
  }
}

.width-pct-451 {
  width: 451% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-451 {
    width: 451% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-451 {
    width: 451% !important;
  }
}

.width-pct-452 {
  width: 452% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-452 {
    width: 452% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-452 {
    width: 452% !important;
  }
}

.width-pct-453 {
  width: 453% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-453 {
    width: 453% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-453 {
    width: 453% !important;
  }
}

.width-pct-454 {
  width: 454% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-454 {
    width: 454% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-454 {
    width: 454% !important;
  }
}

.width-pct-455 {
  width: 455% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-455 {
    width: 455% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-455 {
    width: 455% !important;
  }
}

.width-pct-456 {
  width: 456% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-456 {
    width: 456% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-456 {
    width: 456% !important;
  }
}

.width-pct-457 {
  width: 457% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-457 {
    width: 457% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-457 {
    width: 457% !important;
  }
}

.width-pct-458 {
  width: 458% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-458 {
    width: 458% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-458 {
    width: 458% !important;
  }
}

.width-pct-459 {
  width: 459% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-459 {
    width: 459% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-459 {
    width: 459% !important;
  }
}

.width-pct-460 {
  width: 460% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-460 {
    width: 460% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-460 {
    width: 460% !important;
  }
}

.width-pct-461 {
  width: 461% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-461 {
    width: 461% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-461 {
    width: 461% !important;
  }
}

.width-pct-462 {
  width: 462% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-462 {
    width: 462% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-462 {
    width: 462% !important;
  }
}

.width-pct-463 {
  width: 463% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-463 {
    width: 463% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-463 {
    width: 463% !important;
  }
}

.width-pct-464 {
  width: 464% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-464 {
    width: 464% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-464 {
    width: 464% !important;
  }
}

.width-pct-465 {
  width: 465% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-465 {
    width: 465% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-465 {
    width: 465% !important;
  }
}

.width-pct-466 {
  width: 466% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-466 {
    width: 466% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-466 {
    width: 466% !important;
  }
}

.width-pct-467 {
  width: 467% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-467 {
    width: 467% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-467 {
    width: 467% !important;
  }
}

.width-pct-468 {
  width: 468% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-468 {
    width: 468% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-468 {
    width: 468% !important;
  }
}

.width-pct-469 {
  width: 469% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-469 {
    width: 469% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-469 {
    width: 469% !important;
  }
}

.width-pct-470 {
  width: 470% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-470 {
    width: 470% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-470 {
    width: 470% !important;
  }
}

.width-pct-471 {
  width: 471% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-471 {
    width: 471% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-471 {
    width: 471% !important;
  }
}

.width-pct-472 {
  width: 472% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-472 {
    width: 472% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-472 {
    width: 472% !important;
  }
}

.width-pct-473 {
  width: 473% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-473 {
    width: 473% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-473 {
    width: 473% !important;
  }
}

.width-pct-474 {
  width: 474% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-474 {
    width: 474% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-474 {
    width: 474% !important;
  }
}

.width-pct-475 {
  width: 475% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-475 {
    width: 475% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-475 {
    width: 475% !important;
  }
}

.width-pct-476 {
  width: 476% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-476 {
    width: 476% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-476 {
    width: 476% !important;
  }
}

.width-pct-477 {
  width: 477% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-477 {
    width: 477% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-477 {
    width: 477% !important;
  }
}

.width-pct-478 {
  width: 478% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-478 {
    width: 478% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-478 {
    width: 478% !important;
  }
}

.width-pct-479 {
  width: 479% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-479 {
    width: 479% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-479 {
    width: 479% !important;
  }
}

.width-pct-480 {
  width: 480% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-480 {
    width: 480% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-480 {
    width: 480% !important;
  }
}

.width-pct-481 {
  width: 481% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-481 {
    width: 481% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-481 {
    width: 481% !important;
  }
}

.width-pct-482 {
  width: 482% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-482 {
    width: 482% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-482 {
    width: 482% !important;
  }
}

.width-pct-483 {
  width: 483% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-483 {
    width: 483% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-483 {
    width: 483% !important;
  }
}

.width-pct-484 {
  width: 484% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-484 {
    width: 484% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-484 {
    width: 484% !important;
  }
}

.width-pct-485 {
  width: 485% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-485 {
    width: 485% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-485 {
    width: 485% !important;
  }
}

.width-pct-486 {
  width: 486% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-486 {
    width: 486% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-486 {
    width: 486% !important;
  }
}

.width-pct-487 {
  width: 487% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-487 {
    width: 487% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-487 {
    width: 487% !important;
  }
}

.width-pct-488 {
  width: 488% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-488 {
    width: 488% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-488 {
    width: 488% !important;
  }
}

.width-pct-489 {
  width: 489% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-489 {
    width: 489% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-489 {
    width: 489% !important;
  }
}

.width-pct-490 {
  width: 490% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-490 {
    width: 490% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-490 {
    width: 490% !important;
  }
}

.width-pct-491 {
  width: 491% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-491 {
    width: 491% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-491 {
    width: 491% !important;
  }
}

.width-pct-492 {
  width: 492% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-492 {
    width: 492% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-492 {
    width: 492% !important;
  }
}

.width-pct-493 {
  width: 493% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-493 {
    width: 493% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-493 {
    width: 493% !important;
  }
}

.width-pct-494 {
  width: 494% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-494 {
    width: 494% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-494 {
    width: 494% !important;
  }
}

.width-pct-495 {
  width: 495% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-495 {
    width: 495% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-495 {
    width: 495% !important;
  }
}

.width-pct-496 {
  width: 496% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-496 {
    width: 496% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-496 {
    width: 496% !important;
  }
}

.width-pct-497 {
  width: 497% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-497 {
    width: 497% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-497 {
    width: 497% !important;
  }
}

.width-pct-498 {
  width: 498% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-498 {
    width: 498% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-498 {
    width: 498% !important;
  }
}

.width-pct-499 {
  width: 499% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-499 {
    width: 499% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-499 {
    width: 499% !important;
  }
}

.width-pct-500 {
  width: 500% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-500 {
    width: 500% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-500 {
    width: 500% !important;
  }
}

.width-pct-501 {
  width: 501% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-501 {
    width: 501% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-501 {
    width: 501% !important;
  }
}

.width-pct-502 {
  width: 502% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-502 {
    width: 502% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-502 {
    width: 502% !important;
  }
}

.width-pct-503 {
  width: 503% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-503 {
    width: 503% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-503 {
    width: 503% !important;
  }
}

.width-pct-504 {
  width: 504% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-504 {
    width: 504% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-504 {
    width: 504% !important;
  }
}

.width-pct-505 {
  width: 505% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-505 {
    width: 505% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-505 {
    width: 505% !important;
  }
}

.width-pct-506 {
  width: 506% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-506 {
    width: 506% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-506 {
    width: 506% !important;
  }
}

.width-pct-507 {
  width: 507% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-507 {
    width: 507% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-507 {
    width: 507% !important;
  }
}

.width-pct-508 {
  width: 508% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-508 {
    width: 508% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-508 {
    width: 508% !important;
  }
}

.width-pct-509 {
  width: 509% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-509 {
    width: 509% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-509 {
    width: 509% !important;
  }
}

.width-pct-510 {
  width: 510% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-510 {
    width: 510% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-510 {
    width: 510% !important;
  }
}

.width-pct-511 {
  width: 511% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-511 {
    width: 511% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-511 {
    width: 511% !important;
  }
}

.width-pct-512 {
  width: 512% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-512 {
    width: 512% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-512 {
    width: 512% !important;
  }
}

.width-pct-513 {
  width: 513% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-513 {
    width: 513% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-513 {
    width: 513% !important;
  }
}

.width-pct-514 {
  width: 514% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-514 {
    width: 514% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-514 {
    width: 514% !important;
  }
}

.width-pct-515 {
  width: 515% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-515 {
    width: 515% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-515 {
    width: 515% !important;
  }
}

.width-pct-516 {
  width: 516% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-516 {
    width: 516% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-516 {
    width: 516% !important;
  }
}

.width-pct-517 {
  width: 517% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-517 {
    width: 517% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-517 {
    width: 517% !important;
  }
}

.width-pct-518 {
  width: 518% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-518 {
    width: 518% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-518 {
    width: 518% !important;
  }
}

.width-pct-519 {
  width: 519% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-519 {
    width: 519% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-519 {
    width: 519% !important;
  }
}

.width-pct-520 {
  width: 520% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-520 {
    width: 520% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-520 {
    width: 520% !important;
  }
}

.width-pct-521 {
  width: 521% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-521 {
    width: 521% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-521 {
    width: 521% !important;
  }
}

.width-pct-522 {
  width: 522% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-522 {
    width: 522% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-522 {
    width: 522% !important;
  }
}

.width-pct-523 {
  width: 523% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-523 {
    width: 523% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-523 {
    width: 523% !important;
  }
}

.width-pct-524 {
  width: 524% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-524 {
    width: 524% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-524 {
    width: 524% !important;
  }
}

.width-pct-525 {
  width: 525% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-525 {
    width: 525% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-525 {
    width: 525% !important;
  }
}

.width-pct-526 {
  width: 526% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-526 {
    width: 526% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-526 {
    width: 526% !important;
  }
}

.width-pct-527 {
  width: 527% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-527 {
    width: 527% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-527 {
    width: 527% !important;
  }
}

.width-pct-528 {
  width: 528% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-528 {
    width: 528% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-528 {
    width: 528% !important;
  }
}

.width-pct-529 {
  width: 529% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-529 {
    width: 529% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-529 {
    width: 529% !important;
  }
}

.width-pct-530 {
  width: 530% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-530 {
    width: 530% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-530 {
    width: 530% !important;
  }
}

.width-pct-531 {
  width: 531% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-531 {
    width: 531% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-531 {
    width: 531% !important;
  }
}

.width-pct-532 {
  width: 532% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-532 {
    width: 532% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-532 {
    width: 532% !important;
  }
}

.width-pct-533 {
  width: 533% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-533 {
    width: 533% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-533 {
    width: 533% !important;
  }
}

.width-pct-534 {
  width: 534% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-534 {
    width: 534% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-534 {
    width: 534% !important;
  }
}

.width-pct-535 {
  width: 535% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-535 {
    width: 535% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-535 {
    width: 535% !important;
  }
}

.width-pct-536 {
  width: 536% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-536 {
    width: 536% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-536 {
    width: 536% !important;
  }
}

.width-pct-537 {
  width: 537% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-537 {
    width: 537% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-537 {
    width: 537% !important;
  }
}

.width-pct-538 {
  width: 538% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-538 {
    width: 538% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-538 {
    width: 538% !important;
  }
}

.width-pct-539 {
  width: 539% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-539 {
    width: 539% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-539 {
    width: 539% !important;
  }
}

.width-pct-540 {
  width: 540% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-540 {
    width: 540% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-540 {
    width: 540% !important;
  }
}

.width-pct-541 {
  width: 541% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-541 {
    width: 541% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-541 {
    width: 541% !important;
  }
}

.width-pct-542 {
  width: 542% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-542 {
    width: 542% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-542 {
    width: 542% !important;
  }
}

.width-pct-543 {
  width: 543% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-543 {
    width: 543% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-543 {
    width: 543% !important;
  }
}

.width-pct-544 {
  width: 544% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-544 {
    width: 544% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-544 {
    width: 544% !important;
  }
}

.width-pct-545 {
  width: 545% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-545 {
    width: 545% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-545 {
    width: 545% !important;
  }
}

.width-pct-546 {
  width: 546% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-546 {
    width: 546% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-546 {
    width: 546% !important;
  }
}

.width-pct-547 {
  width: 547% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-547 {
    width: 547% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-547 {
    width: 547% !important;
  }
}

.width-pct-548 {
  width: 548% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-548 {
    width: 548% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-548 {
    width: 548% !important;
  }
}

.width-pct-549 {
  width: 549% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-549 {
    width: 549% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-549 {
    width: 549% !important;
  }
}

.width-pct-550 {
  width: 550% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-550 {
    width: 550% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-550 {
    width: 550% !important;
  }
}

.width-pct-551 {
  width: 551% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-551 {
    width: 551% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-551 {
    width: 551% !important;
  }
}

.width-pct-552 {
  width: 552% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-552 {
    width: 552% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-552 {
    width: 552% !important;
  }
}

.width-pct-553 {
  width: 553% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-553 {
    width: 553% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-553 {
    width: 553% !important;
  }
}

.width-pct-554 {
  width: 554% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-554 {
    width: 554% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-554 {
    width: 554% !important;
  }
}

.width-pct-555 {
  width: 555% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-555 {
    width: 555% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-555 {
    width: 555% !important;
  }
}

.width-pct-556 {
  width: 556% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-556 {
    width: 556% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-556 {
    width: 556% !important;
  }
}

.width-pct-557 {
  width: 557% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-557 {
    width: 557% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-557 {
    width: 557% !important;
  }
}

.width-pct-558 {
  width: 558% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-558 {
    width: 558% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-558 {
    width: 558% !important;
  }
}

.width-pct-559 {
  width: 559% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-559 {
    width: 559% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-559 {
    width: 559% !important;
  }
}

.width-pct-560 {
  width: 560% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-560 {
    width: 560% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-560 {
    width: 560% !important;
  }
}

.width-pct-561 {
  width: 561% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-561 {
    width: 561% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-561 {
    width: 561% !important;
  }
}

.width-pct-562 {
  width: 562% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-562 {
    width: 562% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-562 {
    width: 562% !important;
  }
}

.width-pct-563 {
  width: 563% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-563 {
    width: 563% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-563 {
    width: 563% !important;
  }
}

.width-pct-564 {
  width: 564% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-564 {
    width: 564% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-564 {
    width: 564% !important;
  }
}

.width-pct-565 {
  width: 565% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-565 {
    width: 565% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-565 {
    width: 565% !important;
  }
}

.width-pct-566 {
  width: 566% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-566 {
    width: 566% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-566 {
    width: 566% !important;
  }
}

.width-pct-567 {
  width: 567% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-567 {
    width: 567% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-567 {
    width: 567% !important;
  }
}

.width-pct-568 {
  width: 568% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-568 {
    width: 568% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-568 {
    width: 568% !important;
  }
}

.width-pct-569 {
  width: 569% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-569 {
    width: 569% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-569 {
    width: 569% !important;
  }
}

.width-pct-570 {
  width: 570% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-570 {
    width: 570% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-570 {
    width: 570% !important;
  }
}

.width-pct-571 {
  width: 571% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-571 {
    width: 571% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-571 {
    width: 571% !important;
  }
}

.width-pct-572 {
  width: 572% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-572 {
    width: 572% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-572 {
    width: 572% !important;
  }
}

.width-pct-573 {
  width: 573% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-573 {
    width: 573% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-573 {
    width: 573% !important;
  }
}

.width-pct-574 {
  width: 574% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-574 {
    width: 574% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-574 {
    width: 574% !important;
  }
}

.width-pct-575 {
  width: 575% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-575 {
    width: 575% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-575 {
    width: 575% !important;
  }
}

.width-pct-576 {
  width: 576% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-576 {
    width: 576% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-576 {
    width: 576% !important;
  }
}

.width-pct-577 {
  width: 577% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-577 {
    width: 577% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-577 {
    width: 577% !important;
  }
}

.width-pct-578 {
  width: 578% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-578 {
    width: 578% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-578 {
    width: 578% !important;
  }
}

.width-pct-579 {
  width: 579% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-579 {
    width: 579% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-579 {
    width: 579% !important;
  }
}

.width-pct-580 {
  width: 580% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-580 {
    width: 580% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-580 {
    width: 580% !important;
  }
}

.width-pct-581 {
  width: 581% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-581 {
    width: 581% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-581 {
    width: 581% !important;
  }
}

.width-pct-582 {
  width: 582% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-582 {
    width: 582% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-582 {
    width: 582% !important;
  }
}

.width-pct-583 {
  width: 583% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-583 {
    width: 583% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-583 {
    width: 583% !important;
  }
}

.width-pct-584 {
  width: 584% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-584 {
    width: 584% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-584 {
    width: 584% !important;
  }
}

.width-pct-585 {
  width: 585% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-585 {
    width: 585% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-585 {
    width: 585% !important;
  }
}

.width-pct-586 {
  width: 586% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-586 {
    width: 586% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-586 {
    width: 586% !important;
  }
}

.width-pct-587 {
  width: 587% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-587 {
    width: 587% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-587 {
    width: 587% !important;
  }
}

.width-pct-588 {
  width: 588% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-588 {
    width: 588% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-588 {
    width: 588% !important;
  }
}

.width-pct-589 {
  width: 589% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-589 {
    width: 589% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-589 {
    width: 589% !important;
  }
}

.width-pct-590 {
  width: 590% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-590 {
    width: 590% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-590 {
    width: 590% !important;
  }
}

.width-pct-591 {
  width: 591% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-591 {
    width: 591% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-591 {
    width: 591% !important;
  }
}

.width-pct-592 {
  width: 592% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-592 {
    width: 592% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-592 {
    width: 592% !important;
  }
}

.width-pct-593 {
  width: 593% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-593 {
    width: 593% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-593 {
    width: 593% !important;
  }
}

.width-pct-594 {
  width: 594% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-594 {
    width: 594% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-594 {
    width: 594% !important;
  }
}

.width-pct-595 {
  width: 595% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-595 {
    width: 595% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-595 {
    width: 595% !important;
  }
}

.width-pct-596 {
  width: 596% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-596 {
    width: 596% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-596 {
    width: 596% !important;
  }
}

.width-pct-597 {
  width: 597% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-597 {
    width: 597% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-597 {
    width: 597% !important;
  }
}

.width-pct-598 {
  width: 598% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-598 {
    width: 598% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-598 {
    width: 598% !important;
  }
}

.width-pct-599 {
  width: 599% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-599 {
    width: 599% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-599 {
    width: 599% !important;
  }
}

.width-pct-600 {
  width: 600% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-600 {
    width: 600% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-600 {
    width: 600% !important;
  }
}

.width-pct-601 {
  width: 601% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-601 {
    width: 601% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-601 {
    width: 601% !important;
  }
}

.width-pct-602 {
  width: 602% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-602 {
    width: 602% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-602 {
    width: 602% !important;
  }
}

.width-pct-603 {
  width: 603% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-603 {
    width: 603% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-603 {
    width: 603% !important;
  }
}

.width-pct-604 {
  width: 604% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-604 {
    width: 604% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-604 {
    width: 604% !important;
  }
}

.width-pct-605 {
  width: 605% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-605 {
    width: 605% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-605 {
    width: 605% !important;
  }
}

.width-pct-606 {
  width: 606% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-606 {
    width: 606% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-606 {
    width: 606% !important;
  }
}

.width-pct-607 {
  width: 607% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-607 {
    width: 607% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-607 {
    width: 607% !important;
  }
}

.width-pct-608 {
  width: 608% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-608 {
    width: 608% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-608 {
    width: 608% !important;
  }
}

.width-pct-609 {
  width: 609% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-609 {
    width: 609% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-609 {
    width: 609% !important;
  }
}

.width-pct-610 {
  width: 610% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-610 {
    width: 610% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-610 {
    width: 610% !important;
  }
}

.width-pct-611 {
  width: 611% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-611 {
    width: 611% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-611 {
    width: 611% !important;
  }
}

.width-pct-612 {
  width: 612% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-612 {
    width: 612% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-612 {
    width: 612% !important;
  }
}

.width-pct-613 {
  width: 613% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-613 {
    width: 613% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-613 {
    width: 613% !important;
  }
}

.width-pct-614 {
  width: 614% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-614 {
    width: 614% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-614 {
    width: 614% !important;
  }
}

.width-pct-615 {
  width: 615% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-615 {
    width: 615% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-615 {
    width: 615% !important;
  }
}

.width-pct-616 {
  width: 616% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-616 {
    width: 616% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-616 {
    width: 616% !important;
  }
}

.width-pct-617 {
  width: 617% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-617 {
    width: 617% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-617 {
    width: 617% !important;
  }
}

.width-pct-618 {
  width: 618% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-618 {
    width: 618% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-618 {
    width: 618% !important;
  }
}

.width-pct-619 {
  width: 619% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-619 {
    width: 619% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-619 {
    width: 619% !important;
  }
}

.width-pct-620 {
  width: 620% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-620 {
    width: 620% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-620 {
    width: 620% !important;
  }
}

.width-pct-621 {
  width: 621% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-621 {
    width: 621% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-621 {
    width: 621% !important;
  }
}

.width-pct-622 {
  width: 622% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-622 {
    width: 622% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-622 {
    width: 622% !important;
  }
}

.width-pct-623 {
  width: 623% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-623 {
    width: 623% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-623 {
    width: 623% !important;
  }
}

.width-pct-624 {
  width: 624% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-624 {
    width: 624% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-624 {
    width: 624% !important;
  }
}

.width-pct-625 {
  width: 625% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-625 {
    width: 625% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-625 {
    width: 625% !important;
  }
}

.width-pct-626 {
  width: 626% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-626 {
    width: 626% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-626 {
    width: 626% !important;
  }
}

.width-pct-627 {
  width: 627% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-627 {
    width: 627% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-627 {
    width: 627% !important;
  }
}

.width-pct-628 {
  width: 628% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-628 {
    width: 628% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-628 {
    width: 628% !important;
  }
}

.width-pct-629 {
  width: 629% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-629 {
    width: 629% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-629 {
    width: 629% !important;
  }
}

.width-pct-630 {
  width: 630% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-630 {
    width: 630% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-630 {
    width: 630% !important;
  }
}

.width-pct-631 {
  width: 631% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-631 {
    width: 631% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-631 {
    width: 631% !important;
  }
}

.width-pct-632 {
  width: 632% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-632 {
    width: 632% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-632 {
    width: 632% !important;
  }
}

.width-pct-633 {
  width: 633% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-633 {
    width: 633% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-633 {
    width: 633% !important;
  }
}

.width-pct-634 {
  width: 634% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-634 {
    width: 634% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-634 {
    width: 634% !important;
  }
}

.width-pct-635 {
  width: 635% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-635 {
    width: 635% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-635 {
    width: 635% !important;
  }
}

.width-pct-636 {
  width: 636% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-636 {
    width: 636% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-636 {
    width: 636% !important;
  }
}

.width-pct-637 {
  width: 637% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-637 {
    width: 637% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-637 {
    width: 637% !important;
  }
}

.width-pct-638 {
  width: 638% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-638 {
    width: 638% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-638 {
    width: 638% !important;
  }
}

.width-pct-639 {
  width: 639% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-639 {
    width: 639% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-639 {
    width: 639% !important;
  }
}

.width-pct-640 {
  width: 640% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-640 {
    width: 640% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-640 {
    width: 640% !important;
  }
}

.width-pct-641 {
  width: 641% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-641 {
    width: 641% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-641 {
    width: 641% !important;
  }
}

.width-pct-642 {
  width: 642% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-642 {
    width: 642% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-642 {
    width: 642% !important;
  }
}

.width-pct-643 {
  width: 643% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-643 {
    width: 643% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-643 {
    width: 643% !important;
  }
}

.width-pct-644 {
  width: 644% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-644 {
    width: 644% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-644 {
    width: 644% !important;
  }
}

.width-pct-645 {
  width: 645% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-645 {
    width: 645% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-645 {
    width: 645% !important;
  }
}

.width-pct-646 {
  width: 646% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-646 {
    width: 646% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-646 {
    width: 646% !important;
  }
}

.width-pct-647 {
  width: 647% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-647 {
    width: 647% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-647 {
    width: 647% !important;
  }
}

.width-pct-648 {
  width: 648% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-648 {
    width: 648% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-648 {
    width: 648% !important;
  }
}

.width-pct-649 {
  width: 649% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-649 {
    width: 649% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-649 {
    width: 649% !important;
  }
}

.width-pct-650 {
  width: 650% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-650 {
    width: 650% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-650 {
    width: 650% !important;
  }
}

.width-pct-651 {
  width: 651% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-651 {
    width: 651% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-651 {
    width: 651% !important;
  }
}

.width-pct-652 {
  width: 652% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-652 {
    width: 652% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-652 {
    width: 652% !important;
  }
}

.width-pct-653 {
  width: 653% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-653 {
    width: 653% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-653 {
    width: 653% !important;
  }
}

.width-pct-654 {
  width: 654% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-654 {
    width: 654% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-654 {
    width: 654% !important;
  }
}

.width-pct-655 {
  width: 655% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-655 {
    width: 655% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-655 {
    width: 655% !important;
  }
}

.width-pct-656 {
  width: 656% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-656 {
    width: 656% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-656 {
    width: 656% !important;
  }
}

.width-pct-657 {
  width: 657% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-657 {
    width: 657% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-657 {
    width: 657% !important;
  }
}

.width-pct-658 {
  width: 658% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-658 {
    width: 658% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-658 {
    width: 658% !important;
  }
}

.width-pct-659 {
  width: 659% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-659 {
    width: 659% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-659 {
    width: 659% !important;
  }
}

.width-pct-660 {
  width: 660% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-660 {
    width: 660% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-660 {
    width: 660% !important;
  }
}

.width-pct-661 {
  width: 661% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-661 {
    width: 661% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-661 {
    width: 661% !important;
  }
}

.width-pct-662 {
  width: 662% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-662 {
    width: 662% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-662 {
    width: 662% !important;
  }
}

.width-pct-663 {
  width: 663% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-663 {
    width: 663% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-663 {
    width: 663% !important;
  }
}

.width-pct-664 {
  width: 664% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-664 {
    width: 664% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-664 {
    width: 664% !important;
  }
}

.width-pct-665 {
  width: 665% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-665 {
    width: 665% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-665 {
    width: 665% !important;
  }
}

.width-pct-666 {
  width: 666% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-666 {
    width: 666% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-666 {
    width: 666% !important;
  }
}

.width-pct-667 {
  width: 667% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-667 {
    width: 667% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-667 {
    width: 667% !important;
  }
}

.width-pct-668 {
  width: 668% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-668 {
    width: 668% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-668 {
    width: 668% !important;
  }
}

.width-pct-669 {
  width: 669% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-669 {
    width: 669% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-669 {
    width: 669% !important;
  }
}

.width-pct-670 {
  width: 670% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-670 {
    width: 670% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-670 {
    width: 670% !important;
  }
}

.width-pct-671 {
  width: 671% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-671 {
    width: 671% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-671 {
    width: 671% !important;
  }
}

.width-pct-672 {
  width: 672% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-672 {
    width: 672% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-672 {
    width: 672% !important;
  }
}

.width-pct-673 {
  width: 673% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-673 {
    width: 673% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-673 {
    width: 673% !important;
  }
}

.width-pct-674 {
  width: 674% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-674 {
    width: 674% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-674 {
    width: 674% !important;
  }
}

.width-pct-675 {
  width: 675% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-675 {
    width: 675% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-675 {
    width: 675% !important;
  }
}

.width-pct-676 {
  width: 676% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-676 {
    width: 676% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-676 {
    width: 676% !important;
  }
}

.width-pct-677 {
  width: 677% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-677 {
    width: 677% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-677 {
    width: 677% !important;
  }
}

.width-pct-678 {
  width: 678% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-678 {
    width: 678% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-678 {
    width: 678% !important;
  }
}

.width-pct-679 {
  width: 679% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-679 {
    width: 679% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-679 {
    width: 679% !important;
  }
}

.width-pct-680 {
  width: 680% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-680 {
    width: 680% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-680 {
    width: 680% !important;
  }
}

.width-pct-681 {
  width: 681% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-681 {
    width: 681% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-681 {
    width: 681% !important;
  }
}

.width-pct-682 {
  width: 682% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-682 {
    width: 682% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-682 {
    width: 682% !important;
  }
}

.width-pct-683 {
  width: 683% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-683 {
    width: 683% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-683 {
    width: 683% !important;
  }
}

.width-pct-684 {
  width: 684% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-684 {
    width: 684% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-684 {
    width: 684% !important;
  }
}

.width-pct-685 {
  width: 685% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-685 {
    width: 685% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-685 {
    width: 685% !important;
  }
}

.width-pct-686 {
  width: 686% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-686 {
    width: 686% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-686 {
    width: 686% !important;
  }
}

.width-pct-687 {
  width: 687% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-687 {
    width: 687% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-687 {
    width: 687% !important;
  }
}

.width-pct-688 {
  width: 688% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-688 {
    width: 688% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-688 {
    width: 688% !important;
  }
}

.width-pct-689 {
  width: 689% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-689 {
    width: 689% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-689 {
    width: 689% !important;
  }
}

.width-pct-690 {
  width: 690% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-690 {
    width: 690% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-690 {
    width: 690% !important;
  }
}

.width-pct-691 {
  width: 691% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-691 {
    width: 691% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-691 {
    width: 691% !important;
  }
}

.width-pct-692 {
  width: 692% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-692 {
    width: 692% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-692 {
    width: 692% !important;
  }
}

.width-pct-693 {
  width: 693% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-693 {
    width: 693% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-693 {
    width: 693% !important;
  }
}

.width-pct-694 {
  width: 694% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-694 {
    width: 694% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-694 {
    width: 694% !important;
  }
}

.width-pct-695 {
  width: 695% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-695 {
    width: 695% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-695 {
    width: 695% !important;
  }
}

.width-pct-696 {
  width: 696% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-696 {
    width: 696% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-696 {
    width: 696% !important;
  }
}

.width-pct-697 {
  width: 697% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-697 {
    width: 697% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-697 {
    width: 697% !important;
  }
}

.width-pct-698 {
  width: 698% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-698 {
    width: 698% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-698 {
    width: 698% !important;
  }
}

.width-pct-699 {
  width: 699% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-699 {
    width: 699% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-699 {
    width: 699% !important;
  }
}

.width-pct-700 {
  width: 700% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-700 {
    width: 700% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-700 {
    width: 700% !important;
  }
}

.width-pct-701 {
  width: 701% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-701 {
    width: 701% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-701 {
    width: 701% !important;
  }
}

.width-pct-702 {
  width: 702% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-702 {
    width: 702% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-702 {
    width: 702% !important;
  }
}

.width-pct-703 {
  width: 703% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-703 {
    width: 703% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-703 {
    width: 703% !important;
  }
}

.width-pct-704 {
  width: 704% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-704 {
    width: 704% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-704 {
    width: 704% !important;
  }
}

.width-pct-705 {
  width: 705% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-705 {
    width: 705% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-705 {
    width: 705% !important;
  }
}

.width-pct-706 {
  width: 706% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-706 {
    width: 706% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-706 {
    width: 706% !important;
  }
}

.width-pct-707 {
  width: 707% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-707 {
    width: 707% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-707 {
    width: 707% !important;
  }
}

.width-pct-708 {
  width: 708% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-708 {
    width: 708% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-708 {
    width: 708% !important;
  }
}

.width-pct-709 {
  width: 709% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-709 {
    width: 709% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-709 {
    width: 709% !important;
  }
}

.width-pct-710 {
  width: 710% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-710 {
    width: 710% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-710 {
    width: 710% !important;
  }
}

.width-pct-711 {
  width: 711% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-711 {
    width: 711% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-711 {
    width: 711% !important;
  }
}

.width-pct-712 {
  width: 712% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-712 {
    width: 712% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-712 {
    width: 712% !important;
  }
}

.width-pct-713 {
  width: 713% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-713 {
    width: 713% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-713 {
    width: 713% !important;
  }
}

.width-pct-714 {
  width: 714% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-714 {
    width: 714% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-714 {
    width: 714% !important;
  }
}

.width-pct-715 {
  width: 715% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-715 {
    width: 715% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-715 {
    width: 715% !important;
  }
}

.width-pct-716 {
  width: 716% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-716 {
    width: 716% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-716 {
    width: 716% !important;
  }
}

.width-pct-717 {
  width: 717% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-717 {
    width: 717% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-717 {
    width: 717% !important;
  }
}

.width-pct-718 {
  width: 718% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-718 {
    width: 718% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-718 {
    width: 718% !important;
  }
}

.width-pct-719 {
  width: 719% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-719 {
    width: 719% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-719 {
    width: 719% !important;
  }
}

.width-pct-720 {
  width: 720% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-720 {
    width: 720% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-720 {
    width: 720% !important;
  }
}

.width-pct-721 {
  width: 721% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-721 {
    width: 721% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-721 {
    width: 721% !important;
  }
}

.width-pct-722 {
  width: 722% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-722 {
    width: 722% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-722 {
    width: 722% !important;
  }
}

.width-pct-723 {
  width: 723% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-723 {
    width: 723% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-723 {
    width: 723% !important;
  }
}

.width-pct-724 {
  width: 724% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-724 {
    width: 724% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-724 {
    width: 724% !important;
  }
}

.width-pct-725 {
  width: 725% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-725 {
    width: 725% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-725 {
    width: 725% !important;
  }
}

.width-pct-726 {
  width: 726% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-726 {
    width: 726% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-726 {
    width: 726% !important;
  }
}

.width-pct-727 {
  width: 727% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-727 {
    width: 727% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-727 {
    width: 727% !important;
  }
}

.width-pct-728 {
  width: 728% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-728 {
    width: 728% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-728 {
    width: 728% !important;
  }
}

.width-pct-729 {
  width: 729% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-729 {
    width: 729% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-729 {
    width: 729% !important;
  }
}

.width-pct-730 {
  width: 730% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-730 {
    width: 730% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-730 {
    width: 730% !important;
  }
}

.width-pct-731 {
  width: 731% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-731 {
    width: 731% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-731 {
    width: 731% !important;
  }
}

.width-pct-732 {
  width: 732% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-732 {
    width: 732% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-732 {
    width: 732% !important;
  }
}

.width-pct-733 {
  width: 733% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-733 {
    width: 733% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-733 {
    width: 733% !important;
  }
}

.width-pct-734 {
  width: 734% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-734 {
    width: 734% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-734 {
    width: 734% !important;
  }
}

.width-pct-735 {
  width: 735% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-735 {
    width: 735% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-735 {
    width: 735% !important;
  }
}

.width-pct-736 {
  width: 736% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-736 {
    width: 736% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-736 {
    width: 736% !important;
  }
}

.width-pct-737 {
  width: 737% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-737 {
    width: 737% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-737 {
    width: 737% !important;
  }
}

.width-pct-738 {
  width: 738% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-738 {
    width: 738% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-738 {
    width: 738% !important;
  }
}

.width-pct-739 {
  width: 739% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-739 {
    width: 739% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-739 {
    width: 739% !important;
  }
}

.width-pct-740 {
  width: 740% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-740 {
    width: 740% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-740 {
    width: 740% !important;
  }
}

.width-pct-741 {
  width: 741% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-741 {
    width: 741% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-741 {
    width: 741% !important;
  }
}

.width-pct-742 {
  width: 742% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-742 {
    width: 742% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-742 {
    width: 742% !important;
  }
}

.width-pct-743 {
  width: 743% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-743 {
    width: 743% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-743 {
    width: 743% !important;
  }
}

.width-pct-744 {
  width: 744% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-744 {
    width: 744% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-744 {
    width: 744% !important;
  }
}

.width-pct-745 {
  width: 745% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-745 {
    width: 745% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-745 {
    width: 745% !important;
  }
}

.width-pct-746 {
  width: 746% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-746 {
    width: 746% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-746 {
    width: 746% !important;
  }
}

.width-pct-747 {
  width: 747% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-747 {
    width: 747% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-747 {
    width: 747% !important;
  }
}

.width-pct-748 {
  width: 748% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-748 {
    width: 748% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-748 {
    width: 748% !important;
  }
}

.width-pct-749 {
  width: 749% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-749 {
    width: 749% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-749 {
    width: 749% !important;
  }
}

.width-pct-750 {
  width: 750% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-750 {
    width: 750% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-750 {
    width: 750% !important;
  }
}

.width-pct-751 {
  width: 751% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-751 {
    width: 751% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-751 {
    width: 751% !important;
  }
}

.width-pct-752 {
  width: 752% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-752 {
    width: 752% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-752 {
    width: 752% !important;
  }
}

.width-pct-753 {
  width: 753% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-753 {
    width: 753% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-753 {
    width: 753% !important;
  }
}

.width-pct-754 {
  width: 754% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-754 {
    width: 754% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-754 {
    width: 754% !important;
  }
}

.width-pct-755 {
  width: 755% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-755 {
    width: 755% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-755 {
    width: 755% !important;
  }
}

.width-pct-756 {
  width: 756% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-756 {
    width: 756% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-756 {
    width: 756% !important;
  }
}

.width-pct-757 {
  width: 757% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-757 {
    width: 757% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-757 {
    width: 757% !important;
  }
}

.width-pct-758 {
  width: 758% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-758 {
    width: 758% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-758 {
    width: 758% !important;
  }
}

.width-pct-759 {
  width: 759% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-759 {
    width: 759% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-759 {
    width: 759% !important;
  }
}

.width-pct-760 {
  width: 760% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-760 {
    width: 760% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-760 {
    width: 760% !important;
  }
}

.width-pct-761 {
  width: 761% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-761 {
    width: 761% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-761 {
    width: 761% !important;
  }
}

.width-pct-762 {
  width: 762% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-762 {
    width: 762% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-762 {
    width: 762% !important;
  }
}

.width-pct-763 {
  width: 763% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-763 {
    width: 763% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-763 {
    width: 763% !important;
  }
}

.width-pct-764 {
  width: 764% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-764 {
    width: 764% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-764 {
    width: 764% !important;
  }
}

.width-pct-765 {
  width: 765% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-765 {
    width: 765% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-765 {
    width: 765% !important;
  }
}

.width-pct-766 {
  width: 766% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-766 {
    width: 766% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-766 {
    width: 766% !important;
  }
}

.width-pct-767 {
  width: 767% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-767 {
    width: 767% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-767 {
    width: 767% !important;
  }
}

.width-pct-768 {
  width: 768% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-768 {
    width: 768% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-768 {
    width: 768% !important;
  }
}

.width-pct-769 {
  width: 769% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-769 {
    width: 769% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-769 {
    width: 769% !important;
  }
}

.width-pct-770 {
  width: 770% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-770 {
    width: 770% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-770 {
    width: 770% !important;
  }
}

.width-pct-771 {
  width: 771% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-771 {
    width: 771% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-771 {
    width: 771% !important;
  }
}

.width-pct-772 {
  width: 772% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-772 {
    width: 772% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-772 {
    width: 772% !important;
  }
}

.width-pct-773 {
  width: 773% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-773 {
    width: 773% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-773 {
    width: 773% !important;
  }
}

.width-pct-774 {
  width: 774% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-774 {
    width: 774% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-774 {
    width: 774% !important;
  }
}

.width-pct-775 {
  width: 775% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-775 {
    width: 775% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-775 {
    width: 775% !important;
  }
}

.width-pct-776 {
  width: 776% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-776 {
    width: 776% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-776 {
    width: 776% !important;
  }
}

.width-pct-777 {
  width: 777% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-777 {
    width: 777% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-777 {
    width: 777% !important;
  }
}

.width-pct-778 {
  width: 778% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-778 {
    width: 778% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-778 {
    width: 778% !important;
  }
}

.width-pct-779 {
  width: 779% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-779 {
    width: 779% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-779 {
    width: 779% !important;
  }
}

.width-pct-780 {
  width: 780% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-780 {
    width: 780% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-780 {
    width: 780% !important;
  }
}

.width-pct-781 {
  width: 781% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-781 {
    width: 781% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-781 {
    width: 781% !important;
  }
}

.width-pct-782 {
  width: 782% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-782 {
    width: 782% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-782 {
    width: 782% !important;
  }
}

.width-pct-783 {
  width: 783% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-783 {
    width: 783% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-783 {
    width: 783% !important;
  }
}

.width-pct-784 {
  width: 784% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-784 {
    width: 784% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-784 {
    width: 784% !important;
  }
}

.width-pct-785 {
  width: 785% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-785 {
    width: 785% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-785 {
    width: 785% !important;
  }
}

.width-pct-786 {
  width: 786% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-786 {
    width: 786% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-786 {
    width: 786% !important;
  }
}

.width-pct-787 {
  width: 787% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-787 {
    width: 787% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-787 {
    width: 787% !important;
  }
}

.width-pct-788 {
  width: 788% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-788 {
    width: 788% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-788 {
    width: 788% !important;
  }
}

.width-pct-789 {
  width: 789% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-789 {
    width: 789% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-789 {
    width: 789% !important;
  }
}

.width-pct-790 {
  width: 790% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-790 {
    width: 790% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-790 {
    width: 790% !important;
  }
}

.width-pct-791 {
  width: 791% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-791 {
    width: 791% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-791 {
    width: 791% !important;
  }
}

.width-pct-792 {
  width: 792% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-792 {
    width: 792% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-792 {
    width: 792% !important;
  }
}

.width-pct-793 {
  width: 793% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-793 {
    width: 793% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-793 {
    width: 793% !important;
  }
}

.width-pct-794 {
  width: 794% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-794 {
    width: 794% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-794 {
    width: 794% !important;
  }
}

.width-pct-795 {
  width: 795% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-795 {
    width: 795% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-795 {
    width: 795% !important;
  }
}

.width-pct-796 {
  width: 796% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-796 {
    width: 796% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-796 {
    width: 796% !important;
  }
}

.width-pct-797 {
  width: 797% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-797 {
    width: 797% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-797 {
    width: 797% !important;
  }
}

.width-pct-798 {
  width: 798% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-798 {
    width: 798% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-798 {
    width: 798% !important;
  }
}

.width-pct-799 {
  width: 799% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-799 {
    width: 799% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-799 {
    width: 799% !important;
  }
}

.width-pct-800 {
  width: 800% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-800 {
    width: 800% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-800 {
    width: 800% !important;
  }
}

.width-pct-801 {
  width: 801% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-801 {
    width: 801% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-801 {
    width: 801% !important;
  }
}

.width-pct-802 {
  width: 802% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-802 {
    width: 802% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-802 {
    width: 802% !important;
  }
}

.width-pct-803 {
  width: 803% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-803 {
    width: 803% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-803 {
    width: 803% !important;
  }
}

.width-pct-804 {
  width: 804% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-804 {
    width: 804% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-804 {
    width: 804% !important;
  }
}

.width-pct-805 {
  width: 805% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-805 {
    width: 805% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-805 {
    width: 805% !important;
  }
}

.width-pct-806 {
  width: 806% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-806 {
    width: 806% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-806 {
    width: 806% !important;
  }
}

.width-pct-807 {
  width: 807% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-807 {
    width: 807% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-807 {
    width: 807% !important;
  }
}

.width-pct-808 {
  width: 808% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-808 {
    width: 808% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-808 {
    width: 808% !important;
  }
}

.width-pct-809 {
  width: 809% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-809 {
    width: 809% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-809 {
    width: 809% !important;
  }
}

.width-pct-810 {
  width: 810% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-810 {
    width: 810% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-810 {
    width: 810% !important;
  }
}

.width-pct-811 {
  width: 811% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-811 {
    width: 811% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-811 {
    width: 811% !important;
  }
}

.width-pct-812 {
  width: 812% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-812 {
    width: 812% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-812 {
    width: 812% !important;
  }
}

.width-pct-813 {
  width: 813% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-813 {
    width: 813% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-813 {
    width: 813% !important;
  }
}

.width-pct-814 {
  width: 814% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-814 {
    width: 814% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-814 {
    width: 814% !important;
  }
}

.width-pct-815 {
  width: 815% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-815 {
    width: 815% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-815 {
    width: 815% !important;
  }
}

.width-pct-816 {
  width: 816% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-816 {
    width: 816% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-816 {
    width: 816% !important;
  }
}

.width-pct-817 {
  width: 817% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-817 {
    width: 817% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-817 {
    width: 817% !important;
  }
}

.width-pct-818 {
  width: 818% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-818 {
    width: 818% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-818 {
    width: 818% !important;
  }
}

.width-pct-819 {
  width: 819% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-819 {
    width: 819% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-819 {
    width: 819% !important;
  }
}

.width-pct-820 {
  width: 820% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-820 {
    width: 820% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-820 {
    width: 820% !important;
  }
}

.width-pct-821 {
  width: 821% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-821 {
    width: 821% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-821 {
    width: 821% !important;
  }
}

.width-pct-822 {
  width: 822% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-822 {
    width: 822% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-822 {
    width: 822% !important;
  }
}

.width-pct-823 {
  width: 823% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-823 {
    width: 823% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-823 {
    width: 823% !important;
  }
}

.width-pct-824 {
  width: 824% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-824 {
    width: 824% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-824 {
    width: 824% !important;
  }
}

.width-pct-825 {
  width: 825% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-825 {
    width: 825% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-825 {
    width: 825% !important;
  }
}

.width-pct-826 {
  width: 826% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-826 {
    width: 826% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-826 {
    width: 826% !important;
  }
}

.width-pct-827 {
  width: 827% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-827 {
    width: 827% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-827 {
    width: 827% !important;
  }
}

.width-pct-828 {
  width: 828% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-828 {
    width: 828% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-828 {
    width: 828% !important;
  }
}

.width-pct-829 {
  width: 829% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-829 {
    width: 829% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-829 {
    width: 829% !important;
  }
}

.width-pct-830 {
  width: 830% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-830 {
    width: 830% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-830 {
    width: 830% !important;
  }
}

.width-pct-831 {
  width: 831% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-831 {
    width: 831% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-831 {
    width: 831% !important;
  }
}

.width-pct-832 {
  width: 832% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-832 {
    width: 832% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-832 {
    width: 832% !important;
  }
}

.width-pct-833 {
  width: 833% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-833 {
    width: 833% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-833 {
    width: 833% !important;
  }
}

.width-pct-834 {
  width: 834% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-834 {
    width: 834% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-834 {
    width: 834% !important;
  }
}

.width-pct-835 {
  width: 835% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-835 {
    width: 835% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-835 {
    width: 835% !important;
  }
}

.width-pct-836 {
  width: 836% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-836 {
    width: 836% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-836 {
    width: 836% !important;
  }
}

.width-pct-837 {
  width: 837% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-837 {
    width: 837% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-837 {
    width: 837% !important;
  }
}

.width-pct-838 {
  width: 838% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-838 {
    width: 838% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-838 {
    width: 838% !important;
  }
}

.width-pct-839 {
  width: 839% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-839 {
    width: 839% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-839 {
    width: 839% !important;
  }
}

.width-pct-840 {
  width: 840% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-840 {
    width: 840% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-840 {
    width: 840% !important;
  }
}

.width-pct-841 {
  width: 841% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-841 {
    width: 841% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-841 {
    width: 841% !important;
  }
}

.width-pct-842 {
  width: 842% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-842 {
    width: 842% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-842 {
    width: 842% !important;
  }
}

.width-pct-843 {
  width: 843% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-843 {
    width: 843% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-843 {
    width: 843% !important;
  }
}

.width-pct-844 {
  width: 844% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-844 {
    width: 844% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-844 {
    width: 844% !important;
  }
}

.width-pct-845 {
  width: 845% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-845 {
    width: 845% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-845 {
    width: 845% !important;
  }
}

.width-pct-846 {
  width: 846% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-846 {
    width: 846% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-846 {
    width: 846% !important;
  }
}

.width-pct-847 {
  width: 847% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-847 {
    width: 847% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-847 {
    width: 847% !important;
  }
}

.width-pct-848 {
  width: 848% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-848 {
    width: 848% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-848 {
    width: 848% !important;
  }
}

.width-pct-849 {
  width: 849% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-849 {
    width: 849% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-849 {
    width: 849% !important;
  }
}

.width-pct-850 {
  width: 850% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-850 {
    width: 850% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-850 {
    width: 850% !important;
  }
}

.width-pct-851 {
  width: 851% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-851 {
    width: 851% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-851 {
    width: 851% !important;
  }
}

.width-pct-852 {
  width: 852% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-852 {
    width: 852% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-852 {
    width: 852% !important;
  }
}

.width-pct-853 {
  width: 853% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-853 {
    width: 853% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-853 {
    width: 853% !important;
  }
}

.width-pct-854 {
  width: 854% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-854 {
    width: 854% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-854 {
    width: 854% !important;
  }
}

.width-pct-855 {
  width: 855% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-855 {
    width: 855% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-855 {
    width: 855% !important;
  }
}

.width-pct-856 {
  width: 856% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-856 {
    width: 856% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-856 {
    width: 856% !important;
  }
}

.width-pct-857 {
  width: 857% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-857 {
    width: 857% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-857 {
    width: 857% !important;
  }
}

.width-pct-858 {
  width: 858% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-858 {
    width: 858% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-858 {
    width: 858% !important;
  }
}

.width-pct-859 {
  width: 859% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-859 {
    width: 859% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-859 {
    width: 859% !important;
  }
}

.width-pct-860 {
  width: 860% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-860 {
    width: 860% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-860 {
    width: 860% !important;
  }
}

.width-pct-861 {
  width: 861% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-861 {
    width: 861% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-861 {
    width: 861% !important;
  }
}

.width-pct-862 {
  width: 862% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-862 {
    width: 862% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-862 {
    width: 862% !important;
  }
}

.width-pct-863 {
  width: 863% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-863 {
    width: 863% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-863 {
    width: 863% !important;
  }
}

.width-pct-864 {
  width: 864% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-864 {
    width: 864% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-864 {
    width: 864% !important;
  }
}

.width-pct-865 {
  width: 865% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-865 {
    width: 865% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-865 {
    width: 865% !important;
  }
}

.width-pct-866 {
  width: 866% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-866 {
    width: 866% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-866 {
    width: 866% !important;
  }
}

.width-pct-867 {
  width: 867% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-867 {
    width: 867% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-867 {
    width: 867% !important;
  }
}

.width-pct-868 {
  width: 868% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-868 {
    width: 868% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-868 {
    width: 868% !important;
  }
}

.width-pct-869 {
  width: 869% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-869 {
    width: 869% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-869 {
    width: 869% !important;
  }
}

.width-pct-870 {
  width: 870% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-870 {
    width: 870% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-870 {
    width: 870% !important;
  }
}

.width-pct-871 {
  width: 871% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-871 {
    width: 871% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-871 {
    width: 871% !important;
  }
}

.width-pct-872 {
  width: 872% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-872 {
    width: 872% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-872 {
    width: 872% !important;
  }
}

.width-pct-873 {
  width: 873% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-873 {
    width: 873% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-873 {
    width: 873% !important;
  }
}

.width-pct-874 {
  width: 874% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-874 {
    width: 874% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-874 {
    width: 874% !important;
  }
}

.width-pct-875 {
  width: 875% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-875 {
    width: 875% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-875 {
    width: 875% !important;
  }
}

.width-pct-876 {
  width: 876% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-876 {
    width: 876% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-876 {
    width: 876% !important;
  }
}

.width-pct-877 {
  width: 877% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-877 {
    width: 877% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-877 {
    width: 877% !important;
  }
}

.width-pct-878 {
  width: 878% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-878 {
    width: 878% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-878 {
    width: 878% !important;
  }
}

.width-pct-879 {
  width: 879% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-879 {
    width: 879% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-879 {
    width: 879% !important;
  }
}

.width-pct-880 {
  width: 880% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-880 {
    width: 880% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-880 {
    width: 880% !important;
  }
}

.width-pct-881 {
  width: 881% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-881 {
    width: 881% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-881 {
    width: 881% !important;
  }
}

.width-pct-882 {
  width: 882% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-882 {
    width: 882% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-882 {
    width: 882% !important;
  }
}

.width-pct-883 {
  width: 883% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-883 {
    width: 883% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-883 {
    width: 883% !important;
  }
}

.width-pct-884 {
  width: 884% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-884 {
    width: 884% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-884 {
    width: 884% !important;
  }
}

.width-pct-885 {
  width: 885% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-885 {
    width: 885% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-885 {
    width: 885% !important;
  }
}

.width-pct-886 {
  width: 886% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-886 {
    width: 886% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-886 {
    width: 886% !important;
  }
}

.width-pct-887 {
  width: 887% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-887 {
    width: 887% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-887 {
    width: 887% !important;
  }
}

.width-pct-888 {
  width: 888% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-888 {
    width: 888% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-888 {
    width: 888% !important;
  }
}

.width-pct-889 {
  width: 889% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-889 {
    width: 889% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-889 {
    width: 889% !important;
  }
}

.width-pct-890 {
  width: 890% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-890 {
    width: 890% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-890 {
    width: 890% !important;
  }
}

.width-pct-891 {
  width: 891% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-891 {
    width: 891% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-891 {
    width: 891% !important;
  }
}

.width-pct-892 {
  width: 892% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-892 {
    width: 892% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-892 {
    width: 892% !important;
  }
}

.width-pct-893 {
  width: 893% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-893 {
    width: 893% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-893 {
    width: 893% !important;
  }
}

.width-pct-894 {
  width: 894% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-894 {
    width: 894% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-894 {
    width: 894% !important;
  }
}

.width-pct-895 {
  width: 895% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-895 {
    width: 895% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-895 {
    width: 895% !important;
  }
}

.width-pct-896 {
  width: 896% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-896 {
    width: 896% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-896 {
    width: 896% !important;
  }
}

.width-pct-897 {
  width: 897% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-897 {
    width: 897% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-897 {
    width: 897% !important;
  }
}

.width-pct-898 {
  width: 898% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-898 {
    width: 898% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-898 {
    width: 898% !important;
  }
}

.width-pct-899 {
  width: 899% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-899 {
    width: 899% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-899 {
    width: 899% !important;
  }
}

.width-pct-900 {
  width: 900% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-900 {
    width: 900% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-900 {
    width: 900% !important;
  }
}

.width-pct-901 {
  width: 901% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-901 {
    width: 901% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-901 {
    width: 901% !important;
  }
}

.width-pct-902 {
  width: 902% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-902 {
    width: 902% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-902 {
    width: 902% !important;
  }
}

.width-pct-903 {
  width: 903% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-903 {
    width: 903% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-903 {
    width: 903% !important;
  }
}

.width-pct-904 {
  width: 904% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-904 {
    width: 904% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-904 {
    width: 904% !important;
  }
}

.width-pct-905 {
  width: 905% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-905 {
    width: 905% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-905 {
    width: 905% !important;
  }
}

.width-pct-906 {
  width: 906% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-906 {
    width: 906% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-906 {
    width: 906% !important;
  }
}

.width-pct-907 {
  width: 907% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-907 {
    width: 907% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-907 {
    width: 907% !important;
  }
}

.width-pct-908 {
  width: 908% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-908 {
    width: 908% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-908 {
    width: 908% !important;
  }
}

.width-pct-909 {
  width: 909% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-909 {
    width: 909% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-909 {
    width: 909% !important;
  }
}

.width-pct-910 {
  width: 910% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-910 {
    width: 910% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-910 {
    width: 910% !important;
  }
}

.width-pct-911 {
  width: 911% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-911 {
    width: 911% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-911 {
    width: 911% !important;
  }
}

.width-pct-912 {
  width: 912% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-912 {
    width: 912% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-912 {
    width: 912% !important;
  }
}

.width-pct-913 {
  width: 913% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-913 {
    width: 913% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-913 {
    width: 913% !important;
  }
}

.width-pct-914 {
  width: 914% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-914 {
    width: 914% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-914 {
    width: 914% !important;
  }
}

.width-pct-915 {
  width: 915% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-915 {
    width: 915% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-915 {
    width: 915% !important;
  }
}

.width-pct-916 {
  width: 916% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-916 {
    width: 916% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-916 {
    width: 916% !important;
  }
}

.width-pct-917 {
  width: 917% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-917 {
    width: 917% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-917 {
    width: 917% !important;
  }
}

.width-pct-918 {
  width: 918% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-918 {
    width: 918% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-918 {
    width: 918% !important;
  }
}

.width-pct-919 {
  width: 919% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-919 {
    width: 919% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-919 {
    width: 919% !important;
  }
}

.width-pct-920 {
  width: 920% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-920 {
    width: 920% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-920 {
    width: 920% !important;
  }
}

.width-pct-921 {
  width: 921% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-921 {
    width: 921% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-921 {
    width: 921% !important;
  }
}

.width-pct-922 {
  width: 922% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-922 {
    width: 922% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-922 {
    width: 922% !important;
  }
}

.width-pct-923 {
  width: 923% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-923 {
    width: 923% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-923 {
    width: 923% !important;
  }
}

.width-pct-924 {
  width: 924% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-924 {
    width: 924% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-924 {
    width: 924% !important;
  }
}

.width-pct-925 {
  width: 925% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-925 {
    width: 925% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-925 {
    width: 925% !important;
  }
}

.width-pct-926 {
  width: 926% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-926 {
    width: 926% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-926 {
    width: 926% !important;
  }
}

.width-pct-927 {
  width: 927% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-927 {
    width: 927% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-927 {
    width: 927% !important;
  }
}

.width-pct-928 {
  width: 928% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-928 {
    width: 928% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-928 {
    width: 928% !important;
  }
}

.width-pct-929 {
  width: 929% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-929 {
    width: 929% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-929 {
    width: 929% !important;
  }
}

.width-pct-930 {
  width: 930% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-930 {
    width: 930% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-930 {
    width: 930% !important;
  }
}

.width-pct-931 {
  width: 931% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-931 {
    width: 931% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-931 {
    width: 931% !important;
  }
}

.width-pct-932 {
  width: 932% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-932 {
    width: 932% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-932 {
    width: 932% !important;
  }
}

.width-pct-933 {
  width: 933% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-933 {
    width: 933% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-933 {
    width: 933% !important;
  }
}

.width-pct-934 {
  width: 934% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-934 {
    width: 934% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-934 {
    width: 934% !important;
  }
}

.width-pct-935 {
  width: 935% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-935 {
    width: 935% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-935 {
    width: 935% !important;
  }
}

.width-pct-936 {
  width: 936% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-936 {
    width: 936% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-936 {
    width: 936% !important;
  }
}

.width-pct-937 {
  width: 937% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-937 {
    width: 937% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-937 {
    width: 937% !important;
  }
}

.width-pct-938 {
  width: 938% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-938 {
    width: 938% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-938 {
    width: 938% !important;
  }
}

.width-pct-939 {
  width: 939% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-939 {
    width: 939% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-939 {
    width: 939% !important;
  }
}

.width-pct-940 {
  width: 940% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-940 {
    width: 940% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-940 {
    width: 940% !important;
  }
}

.width-pct-941 {
  width: 941% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-941 {
    width: 941% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-941 {
    width: 941% !important;
  }
}

.width-pct-942 {
  width: 942% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-942 {
    width: 942% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-942 {
    width: 942% !important;
  }
}

.width-pct-943 {
  width: 943% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-943 {
    width: 943% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-943 {
    width: 943% !important;
  }
}

.width-pct-944 {
  width: 944% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-944 {
    width: 944% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-944 {
    width: 944% !important;
  }
}

.width-pct-945 {
  width: 945% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-945 {
    width: 945% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-945 {
    width: 945% !important;
  }
}

.width-pct-946 {
  width: 946% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-946 {
    width: 946% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-946 {
    width: 946% !important;
  }
}

.width-pct-947 {
  width: 947% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-947 {
    width: 947% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-947 {
    width: 947% !important;
  }
}

.width-pct-948 {
  width: 948% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-948 {
    width: 948% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-948 {
    width: 948% !important;
  }
}

.width-pct-949 {
  width: 949% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-949 {
    width: 949% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-949 {
    width: 949% !important;
  }
}

.width-pct-950 {
  width: 950% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-950 {
    width: 950% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-950 {
    width: 950% !important;
  }
}

.width-pct-951 {
  width: 951% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-951 {
    width: 951% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-951 {
    width: 951% !important;
  }
}

.width-pct-952 {
  width: 952% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-952 {
    width: 952% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-952 {
    width: 952% !important;
  }
}

.width-pct-953 {
  width: 953% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-953 {
    width: 953% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-953 {
    width: 953% !important;
  }
}

.width-pct-954 {
  width: 954% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-954 {
    width: 954% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-954 {
    width: 954% !important;
  }
}

.width-pct-955 {
  width: 955% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-955 {
    width: 955% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-955 {
    width: 955% !important;
  }
}

.width-pct-956 {
  width: 956% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-956 {
    width: 956% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-956 {
    width: 956% !important;
  }
}

.width-pct-957 {
  width: 957% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-957 {
    width: 957% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-957 {
    width: 957% !important;
  }
}

.width-pct-958 {
  width: 958% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-958 {
    width: 958% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-958 {
    width: 958% !important;
  }
}

.width-pct-959 {
  width: 959% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-959 {
    width: 959% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-959 {
    width: 959% !important;
  }
}

.width-pct-960 {
  width: 960% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-960 {
    width: 960% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-960 {
    width: 960% !important;
  }
}

.width-pct-961 {
  width: 961% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-961 {
    width: 961% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-961 {
    width: 961% !important;
  }
}

.width-pct-962 {
  width: 962% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-962 {
    width: 962% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-962 {
    width: 962% !important;
  }
}

.width-pct-963 {
  width: 963% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-963 {
    width: 963% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-963 {
    width: 963% !important;
  }
}

.width-pct-964 {
  width: 964% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-964 {
    width: 964% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-964 {
    width: 964% !important;
  }
}

.width-pct-965 {
  width: 965% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-965 {
    width: 965% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-965 {
    width: 965% !important;
  }
}

.width-pct-966 {
  width: 966% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-966 {
    width: 966% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-966 {
    width: 966% !important;
  }
}

.width-pct-967 {
  width: 967% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-967 {
    width: 967% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-967 {
    width: 967% !important;
  }
}

.width-pct-968 {
  width: 968% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-968 {
    width: 968% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-968 {
    width: 968% !important;
  }
}

.width-pct-969 {
  width: 969% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-969 {
    width: 969% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-969 {
    width: 969% !important;
  }
}

.width-pct-970 {
  width: 970% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-970 {
    width: 970% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-970 {
    width: 970% !important;
  }
}

.width-pct-971 {
  width: 971% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-971 {
    width: 971% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-971 {
    width: 971% !important;
  }
}

.width-pct-972 {
  width: 972% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-972 {
    width: 972% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-972 {
    width: 972% !important;
  }
}

.width-pct-973 {
  width: 973% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-973 {
    width: 973% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-973 {
    width: 973% !important;
  }
}

.width-pct-974 {
  width: 974% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-974 {
    width: 974% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-974 {
    width: 974% !important;
  }
}

.width-pct-975 {
  width: 975% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-975 {
    width: 975% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-975 {
    width: 975% !important;
  }
}

.width-pct-976 {
  width: 976% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-976 {
    width: 976% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-976 {
    width: 976% !important;
  }
}

.width-pct-977 {
  width: 977% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-977 {
    width: 977% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-977 {
    width: 977% !important;
  }
}

.width-pct-978 {
  width: 978% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-978 {
    width: 978% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-978 {
    width: 978% !important;
  }
}

.width-pct-979 {
  width: 979% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-979 {
    width: 979% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-979 {
    width: 979% !important;
  }
}

.width-pct-980 {
  width: 980% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-980 {
    width: 980% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-980 {
    width: 980% !important;
  }
}

.width-pct-981 {
  width: 981% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-981 {
    width: 981% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-981 {
    width: 981% !important;
  }
}

.width-pct-982 {
  width: 982% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-982 {
    width: 982% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-982 {
    width: 982% !important;
  }
}

.width-pct-983 {
  width: 983% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-983 {
    width: 983% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-983 {
    width: 983% !important;
  }
}

.width-pct-984 {
  width: 984% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-984 {
    width: 984% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-984 {
    width: 984% !important;
  }
}

.width-pct-985 {
  width: 985% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-985 {
    width: 985% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-985 {
    width: 985% !important;
  }
}

.width-pct-986 {
  width: 986% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-986 {
    width: 986% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-986 {
    width: 986% !important;
  }
}

.width-pct-987 {
  width: 987% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-987 {
    width: 987% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-987 {
    width: 987% !important;
  }
}

.width-pct-988 {
  width: 988% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-988 {
    width: 988% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-988 {
    width: 988% !important;
  }
}

.width-pct-989 {
  width: 989% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-989 {
    width: 989% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-989 {
    width: 989% !important;
  }
}

.width-pct-990 {
  width: 990% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-990 {
    width: 990% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-990 {
    width: 990% !important;
  }
}

.width-pct-991 {
  width: 991% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-991 {
    width: 991% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-991 {
    width: 991% !important;
  }
}

.width-pct-992 {
  width: 992% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-992 {
    width: 992% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-992 {
    width: 992% !important;
  }
}

.width-pct-993 {
  width: 993% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-993 {
    width: 993% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-993 {
    width: 993% !important;
  }
}

.width-pct-994 {
  width: 994% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-994 {
    width: 994% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-994 {
    width: 994% !important;
  }
}

.width-pct-995 {
  width: 995% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-995 {
    width: 995% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-995 {
    width: 995% !important;
  }
}

.width-pct-996 {
  width: 996% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-996 {
    width: 996% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-996 {
    width: 996% !important;
  }
}

.width-pct-997 {
  width: 997% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-997 {
    width: 997% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-997 {
    width: 997% !important;
  }
}

.width-pct-998 {
  width: 998% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-998 {
    width: 998% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-998 {
    width: 998% !important;
  }
}

.width-pct-999 {
  width: 999% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-999 {
    width: 999% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-999 {
    width: 999% !important;
  }
}

.width-pct-1000 {
  width: 1000% !important;
}

@media (min-width: 897px) {
  .width-pct-pc-1000 {
    width: 1000% !important;
  }
}

@media (max-width: 896px) {
  .width-pct-sp-1000 {
    width: 1000% !important;
  }
}
