@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 box
========================================================== */
/* c-border
---------------------------------------------------------- */
.c-border-bottom { border-bottom: 1px solid #f3f3f3; }
.c-border-top { border-bottom: 1px solid #f3f3f3; }
.c-border-all { border: 1px solid #f3f3f3; }