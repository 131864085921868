@for $i from 0 through 9 {
  @for $j from 0 through 9 {
    @if($j != 0){
      .lineheight-#{$i}_#{$j} {
        line-height: #{$i}#{'.'}#{$j} !important;
      }
    } @else {
      .lineheight-#{$i} {
        line-height: #{$i} !important;
      }
    }

    @include mq-sp {
      @if($j != 0){
        .lineheight-sp-#{$i}_#{$j} {
          line-height: #{$i}#{'.'}#{$j} !important;
        }
      } @else {
        .lineheight-sp-#{$i} {
          line-height: #{$i} !important;
        }
      }
    }
    
    @include mq-pc {
      @if($j != 0){
        .lineheight-pc-#{$i}_#{$j} {
          line-height: #{$i}#{'.'}#{$j} !important;
        }
      } @else {
        .lineheight-pc-#{$i} {
          line-height: #{$i} !important;
        }
      }
    }
  }
}