@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 footer
========================================================== */
.footer {
  .btn-pagetop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    transition: all .2s;

    &.-show {
      visibility: visible;
      opacity: 1;
      z-index: 98;
    }

    @include mq-sp {
      right: 15px;
      bottom: 15px;
    }

    a {
      display: block;
      width: 60px;
      height: 60px;
      opacity: .7;
      transition: opacity .2s;

      &:hover {
        opacity: .5;
      }

      @include mq-sp {
        width: 30px;
        height: 30px;
      }

      img {
        width: 100%;
      }
    }
  }
}

/* footer-top
---------------------------------------------------------- */
.footer-top {
  background-color: $color-navi-dark;

  .container {
    @include mq-sp {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .list-link {
    .col {
      &:first-child {
        a {
          .box {
            border-left: 0;
          }
        }
      }
    }

    a {
      position: relative;
      display: block;
      padding: 50px 0;
      font-size: 2rem;
      font-weight: bold;
      color: #fff;
      line-height: 1.5;
      text-align: center;

      @include mq-pc {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-position: center bottom;
          background-size: 100% auto;
          background-repeat: no-repeat;
          visibility: hidden;
          opacity: 0;
          transition: all .2s;
        }

        &:hover:after {
          visibility: visible;
          opacity: 1;
        }
        
        // link-apply
        &.link-apply:after {
          background-image: url('/assets/images/common/bg_footer_hover_01.png');
        }
  
        // link-denki
        &.link-denki:after {
          background-image: url('/assets/images/common/bg_footer_hover_02.png');
        }
  
        // link-gas
        &.link-gas:after {
          background-image: url('/assets/images/common/bg_footer_hover_03.png');
        }
      }

      @include mq-sp {
        padding: 20px 0;
        font-size: 1.1rem;
      }

      .box {
        padding: 10px 10px 15px;
        border-left: 1px solid #3c4253;

        @include mq-sp {
          padding: 5px;
        }
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        text-align: center;
        line-height: 1;

        @include mq-sp {
          margin-bottom: 5px;
        }
      }
    }
  }
}

/* footer-mid
---------------------------------------------------------- */
.footer-mid {
  background-color: $color-navi;
  
  .container {
    @include mq-sp {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .heading {
    padding-top: 60px;
    padding-bottom: 40px;

    @include mq-pc {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      border-bottom: 1px solid #3c4253;
    }

    @include mq-sp {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .list-link {
      display: flex;
      margin-left: 80px;

      .item {
        margin-left: 50px;

        &:first-child {
          margin-left: 0;
        }
      }

      a {
        display: inline-block;
        position: relative;
        font-size: 1.6rem;
        color: #fff;
        @include is-opacity;

        &:after {
          content: "";
          position: relative;
          top: 2px;
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-left: 5px;
          background: url('/assets/images/common/icon_arrow_link.png') 0 0 no-repeat;
          background-size: 100% auto;
        }
      }
    }

    .btn-sitemap {
      display: inline-block;
      color: #fff;
      font-size: 1.4rem;
      opacity: .7;
      transition: opacity .2s;

      &:hover {
        opacity: .5;
      }

      &:after {
        content: "";
        position: relative;
        display: inline-block;
        width: 11px;
        height: 9px;
        margin-left: 5px;
        background: url('/assets/images/common/icon_menu.png') 0 0 no-repeat;
        background-size: 100% auto;
      }
    }
  }

  .box-sns {
    padding: 30px 15px;

    @include mq-pc {
      padding-top: 50px;
      padding-bottom: 55px;
    }
  }
}

/* list-footer-link
---------------------------------------------------------- */
.list-footer-link {
  @include mq-pc {
    display: flex;
  }

  > .item {
    @include mq-pc {
      &.-denki {
        width: 57%;
      }
  
      &.-gas {
        width: 27%;
        padding-left: 35px
      }
  
      &.-other {
        width: 16%;
      }
    }

    @include mq-sp {
      border-top: 1px solid #3c4253;

      &:last-child {
        border-bottom: 1px solid #3c4253;
      }
    }
  }

  .link-main {
    position: relative;
    font-size: 1.6rem;
    font-weight: bold;
    color: #fff;

    @include mq-sp {
      display: block;
      padding: 13px 15px;
      font-size: 1.3rem;
    }

    &.accd {
      @include mq-sp {
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          width: 11px;
          height: 11px;
          background-image: url('/assets/images/common/icon_plus_white.png');
          background-position: 0 0;
          background-size: 100% auto;
          background-repeat: no-repeat;
        }

        &.-active:after {
          background-image: url('/assets/images/common/icon_minus_white.png');
        }
      }
    }
  }

  // box-link-sub
  .box-link-sub {
    @include mq-pc {
      padding-top: 15px;
    }

    @include mq-sp {
      display: none;
      border-top: 1px solid #3c4253;
    }
  }

  // list-link-sub
  .list-link-sub {
    @include mq-sp {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -1px;
      background-color: #2e364b;
    }

    .item {
      @include mq-pc {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include mq-sp {
        width: 50%;
        border-bottom: 1px solid #3c4253;

        &:nth-child(odd) {
          border-right: 1px solid #3c4253;
        }
      }
    }

    a {
      font-size: 1.4rem;
      color: rgba(255, 255, 255, .7);
      @include is-opacity;

      @include mq-sp {
        display: block;
        padding: 18px 15px;
        font-size: 1rem;
      }
    }
  }
}

/* footer-bottom
---------------------------------------------------------- */
.footer-bottom {
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;

  @include mq-sp {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  
  .box-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    @include mq-sp {
      display: block;
      text-align: center;
    }

    .logo-large {
      @include mq-sp {
        margin-bottom: 27px;
      }
    }
    .box {
      display: flex;
      align-items: center;

      @include mq-sp {
        display: block;
      }

      .list-logo-01 {
        display: flex;
        align-items: center;

        @include mq-sp {
          margin-bottom: 25px;
        }
  
        .item {
          padding: 0 20px;

          @include mq-sp {
            width: 25%;
            padding: 0 15px;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            @include mq-sp {
              padding-right: 0;
            }
          }
        }
      }
      .list-logo-02 {
        display: flex;
        align-items: center;
  
        .item {
          padding: 0 20px;

          @include mq-sp {
            width: 25%;
            padding: 0 28px;
          }

          &:first-child {
            @include mq-sp {
              padding-left: 0;
            }
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }

      img {
        display: block;
        @include mq-sp {
          margin: 0 auto;
        }
      }
    }

    .link-logo {
      display: block;
      @include is-opacity();
    }
  }

  .copyright {
    font-family: 'Roboto Condensed';
    font-size: 1.2rem;
    color:#999;
    text-align: center;

    @include mq-sp {
      font-size: .9rem;
    }
  }
}