@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Article
========================================================== */
/* c-article
---------------------------------------------------------- */
.c-article {
  .sec-main {
    padding-top: 50px;
    padding-bottom: 40px;

    @include mq-pc {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  .heading {
    margin-bottom: 35px;

    @include mq-pc {
      margin-bottom: 45px;
    }

    .cate {
      display: inline-block;
      padding: 2px 5px;
      border-top: 1px solid $color-blue;
      border-bottom: 1px solid $color-blue;
      font-size: 1.4rem;
      font-weight: bold;
      color: $color-blue;

      @include mq-pc {
        padding: 7px 10px;
        font-size: 1.6rem;
      }
    }

    .title {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sec {
    margin-bottom: 40px;

    @include mq-pc {
      margin-bottom: 60px;
    }
  }

  p {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .box-link {
    display: block;
    margin-bottom: 50px;
    @include is-opacity;

    @include mq-pc {
      display: flex;
      margin-bottom: 90px;
    }

    .label {
      display: block;
      padding: 10px;
      background-color: $color-blue-dark;
      font-weight: bold;
      font-size: 1.4rem;
      color: #fff;
      text-align: center;

      @include mq-pc {
        display: flex;
        align-items: center;
        padding: 20px;
        font-size: 1.6rem;
        text-align: left;
      }
    }

    .detail {
      flex-grow: 1;
      display: block;
      position: relative;
      padding: 15px 25px;
      background-color: $color-blue;
      font-weight: bold;
      font-size: 1.6rem;
      color: #fff;

      @include mq-pc {
        display: flex;
        align-items: center;
        padding: 20px;
        font-size: 2rem;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        width: 13px;
        height: 12px;
        background: url('/assets/images/common/icon_arrow_tail_white.png') 0 0 no-repeat;
        background-size: 100% auto;

        @include mq-pc {
          right: 25px;
          width: 18px;
          height: 16px;
        }
      }
    }
  }

  .box-shop-info {
    .image {
      position: relative;
      z-index: 1;
      padding-top: 55%;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }

    .detail {
      @include mq-pc {
        margin-top: -10px;
      }
    }

    .title {
      margin-bottom: 15px;
      font-weight: bold;
      font-size: 1.6rem;

      @include mq-pc {
        font-size: 2rem;
      }
    }

    table {
      width: 100%;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      tr {
        border-bottom: 1px solid #e0e0e0;

        &:first-child {
          border-top: 1px solid #e0e0e0;
        }
      }

      th, td {
        vertical-align: top;
        padding: 8px 0;
        font-size: 1.3rem;

        @include mq-pc {
          font-size: 1.4rem;
        }
      }

      th {
        width: 110px;
      }
    }
  }

  .sec-relate {
    padding-top: 50px;
    padding-bottom: 95px;
    background-color: #fff;

    @include mq-pc {
      padding-top: 80px;
      padding-bottom: 150px;
    }
  }
}