
@mixin mq-mn($width) {
  @media (min-width: '#{$width}px') {
    @content;
  }
}

@mixin mq-mx($width) {
  @media (max-width: '#{$width}px') {
    @content;
  }
}

@mixin mq-sp {
  @media (max-width: #{$pc-breakpoint - 1px}) {
    @content;
  }
}

@mixin mq-sp-land {
  @media (max-width: #{$pc-breakpoint - 1px}) and (orientation : landscape) {
    @content;
  }
}

@mixin mq-pc {
  @media (min-width: #{$pc-breakpoint}) {
    @content;
  }
}

@mixin is-opacity($opacity: 0.7) {
  @include mq-pc {
    opacity: 1;
    transition: opacity 0.2s ease 0s;

    &:hover {
      opacity: $opacity;
      text-decoration: none;
    }
  }
}
