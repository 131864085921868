@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 list
========================================================== */
/* c-list-bullet
---------------------------------------------------------- */
.c-list-bullet {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    margin-bottom: 40px;
  }
  
  > .item {
    position: relative;
    padding-left: 15px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq-pc {
      padding-left: 20px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-black;

      @include mq-pc {
        top: 9px;
      }
    }
  }
}

/* c-list-number
---------------------------------------------------------- */
.c-list-number {
  counter-reset: number;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    margin-bottom: 40px;
  }

  > .item {
    position: relative;
    counter-increment: number;
    padding-left: 18px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq-pc {
      padding-left: 34px;
    }

    &:before {
      content: counter(number);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

/* c-list-news
---------------------------------------------------------- */
.c-list-news {
  margin-bottom: 45px;
  @include mq-pc {
    margin-bottom: 80px;
  }
  .date { 
    display: block;
    @include mq-pc {
      display: inline-block;
    }
  }
  .c-link-blank {
    @include mq-pc {
      margin-left: 30px;
    }
  }
}

/* c-list-note
---------------------------------------------------------- */
.c-list-note {
  > .item {
    font-size: 1.2rem;
    position: relative;
    padding-left: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq-pc {
      padding-left: 16px;
    }

    &:before {
      content: '※';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

/* c-list-note-number
---------------------------------------------------------- */
.c-list-note-number {
  counter-reset: number;
  
  > .item {
    font-size: 1.2rem;
    position: relative;
    counter-increment: number;
    padding-left: 26px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq-pc {
      padding-left: 30px;
    }

    &:before {
      content: '※'counter(number);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

/* c-list-qa
---------------------------------------------------------- */
.c-list-qa {
  > .item {
    border-bottom: 1px solid #ebebeb;
  }

  .heading {
    position: relative;
    padding: 20px 30px 20px 0;
  
    @include mq-pc {
      padding: 35px 35px 35px 0;
    }

    .q {
      margin-right: 10px;
      color: $color-blue;
      font-size: 2rem;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 21px;
      height: 21px;
      background-image: url('/assets/images/common/icon_plus_lg.png');
      background-size: 100% auto;
      background-position: 0 0;
      background-repeat: no-repeat;

      @include mq-pc {
        right: 20px;
      }
    }

    &.-on {

      &:after {
        background-image: url('/assets/images/common/icon_minus_lg.png');
      }
    }
  }

  .question {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.4;
    padding-right: 5px;

    @include mq-pc {
      font-size: 2.2rem;
    }

    .q {
      position: relative;
      top: -1px;
      margin-right: 10px;
      color: $color-blue;
      font-size: 2rem;
      font-family: $font-roboto;
      @include mq-pc {
        font-size: 2.8rem;
        margin-right: 15px;
      }
    }
  }

  .answer {
    display: none;
    margin-top: -10px;
    font-size: 1.4rem;
    padding-bottom: 20px;

    @include mq-pc {
      margin-top: -3px;
      padding-bottom: 30px;
      font-size: 1.6rem;
    }
  }
}

/* c-list-service
---------------------------------------------------------- */
.c-list-service {
  > .item {
    margin-top: 90px !important;

    @include mq-pc {
      margin-top: 100px !important;
    }
  }
}

/* c-list-step
---------------------------------------------------------- */
.c-list-step {
  > .item {
    position: relative;
    display: flex;
    padding-top: 30px;
    padding-bottom: 50px;
    border-bottom: 1px solid #ebebeb;

    @include mq-sp {
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: -19px;
      left: 50%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 19px 19px 0 19px;
      border-color: #ffffff transparent transparent transparent;
      z-index: 2;

      @include mq-sp {
        bottom: -9px;
        border-width: 9px 9px 0 9px;
        border-color: #ffffff transparent transparent transparent;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: -21px;
      left: 50%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 21px 21px 0 21px;
      border-color: #ebebeb transparent transparent transparent;

      @include mq-sp {
        bottom: -11px;
        border-width: 11px 11px 0 11px;
        border-color: #ebebeb transparent transparent transparent;
      }
    }

    &:last-child {
      border-bottom: none;

      &:before,
      &:after {
        display: none;
      }
    }

    .box-icon {
      width: 180px;
      height: 180px;
      background-color: #f6f7f9;
      border-radius: 180px;
      text-align: center;

      @include mq-pc {
        flex: 1 0 auto;
      }

      @include mq-sp {
        width: 135px;
        height: 135px;
        border-radius: 135px;
      }

      .icon {
        height: 72px;

        @include mq-sp {
          height: 54px;
        }
      }

      .number {
        font-size: 1.4rem;
        font-weight: 700;
        margin-top: 35px;
        margin-bottom: 4px;

        @include mq-sp {
          font-size: 1.1rem;
          margin-top: 28px;
        }

        .num {
          font-size: 3rem;
          margin-left: 4px;
          line-height: 1;

          @include mq-sp {
            font-size: 2.3rem;
          }
        }
      }
    }
  
    .detail {
      width: 100%;
      padding-top: 35px;
      padding-left: 40px;

      @include mq-sp {
        padding-left: 0;
        padding-top: 20px;
      }
    }

    .title {
      font-size: 2rem;
      font-weight: 700;
      color: #2157a7;
      line-height: 1.3;
      .-sm {
        font-size: 1.4rem;
      }

      @include mq-sp {
        font-size: 1.6rem;
      }
    }

    .text {
      margin-top: 10px;
    }
  }

  .c-box-dark {
    padding: 25px 30px;

    @include mq-sp {
      padding: 20px 25px;
    }
  }
}


/* c-list-link
---------------------------------------------------------- */
.c-list-link {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
  margin-left: -44px;

  @include mq-pc {
    margin-left: -65px;
  }

  .item {
    margin-left: 44px;
    margin-bottom: 15px;

    @include mq-pc {
      margin-left: 65px;
    }
  }
}

/* c-list-flow
---------------------------------------------------------- */
.c-list-flow {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;

  @include mq-sp {
    margin-left: -10px;
    margin-right: -10px;
  }

  > .item {
    position: relative;
    padding: 0 15px;

    @include mq-sp {
      width: 50%;
      padding: 0 10px;
    }

    &:last-child:after {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -7px; 
      width: 14px;
      height: 23px;
      transform: translateY(-50%);
      background: url(/assets/images/common/ico_arrow_flow.png) no-repeat;
      background-size: 14px 23px;
    }

    .box-icon {
      width: 180px;
      height: 180px;
      background-color: #f6f7f9;
      border-radius: 180px;
      text-align: center;

      @include mq-sp {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36vw;
        height: 36vw;
        border-radius: 36vw;
        margin: 0 auto;
      }

      img {
        width: auto;
        height: 58px;

        @include mq-sp {
          height: 36px;
        }
      }

      .number {
        font-size: 1.4rem;
        font-weight: 700;
        padding-top: 22px;
        padding-bottom: 4px;

        @include mq-sp {
          font-size: 1.1rem;
        }

        .num {
          font-size: 3rem;
          margin-left: 4px;
          line-height: 1;

          @include mq-sp {
            font-size: 2.3rem;
            padding-top: 16px;
          }
        }
      }

      .text {
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: 0.2rem;
        line-height: 1.4;
        margin-top: 5px;

        @include mq-sp {
          align-items: flex-start;
          font-size: 1.2rem;
          margin-top: 5px;
          letter-spacing: 0;
          height: 32px;
        }
        &.-long {
          line-height: 1;
        }
      }
    }
  }

  @include mq-mn(897) {
    justify-content: center;

    .item {
      width: 33.33%;
    }

    .box-icon {
      margin: 0 auto;
    }
  }

  @include mq-mn(1231) {
    .item {
      width: 20%;
    }
  }
}

/* c-list-sns
---------------------------------------------------------- */
.c-list-sns {
  display: flex;
  justify-content: center;

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: -20px -20px 0; 

    @include mq-pc {
      margin: -15px -15px 0; 
    }

    .item {
      padding: 0 20px;
      margin-top: 20px;
      line-height: 1;

      @include mq-pc {
        padding: 0 15px;
        margin-top: 15px;
      }
    }

    a {
      display: flex;
      align-items: center;
      @include is-opacity;

      img {
        display: block;
        max-width: none;
      }
    }
  }
}