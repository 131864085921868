@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 heading
========================================================== */
/* c-ttl-01
---------------------------------------------------------- */
.c-ttl-01 {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    margin-bottom: 40px;
    text-align: center;
  }

  .text {
    display: inline-block;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.4;
    color: $color-black;

    @include mq-pc {
      font-size: 3.2rem;
    }
  }

  &.-txt_small {

    .text {
      font-size: 2rem;

      @include mq-pc {
        font-size: 2.4rem;
      }
    }
  }
}

/* c-ttl-02
---------------------------------------------------------- */
.c-ttl-02 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #e0e0e0;

  @include mq-pc {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 40px;
    height: 2px;
    background-color: $color-blue;

    @include mq-pc {
      width: 70px;
    }
  }

  .text {
    display: inline-block;
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 1.4;
    color: $color-blue;
    .c-link-arrow{
      color: $color-blue;
      position: relative;
      &::after{
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
      }
    }
    @include mq-pc {
      font-size: 2.6rem;
    }
  }

  .sub {
    display: block;
    font-weight: bold;
    font-size: 1.5rem;
    color: $color-black;

    @include mq-pc {
      margin-top: 10px;
      font-size: 1.8rem;
    }
  }

  .date {
    display: block;
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    color: $color-blue;

    @include mq-pc {
      display: inline-block;
      margin-top: 0;
    }
  }

  // -center
  &.-center {
    padding-bottom: 10px;
    text-align: center;
    border-bottom: 0;
    color: #333;

    @include mq-pc {
      padding-bottom: 20px;
    }

    &:after {
      width: 40px;
      left: 50%;
      transform: translateX(-50%);

      @include mq-sp {
        width: 30px;
      }
    }

    .text {
      color: $color-black;
    }
  }

  &.-no_bd_gray {
    border-bottom: none;

    &:after {
      @include mq-pc {
        width: 40px;
      }
    }
  }
}

/* c-ttl-03
---------------------------------------------------------- */
.c-ttl-03 {
  position: relative;
  padding-left: 10px;
  margin-bottom: 15px;
  border-left: 2px solid $color-blue;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    padding-left: 15px;
    border-width: 4px;
    line-height: 1.4;
  }

  .text {
    display: inline-block;
    font-weight: bold;
    font-size: 1.6rem;
    color: $color-black;
    @include mq-pc {
      line-height: 1.4;
      font-size: 2rem;
    }
  }

  // -no_border
  &.-no_border {
    padding-left: 0;
    border-left: 0;
  }
}

/* c-ttl-04
---------------------------------------------------------- */
.c-ttl-04 {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    margin-bottom: 20px;
  }

  .text {
    display: inline-block;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.4;
    color: $color-black;

    @include mq-pc {
      font-size: 1.8rem;
    }
  }
}

/* c-ttl-05
---------------------------------------------------------- */
.c-ttl-05 {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    margin-bottom: 40px;
  }

  .text {
    display: block;
    padding-bottom: 9px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 1.7rem;
    font-weight: bold;
    color: #111;

    @include mq-pc {
      padding-bottom: 13px;
    }
  }
}

/* c-ttl-dot
---------------------------------------------------------- */
.c-ttl-dot {
  margin-bottom: 15px;
  
  .text {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.4;
    color: $color-black;

    @include mq-pc {
      font-size: 2rem;
    }
  }
}

/* c-ttl-bar
---------------------------------------------------------- */
.c-ttl-bar {
  position: relative;
  background-color: #999;

  .label {
    display: block;
    height: 100%;
    padding: 15px;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.4;
    color: #fff;
    text-align: center;

    @include mq-pc {
      padding: 18px 15px;
      font-size: 2.2rem;
    }

    &.-orange {
      background-color: $color-orange;
    }
  }

  &.-blue {
    background-color: $color-blue;
  }
  
  .text {
    display: block;
    padding: 15px;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.4;
    color: #fff;
    text-align: center;

    @include mq-pc {
      padding: 18px 15px;
      font-size: 2.2rem;
    }
  }

  &.-small {
    .text {
      @include mq-pc {
        padding: 15px;
        font-size: 1.6rem;
      }
    }
  }

  // -accd
  &.-accd {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 21px;
      height: 21px;
      background-image: url('/assets/images/common/icon_accd_white.png');
      background-size: 100% auto;
      background-position: 0 0;
      background-repeat: no-repeat;

      @include mq-pc {
        right: 30px;
      }
    }

    &.-on:after {
      background-image: url('/assets/images/common/icon_accd_white_on.png');
    }

    .text {
      @include mq-sp {
        padding: 15px 40px 15px 15px;
      }
    }
  }
}

/* c-ttl-label
---------------------------------------------------------- */
.c-ttl-label {
  display: inline-block;
  margin-bottom: 40px;
  padding: 2px 5px;
  width: 120px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.4;
  color: #2157a7;
  text-align: center;
  border-radius: 100px;
  border: 1px solid #2157a7;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    padding: 2px 10px;
    width: 134px;
  }

  &.-auto_width {
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
  }

  &.-blue {
    background-color: $color-blue;
    color: #fff;
  }

  &.-black {
    background-color: $color-black;
    border-color: $color-black;
    color: #fff;
  }
}

/* c-ttl-en
---------------------------------------------------------- */
.c-ttl-en {
  font-size: 1.8rem;
  font-family: $font-roboto;
  line-height: 1.4;
  color: $color-blue;

  @include mq-pc {
    font-size: 2.2rem;
  }
}

/* c-ttl-part
---------------------------------------------------------- */
.c-ttl-part {
  margin-bottom: 40px;
  padding: 10px;
  background-color: #333;
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  text-align: center;

  @include mq-pc {
    padding: 15px;
    font-size: 1.8rem;
  }
}

.c-ttl-n-label {
  display: flex;
  justify-content: flex-start;
  color:$color-blue;
  font-weight: 700;
  margin-bottom: 15px;
  flex-direction: column-reverse;
  align-items: flex-start;
  @include mq-pc {
    font-size: 1.8rem;
    flex-direction: row;
    align-items: center;
  }
  >.label{
    display: inline-block;
    padding: 5px 15px;
    font-size: 1.2rem;
    line-height: 1.4;
    color: #2157a7;
    text-align: center;
    border-radius: 20px;
    border: 1px solid #2157a7;
    margin: 0 0 15px 0;
    @include mq-pc {
      margin: 0 0 0 15px;
      font-size: 1.3rem;
    }
  }
}