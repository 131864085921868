@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 list
========================================================== */
/* c-card-image
---------------------------------------------------------- */
.c-card-image {
  > * {
    display: block;
  }

  .image {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.-border {
      border: 1px solid #ebebeb;
    }

    img {
      transition: transform .3s;
      width: 100%;
    }
  }

  .detail {
    padding-top: 15px;
  }

  .ttl-blue {
    margin-bottom: 15px;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.35;
    color: $color-blue;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq-pc {
      font-size: 2.4rem;
    }
  }
  .txt-detail {
    font-weight: bold;
    color: #111;
  }
  // -frame
  &.-frame {
    background-color: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

    a {
      transition: background-color .2s;

      &:hover {
        background-color: #ededed;
      }
    }

    .detail {
      padding: 25px;

      @include mq-pc {
      padding: 30px 50px;
      }
    }
  }

  > a {
    &:hover {
      .image {
        img {
          transform: translate(0, 0) scale(1.1, 1.1);
        }
      }
    }
  }

  // -hover_opacity
  &.-hover_opacity {
    > a {
      @include is-opacity;

      &:hover {
        .image {
          img {
            transform: translate(0, 0) scale(1, 1);
          }
        }
      }
    }
  }
}

/* c-card-point
---------------------------------------------------------- */
.c-card-point {
  position: relative;
  .image {
    overflow: hidden;
    border-radius: 14px;

    img {
      width: 100%;
    }
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -23px;
    left: -4px;
    width: 65px;
    height: 65px;
    border-radius: 65px;
    background-color: #006cb5;

    .text {
      display: block;
      font-size: 3.0rem;
      text-align: center;
      color: #fff;
      line-height: 1.1;
      padding-top: 10px;
    }
    
    .txt-point {
      display: block;
      font-size: 1rem;
      letter-spacing: 0.1rem;
    }
  }

  // -frame
  &.-frame {
    .image {
      box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

/* c-card-service
---------------------------------------------------------- */
.c-card-service {
  position: relative;
  height: 100%;
  background-color: #f6f7f9;
  border-radius: 8px;
  padding: 64px 20px 40px;
  text-align: center;

  @include mq-pc {
    padding-top: 61px;
    padding-bottom: 30px;
  }

  .icon {
    position: absolute;
    top: -64px;
    left: 50%;
    transform: translateX(-50%);

    @include mq-pc {
      top: -61px;
    }

    img {
      width: 134px;

      @include mq-pc {
        width: 122px;
      }
    }
  }
  
  .title {
    font-size: 2.2rem;
    font-weight: 700;
    color: #f98700;
    line-height: 1.4;
    letter-spacing: 0.2rem;

    @include mq-pc {
      font-size: 2rem;
    }

    .sub {
      display: block;
      font-size: 1.5rem;

      @include mq-pc {
        font-size: 1.4rem;
      }
    }
  }

  .text {
    color: $color-black;
    margin-top: 18px;

    @include mq-pc {
      line-height: 1.7;
      margin-top: 11px;
    }
  }
}