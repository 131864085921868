@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Icon
========================================================== */
/* c-icon
---------------------------------------------------------- */
.c-icon {
  text-decoration: underline;
}