@import "../_variables.scss";
@import "../_mixin.scss";

/* margin-col
---------------------------------------------------------- */
@mixin margin-col($w,$col) {
  @include mq-sp {
    margin-bottom: 20px;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mq-pc {
    width: $w;
    &:nth-child(#{$col}) {
      margin-bottom: 25px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* grid-sitemap
---------------------------------------------------------- */
.grid-sitemap {
  display: flex;
  flex-wrap: wrap;

  >.col {
    width: 100%;
    @include margin-col(25%,4n);

  }
}

/* grid-custom-sitemap
---------------------------------------------------------- */
.grid-custom-sitemap {
  display: flex;
  flex-wrap: wrap;

  .box-1 {
    width: 25%;
    @include mq-sp { width: 100%;margin-bottom: 20px;}
  }
  .box-3 {
    width: 66.6%;
    display: flex;
    flex-wrap: wrap;
    @include mq-sp { width: 100%;}

  }
  .box-4{
    width: 33.3%;
    @include mq-sp { width: 100%;margin-bottom: 20px;}
  }
  .box-2 {
    width: 75%;
    @include mq-sp { width: 100%;}
    .box-list {
      margin-bottom: 25px;
      display: flex;
      flex-wrap: wrap;
      @include mq-sp {
        margin-bottom: 40px;
      }

      .col-1 {
        width: 100%;
      }

      .col-2 {
        @include margin-col(33.3%,3n);
      }
      .col-3{
        @include margin-col(50%,2n);

      }
      &:last-child {
        margin-bottom: 0;
      }
      @include mq-sp {
        margin-bottom: 20px;
        &:nth-child(3){
          flex-direction: column-reverse;
        }
      }

    }

  }
}