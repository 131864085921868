@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 table
========================================================== */
/* c-table-wrap
---------------------------------------------------------- */
.c-table-wrap {
  margin-bottom: 15px;
  @include mq-sp {
    overflow-y: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 4px;
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #a0a0a0;
    }
  }
  .c-table {
    @include mq-sp {
      width: 980px;
    }
  }

  // -no_scroll
  &.-no_scroll {
    @include mq-sp {
      overflow-x: unset;
    }

    .c-table {
      @include mq-sp {
        width: 100%;
      }
    }
  }
}
/* c-table
---------------------------------------------------------- */
.c-table {
  width: 100%;
  border: 1px solid #ebebeb;

  &.-layout_fixed {
    table-layout: fixed;
  }

  > thead,
  > tbody {
    > tr {
      > th,
      > td {
        padding: 15px 10px;
        border-bottom: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
        text-align: left;
        line-height: 1.3;

        @include mq-sp {
          padding: 12px 8px;
          font-size: 1.1rem;
        }

        &.v-top {
          vertical-align: top;
        }

        &.v-mid {
          vertical-align: middle;
        }

        &.v-bottom {
          vertical-align: bottom;
        }
      }
      > th {
        background-color: #f6f7f9;
        font-weight: bold;
        text-align: center;
        &.bg-none{
          background: none;
        }
        &.bg-orange {
          color: #fff;
          background: $color-orange;
        }
        &.bg-blue {
          color: #fff;
          background: $color-blue;
        }
        &.bg-grey {
          color: #fff;
          background: $color-grey-dark;
        }
      }
      > td {
        background-color: #fff;
      }
    }
  }
}


/* c-table-list
---------------------------------------------------------- */
.c-table-list {
  margin-bottom: 35px;

  @include mq-pc {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px 40px;
  }

  .item {

    @include mq-pc {
      width: 50%;
      padding: 0 25px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      dl {
        border-top: 1px solid #ebebeb;
      }
    }
    &:nth-child(2) {
      dl {
        @include mq-sp {
          border-top: none;
        }
      }
    }

    dl {
      padding: 17px 0;
      border-bottom: 1px solid #ebebeb;

      @include mq-pc {
        display: flex;
        align-items: center;
        padding: 25px 0;
      }

      dt {
        @include mq-pc {
          width: 155px;
          flex: 1 0 auto;
        }
      }
      dd {
        width: 100%;
      }
    }
  }
}