/* ==========================================================
 color
========================================================== */
$color-navi: #262d40;
$color-navi-dark: #202636;
$color-blue: #2157a7;
$color-blue-dark: #1b4d96;
$color-orange: #f93b00;
$color-orange-light: #f98700;
$color-grey-dark: #888;
$color-black: #111;
$color-white: #fff;

/* ==========================================================
 breakpoint
========================================================== */
$pc-breakpoint: 897px;

/* ==========================================================
 font
========================================================== */
$font-roboto: 'Roboto Condensed';

