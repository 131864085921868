@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 form component
========================================================== */
/* c-input-text
---------------------------------------------------------- */

/* c-select
---------------------------------------------------------- */
.c-select {
  
}

/* c-radio
---------------------------------------------------------- */
.c-radio {
  
}

/* c-checkbox
---------------------------------------------------------- */
.c-checkbox {
  
}



/* c-text-area
---------------------------------------------------------- */
.c-text-area {
  
}

/* ==========================================================
 form layout
========================================================== */
/* c-form
---------------------------------------------------------- */