@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 box
========================================================== */
/* c-box-white-shadow
---------------------------------------------------------- */
.c-box-white-shadow {
  padding: 30px 25px 40px;
  margin-bottom: 50px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-pc {
    padding: 80px 7% 90px;
    margin-bottom: 70px;
  }
}

/* c-box-dark
---------------------------------------------------------- */
.c-box-dark {
  margin-bottom: 25px;
  background-color: #f6f7f9;
  padding: 20px 20px 25px;
  
  @include mq-pc {
    padding: 45px 50px 40px;
  }
}


/* c-box-contact
---------------------------------------------------------- */
.c-box-contact {
  @include mq-pc {
    display: flex;
    align-items: center;
    width: 555px;
    margin: 0 auto;
  }

  .title {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 20px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;

    @include mq-pc {
      width: 175px;
      flex: 1 0 auto;
      font-size: 2rem;
      padding-bottom: 0;
      text-align: left;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 30px;
      height: 2px;
      margin: 0 auto;
      background-color: #e0e0e0;

      @include mq-pc {
        top: -13px;
        left: inherit;
        width: 2px;
        height: 63px;
      }
    }
  }

  .box {
    text-align: center;

    @include mq-pc {
      width: 100%;
      text-align: left;
      padding-left: 40px;
    }

    .c-tel-number {
      line-height: 1.2;
    }
  }
}

/* c-box-accd
---------------------------------------------------------- */
.c-box-accd {
  margin-bottom: 20px;
  background-color: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .accd-heading {
    position: relative;
    cursor: pointer;

    .inner {
      padding: 25px 15px 15px;

      @include mq-pc {
        padding: 35px 100px 35px 40px;
      }
    }

    @include mq-pc {
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background-image: url('/assets/images/common/icon_accd_black.png');
        background-size: 100% auto;
        background-position: 0 0;
        background-repeat: no-repeat;
      }
    }

    .c-ttl-n-label {
      margin-bottom: 5px;

      @include mq-pc {
        margin-bottom: 10px;
      }

      .label {
        @include mq-sp {
          margin-bottom: 10px;
        }
      }
    }

    // -on
    &.-on {
      background-color: #228bdb;

      .c-ttl-n-label {
        color: #fff;

        .label {
          color: #fff;
          border-color: #fff;
        }
      }

      .c-ttl-03 {
        .text {
          color: #fff;
        }
      }

      @include mq-sp {
        .accd-btn {
          &.-see_detail {
            display: none;
          }
        }
      }
      
      @include mq-pc {
        &:after {
          background-image: url('/assets/images/common/icon_accd_white_on.png');
        }
      }
    }
  }

  .accd-content {
    display: none;
    padding: 25px 15px;
    border-left: 2px solid #228bdb;
    border-right: 2px solid #228bdb;
    border-top: 0;

    @include mq-pc {
      padding: 50px;
      border-width: 4px;
    }
  }

  .accd-btn {
    position: relative;
    padding: 15px;
    background-color: #228bdb;
    color: #fff;
    line-height: 1;
    cursor: pointer;

    @include mq-pc {
      padding: 16px;
      text-align: center;
    }

    @include mq-sp {
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        background-image: url('/assets/images/common/icon_plus_white.png');
        background-size: 100% auto;
        background-position: 0 0;
        background-repeat: no-repeat;
      }
    }

    .text {
      display: block;
      color: #fff;
      line-height: 1;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1;

      @include mq-pc {
        display: inline-block;
        align-items: center;
        font-size: 1.6rem;

        .icon-x {
          position: relative;
          top: -2px;
          display: inline-block;
          margin-left: 5px;
          font-weight: normal;
        }
      }
    }

    // -see_detail
    &.-see_detail {
      @include mq-pc {
        display: none;
      }
    }

    // -close
    &.-close {
      display: none;

      @include mq-sp {
        &:after {
          background-image: url('/assets/images/common/icon_minus_white.png');
        }
      }
    }
  }
}

/* c-box-balloon
---------------------------------------------------------- */
.c-box-balloon {
  position: relative;
  display: inline-block;
  padding: 20px;
  margin-bottom: 25px;
  border: 1px solid $color-blue;
  border-radius: 10px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: $color-blue;

  &:after {
    content: "";
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    width: 26px;
    height: 14px;
    background: url('/assets/images/common/icon_balloon_tail.png') 0 0 no-repeat;
    background-size: 100% auto;
  }

  @include mq-pc {
    margin-bottom: 30px;
  }
}