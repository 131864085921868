@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Slider
========================================================== */
/* c-slider-thumbnail
---------------------------------------------------------- */
.c-slider-thumbnail {
  margin-bottom: 25px;
  height: 500px;
  visibility: hidden;

  @include mq-pc {
    margin-bottom: 40px;
  }

  // slider-main
  .slider-main {
    margin-bottom: 15px;
    overflow: hidden;

    @include mq-pc {
      margin-bottom: 20px;
    }

    .slider {
      .item {
        position: relative;
        overflow: hidden;
        outline: none;
      }

      img {
        width: 100%;
      }
    }
  }

  // slick-dots
  .slick-dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;

    > li {
      position: relative;
      width: 18%;
      padding-top: 10%;
      margin-top: 10px;
      overflow: hidden;
      cursor: pointer;
      outline: none;

      @include mq-pc {
        width: 13.4%;
        padding-top: 7.8%;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .8);
        opacity: 0;
        visibility: hidden;
        transition: all .2s;
      }

      &.slick-active:before, &:hover:before {
        visibility: visible;
        opacity: 1;
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
}