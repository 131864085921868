/* ==========================================================
 Width size
========================================================== */
/* Width pixel
---------------------------------------------------------- */
@for $i from 10 through 1000 {
  .width-px-#{$i} {
    width: #{$i}px !important;
  }

  @include mq-pc {
    .width-px-pc-#{$i} {
      width: #{$i}px !important;
    }
  }

  @include mq-sp {
    .width-px-sp-#{$i} {
      width: #{$i}px !important;
    }
  }
}

/* Width percentage
---------------------------------------------------------- */
@for $i from 10 through 1000 {
  .width-pct-#{$i} {
    width: #{$i}% !important;
  }

  @include mq-pc {
    .width-pct-pc-#{$i} {
      width: #{$i}% !important;
    }
  }

  @include mq-sp {
    .width-pct-sp-#{$i} {
      width: #{$i}% !important;
    }
  }
}