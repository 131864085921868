@import '../_variables.scss';
@import '../_mixin.scss';

.flex-row            { flex-direction: row !important; }
.flex-column         { flex-direction: column !important; }
.flex-row-reverse    { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap         { flex-wrap: wrap !important; }
.flex-nowrap       { flex-wrap: nowrap !important; }
.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.flex-fill         { flex: 1 1 auto !important; }
.flex-grow-0       { flex-grow: 0 !important; }
.flex-grow-1       { flex-grow: 1 !important; }
.flex-shrink-0     { flex-shrink: 0 !important; }
.flex-shrink-1     { flex-shrink: 1 !important; }

.justify-content-start   { justify-content: flex-start !important; }
.justify-content-end     { justify-content: flex-end !important; }
.justify-content-center  { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around  { justify-content: space-around !important; }

.align-items-start    { align-items: flex-start !important; }
.align-items-end      { align-items: flex-end !important; }
.align-items-center   { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch  { align-items: stretch !important; }

.align-content-start   { align-content: flex-start !important; }
.align-content-end     { align-content: flex-end !important; }
.align-content-center  { align-content: center !important; }
.align-content-between { align-content: space-between !important; }
.align-content-around  { align-content: space-around !important; }
.align-content-stretch { align-content: stretch !important; }

.align-self-auto     { align-self: auto !important; }
.align-self-start    { align-self: flex-start !important; }
.align-self-end      { align-self: flex-end !important; }
.align-self-center   { align-self: center !important; }
.align-self-baseline { align-self: baseline !important; }
.align-self-stretch  { align-self: stretch !important; }

@include mq-pc {
  .flex-pc-row            { flex-direction: row !important; }
  .flex-pc-column         { flex-direction: column !important; }
  .flex-pc-row-reverse    { flex-direction: row-reverse !important; }
  .flex-pc-column-reverse { flex-direction: column-reverse !important; }

  .flex-pc-wrap         { flex-wrap: wrap !important; }
  .flex-pc-nowrap       { flex-wrap: nowrap !important; }
  .flex-pc-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-pc-fill         { flex: 1 1 auto !important; }
  .flex-pc-grow-0       { flex-grow: 0 !important; }
  .flex-pc-grow-1       { flex-grow: 1 !important; }
  .flex-pc-shrink-0     { flex-shrink: 0 !important; }
  .flex-pc-shrink-1     { flex-shrink: 1 !important; }

  .justify-content-pc-start   { justify-content: flex-start !important; }
  .justify-content-pc-end     { justify-content: flex-end !important; }
  .justify-content-pc-center  { justify-content: center !important; }
  .justify-content-pc-between { justify-content: space-between !important; }
  .justify-content-pc-around  { justify-content: space-around !important; }

  .align-items-pc-start    { align-items: flex-start !important; }
  .align-items-pc-end      { align-items: flex-end !important; }
  .align-items-pc-center   { align-items: center !important; }
  .align-items-pc-baseline { align-items: baseline !important; }
  .align-items-pc-stretch  { align-items: stretch !important; }

  .align-content-pc-start   { align-content: flex-start !important; }
  .align-content-pc-end     { align-content: flex-end !important; }
  .align-content-pc-center  { align-content: center !important; }
  .align-content-pc-between { align-content: space-between !important; }
  .align-content-pc-around  { align-content: space-around !important; }
  .align-content-pc-stretch { align-content: stretch !important; }

  .align-self-pc-auto     { align-self: auto !important; }
  .align-self-pc-start    { align-self: flex-start !important; }
  .align-self-pc-end      { align-self: flex-end !important; }
  .align-self-pc-center   { align-self: center !important; }
  .align-self-pc-baseline { align-self: baseline !important; }
  .align-self-pc-stretch  { align-self: stretch !important; }
  .flex-grow-pc-auto  { flex: 0 0 auto !important;}
}

@include mq-sp {
  .flex-sp-row            { flex-direction: row !important; }
  .flex-sp-column         { flex-direction: column !important; }
  .flex-sp-row-reverse    { flex-direction: row-reverse !important; }
  .flex-sp-column-reverse { flex-direction: column-reverse !important; }

  .flex-sp-wrap         { flex-wrap: wrap !important; }
  .flex-sp-nowrap       { flex-wrap: nowrap !important; }
  .flex-sp-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sp-fill         { flex: 1 1 auto !important; }
  .flex-sp-grow-0       { flex-grow: 0 !important; }
  .flex-sp-grow-1       { flex-grow: 1 !important; }
  .flex-sp-shrink-0     { flex-shrink: 0 !important; }
  .flex-sp-shrink-1     { flex-shrink: 1 !important; }

  .justify-content-sp-start   { justify-content: flex-start !important; }
  .justify-content-sp-end     { justify-content: flex-end !important; }
  .justify-content-sp-center  { justify-content: center !important; }
  .justify-content-sp-between { justify-content: space-between !important; }
  .justify-content-sp-around  { justify-content: space-around !important; }

  .align-items-sp-start    { align-items: flex-start !important; }
  .align-items-sp-end      { align-items: flex-end !important; }
  .align-items-sp-center   { align-items: center !important; }
  .align-items-sp-baseline { align-items: baseline !important; }
  .align-items-sp-stretch  { align-items: stretch !important; }

  .align-content-sp-start   { align-content: flex-start !important; }
  .align-content-sp-end     { align-content: flex-end !important; }
  .align-content-sp-center  { align-content: center !important; }
  .align-content-sp-between { align-content: space-between !important; }
  .align-content-sp-around  { align-content: space-around !important; }
  .align-content-sp-stretch { align-content: stretch !important; }

  .align-self-sp-auto     { align-self: auto !important; }
  .align-self-sp-start    { align-self: flex-start !important; }
  .align-self-sp-end      { align-self: flex-end !important; }
  .align-self-sp-center   { align-self: center !important; }
  .align-self-sp-baseline { align-self: baseline !important; }
  .align-self-sp-stretch  { align-self: stretch !important; }
  .flex-grow-sp-auto  { flex: 0 0 auto !important;}
}
