@import "../_variables.scss";
@import "../_mixin.scss";

/* ==========================================================
 Text align
========================================================== */
.txt-left {
  text-align: left !important;
}
.txt-center {
  text-align: center !important;
}
.txt-right {
  text-align: right !important;
}

@include mq-pc {
  .txt-pc-left {
    text-align: left !important;
  }
  .txt-pc-center {
    text-align: center !important;
  }
  .txt-pc-right {
    text-align: right !important;
  }
}

@include mq-sp {
  .txt-sp-left {
    text-align: left !important;
  }
  .txt-sp-center {
    text-align: center !important;
  }
  .txt-sp-right {
    text-align: right !important;
  }
}

/* ==========================================================
 Text weight
========================================================== */
.txt-normal {
  font-weight: normal !important;
}
.txt-bold {
  font-weight: bold !important;
}

/* ==========================================================
 Text color
========================================================== */
.txt-blue {
  color: $color-blue !important;
}

.txt-red {
  color: #ff0000 !important;
}
.txt-orange{
  color: $color-orange;
}
.txt-black {
  color: $color-black !important;
}

.txt-white {
  color: #fff !important;
}



/* ==========================================================
 Text size
========================================================== */
@for $i from 8 through 100 {
  .txt-size-#{$i} {
    font-size: #{$i / 10}rem !important;
  }

  @include mq-sp {
    .txt-size-sp-#{$i} {
      font-size: #{$i / 10}rem !important;
    }
  }
  @include mq-pc {
    .txt-size-pc-#{$i} {
      font-size: #{$i / 10}rem !important;
    }
  }
}

