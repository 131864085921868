
/* border side
---------------------------------------------------------- */
$sides: (top, bottom, left, right);
@each $side in $sides {
  .bd-#{$side} {
    border-#{$side}: 1px solid #e0e0e0 !important;
  }
}

@include mq-pc {
  @each $side in $sides {
    .bd-pc-#{$side} {
      border-#{$side}: 1px solid #e0e0e0 !important;
    }
  }
}

@include mq-sp {
  @each $side in $sides {
    .bd-sp-#{$side} {
      border-#{$side}: 1px solid #e0e0e0 !important;
    }
  }
}

/* border width
---------------------------------------------------------- */
$widths: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
@each $width in $widths {
  .bd-width-#{$width} {
    border-width: #{$width}px !important;
  }
}

@include mq-pc {
  @each $width in $widths {
    .bd-width-pc-#{$width} {
      border-width: #{$width}px !important;
    }
  }
}

@include mq-sp {
  @each $width in $widths {
    .bd-width-sp-#{$width} {
      border-width: #{$width}px !important;
    }
  }
}

/* border color
---------------------------------------------------------- */
.bd-white {
  border-color: #fff !important;
}