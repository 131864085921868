/* ==========================================================
 mainvisual
========================================================== */
/* c-mainvisual
---------------------------------------------------------- */
.c-mainvisual {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
  background-color: #fff;

  @include mq-pc {
    height: 240px;
  }

  .box {
    .icon {
      display: block;
      margin-bottom: 15px;
      text-align: center;

      @include mq-pc {
      }
    }

    .text {
      font-size: 3rem;
      line-height: 1.4;
      font-weight: bold;
      color: $color-black;

      @include mq-pc {
        font-size: 3.6rem;
      }
    }
  }

  // .-denki
  &.-denki {
    background-image: linear-gradient(to right, #fa9b23 , #f8b831);

    .box {
      .icon {
  
      }
  
      .text {
        color: #fff;
      }
    }
  }

  // .-gas
  &.-gas {
    background-image: linear-gradient(to right, #23a0e8 , #17b5e2);

    .box {
      .icon {
  
      }
  
      .text {
        color: #fff;
      }
    }
  }

  // -musubi_project 
  &.-musubi_project {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 15px;
    height: auto;
    @include mq-pc {
      padding: 50px 15px 90px;
    }
    img {
      max-width: 100%;
    }
  } 

}

/* c-mainvisual-img
---------------------------------------------------------- */
.c-mainvisual-img {
  position: relative;
  width: 100%;

  .box {
    width: 100%;
  }

  figure {
    img {
      width: 100%;
    }
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    letter-spacing: 0.15em;

    @include mq-pc {
      font-size: 3.6rem;
    }

    @include mq-mn(1170) {
      width: 1170px;
    }
  }
}