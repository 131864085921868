@import "../_variables.scss";
@import "../_mixin.scss";

/* box-btn-teiden
---------------------------------------------------------- */
.box-btn-teiden {
  > .item {
    @include mq-pc {
      width: calc(50% - 1px);
    }
  }
}
