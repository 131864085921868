@import "../_variables.scss";
@import "../_mixin.scss";


/* ttl-qa
---------------------------------------------------------- */
.ttl-qa {
  margin-bottom: 35px;
  @include mq-pc {
    display: flex;
    align-items: center;
    margin-bottom: 70px;
  }
  .num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 33px;
    margin-bottom: 13px;
    background-color: #000;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    text-align: center;

    @include mq-pc {
      width: 80px;
      height: 47px;
      margin: 0 22px 0 0;
      font-size: 2rem;
    }
  }
  .text {
    font-size: 2rem;
    font-weight: bold;

    @include mq-pc {
      font-size: 2.4rem;
    }
  }
}