.list-icon-prime {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: -35px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ebebeb;

  @include mq-pc {
    padding-bottom: 50px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    width: 21px;
    height: 11px;
    background: #fff url('/assets/images/prime/arrow_down.png') 0 0 no-repeat;
    background-size: 100% auto;

    @include mq-pc {
      bottom: -22px;
      width: 42px;
      height: 22px;
    }
  }
  

  @include mq-pc {
    margin-top: -40px;
  }
  
  .item {
    width: 50%;
    padding: 0 15px;
    margin-top: 35px;

    @include mq-pc {
      width: 16.66%;
      margin-top: 40px;
      padding: 0 10px;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 15px;

    @include mq-pc {
      margin-bottom: 20px;
    }
    
  }

  .text {
    display: block;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.4;
  }
}