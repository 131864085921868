.nav-side-button {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(185px, -50%);
  width: 250px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  box-shadow: 0px 0px 10px 0px #ededed;
  transition: transform .4s, opacity .2s;
  visibility: hidden;
  opacity: 0;
  z-index: 0;

  &.-show {
    visibility: visible;
    opacity: 1;
    z-index: 98;
  }

  @include mq-sp {
    display: none;
  }

  .item {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      width: 100%;
      height: 1px;
      background-color: #ededed;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      height: 70px;
      padding-left: 20px;
      transition: background-color .2s;

      &:hover {
        background-color: #ededed;
      }

      .icon {
        flex-shrink: 0;
        display: block;
        width: 32px;
        text-align: center;
      }

      .text {
        flex-grow: 1;
        padding-left: 13px;
        font-size: 1.4rem;
        font-weight: bold;
      }
    }
  }

  // hover
  &:hover {
    transform: translate(0, -50%);
  }
}