@import "../_variables.scss";
@import "../_mixin.scss";


.c-card-moshimo-gas {
  border: 1px solid #ebebeb;
  padding: 0 25px 30px;
  @include mq-pc {
    padding: 0 50px 40px;
  }
  .image {
    text-align: center;
    height: 100px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq-pc {
      height: 165px;
      margin-top: 0;
    }
    &.-image3 {
      height: 92px;
      @include mq-pc {
        height: 190px;
      }
    }
    &.-image4 {
      height: 120px;
      @include mq-pc {
        height: 190px;
      }
    }
  }
}
.txt-moshimo-gas {
  font-size: 12px;
  line-height: 1.5;
  position: relative;
  padding-left: 10px;
  &:before {
    content: '＊';
    position: absolute;
    left: 0;
  }
}
