@import "../_variables.scss";
@import "../_mixin.scss";


/* sec-musubi-intro
---------------------------------------------------------- */
.sec-musubi-intro {
  padding-top: 50px;
  padding-bottom: 140px;
  background: url('/assets/images/musubi-project/bg_musubi.jpg') center no-repeat;
  background-size: cover;
  background-repeat: no-repeat;

  @include mq-pc {
    padding-top: 160px;
    padding-bottom: 360px;
  }
}

/* sec-musubi-logo
---------------------------------------------------------- */
.sec-musubi-logo {
  position: relative;
  margin-top: -90px;
  padding-bottom: 10px;

  @include mq-pc {
    margin-top: -215px;
  }
}

/* box-musubi-case
---------------------------------------------------------- */
.box-musubi-case {
  padding: 27px 24px;
  background-color: #f93b00;
  text-align: center;

  @include mq-pc {
    padding: 45px 30px;
  }

  .heading {
    display: inline-block;
    padding: 6px 25px;
    margin-bottom: 15px;
    border: 2px solid #fff;
    border-radius: 100px;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.3;
    color: #fff;
    text-align: center;

    @include mq-pc {
      padding: 12px 50px;
      font-size: 2.2rem;
    }

    .price {
      position: relative;
      margin-left: 10px;
      font-size: 1.5rem;

      @include mq-pc {
        top: 2px;
        font-size: 2.6rem;
      }
    }
  }

  .text {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.3;
    color: #fff;

    @include mq-pc {
      font-size: 3rem;
    }

    .price {
      position: relative;
      margin-right: 10px;
      font-size: 4rem;

      @include mq-pc {
        top: 5px;
        margin: 0 10px;
        font-size: 6.4rem;
      }
    }
  }
}

/* mod-musubi-case-accd
---------------------------------------------------------- */
.mod-musubi-case-accd {
  .issue {
    display: none;
  }
  
  .c-box-dark {
    position: relative;
    padding: 70px 20px 40px;
    margin-bottom: 40px;

    @include mq-pc {
      padding: 90px 7.5% 60px;
      margin-bottom: 60px;
    }

    .heading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;

      .text {
        display: inline-block;
        padding: 7px 25px;
        background-color: $color-black;
        color: #fff;
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.4;

        @include mq-pc {
          padding: 10px 35px;
          font-size: 2rem;
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 20px;
      background-image: url('/assets/images/common/icon_plus.png');
      background-size: 100% auto;
      background-position: 0 0;
      background-repeat: no-repeat;

      @include mq-pc {
        bottom: -40px;
      }
    }

    &.-equal:after {
      width: 13px;
      height: 20px;
      background-image: url('/assets/images/common/ico_equa_02.png');
    }
    &.-nosign:after {
      content: none;
    }
  }

  .cont {
    line-height: 1.4;
  }
}
.c-list-qa {
  @include mq-pc {
    margin: 0 auto;
  }
}