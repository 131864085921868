@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Toppage
========================================================== */
.mainvisual-top {
  position: relative;
  height: 900px;
  visibility: hidden;
  overflow: hidden;

  &.-loaded {
    height: auto;
    visibility: visible;
  }

  .scroll-guide {
    position: absolute;
    left: 26px;
    bottom: 140px;
    letter-spacing: .1em;
    font-size: 1.2rem;
    color: #fff;
    line-height: 1;
    transform: translateZ(0) rotate(90deg);

    &:after {
      content: "";
      position: absolute;
      bottom: 5px;
      left: 68px;
      height: 1px;
      width: 100px;
      background: #fff;
      animation: scroll_effect 2s 2s cubic-bezier(.3,.4,.1,1) infinite;
    }
  }

  .list-slider {
    .item {
      position: relative;
      outline: none;
      overflow: hidden;

      @include mq-pc {
        height: calc(100vh - 80px);
      }

      @include mq-sp {
        height: auto;
      }
      
      .image {
        @include mq-pc {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          img.only-pc {
            opacity: 0;
          }
        }

        img {
          @include mq-sp {
            width: 100%;
          }
        }
      }

      .bg-switch {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        > img{
          max-width: none;

          &.ww {
            width: 100%;
          }

          &.wh {
            height: 100%;
          }
        }

        .box-switch {
          position: absolute;
          top: 0;
          left: 20%;
          display: block;
          width: 16.5%;
          margin-top: 18.2%;
          z-index: 2;
          outline: none;
          //background-color: rgba(0, 0, 0, .5);
          
          img {
            width: 100%;
          }
        }
      }

      .btn-click {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-image: url('/assets/images/top/btn_click.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        animation: bounce 1s infinite;
        pointer-events: none;

        @include mq-sp {
          background-image: url('/assets/images/top/btn_touch.png');
          background-position: 0 0;
          background-size: 100% auto;
        }
      }

      &.item01 {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 0;
          padding-top: 33.25%;
          background: url('/assets/images/top/light_hover.png') center top no-repeat;
          background-size: auto 100%;
          opacity: 0;
          visibility: hidden;
          transition: all .3s;
          pointer-events: none;
        }

        &.-hover {
          &:before {
            opacity: 1;
            visibility: visible;
          }

          .box-text {
            .title {
              text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
              transition: text-shadow .3s;
            }
          }
        }

        @include mq-sp {
          .image {
            position: relative;
          }

          .box-switch {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 35%;
            margin-top: 87%;
            z-index: 1;
            outline: none;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .list-thumbnails {
    @include mq-pc {
      position: absolute;
      right: 40px;
      bottom: 20px;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;

      .item {
        position: relative;
        cursor: pointer;
        margin-left: 1px;
        outline: none;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, .6);
          opacity: 0;
          transition: opacity .4s;
        }

        &:hover:before,
        &.-active:before {
          opacity: 1;
        }

        img {
          width: 100%;
        }
      }
    }

    @include mq-sp {
      display: none;
    }
  }

  // slick-dots
  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;

    li {
      padding: 0 5px;
      outline: none;
      line-height: 1;
      
      button {
        width: 8px;
        height: 8px;
        border: 1px solid #ccc;
        border-radius: 50%;
        appearance: none;
        text-indent: -9999px;
        overflow: hidden;
        outline: none;
      }

      // active
      &.slick-active {
        button {
          background-color: #ccc;
        }
      }
    }
  }

  .box-text {
    @include mq-pc {
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 3;
      transform: translateY(-50%);
      padding-left: 50%;
      width: 100%;

      .c-link-arrow {
        transform: translateZ(0);
        backface-visibility: hidden;
      }
    }

    @include mq-sp {
      height: 260px;
      background-color: #fff;
      padding: 20px 25px;
    }

    .title {
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 4.4rem;
      line-height: 1.5;
      color: #111;
      
      @include mq-sp {
        margin-bottom: 15px;
        font-size: 2.7rem;
      }
    }

    .text {
      font-size: 1.8rem;
      line-height: 2.1;
      color: #111;

      @include mq-sp {
        font-size: 1.4rem;
        line-height: 1.78;
      }
    }

    .link {
      display: inline-block;
      &:hover {
        opacity: .7;
      }

      a {
        display: inline-block;
        transition: none;

        &:hover {
          opacity: 1;
        }
      }
    }

    &.box01 {
      .title, .text {
        color: #fff;
      }

      @include mq-pc {
        padding-left: 0;
        margin-left: 41%;
      }

      @include mq-sp {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        padding: 40px 20px;
        background-color: transparent;

        .image {
          position: relative;
        }
      }
    }

    &.box02 {
      @include mq-pc {
        padding-left: 20%;
      }
    }

    &.box03 {
      @include mq-pc {
        padding-left: 20%;

        .title, .text, a {
          color: #fff;
        }
      }
    }

    &.box04 {
      @include mq-pc {
        padding-left: 20%;

        .title, .text, a {
          color: #fff;
        }
      }
    }

    &.box05 {
      @include mq-pc {
        padding-left: 47%;
      }
    }

    &.box06 {
      @include mq-pc {
        .title, .text, a {
          color: #fff;
        }
      }
    }
  }
}

@keyframes scroll_effect {
  0% { width:0; }
  50% { width: 100px; }
  75% { transform: translateX(0); }
  100% { transform: translateX(100%); }
}

@keyframes bounce {
  0% { transform: translateY(-10px); }
  50% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

/* sec-top
---------------------------------------------------------- */
.sec-top {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;

  @include mq-pc {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

/* ttl-top
---------------------------------------------------------- */
.ttl-top {
  margin-bottom: 35px;
  color: #111;

  @include mq-pc {
    margin-bottom: 50px;
  }

  .en {
    display: block;
    font-family: $font-roboto;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;

    @include mq-pc {
      font-size: 1.8rem;
    }
  }

  .jp {
    display: block;
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.4;

    @include mq-pc {
      font-size: 3.6rem;
    }
  }

  // -white
  &.-white {
    color: #fff;
  }
}

/* box-main-cate
---------------------------------------------------------- */
.box-main-cate {
  padding: 60px 25px;
  background-size: 100% auto;
  background-position: right top;
  background-repeat: no-repeat;
  
  @include mq-pc {
    position: relative;
    height: 515px;
    background-size: auto 125%;
    background-position: center;
    padding: 90px 5.5%;
  }
  @include mq-mx(1230) {
    padding-left: 3.5%;
    padding-right: 3.5%;
  }

  // -denki
  &.-denki {
    background-color: #160b00;
    background-image: url('/assets/images/top/bg_denki_sp.jpg');
    @include mq-pc {
      background-image: url('/assets/images/top/bg_denki_pc.jpg');
    }
  }

  // -gas
  &.-gas {
    background-color: #000d18;
    background-image: url('/assets/images/top/bg_gas_sp.jpg');
    @include mq-pc {
      background-image: url('/assets/images/top/bg_gas_pc.jpg');
    }
  }

  .list-link {
    display: flex;
    flex-wrap: wrap;
    margin-top: -30px;

    @include mq-pc {
      width: 400px;
      margin-top: -20px;
    }

    .item {
      width: 50%;
      margin-top: 30px;
      padding-right: 10px;
      line-height: 1;

      @include mq-pc {
        padding-right: 15px;
        margin-top: 20px;
      }
    }

    a {
      position: relative;
      display: inline-block;
      padding-left: 12px;
      color: #fff;
      font-size: 1.2rem;
      line-height: 1.5;
      letter-spacing: -0.05em;

      &:after {
        content: "";
        position: absolute;
        top: 9px;
        left: 0;
        width: 5px;
        height: 1px;
        background-color: #fff;
      }

      &:hover {
        text-decoration: underline;
      }

      @include mq-pc {
        font-size: 1.4rem;
      }
    }
  }
}

/* list-top-banner
---------------------------------------------------------- */
.list-top-banner {
  .list {
    .item {
      outline: none;
      
      @include mq-pc {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include mq-sp {
        padding: 0 8px;
      }
    }

    a {
      display: block;
      outline: none;
      @include is-opacity;

      img {
        width: 100%;
      }
    }
  }
}

/* sec-top-news
---------------------------------------------------------- */
.sec-top-news {
  background-color: #fff;

  @include mq-pc {
    padding-bottom: 130px;
  }

  .col {
    &:first-child {
      @include mq-pc {
        width: 74%;
        padding-right: 3%;
      }

      @include mq-sp {
        margin-bottom: 60px;
      }
      
    }

    &:last-child {
      @include mq-pc {
        width: 26%;
        padding-left: 3%;
      }
    }
  }
  .ttl-top{
    margin-bottom: 0;
    @include mq-pc{
      margin-bottom: 28px;
    }
  }
  .box-news-wrap {
    .box-news {
      padding-bottom: 0;
      padding-top: 0;
      @include mq-sp {
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 0;
      }

      .item {
        @include mq-pc {
          padding-top: 15px;
          padding-bottom: 15px;
          border: none;
        }
      }

      .date {
        font-weight: normal;
      }

      .category {
        background-color: #f2f5f8;
        @include mq-pc{
          margin-left: 0;

          &:hover {
            background-color: #385199;
            a {
              color: #f2f5f8;
            }
          }
        }
      }
    }
  }
}

/* sec-top-pickup
---------------------------------------------------------- */
.sec-top-pickup {
  position: relative;

  .navi-panel {
    content: "";
    position: absolute;
    top: 158px;
    right: 0;
    width: 36%;
    height: 100%;
    background-color: #262d40;

    @include mq-sp {
      right: inherit;
      left: 0;
      width: 100%;
    }
  }

  .link-group {
    position: relative;
    z-index: 1;
  }
}

/* slider-pickup
---------------------------------------------------------- */
.slider-pickup-wrap {
  position: relative;
  z-index: 1;

  @include mq-pc {
    display: flex;
  }
}

.slider-pickup {
  @include mq-pc {
    position: relative;
    width: 69%;

    display: flex; // modified l to r
    justify-content: flex-end; // modified l to r

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      z-index: 1;
      height: 100%;
      padding-left: 47%;
      background: url('/assets/images/top/img_shadow_white.png') 0 0 no-repeat;
      background-size: auto 100%;
      pointer-events: none;
    }
  }

  .list-slider {
    
    @include mq-pc { // modified l to r
      width: 416px;
      margin: 0 -16px;
    }

    .item {
      outline: none;
      padding: 0 10px;
      transform: translate3d(0,0,0);
      backface-visibility: hidden;
  
      @include mq-pc {
        width: 416px;
        padding: 0 16px;
      }
    }

    // reset slick
    .slick-list {
      @include mq-pc {
        overflow: visible;
      }
    }

    .slick-slide {
      transform: translate3d(0,0,0);
      backface-visibility: hidden;
    }
    
  }

  a {
    display: block;
    outline: none;
    transform: translateZ(0);
    backface-visibility: hidden;
    @include is-opacity;
  }

  .card {
    background-color: #fff;
    box-shadow: 0 10px 25px 0px rgba(0,0,0,0.05);

    .image {
      position: relative;

      img {
        position: relative;
        width: 100%;
        transform: translateZ(0);
        backface-visibility: hidden;
      }
    }

    .detail {
      padding: 35px 40px;
      min-height: 160px;
      text-align: left;

      @include mq-sp {
        padding: 20px;
      }

      .title {
        margin-bottom: 10px;
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.3;

        @include mq-sp {
          font-size: 1.9rem;
        }
      }

      .text {
        font-size: 1.4rem;
        line-height: 1.78;

        @include mq-sp {
          font-size: 1.3rem;
        }
      }
    }
  }

  // slick-dots
  .slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    li {
      padding: 0 5px;
      
      button {
        width: 8px;
        height: 8px;
        border: 1px solid #ccc;
        border-radius: 50%;
        appearance: none;
        text-indent: -9999px;
        overflow: hidden;
      }

      // active
      &.slick-active {
        button {
          background-color: #ccc;
        }
      }
    }
  }
}

.slider-pickup-nav {
  position: relative;
  z-index: 1;
  padding-top: 35px;

  @include mq-pc {
    width: 32%;
    padding-left: 6.25%;
    background-color: #262d40;
  }

  @include mq-sp {
    visibility: hidden;
    height: 0;
    padding-top: 0;
  }

  .list-nav {
    position: relative;
    height: 210px;
    overflow: hidden;

    .item {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2.2;
      color: #fff;
      cursor: pointer;
      user-select: none;
      transition: all .3s;

      &:first-child, &:last-child {
        opacity: 0;
      }

      &:nth-child(2) {
        transform: translateY(30px);
      }

      &:nth-child(3) {
        transform: translateY(60px);
      }

      &:nth-child(4) {
        transform: translateY(90px);
      }

      &:nth-child(5) {
        transform: translateY(120px);
      }

      &:nth-child(6) {
        transform: translateY(150px);
      }

      &:nth-child(7) {
        transform: translateY(180px);
      }

      &:nth-child(2),
      &:nth-child(6) {
        opacity: .1;
      }

      &:nth-child(3),
      &:nth-child(5) {
        opacity: .3;
      }
    }
  }
}

.slider-pickup-arrows {
  display: flex;

  .slick-arrow {
    width: 60px;
    height: 60px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    text-indent: -999px;
    overflow: hidden;
    outline: none;
    @include is-opacity;

    &.slick-prev {
      margin-right: 30px;
      background-image: url('/assets/images/top/arrow_prev.png');
    }

    &.slick-next {
      background-image: url('/assets/images/top/arrow_next.png');
    }
  }
  @include mq-sp {
    display: none;
  }
}